import React, { useEffect, useState } from 'react';
import { useContractsContext } from '../../../context/contractsContext/ContractsContext';

import PageTitle from '../../../components/pageContent/PageTitle';

import AddIcon from '@mui/icons-material/Add';

import { useNavigate } from 'react-router-dom';

import PermissionAlert from '../../../components/pageContent/PermissionAlert';
import { useAppContext } from '../../../context/appContext/appContext';

import { useData } from '../../../utils/useData';
import Loading from '../../../components/pageContent/Loading';
import SnackbarAfterAction from '../../../components/uiComponents/SnackbarAfterAction';
import DeleteDialog from '../../../components/pageContent/TableView/DeleteDialog';
import { AlertColor, TablePagination } from '@mui/material';
import { useUpdate } from '../../../utils/useUpdate';
import TableView from '../../../components/pageContent/TableView/TableView';

import { dict } from '../../../translate/translate-dictionary';
import { useMyContractsContext } from '../../../context/myContractsContext/MyContractsContext';
import FilterContract from './FilterContract';
import TitleTableRow from './dataTable/TitleTableRow';
import DataTableRows from './dataTable/DataTableRows';

type Props = {};

const MyContractsList = (props: Props) => {
	const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
	const [msgSnackbar, setMsgSnackbar] = useState<string>('');
	const [severitySnackbar, setSeveritySnackbar] = useState<AlertColor>('success');

	const { state, setRowsPerPage } = useAppContext();

	const { state: stateContract, setPage, setInitContracts, refetchContracts } = useMyContractsContext();

	const navigate = useNavigate();

	const lang = state.lang;

	// ____________ SNACKBAR ______________________
	const handleCloseSnackbar = () => {
		setOpenSnackbar(false);
	};

	// ____________ SET PAGE ______________________
	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	// ____________ SET ROWS PER PAGE ______________________
	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	// ____________ REPORT  ______________________
	const handleReport = (id: number): void => {
		navigate(`/my-contracts/report/${id}/driver`);
	};

	// ____________ NASTAVENI INICIALIZACE NEBO PO NAVRATU REFETCH ______________________
	useEffect(() => {
		if (stateContract.isInit === false) {
			setInitContracts();
		} else {
			refetchContracts();
		}
	}, [setInitContracts, refetchContracts, stateContract.isInit]);

	// _______________________ VYSTUP ________________________

	if (!state.user?.roles.includes('user')) {
		return <PermissionAlert />;
	}

	return (
		<>
			<Loading isLoading={stateContract.isLoading} />

			<SnackbarAfterAction
				severity={severitySnackbar}
				openSnackbar={openSnackbar}
				onClose={handleCloseSnackbar}
				msgSnackbar={msgSnackbar}
			/>

			<PageTitle title="Moje zakázky" />

			<FilterContract />

			<TableView
				titleRow={<TitleTableRow />}
				dataRow={
					<DataTableRows
						contracts={stateContract.contracts}
						onReport={handleReport}
						rowsPerPage={state.rowsPerPage}
						page={stateContract.page}
					/>
				}
				tablePagination={
					<TablePagination
						count={stateContract.contracts ? stateContract.contracts.length : 0}
						component="div"
						rowsPerPage={state.rowsPerPage}
						page={stateContract.page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						rowsPerPageOptions={[5, 10, 15]}
						labelRowsPerPage={dict['ROWS_PER_PAGE'][lang]}
					/>
				}
				colspan={5}
			/>
		</>
	);
};

export default MyContractsList;
