import { Box, useTheme } from '@mui/material';
import React from 'react';

import MainRoutes from './routes/MainRoutes';

function App() {
	const theme = useTheme();

	return (
		<Box
			sx={{
				display: 'flex',

				backgroundColor: '#fff',
			}}>
			<Box
				sx={{
					display: 'flex',
					maxWidth: theme.breakpoints.values.lg,
					marginX: 'auto',
					width: '100%',

					// flexDirection: 'row',
					backgroundColor: '#eee',
				}}>
				<MainRoutes />
			</Box>
		</Box>
	);
}

export default App;
