import React, { useEffect, useState } from 'react';
import { AlertColor, Button, Card, CardActions, CardContent, IconButton, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';

import ModeIcon from '@mui/icons-material/Mode';
import LockResetIcon from '@mui/icons-material/LockReset';
import FormModal from '../../components/modal/FormModal';
import Loading from '../../components/pageContent/Loading';
import PageTitle from '../../components/pageContent/PageTitle';
import PermissionAlert from '../../components/pageContent/PermissionAlert';
import SnackbarAfterAction from '../../components/uiComponents/SnackbarAfterAction';
import { useAppContext } from '../../context/appContext/appContext';

import { useUpdate } from '../../utils/useUpdate';
import { MyPasswordFormInputs, UserDB, UserProfileFormInputs } from '../users/types';

import UserProfileForm from './UserProfileForm';
import MyPasswordForm from './MyPasswordForm';
import { setLocalStorage } from '../../context/appContext/handle-local-storage';
import { useData } from '../../utils/useData';

import HideImageIcon from '@mui/icons-material/HideImage';
import ImageIcon from '@mui/icons-material/Image';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

import customFetcher from '../../utils/fetchInstance';
import DeleteDialog from '../../components/pageContent/TableView/DeleteDialog';
import WebCamModal from '../../components/modal/WebCamModal';

type Props = {};

const MyAccount = (props: Props) => {
	const [webcamModal, setWebcamModal] = useState<boolean>(false);
	const [isLoadingImage, setIsLoadingImage] = useState<boolean>(false);
	const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
	const [idDelete, setIdDelete] = React.useState<number | null>(null);

	const [openModal, setOpenModal] = useState<boolean>(false);
	const [userData, setUserData] = useState<UserDB | null>(null);

	const [avatarUrl, setAvatarUrl] = useState<string | undefined>(undefined);

	const [isEditProfile, setIsEditProfile] = useState<boolean>(false);
	const [isEditMyPassword, setIsEditMyPassword] = useState<boolean>(false);

	const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
	const [msgSnackbar, setMsgSnackbar] = useState<string>('');
	const [severitySnackbar, setSeveritySnackbar] = useState<AlertColor>('success');

	// state pro usera a lang
	const { state, getCurrentUser } = useAppContext();
	const lang = state.lang;

	// ____________ USE DATA - načtení users ______________________
	const {
		isLoading,
		data: userProfile,
		refetch,
	} = useData<void, { user: UserDB }>(`/users/${state.user?.id}`, data => setUserData(data.user));

	// ____________ USE DATA - načtení users ______________________
	const {
		isLoading: isLoadingEdit,
		data: dataEditing,
		update: updateUser,
		error: updateError,
	} = useUpdate<{ status: string; error?: string; refreshToken?: string; user?: UserDB }>('');

	// ____________ SET EDIT ______________________
	const handleEditProfile = async (): Promise<void> => {
		await refetch();
		setIsEditProfile(true);
		setOpenModal(true);
	};
	const handleEditMyPassword = async (): Promise<void> => {
		setIsEditMyPassword(true);
		setOpenModal(true);
	};

	// ____________ SUBMIT FORM MODAL ______________________
	const handleFormSubmit = async (data: UserProfileFormInputs) => {
		if (state.user?.id) {
			const response = await updateUser(
				{
					firstName: data.firstName,
					lastName: data.lastName,
					email: data.email,
					phone: data.phone,
					street: data.street,
					city: data.city,
					zipCode: data.zipCode,
				},
				'PATCH',
				`/users/update-me`
			);

			if (!response) {
				return 'ERROR_NETWORK';
			}

			if (response?.status === 'error') {
				return response.error;
			}
		}

		await refetch();
		await getCurrentUser();

		setOpenModal(false);

		setIsEditProfile(false);
		setIsEditMyPassword(false);

		return undefined;
	};

	const handleFormMyPasswordSubmit = async (data: MyPasswordFormInputs) => {
		if (state.user?.id) {
			const response = await updateUser(
				{
					oldPassword: data.oldPassword,
					password: data.password,
					passwordConfirm: data.passwordConfirm,
				},
				'PATCH',
				`/auth/update-me-password`
			);

			if (!response) {
				return 'ERROR_NETWORK';
			}

			if (response?.status === 'error') {
				return response.error;
			}

			if (response?.status === 'success') {
				const refreshToken = response.refreshToken;
				if (refreshToken) setLocalStorage('refreshToken', refreshToken);
			}
		}

		setOpenModal(false);
		setIsEditProfile(false);
		setIsEditMyPassword(false);
		return undefined;
	};

	const handleCloseModal = (): void => {
		setOpenModal(false);
		setIsEditProfile(false);
		setIsEditMyPassword(false);
	};

	const handleCloseWebCamModal = (): void => {
		setWebcamModal(false);
	};

	// ____________ SNACKBAR ______________________
	const handleCloseSnackbar = () => {
		setOpenSnackbar(false);
	};

	const handleImportImage = async (event: any) => {
		setIsLoadingImage(true);
		if (event.target.files[0]) {
			const form = new FormData();
			form.append('idUser', userData ? userData?.id.toString() : '0');
			form.append('avatar', event.target.files[0]);

			const response = await customFetcher(`/users/avatar-upload`, 'POSTIMAGE', form);
			if (response) {
				if (response.data.user.imageUrl) {
					setAvatarUrl(`${process.env.REACT_APP_BASE_IMAGE_URL}/${response.data.user.imageUrl}`);
				}
			}
		}
		setIsLoadingImage(false);

		return false;
	};

	const handleImportCameraImage = async (image: string) => {
		setIsLoadingImage(true);

		if (image) {
			const form = new FormData();
			form.append('idUser', userData ? userData?.id.toString() : '0');
			form.append('avatar', image);

			const response = await customFetcher(`/users/avatar-camera-upload`, 'POST', {
				idUser: userData?.id,
				avatar: image,
			});

			if (response) {
				if (response.data.user.imageUrl) {
					setAvatarUrl(`${process.env.REACT_APP_BASE_IMAGE_URL}/${response.data.user.imageUrl}`);
				}
			}
		}
		setWebcamModal(false);
		setIsLoadingImage(false);

		return false;
	};

	// ____________ DELETE CONFIRM ______________________
	const handleDeleteConfirm = (id: number, name?: string) => {
		setIdDelete(id);
		setOpenDeleteDialog(true);
	};

	// ____________ DELETE CLOSE DIALOG ______________________
	const handleCloseDeleteDialog = () => {
		setIdDelete(null);
		setOpenDeleteDialog(false);
	};

	const handleDeleteImage = async (): Promise<void> => {
		setIsLoadingImage(true);

		const response = await updateUser(null, 'DELETE', `/users/delete-avatar/${idDelete}`);

		setIdDelete(null);
		handleCloseDeleteDialog();

		if (response?.status === 'success') {
			setAvatarUrl(undefined);
		}

		setIsLoadingImage(false);
	};

	useEffect(() => {
		if (userData?.imageUrl) {
			setAvatarUrl(`${process.env.REACT_APP_BASE_IMAGE_URL}/${userData.imageUrl}`);
		}
	}, [userData?.imageUrl]);

	// _______________________ VYSTUP ________________________

	if (!state.user?.roles.includes('user')) {
		return <PermissionAlert />;
	}

	return (
		<>
			<Loading isLoading={isLoading || isLoadingEdit || isLoadingImage} />

			<DeleteDialog
				onOpen={openDeleteDialog}
				handleClose={handleCloseDeleteDialog}
				onDelete={handleDeleteImage}
				recordType={'avatar'}
			/>

			<SnackbarAfterAction
				severity={severitySnackbar}
				openSnackbar={openSnackbar}
				onClose={handleCloseSnackbar}
				msgSnackbar={msgSnackbar}
			/>

			<FormModal
				modalTitle={isEditProfile ? 'Editace profilu uživatele' : isEditMyPassword ? 'Změna hesla' : ''}
				openModal={openModal}
				onClose={handleCloseModal}>
				<>
					{isEditProfile && !isEditMyPassword ? (
						<UserProfileForm
							editData={userData}
							onFormSubmit={handleFormSubmit}
							onClose={handleCloseModal}
						/>
					) : (
						<></>
					)}
					{isEditMyPassword && !isEditProfile ? (
						<MyPasswordForm onFormSubmit={handleFormMyPasswordSubmit} onClose={handleCloseModal} />
					) : (
						<></>
					)}
				</>
			</FormModal>

			<WebCamModal
				modalTitle="Avatar"
				onClose={handleCloseWebCamModal}
				openModal={webcamModal}
				onImportImage={handleImportCameraImage}
			/>

			<PageTitle title="Profil uživatele" />
			<Card>
				<CardContent>
					<Avatar
						variant="rounded"
						alt={`${userData?.firstName} ${userData?.lastName}`}
						src={avatarUrl}
						sx={{ width: 150, height: 150, fontSize: 30 }}>
						{`${userData?.firstName[0]}${userData?.lastName[0]}`}
					</Avatar>
					{avatarUrl && (
						<IconButton
							onClick={() => userData?.id && handleDeleteConfirm(userData.id)}
							color="error"
							size="large"
							component="label"
							sx={{
								border: 0,
								borderRadius: 1,
								p: 0.5,
								marginRight: 1,
								marginTop: 1,
							}}>
							<HideImageIcon fontSize="large" />
						</IconButton>
					)}
					<IconButton
						color="primary"
						size="large"
						component="label"
						sx={{
							border: 0,
							borderRadius: 1,
							p: 0.5,
							marginRight: 1,
							marginTop: 1,
						}}>
						<input name={'avatar'} hidden accept="image/*" type="file" onChange={handleImportImage} />
						<ImageIcon fontSize="large" />
					</IconButton>
					<IconButton
						onClick={() => setWebcamModal(true)}
						size="large"
						color="primary"
						component="label"
						sx={{
							border: 0,
							borderRadius: 1,
							p: 0.5,
							marginRight: 1,
							marginTop: 1,
						}}>
						{/* <input name={'avatarCam'} hidden accept="image/*" type="file" onChange={handleImportImage} /> */}
						<PhotoCameraIcon fontSize="large" />
					</IconButton>
				</CardContent>
				<CardContent>
					<Typography variant="h5" color="text.secondary" gutterBottom>
						{`${userData?.firstName} ${userData?.lastName}`}
					</Typography>

					{userData?.street && (
						<Typography variant="subtitle1" component="div">
							{userData.street}
						</Typography>
					)}

					{(userData?.zipCode || userData?.city) && (
						<Typography variant="subtitle1" component="div">
							{userData.zipCode && `${userData.zipCode} `}
							{userData.city && userData.city}
						</Typography>
					)}
					<Typography variant="subtitle1" component="div">
						{userData?.email}
					</Typography>

					{userData?.phone && (
						<Typography variant="subtitle1" component="div">
							{userData.phone}
						</Typography>
					)}
				</CardContent>
				<CardActions sx={{ flexFlow: { xs: 'column', sm: 'row' }, alignItems: { xs: 'baseline' } }}>
					<Button
						size="small"
						startIcon={<ModeIcon />}
						sx={{ mr: { xs: 0, sm: 2 }, whiteSpace: 'nowrap' }}
						onClick={handleEditProfile}>
						Editovat údaje
					</Button>
					<Button
						size="small"
						startIcon={<LockResetIcon />}
						sx={{ whiteSpace: 'nowrap' }}
						onClick={handleEditMyPassword}>
						Změnit heslo
					</Button>
				</CardActions>
			</Card>
		</>
	);
};

export default MyAccount;
