import { log } from 'console';
import { refreshToken } from './refreshToken';
import { getVersionStorage, setLocalStorage } from '../context/appContext/handle-local-storage';

export const originalRequest = async (url: string, method: string, payload: any = '', config: any) => {
	url = `${process.env.REACT_APP_BASE_API_URL}${url}`;

	if (method === 'GET') {
		config = {
			...config,
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
			credentials: 'include',
		};
	} else if (method === 'POSTIMAGE') {
		config = {
			...config,
			method: 'POST',

			// headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: payload,
			credentials: 'include',
		};
	} else if (method === 'PDF') {
		config = {
			...config,
			method: 'GET',
			headers: {
				'Content-Type': 'application/pdf',
			},

			credentials: 'include',
		};
	} else if (method === 'DELETE') {
		config = {
			...config,
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
			},
			credentials: 'include',
		};
	} else {
		config = {
			...config,
			method: method,
			headers: {
				'Content-Type': 'application/json',
			},
			credentials: 'include',
			body: JSON.stringify(payload),
		};
	}

	let response = await fetch(url, config);

	let data;
	try {
		if (method === 'PDF') {
		} else if (method === 'POSTIMAGE') {
			data = response && (await response.json());
		} else {
			data = response && (await response.json());
		}
	} catch (error) {}

	// _______________ LOG REQUESTING ___________________________________
	if (process.env.REACT_APP_ENV === 'development') console.log('REQUESTING:', data);

	return { response, data };
};

const customFetcher = async (url: string, method: string = 'GET', payload: any = {}, config = {}) => {
	await getVersion();
	let { response, data } = await originalRequest(url, method, payload, config);

	if (response.status === 401) {
		await refreshToken();

		let newResponse = await originalRequest(url, method, payload, config);
		response = newResponse.response;
		data = newResponse.data;
		return { response, data };
	}

	return { response, data };
};

const getVersion = async () => {
	const response = await originalRequest('/version', 'GET', '', '');
	const versionBackend = response.data.version;
	// console.log('v', versionBackend);

	const versionStorage = getVersionStorage();

	if (versionBackend && versionStorage !== versionBackend) {
		// console.log(version);
		setLocalStorage('version', versionBackend);
		window.location.reload();
	}
};

export default customFetcher;
