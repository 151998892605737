import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';

type Props = {
	onOpen: boolean;
	typeDialog: string;
	handleClose: () => void;
	onDocumentReport: () => void;
};

const DocumentReportDialog = ({ onOpen, handleClose, onDocumentReport, typeDialog }: Props) => {
	return (
		<Dialog
			open={onOpen}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description">
			<DialogTitle>
				{typeDialog === 'worker' && `Vyúčtování pracovníků.`}
				{typeDialog === 'car' && `Vyúčtování aut a řidičů.`}
			</DialogTitle>
			<DialogContent>
				{typeDialog === 'worker' &&
					`Bude provedeno vyúčtování veškerých uzavřených a nevyúčtovaných výkazů. Tyto výkazy se týkají všech pracovníků a bez ohledu na zvolený datum ve filtru.`}
				{typeDialog === 'car' &&
					`Bude provedeno vyúčtování veškerých uzavřených a nevyúčtovaných výkazů. Tyto výkazy se týkají všech aut a řidičů, bez ohledu na zvolený datum ve filtru.`}
			</DialogContent>

			<DialogActions>
				<Button onClick={handleClose}>Zrušit</Button>
				<Button onClick={onDocumentReport} autoFocus>
					Ano, vyúčtovat
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DocumentReportDialog;
