import React from 'react';
import {
	Drawer,
	IconButton,
	styled,
	useMediaQuery,
	useTheme,
	Divider,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	autocompleteClasses,
	Box,
} from '@mui/material';

// ikony
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

// konstanty
import { drawerSpacing } from '../../../constants';
import MenuList from './MenuList';

type Props = {
	drawerOpen: boolean;
	handleDrawerToggle: () => void;
	drawerMinOpen: boolean;
	handleDrawerMinToggle: () => void;
	onClickSideBar: (nav: string) => void;
};

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

const Sidebar = ({ drawerMinOpen, handleDrawerMinToggle, drawerOpen, handleDrawerToggle, onClickSideBar }: Props) => {
	const theme = useTheme();
	const drawerWidth = theme.spacing(drawerSpacing);
	const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));

	return (
		<Box
			component="nav"
			sx={{
				position: 'fixed',
				flexShrink: 0,
				width: drawerWidth,
				zIndex: 100,
			}}>
			<Drawer
				open={drawerOpen}
				onClose={handleDrawerToggle}
				PaperProps={{ style: { position: 'absolute' } }}
				sx={{
					'& .MuiDrawer-paper': {
						pb: '100px',
						color: 'white',
						// backgroundColor: '#151415',
						backgroundColor: theme.palette.grey[700],

						// backgroundImage: 'linear-gradient(71deg, #151415 0%, #81367b 46%, #37312b 100%)',

						width: drawerWidth,
						boxSizing: 'border-box',
						whiteSpace: 'nowrap',
						flexShrink: 0,
						height: '100vh',
						overflowY: 'auto',
						top: '63px',

						// pri otevreni
						transition: theme.transitions.create('width', {
							easing: theme.transitions.easing.easeIn,
							duration: theme.transitions.duration.enteringScreen,
						}),

						// pri zavreni = mini drawer
						[theme.breakpoints.up('sm')]: !drawerMinOpen && {
							width: `calc(${theme.spacing(8)} + 1px)`,
							overflowX: 'hidden',
							top: '63px',
							transition: theme.transitions.create('width', {
								easing: theme.transitions.easing.sharp,
								duration: theme.transitions.duration.leavingScreen,
							}),
						},

						// mobilni zarizeni
						[theme.breakpoints.down('sm')]: !drawerMinOpen && {
							width: drawerWidth,
							top: '0',
						},
					},
				}}
				variant={matchUpSm ? 'persistent' : 'temporary'}
				anchor="left">
				<Divider />

				<MenuList drawerMinOpen={drawerMinOpen} onClickSideBar={onClickSideBar} />
			</Drawer>
		</Box>
	);
};

export default Sidebar;
