import React from 'react';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';

const PermissionAlert = () => {
	return (
		<Box mb={2} component="header">
			<Box
				sx={{
					padding: '1.5em 2em',
					borderRadius: 2,
					backgroundColor: 'white',
					alignItems: 'center',
				}}>
				<Alert severity="error">Pro tuto stránku nemáte oprávnění</Alert>
			</Box>
		</Box>
	);
};

export default PermissionAlert;
