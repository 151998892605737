import React, { useEffect, useState } from 'react';

import { CarCategoryDB, CarDB, CarFormInputs } from '../types';

import { useData } from '../../../utils/useData';
import { useUpdate } from '../../../utils/useUpdate';
import { useAppContext } from '../../../context/appContext/appContext';

import PermissionAlert from '../../../components/pageContent/PermissionAlert';

import PageTitle from '../../../components/pageContent/PageTitle';

import PageTabNavigator from '../../../components/pageContent/PageTabNavigator';
import tabsCars from '../tabsCars';

import TableView from '../../../components/pageContent/TableView/TableView';
import TitleTableRow from './dataTable/TitleTableRow';
import DataTableRows from './dataTable/DataTableRows';
import FormModal from '../../../components/modal/FormModal';
import CarForm from '../edit/CarForm';
import SnackbarAfterAction from '../../../components/uiComponents/SnackbarAfterAction';
import Loading from '../../../components/pageContent/Loading';

import DeleteDialog from '../../../components/pageContent/TableView/DeleteDialog';

import AddIcon from '@mui/icons-material/Add';
import { AlertColor, SelectChangeEvent, TablePagination } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useCarsContext } from '../../../context/carsContext/CarsContext';
import { dict } from '../../../translate/translate-dictionary';
import FilterCar from './FilterCar';

type Props = {};

const CarsList = (props: Props) => {
	const [openModal, setOpenModal] = useState<boolean>(false);

	const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
	const [msgSnackbar, setMsgSnackbar] = useState<string>('');
	const [severitySnackbar, setSeveritySnackbar] = useState<AlertColor>('success');

	const [editID, setEditID] = useState<number | null>(null);
	const [editData, setEditData] = useState<CarDB | null>(null);

	// const [categoryFilter, setCategoryFilter] = useState<string>('');
	// const [categorySelect, setCategorySelect] = useState<CarCategoryDB[] | null>(null);

	const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
	const [idDelete, setIdDelete] = React.useState<number | null>(null);
	const [nameDelete, setNameDelete] = React.useState<string>('');

	const { state: stateCar, setPage, setInitCars, refetchCars, removeIsInit } = useCarsContext();
	const { state, setRowsPerPage } = useAppContext();

	const navigate = useNavigate();
	const lang = state.lang;

	// ____________ USE DATA - nacteni categoryCars ______________________
	// const { isLoading: isLoadingCat, data: carsCategory } = useData<CarCategoryDB[], { carsCategory: CarCategoryDB[] }>(
	// 	'/dials/cars/category',
	// 	data => {
	// 		setCategorySelect(data.carsCategory);
	// 		return data.carsCategory;
	// 	}
	// );

	// ____________ USE UPDATE - ostatni metody ______________________
	const {
		isLoading: isUpdateLoading,
		data: dataEditing,
		update: updateCar,
		error: updateError,
	} = useUpdate<{ status: string; error?: string; car?: CarDB }>('');

	// ____________ SET ADD ______________________
	const handleAdd = (): void => {
		setOpenModal(true);
		setEditID(null);
	};

	// ____________ SET EDIT ______________________
	const handleEdit = async (id: number): Promise<void> => {
		const response = await updateCar(null, 'GET', `/dials/cars/${id}`);

		if (!response) {
			return;
		}

		if (response?.status === 'success') {
			if (response.car) setEditData(response.car);
		}

		setOpenModal(true);
		setEditID(id);
	};

	// ____________ DELETE ______________________
	const handleDelete = async (): Promise<void> => {
		const response = await updateCar(null, 'DELETE', `/dials/cars/${idDelete}`);
		if (!response) {
			return;
		}

		if (response.status === 'error') {
			if (response.error === 'RECORD_CANNOT_BE_DELETED') {
				setMsgSnackbar(`Automobil "${nameDelete}" nelze smazat!`);
				setSeveritySnackbar('error');
				setOpenSnackbar(true);
			}
		} else {
			removeIsInit();
			setMsgSnackbar(`Automobil "${nameDelete}" byl vymazán.`);
			setSeveritySnackbar('success');
			setOpenSnackbar(true);
		}

		setIdDelete(null);
		setNameDelete('');
		handleCloseDeleteDialog();
	};

	// ____________ DELETE CONFIRM ______________________
	const handleDeleteConfirm = (id: number, name: string) => {
		setIdDelete(id);
		setNameDelete(name);
		setOpenDeleteDialog(true);
	};

	// ____________ DELETE CLOSE DIALOG ______________________
	const handleCloseDeleteDialog = () => {
		setIdDelete(null);
		setOpenDeleteDialog(false);
	};

	// ____________ IS ACTIVE ______________________
	const handleActive = async (id: number, name: string, isActive: boolean) => {
		const response = await updateCar({ isActive: isActive }, 'POST', `/dials/cars/set-active/${id}`);
		if (!response) {
			return;
		}

		refetchCars();

		setMsgSnackbar(`Automobil "${name}" byl ${isActive ? 'pozastaven' : 'aktivován'}.`);
		setSeveritySnackbar(isActive ? 'warning' : 'success');
		setOpenSnackbar(true);
	};

	// ____________ SUBMIT FORM MODAL ______________________
	const handleFormSubmit = async (data: CarFormInputs) => {
		if (editID) {
			const response = await updateCar(
				{ name: data.name, spz: data.spz, carCategoryId: data.carCategoryId },
				'PATCH',
				`/dials/cars/${editID}`
			);

			if (!response) {
				return 'ERROR_NETWORK';
			}

			if (response?.status === 'error') {
				return response.error;
			}
		} else {
			const response = await updateCar(
				{ name: data.name, spz: data.spz, carCategoryId: data.carCategoryId },
				'POST',
				`/dials/cars`
			);

			if (response?.status === 'error') {
				return response.error;
			}

			if (!response) {
				return 'ERROR_NETWORK';
			}
		}

		editData ? refetchCars() : removeIsInit();

		setOpenModal(false);

		setMsgSnackbar(editData ? `Automobil "${data.name}" byl editován.` : `Automobil "${data.name}" byl přidán.`);
		setSeveritySnackbar('success');
		setOpenSnackbar(true);

		setEditData(null);
		setEditID(null);
		return undefined;
	};

	// ____________ CLOSE MODAL ______________________
	const handleCloseModal = (): void => {
		setOpenModal(false);
		setEditData(null);
		setEditID(null);
		refetchCars();
	};

	// ____________ SNACKBAR ______________________
	const handleCloseSnackbar = () => {
		setOpenSnackbar(false);
	};

	// ____________ SET PAGE ______________________
	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	// ____________ SET ROWS PER PAGE ______________________
	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	// ____________ REPORT CONTRACT ______________________
	const handleReport = (id: number): void => {
		navigate(`/cars/report/${id}`);
	};

	// ____________ NASTAVENI INICIALIZACE NEBO PO NAVRATU REFETCH ______________________
	useEffect(() => {
		if (stateCar.isInit === false) {
			setInitCars();
		} else {
			refetchCars();
		}
	}, [setInitCars, refetchCars, stateCar.isInit]);

	// // ____________ CHANGE FILTER ______________________
	// const handleCategoryChange = (event: SelectChangeEvent) => {
	// 	setCategoryFilter(event.target.value);
	// };

	// _______________________ VYSTUP ________________________

	if (!state.user?.roles.includes('editor')) {
		return <PermissionAlert />;
	}

	return (
		<>
			<Loading isLoading={stateCar.isLoading || stateCar.isLoadingCat || isUpdateLoading} />
			<SnackbarAfterAction
				severity={severitySnackbar}
				openSnackbar={openSnackbar}
				onClose={handleCloseSnackbar}
				msgSnackbar={msgSnackbar}
			/>

			<DeleteDialog onOpen={openDeleteDialog} handleClose={handleCloseDeleteDialog} onDelete={handleDelete} />

			<FormModal
				modalTitle={`${editID ? 'Editace' : 'Přidání'} vozidla`}
				openModal={openModal}
				onClose={handleCloseModal}>
				<CarForm
					editID={editID}
					editData={editData}
					categorySelect={stateCar.carsCategory}
					// categoryFilter={}
					onFormSubmit={handleFormSubmit}
					onClose={handleCloseModal}
				/>
			</FormModal>

			<PageTitle title="Vozidla" buttonText="přidat vozidlo" icon={<AddIcon />} onClickHeaderButton={handleAdd} />

			<FilterCar />

			<TableView
				titleRow={<TitleTableRow />}
				dataRow={
					<DataTableRows
						cars={stateCar.cars}
						onReport={handleReport}
						category={stateCar.carsCategory}
						onEdit={handleEdit}
						onDeleteConfirm={handleDeleteConfirm}
						onActive={handleActive}
						rowsPerPage={state.rowsPerPage}
						page={stateCar.page}
					/>
				}
				tablePagination={
					<TablePagination
						count={stateCar.cars ? stateCar.cars.length : 0}
						component="div"
						rowsPerPage={state.rowsPerPage}
						page={stateCar.page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						rowsPerPageOptions={[5, 10, 15]}
						labelRowsPerPage={dict['ROWS_PER_PAGE'][lang]}
					/>
				}
				colspan={9}
			/>
		</>
	);
};

export default CarsList;
