import { Button, Stack } from '@mui/material';
import React, { useRef, useState, useCallback } from 'react';
import Webcam from 'react-webcam';
import Loading from '../../components/pageContent/Loading';

const videoConstraints = {
	width: 500,
	height: 500,
	facingMode: 'user',
};

type Props = { onClose: () => void; onImportImage: (image: any) => void };

function WebcamCapture({ onClose, onImportImage }: Props) {
	const [isLoading, setIsLoading] = useState(true);

	const webcamRef = useRef<Webcam>(null);

	const handleUserMedia = () => {
		setTimeout(() => setIsLoading(false), 1000);
	};

	const capture = useCallback(() => {
		const imageSrc = webcamRef.current?.getScreenshot();
		if (imageSrc) {
			onImportImage(imageSrc);
		}
	}, [webcamRef]);
	return (
		<>
			<>
				<Loading isLoading={isLoading} />

				<Stack direction="row" justifyContent="center" alignItems="center" spacing={2} mt={3}>
					<Webcam
						onUserMedia={handleUserMedia}
						audio={false}
						width={250}
						height={250}
						ref={webcamRef}
						screenshotFormat="image/jpeg"
						videoConstraints={videoConstraints}
					/>
				</Stack>

				<Stack direction="row" justifyContent="center" alignItems="center" spacing={2} mt={3}>
					<Button variant="contained" size="large" color="inherit" onClick={onClose}>
						zrušit
					</Button>

					<Button variant="contained" type="submit" size="large" onClick={capture}>
						uložit
					</Button>
				</Stack>
			</>
		</>
	);
}

export default WebcamCapture;
