import { AlertColor, SelectChangeEvent } from '@mui/material';
import React, { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import {
	createSearchParams,
	useLocation,
	useNavigate,
	useOutletContext,
	useParams,
	useSearchParams,
} from 'react-router-dom';
import FormModal from '../../../../components/modal/FormModal';
import Loading from '../../../../components/pageContent/Loading';
import DeleteDialog from '../../../../components/pageContent/TableView/DeleteDialog';
import SnackbarAfterAction from '../../../../components/uiComponents/SnackbarAfterAction';
import { useData } from '../../../../utils/useData';
import { useUpdate } from '../../../../utils/useUpdate';
import { ContractUserDB, ReportContractWorkerFormInputs, ReportToWorkerDB, ReportWorkerDetail } from '../types';
import ShowReportWorker from './dataTable/ShowReportWorker';

import FormReportWorker from './FormReportWorker';
import SelectWorker from './SelectWorker';
import FilterReport from '../../../../components/pageContent/FilterReport';
import DocumentReportDialog from '../../../../components/pageContent/DocumentReportDialog';

type Props = {};

const ReportWorker = (props: Props) => {
	const [openModal, setOpenModal] = useState<boolean>(false);

	const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
	const [msgSnackbar, setMsgSnackbar] = useState<string>('');
	const [severitySnackbar, setSeveritySnackbar] = useState<AlertColor>('success');

	const [editID, setEditID] = useState<number | null>(null);
	const [editData, setEditData] = useState<any | null>(null);
	const [editUser, setEditUser] = useState<ContractUserDB | undefined>();

	const [documentReportDialog, setDocumentReportDialog] = React.useState(false);

	const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
	const [idDelete, setIdDelete] = React.useState<number | null>(null);
	const [nameDelete, setNameDelete] = React.useState<string>('');

	const [contractUsers, setContractUsers] = useState<ContractUserDB[] | null>(null);

	const status: string = useOutletContext();

	const { contractId } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();

	// ____________ ZISKANI PARAMETRU Z URL ______________________
	const carId = searchParams.get('carId')! ? searchParams.get('carId')! : '';
	const userId = searchParams.get('userId')! ? searchParams.get('userId')! : '';

	const filterStatus = searchParams.get('filterStatus')! ? searchParams.get('filterStatus')! : '';

	const dateFrom = searchParams.get('dateFrom')!
		? dayjs(searchParams.get('dateFrom')!)
		: dayjs(new Date().setDate(new Date().getDate() - 30));
	const dateTo = searchParams.get('dateTo')!
		? dayjs(searchParams.get('dateTo')!)
		: dayjs(new Date().setDate(new Date().getDate()));

	const navigate = useNavigate();
	const location = useLocation();

	// ____________ SKLADANI PARAMETRU PRO DOTAZ DO DB ______________________
	const params: string[] = [];

	dateFrom ? params.push(`dateFrom=${dateFrom.format('YYYY-MM-DD')}`) : params.push(`dateFrom=`);
	dateTo ? params.push(`dateTo=${dateTo.format('YYYY-MM-DD')}`) : params.push(`dateTo=`);
	filterStatus ? params.push(`status=${filterStatus}`) : params.push(`status=`);

	// ____________ USE DATA - NACTENI CONTRACT USERS ______________________
	const {
		isLoading: isLoading,
		data: contractUsersData,
		refetch,
	} = useData<ContractUserDB[], { contractUsers: ContractUserDB[] }>(
		`/contracts/contract-to-user/${contractId}`,
		data => {
			setContractUsers(data.contractUsers);
			return data.contractUsers;
		}
	);

	// ____________ USE DATA - NACTENI REPORTU PRO USERS  ______________________
	const {
		isLoading: isLoadingReport,
		data: reportsWorker,
		refetch: refetchReportsWorker,
	} = useData<ReportToWorkerDB[], { reportsWorker: ReportToWorkerDB[] }>(
		`/contracts/report-worker-editor/${contractId}/${userId ? userId : 0}?${params.join('&')}`,
		data => data.reportsWorker
	);

	// ____________ USE DATA - NACTENI CLOSED REPORTU PRO USERS  ______________________
	const {
		isLoading: isLoadingClosedReport,
		data: reportsClosedWorker,
		refetch: refetchReportsClosedWorker,
	} = useData<ReportToWorkerDB[], { reportsWorker: ReportToWorkerDB[] }>(
		`/contracts/report-worker-editor/${contractId}/0?status=closed`,
		data => data.reportsWorker
	);

	// ____________ USE UPDATE - ostatni metody ______________________
	const {
		isLoading: isUpdateLoading,
		data: dataEditing,
		update: updateReport,
		error: updateError,
	} = useUpdate<{ status: string; error?: string; reportWorkerDetail?: ReportWorkerDetail }>('');

	// ____________ HANDLE FILTER DATE ______________________
	const handleFilterDate = (dateFromForm: Dayjs, dateToForm: Dayjs) => {
		if (dateFromForm.diff(dateToForm, 'second') > 0) {
			dateToForm = dateFromForm;
		}

		const dateFromSend = dateFromForm ? dateFromForm.format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD');
		const dateToSend = dateToForm ? dateToForm.format('YYYY-MM-DD') : dateFromForm.format('YYYY-MM-DD');

		navigate({
			pathname: location.pathname,
			search: createSearchParams({
				carId: carId,
				userId: userId,
				filterStatus: filterStatus,
				dateFrom: dateFromSend,
				dateTo: dateToSend,
			}).toString(),
		});
	};

	// ____________ HANDLE FILTER STATUS ______________________
	const handleFilterStatus = (filterStatus: string) => {
		navigate({
			pathname: location.pathname,
			search: createSearchParams({
				carId: carId,
				userId: userId,
				filterStatus: filterStatus,
				dateFrom: dateFrom.format('YYYY-MM-DD'),
				dateTo: dateTo.format('YYYY-MM-DD'),
			}).toString(),
		});
	};

	// ____________ HANDLE USER CHANGE CONTRACT USERS ______________________
	const handleWorkerChange = (event: SelectChangeEvent) => {
		navigate({
			pathname: location.pathname,
			search: createSearchParams({
				carId: carId,
				userId: event.target.value,
				filterStatus: filterStatus,
				dateFrom: dateFrom ? dateFrom.format('YYYY-MM-DD') : '',
				dateTo: dateTo ? dateTo.format('YYYY-MM-DD') : '',
			}).toString(),
		});
	};

	// ____________ SET ADD ______________________
	const handleAdd = async (): Promise<void> => {
		const response = await updateReport(null, 'GET', `/contracts/contract-is-active/${contractId}`);

		if (response?.status === 'error') {
			if (response.error === 'INACTIVE') {
				navigate(0);
				return;
			}
		}
		setOpenModal(true);
		setEditID(null);
	};

	// ____________ SET EDIT ______________________
	const handleEdit = async (id: number): Promise<void> => {
		const response = await updateReport(null, 'GET', `/contracts/report-worker-editor/detail/${id}`);
		if (!response) {
			return;
		}

		if (response?.status === 'error') {
			if (response.error === 'Výkaz již nelze editovat.') {
				refetchReportsWorker();
				refetchReportsClosedWorker();
				return;
			}
		}
		if (response?.status === 'success') {
			if (response.reportWorkerDetail) {
				setEditData(response.reportWorkerDetail);

				setEditUser(contractUsers?.find(item => item.userId === response.reportWorkerDetail?.userId));
			}
		}

		setOpenModal(true);
		setEditID(id);
	};

	// ____________ DELETE ______________________
	const handleDelete = async (): Promise<void> => {
		const response = await updateReport(null, 'DELETE', `/contracts/report-worker-editor/${idDelete}`);
		if (!response) {
			return;
		}
		if (response.status === 'error') {
			if (response.error === 'Výkaz již nelze smazat.') {
				setMsgSnackbar(`Výkaz již nelze smazat.`);
				setSeveritySnackbar('error');
				setOpenSnackbar(true);
				refetchReportsWorker();
				refetchReportsClosedWorker();
			}
			if (response.error === 'RECORD_CANNOT_BE_DELETED') {
				setMsgSnackbar(`"${nameDelete}" nelze smazat!`);
				setSeveritySnackbar('error');
				setOpenSnackbar(true);
			}
		} else {
			setMsgSnackbar(`Smazáno: "${nameDelete}"`);
			setSeveritySnackbar('success');
			setOpenSnackbar(true);
			refetchReportsWorker();
			refetchReportsClosedWorker();
		}
		setIdDelete(null);
		setNameDelete('');
		handleCloseDeleteDialog();
	};

	// ____________ SUBMIT FORM MODAL ______________________
	const handleFormSubmit = async (data: ReportContractWorkerFormInputs) => {
		if (editID) {
			const response = await updateReport(
				{
					contractId: data.contractId,
					userId: data.userId,
					date: data.date,
					timeFrom: data.timeFrom,
					timeTo: data.timeTo,
					workedHours: data.workedHours,
					description: data.description,
					costs: data.costs,
					descriptionCosts: data.descriptionCosts,
				},
				'PATCH',
				`/contracts/report-worker-editor/${editID}`
			);
			if (!response) {
				return 'ERROR_NETWORK';
			}
			if (response?.status === 'error') {
				return response.error;
			}
		} else {
			const response = await updateReport(
				{
					contractId: data.contractId,
					userId: data.userId,
					date: data.date,
					timeFrom: data.timeFrom,
					timeTo: data.timeTo,
					workedHours: data.workedHours,
					description: data.description,
					costs: data.costs,
					descriptionCosts: data.descriptionCosts,
				},
				'POST',
				`/contracts/report-worker-editor`
			);

			if (response?.status === 'error') {
				return response.error;
			}

			if (!response) {
				return 'ERROR_NETWORK';
			}
		}

		setOpenModal(false);

		refetchReportsWorker();
		refetchReportsClosedWorker();

		setMsgSnackbar(editData ? `Výkaz byl editován.` : `Výkaz byl přidán.`);
		setSeveritySnackbar('success');
		setOpenSnackbar(true);

		setEditData(null);
		setEditID(null);

		return undefined;
	};

	// ____________ HANDLE CLOSED CONTRACTS CAR - priprava pro vyuctovani  ______________________
	const handleClose = async (id: number, name: string, isClosed: boolean) => {
		const response = await updateReport(
			{ isClosed: isClosed },
			'POST',
			`/contracts/report-worker-editor/set-closed/${id}`
		);
		if (!response) {
			return;
		}

		// if (response.error) {
		// 	if (response.error === 'Výkaz nelze vytvořit.') {
		// 		setMsgSnackbar(`Výkaz nelze vytvořit.`);
		// 		setSeveritySnackbar('error');
		// 		setOpenSnackbar(true);
		// 		refetchReportsWorker();
		// 		refetchReportsClosedWorker();
		// 	}
		// }

		refetchReportsWorker();
		refetchReportsClosedWorker();

		if (isClosed === true) {
			setMsgSnackbar(`UZAVŘENO: ${name}`);
			setSeveritySnackbar('success');
		} else {
			setMsgSnackbar(`OTEVŘENO: ${name}`);
			setSeveritySnackbar('warning');
		}
		setOpenSnackbar(true);
	};

	// ____________ DOCUMENT REPORT CONFIRM DIALOG ______________________
	const handleDocumentReportConfirm = () => {
		setDocumentReportDialog(true);
		// const userName = userId ? contractUsers?.filter(conUser => conUser.id === parseInt(userId)) : '';
		// const name = userName && `${userName[0].user.lastName} ${userName[0].user.firstName}`;
	};

	// ____________ DOCUMENT REPORT CLOSE DIALOG ______________________
	const handleCloseDocumentReportDialog = () => {
		setDocumentReportDialog(false);
	};

	// ____________ HANDLE DOCUMENT REPORT CREATE ______________________
	const handleDocumentReport = async () => {
		const response = await updateReport(
			{
				contractId: contractId,
			},
			'POST',
			`/contracts/report-document-worker`
		);

		if (response?.status === 'error') {
			if (response.error === 'Výkaz nelze vytvořit.') {
				setMsgSnackbar(`Výkaz nelze vytvořit.`);
				setSeveritySnackbar('error');
				setDocumentReportDialog(false);
				setOpenSnackbar(true);
				refetchReportsWorker();
				refetchReportsClosedWorker();
			}

			return response.error;
		}

		if (!response) {
			// return 'ERROR_NETWORK';
			setMsgSnackbar(`Došlo k neznámé chybě. Zkontrolujte si připojení k internetu.`);
			setSeveritySnackbar('warning');
		}

		await refetchReportsWorker();
		await refetchReportsClosedWorker();

		if (response?.status === 'success') {
			setMsgSnackbar(`Vyúčtování bylo provedeno.`);
			setSeveritySnackbar('success');
		}

		setDocumentReportDialog(false);

		setOpenSnackbar(true);
	};

	// ____________ DELETE CONFIRM ______________________
	const handleDeleteConfirm = (id: number, name: string) => {
		setIdDelete(id);
		setNameDelete(name);
		setOpenDeleteDialog(true);
	};

	// ____________ DELETE CLOSE DIALOG ______________________
	const handleCloseDeleteDialog = () => {
		setIdDelete(null);
		setOpenDeleteDialog(false);
	};

	// ____________ CLOSE MODAL ______________________
	const handleCloseModal = (): void => {
		setOpenModal(false);
		setEditData(null);
		setEditID(null);
		refetchReportsWorker();
		refetchReportsClosedWorker();
	};

	// ____________ SNACKBAR ______________________
	const handleCloseSnackbar = () => {
		setOpenSnackbar(false);
	};

	//  ____________________ SELECT OBJECT CONTRACT USER - overi userId jestli je opravdovy zaznam v DB ______________________________________
	const searchWorker = contractUsers && contractUsers.find(item => item.userId === parseInt(userId));
	const contractUser = userId && contractUsers && searchWorker ? searchWorker : null;

	return (
		<>
			<Loading isLoading={isLoading || isLoadingReport || isLoadingClosedReport} />

			<SnackbarAfterAction
				severity={severitySnackbar}
				openSnackbar={openSnackbar}
				onClose={handleCloseSnackbar}
				msgSnackbar={msgSnackbar}
			/>

			<DocumentReportDialog
				onOpen={documentReportDialog}
				handleClose={handleCloseDocumentReportDialog}
				onDocumentReport={handleDocumentReport}
				typeDialog="worker"
			/>

			<DeleteDialog
				onOpen={openDeleteDialog}
				handleClose={handleCloseDeleteDialog}
				onDelete={handleDelete}
				nameDelete={nameDelete}
			/>
			<FormModal
				modalTitle={`${editID ? 'Editace' : 'Vytvoření'} výkazu`}
				openModal={openModal}
				onClose={handleCloseModal}>
				<FormReportWorker
					editID={editID}
					editData={editData}
					onFormSubmit={handleFormSubmit}
					contractUser={editUser ? editUser : contractUser}
					onClose={handleCloseModal}
				/>
			</FormModal>

			<SelectWorker
				status={status}
				onChangeWorker={handleWorkerChange}
				contractUsers={contractUsers}
				userId={userId}
				onAdd={handleAdd}
				contractUser={contractUser}
			/>

			<FilterReport
				handleFilterDate={handleFilterDate}
				handleFilterStatus={handleFilterStatus}
				dateFrom={dateFrom}
				dateTo={dateTo}
				filterStatus={filterStatus}
			/>

			<ShowReportWorker
				filterStatus={filterStatus}
				onClose={handleClose}
				reportsWorker={reportsWorker}
				reportsClosedWorker={reportsClosedWorker}
				onEdit={handleEdit}
				onDeleteConfirm={handleDeleteConfirm}
				onReportConfirm={handleDocumentReportConfirm}
			/>
		</>
	);
};

export default ReportWorker;
