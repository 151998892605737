import React, { useState } from 'react';
import { UserDB, UserModal } from '../types';

import { useData } from '../../../utils/useData';
import { useAppContext } from '../../../context/appContext/appContext';

import PermissionAlert from '../../../components/pageContent/PermissionAlert';

import PageTitle from '../../../components/pageContent/PageTitle';

import TableView from '../../../components/pageContent/TableView/TableView';
import TitleTableRow from './dataTable/TitleTableRow';
import DataTableRows from './dataTable/DataTableRows';

import Loading from '../../../components/pageContent/Loading';

import { AlertColor, TablePagination } from '@mui/material';

import { dict } from '../../../translate/translate-dictionary';

import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import FilterUser from './FilterUser';
import UserCardModal from '../../../components/modal/UserCardModal';

const MyUsersList = () => {
	const [page, setPage] = useState(0);
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [userData, setUserData] = useState<UserModal | null>(null);

	const { state, setRowsPerPage } = useAppContext();

	const navigate = useNavigate();
	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();

	const lang = state.lang;

	// ____________ ZISKANI PARAMETRU Z URL ______________________
	// const status = searchParams.get('status')! ? searchParams.get('status')! : '';
	const search = searchParams.get('search')! ? searchParams.get('search')! : '';

	// ____________ USE DATA - NACTENI USERS ______________________
	const {
		isLoading,
		data: users,
		refetch,
	} = useData<UserDB[], { users: UserDB[] }>(`/users/my-users?search=${encodeURI(search)}`, data => data.users);

	const handleFilterSearchChange = (search: string) => {
		navigate({
			pathname: location.pathname,
			search: search
				? createSearchParams({
						// status: status,
						search: search,
				  }).toString()
				: undefined,
		});
	};

	const handleOpenModal = (id: number): void => {
		const userDetail = users?.find(item => item.id === id);

		if (userDetail) {
			setOpenModal(true);
			setUserData({
				firstName: userDetail.firstName,
				lastName: userDetail.lastName,
				email: userDetail.email,
				phone: userDetail.phone,
				imageUrl: userDetail.imageUrl,
			});
		}
	};

	// ____________ CLOSE MODAL ______________________
	const handleCloseModal = (): void => {
		setOpenModal(false);
		setUserData(null);
	};

	// ____________ SET PAGE ______________________
	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	// ____________ SET ROWS PER PAGE ______________________
	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	// _______________________ VYSTUP ________________________

	if (!state.user?.roles.includes('user')) {
		return <PermissionAlert />;
	}

	return (
		<>
			<Loading isLoading={isLoading} />

			<PageTitle title="Aktivní uživatelé" />

			<UserCardModal onClose={handleCloseModal} openModal={openModal} user={userData} />

			<FilterUser search={search} onSearchCHange={handleFilterSearchChange} />

			<TableView
				titleRow={<TitleTableRow />}
				dataRow={
					<DataTableRows
						users={users}
						rowsPerPage={state.rowsPerPage}
						page={page}
						onDetail={handleOpenModal}
					/>
				}
				tablePagination={
					// stateUser.users?.length ? (
					// 	stateUser.users?.length > state.rowsPerPage ? (
					<TablePagination
						count={users ? users.length : 0}
						component="div"
						rowsPerPage={state.rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						rowsPerPageOptions={[5, 10, 15]}
						labelRowsPerPage={dict['ROWS_PER_PAGE'][lang]}
					/>
					// 	) : undefined
					// ) : undefined
				}
				colspan={6}
			/>
		</>
	);
};

export default MyUsersList;
