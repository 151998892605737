import React, { useState } from 'react';

import PageTitle from '../../../components/pageContent/PageTitle';

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useAppContext } from '../../../context/appContext/appContext';

import { useData } from '../../../utils/useData';
import Loading from '../../../components/pageContent/Loading';
import { TablePagination } from '@mui/material';
import TableView from '../../../components/pageContent/TableView/TableView';

import { dict } from '../../../translate/translate-dictionary';

import TitleTableRow from './dataTable/TitleTableRow';
import DataTableRows from './dataTable/DataTableRows';
import { Document } from '../types';
import { useUpdate } from '../../../utils/useUpdate';
import customFetcher from '../../../utils/fetchInstance';

type Props = {};

const MyReportsList = (props: Props) => {
	const [page, setPage] = useState(0);

	const { state, setRowsPerPage } = useAppContext();
	const lang = state.lang;

	const navigate = useNavigate();
	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();

	// ____________ USE DATA - NACTENI DOCUMENTS ______________________
	const {
		isLoading,
		data: documents,
		refetch: refetchReports,
	} = useData<Document[], { documents: Document[] }>(`/my-contracts/report/documents`, data => data.documents);

	const handlePdfDownload = async (id: number, type: string): Promise<void> => {
		let urlSend = 'report-worker-pdf';
		if (type === 'driver') urlSend = 'report-driver-pdf';

		// const response = await customFetcher(`/my-contracts/report/${urlSend}/${id}`, 'GET');
		// return;

		const response = await customFetcher(`/my-contracts/report/${urlSend}/${id}`, 'PDF');
		const filename = response.response.headers.get('Content-Disposition')?.split('filename=')[1];

		const blob = await response.response.blob();

		const url = URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', filename ? filename : 'report.pdf');
		document.body.appendChild(link);
		link.click();
		link.remove();
	};

	// ____________ SET PAGE ______________________
	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	// ____________ SET ROWS PER PAGE ______________________
	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	return (
		<>
			<Loading isLoading={isLoading} />

			<PageTitle title="Moje výkazy" />

			{/* <FilterContract
				status={status}
				search={search}
				onStatusCHange={handleFilterStatusChange}
				onSearchCHange={handleFilterSearchChange}
			/> */}

			<TableView
				titleRow={<TitleTableRow />}
				dataRow={
					<DataTableRows
						documents={documents}
						rowsPerPage={state.rowsPerPage}
						page={page}
						onPdfDownload={handlePdfDownload}
					/>
				}
				tablePagination={
					<TablePagination
						count={documents ? documents.length : 0}
						component="div"
						rowsPerPage={state.rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						rowsPerPageOptions={[5, 10, 15]}
						labelRowsPerPage={dict['ROWS_PER_PAGE'][lang]}
					/>
				}
				colspan={4}
			/>
		</>
	);
};

export default MyReportsList;
