import React, { ReactNode, useCallback, useContext, useMemo, useState } from 'react';

import customFetcher from '../../utils/fetchInstance';

import { ContextData, ContextState } from './types';

type Props = {
	children: ReactNode;
};

const UsersContext = React.createContext<ContextData>(null as any);

const initialState: ContextState = {
	page: 0,
	isInit: false,
	isLoading: false,
	filterStatus: '',
	users: null,
	filterText: '',
};

const UsersProvider: React.FC<Props> = ({ children }) => {
	const [state, setState] = useState(initialState);

	const getUsers = useCallback(async (filterStatus: string, filterText: string) => {
		try {
			const { data } = await customFetcher(
				`/users?status=${encodeURI(filterStatus)}&search=${encodeURI(filterText)}`
			);
			const { users } = data;
			setState(prevState => {
				return { ...prevState, users };
			});
		} catch (error) {
			console.log(error);
		}
	}, []);

	const setInitUsers = useCallback(async () => {
		setState(prevState => {
			return {
				...prevState,
				isInit: true,
				isLoading: true,
				page: 0,
				filterStatus: '',
				filterText: '',
			};
		});
		await getUsers('', '');

		setState(prevState => {
			return { ...prevState, isLoading: false };
		});
	}, [getUsers]);

	const removeIsInit = useCallback(async () => {
		setState({ ...state, isInit: false });
	}, [state]);

	const setPage = useCallback(
		(page: number): void => {
			setState({ ...state, page });
		},
		[state]
	);

	const setFilterStatus = useCallback(
		async (filterStatus: string) => {
			setState(prevState => {
				return { ...prevState, isLoading: true, page: 0, filterStatus };
			});

			await getUsers(filterStatus, state.filterText);

			setState(prevState => {
				return { ...prevState, isLoading: false };
			});
		},
		[getUsers, state.filterText]
	);

	const setFilterText = useCallback(
		async (filterText: string) => {
			setState(prevState => {
				return { ...prevState, isLoading: true, page: 0, filterText };
			});

			await getUsers(state.filterStatus, filterText);

			setState(prevState => {
				return { ...prevState, isLoading: false };
			});
		},
		[getUsers, state.filterStatus]
	);

	const refetchUsers = useCallback(async () => {
		setState(prevState => {
			return { ...prevState, isLoading: true };
		});

		await getUsers(state.filterStatus, state.filterText);

		setState(prevState => {
			return { ...prevState, isLoading: false };
		});
	}, [getUsers, state.filterStatus, state.filterText]);

	const value = useMemo(
		(): ContextData => ({
			state,
			setInitUsers,
			removeIsInit,
			setFilterStatus,
			setPage,
			refetchUsers,
			setFilterText,
		}),
		[state, setInitUsers, removeIsInit, setFilterStatus, setPage, refetchUsers, setFilterText]
	);
	return <UsersContext.Provider value={value}>{children}</UsersContext.Provider>;
};

const useUsersContext = () => {
	return useContext(UsersContext);
};

export { UsersProvider, useUsersContext };
