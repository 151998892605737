import { Box, Typography } from '@mui/material';
import React from 'react';
import TableView from '../../../../../components/pageContent/TableView/TableView';
import InfoRecords from '../../../../../components/uiComponents/InfoRecords';
import { ReportToDriverDB, ReportToWorkerDB } from '../../types';
import DataTableRows from './DataTableRows';
import TitleTableRow from './TitleTableRow';

type Props = {
	filterStatus: string;
	reportsDriver: ReportToDriverDB[] | null;
};

const ShowReportDriver = ({ reportsDriver, filterStatus }: Props) => {
	const records = reportsDriver?.length;
	return (
		<>
			<Box sx={{ marginY: 2 }}>
				<InfoRecords records={records} />

				{reportsDriver &&
					reportsDriver.map(report => {
						return (
							<React.Fragment key={report.id}>
								<TableView
									titleRow={<TitleTableRow report={report} />}
									dataRow={<DataTableRows report={report} />}
									colspan={8}
								/>
							</React.Fragment>
						);
					})}
			</Box>
		</>
	);
};
export default ShowReportDriver;
