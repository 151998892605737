import React, { useState } from 'react';

import PageTitle from '../../../components/pageContent/PageTitle';

import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useAppContext } from '../../../context/appContext/appContext';

import { useData } from '../../../utils/useData';
import Loading from '../../../components/pageContent/Loading';
import { AlertColor, TablePagination } from '@mui/material';
import TableView from '../../../components/pageContent/TableView/TableView';

import { dict } from '../../../translate/translate-dictionary';

import FilterContract from './FilterContract';
import { ContractDB } from '../types';
import TitleTableRow from './dataTable/TitleTableRow';
import DataTableRows from './dataTable/DataTableRows';

type Props = {};

const AllContractsList = (props: Props) => {
	const [page, setPage] = useState(0);

	const { state, setRowsPerPage } = useAppContext();
	const lang = state.lang;

	const navigate = useNavigate();
	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();

	// ____________ ZISKANI PARAMETRU Z URL ______________________
	// const status = searchParams.get('status')! ? searchParams.get('status')! : '';
	const status = '';
	const search = searchParams.get('search')! ? searchParams.get('search')! : '';

	// ____________ USE DATA - NACTENI ALL CONTRACTS ______________________
	const {
		isLoading,
		data: contracts,
		refetch: refetchContracts,
	} = useData<ContractDB[], { contracts: ContractDB[] }>(
		`/contracts?search=${encodeURI(search)}`,
		data => data.contracts
	);

	const handleFilterStatusChange = (status: string) => {
		navigate({
			pathname: location.pathname,
			search: createSearchParams({
				// status: status,
				search: search,
			}).toString(),
		});
	};

	const handleFilterSearchChange = (search: string) => {
		navigate({
			pathname: location.pathname,
			search: search
				? createSearchParams({
						// status: status,
						search: search,
				  }).toString()
				: undefined,
		});
	};

	// ____________ SET PAGE ______________________
	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	// ____________ SET ROWS PER PAGE ______________________
	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	return (
		<>
			<Loading isLoading={isLoading} />

			<PageTitle title="Přehled aktivních zakázek" />

			<FilterContract
				status={status}
				search={search}
				onStatusCHange={handleFilterStatusChange}
				onSearchCHange={handleFilterSearchChange}
			/>

			<TableView
				titleRow={<TitleTableRow />}
				dataRow={<DataTableRows contracts={contracts} rowsPerPage={state.rowsPerPage} page={page} />}
				tablePagination={
					<TablePagination
						count={contracts ? contracts.length : 0}
						component="div"
						rowsPerPage={state.rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						rowsPerPageOptions={[5, 10, 15]}
						labelRowsPerPage={dict['ROWS_PER_PAGE'][lang]}
					/>
				}
				colspan={4}
			/>
		</>
	);
};

export default AllContractsList;
