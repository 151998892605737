import React, { ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import customFetcher from '../../utils/fetchInstance';
import { ContextData, ContextState } from './types';

type Props = {
	children: ReactNode;
};

const MyContractsContext = React.createContext<ContextData>(null as any);

const initialState: ContextState = {
	page: 0,
	isInit: false,
	isLoading: false,
	filterStatus: '',
	contracts: null,
	contract: null,
	filterText: '',
};

const MyContractsProvider: React.FC<Props> = ({ children }) => {
	const [state, setState] = useState(initialState);

	const getContracts = useCallback(async (filterStatus: string, filterText: string) => {
		try {
			const status = filterStatus ? filterStatus : 'active';
			const { data } = await customFetcher(
				`/my-contracts?status=${encodeURI(status)}&search=${encodeURI(filterText)}`
			);
			const { contracts } = data;
			setState(prevState => {
				return { ...prevState, contracts };
			});
		} catch (error) {
			console.log(error);
		}
	}, []);

	const getContract = useCallback(async (id: string) => {
		try {
			const { data } = await customFetcher(`/my-contracts/${id}`);
			const { contract } = data;
			setState(prevState => {
				return { ...prevState, contract };
			});
		} catch (error) {
			console.log(error);
		}
	}, []);

	const setInitContracts = useCallback(async () => {
		setState(prevState => {
			return {
				...prevState,
				isInit: true,
				isLoading: true,
				page: 0,
				filterStatus: '',
				filterText: '',
			};
		});
		await getContracts('', '');

		setState(prevState => {
			return { ...prevState, isLoading: false };
		});
	}, [getContracts]);

	const removeIsInit = useCallback(async () => {
		setState({ ...state, isInit: false });
	}, [state]);

	const setPage = useCallback(
		(page: number): void => {
			setState({ ...state, page });
		},
		[state]
	);

	const setFilterStatus = useCallback(
		async (filterStatus: string) => {
			setState(prevState => {
				return { ...prevState, isLoading: true, page: 0, filterStatus };
			});

			await getContracts(filterStatus, state.filterText);

			setState(prevState => {
				return { ...prevState, isLoading: false };
			});
		},
		[getContracts, state.filterText]
	);

	const setContract = useCallback(
		async (id: string) => {
			setState(prevState => {
				return { ...prevState, isLoading: true };
			});

			await getContract(id);

			setState(prevState => {
				return { ...prevState, isLoading: false };
			});
		},
		[getContract]
	);

	const refetchContracts = useCallback(async () => {
		setState(prevState => {
			return { ...prevState, isLoading: true };
		});

		await getContracts(state.filterStatus, state.filterText);

		setState(prevState => {
			return { ...prevState, isLoading: false };
		});
	}, [getContracts, state.filterStatus, state.filterText]);

	const setFilterText = useCallback(
		async (filterText: string) => {
			setState(prevState => {
				return { ...prevState, isLoading: true, page: 0, filterText };
			});

			await getContracts(state.filterStatus, filterText);

			setState(prevState => {
				return { ...prevState, isLoading: false };
			});
		},
		[getContracts, state.filterStatus]
	);

	const value = useMemo(
		(): ContextData => ({
			state,
			setInitContracts,
			removeIsInit,
			setFilterStatus,
			setPage,
			refetchContracts,
			setFilterText,
			setContract,
		}),
		[state, setInitContracts, removeIsInit, setFilterStatus, setPage, refetchContracts, setFilterText, setContract]
	);
	return <MyContractsContext.Provider value={value}>{children}</MyContractsContext.Provider>;
};

const useMyContractsContext = () => {
	return useContext(MyContractsContext);
};

export { MyContractsProvider, useMyContractsContext };
