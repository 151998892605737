import React from 'react';
import ModalHeader from './ModalHeader';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { UserModal } from '../../pages/myContracts/types';
import { Avatar } from '@mui/material';

const style = {
	position: 'relative',
	top: '50%',
	left: '50%',

	transform: 'translate(-50%, -50%)',
	minWidth: 200,
	maxWidth: 500,
	maxHeight: '80vh',
	width: 'calc(100% - 64px)',

	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	paddingTop: 2,
	paddingBottom: 4,
	paddingX: 4,

	overflow: 'auto',
};
type Props = {
	openModal: boolean;
	onClose: () => void;
	user: UserModal | null;
};

const UserCardModal = ({ openModal, onClose, user }: Props) => {
	return (
		<Modal open={openModal} onClose={onClose}>
			<Box sx={style}>
				<ModalHeader title={`${user?.firstName} ${user?.lastName}`} onClose={onClose} />
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						marginTop: 5,
					}}>
					<Avatar
						variant="rounded"
						alt={`${user?.firstName} ${user?.lastName}`}
						src={user?.imageUrl ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${user.imageUrl}` : undefined}
						sx={{ width: 200, height: 200, fontSize: 40 }}>
						{`${user?.firstName[0]}${user?.lastName[0]}`}
					</Avatar>
					<Box sx={{ marginY: 1 }}> </Box>
					{user?.phone && (
						<Box sx={{ marginY: 1 }}>
							Tel: <a href={`tel:${user?.phone}`}>{user?.phone}</a>
						</Box>
					)}

					{user?.email && (
						<Box sx={{ marginY: 1 }}>
							E-mail: <a href={`mailto:${user?.email}`}>{user?.email}</a>
						</Box>
					)}
				</Box>
			</Box>
		</Modal>
	);
};

export default UserCardModal;
