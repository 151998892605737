import { AlertColor, SelectChangeEvent } from '@mui/material';
import React, { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { createSearchParams, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Loading from '../../../../components/pageContent/Loading';

import SnackbarAfterAction from '../../../../components/uiComponents/SnackbarAfterAction';
import { useData } from '../../../../utils/useData';

import { ContractUserDB, ReportToDriverDB, ReportToWorkerDB, ReportWorkerDetail } from '../types';
import ShowReportDriver from './dataTable/ShowReportDriver';
// import ShowReportWorker from './dataTable/ShowReportWorker';
import SelectDriver from './SelectDriver';

import { log } from 'console';
import FilterReport from '../../../../components/pageContent/FilterReport';

type Props = {};

const ReportDriver = (props: Props) => {
	const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
	const [msgSnackbar, setMsgSnackbar] = useState<string>('');
	const [severitySnackbar, setSeveritySnackbar] = useState<AlertColor>('success');

	const [contractUsers, setContractUsers] = useState<ContractUserDB[] | null>(null);

	const { contractId } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();

	// ____________ ZISKANI PARAMETRU Z URL ______________________
	const carId = searchParams.get('carId')! ? searchParams.get('carId')! : '';
	const userId = searchParams.get('userId')! ? searchParams.get('userId')! : '';

	const filterStatus = searchParams.get('filterStatus')! ? searchParams.get('filterStatus')! : '';

	const dateFrom = searchParams.get('dateFrom')!
		? dayjs(searchParams.get('dateFrom')!)
		: dayjs(new Date().setDate(new Date().getDate() - 30));
	const dateTo = searchParams.get('dateTo')!
		? dayjs(searchParams.get('dateTo')!)
		: dayjs(new Date().setDate(new Date().getDate()));

	const navigate = useNavigate();
	const location = useLocation();

	// ____________ SKLADANI PARAMETRU PRO DOTAZ DO DB ______________________
	const params: string[] = [];

	dateFrom ? params.push(`dateFrom=${dateFrom.format('YYYY-MM-DD')}`) : params.push(`dateFrom=`);
	dateTo ? params.push(`dateTo=${dateTo.format('YYYY-MM-DD')}`) : params.push(`dateTo=`);
	filterStatus ? params.push(`status=${filterStatus}`) : params.push(`status=`);

	// ____________ USE DATA - NACTENI CONTRACT USERS ______________________
	const {
		isLoading: isLoading,
		data: contractUsersData,
		refetch,
	} = useData<ContractUserDB[], { contractUsers: ContractUserDB[] }>(
		`/contracts/contract-to-user/${contractId}`,
		data => {
			setContractUsers(data.contractUsers);
			return data.contractUsers;
		}
	);

	// ____________ USE DATA - NACTENI REPORTU PRO USERS  ______________________
	const {
		isLoading: isLoadingReport,
		data: reportsDriver,
		refetch: refetchReportsDriver,
	} = useData<ReportToDriverDB[], { reportsDriver: ReportToDriverDB[] }>(
		`/contracts/report-driver-editor/${contractId}/${userId ? userId : 0}?${params.join('&')}`,
		data => data.reportsDriver
	);

	// ____________ HANDLE FILTER DATE ______________________
	const handleFilterDate = (dateFromForm: Dayjs, dateToForm: Dayjs) => {
		if (dateFromForm.diff(dateToForm, 'second') > 0) {
			dateToForm = dateFromForm;
		}

		const dateFromSend = dateFromForm ? dateFromForm.format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD');
		const dateToSend = dateToForm ? dateToForm.format('YYYY-MM-DD') : dateFromForm.format('YYYY-MM-DD');

		navigate({
			pathname: location.pathname,
			search: createSearchParams({
				carId: carId,
				userId: userId,
				filterStatus: filterStatus,
				dateFrom: dateFromSend,
				dateTo: dateToSend,
			}).toString(),
		});
	};

	// ____________ HANDLE FILTER STATUS ______________________
	const handleFilterStatus = (filterStatus: string) => {
		navigate({
			pathname: location.pathname,
			search: createSearchParams({
				carId: carId,
				userId: userId,
				filterStatus: filterStatus,
				dateFrom: dateFrom.format('YYYY-MM-DD'),
				dateTo: dateTo.format('YYYY-MM-DD'),
			}).toString(),
		});
	};

	// ____________ HANDLE USER CHANGE CONTRACT USERS ______________________
	const handleDriverChange = (event: SelectChangeEvent) => {
		console.log(dateFrom);

		navigate({
			pathname: location.pathname,
			search: createSearchParams({
				carId: carId,
				userId: event.target.value,
				filterStatus: filterStatus,
				dateFrom: dateFrom ? dateFrom.format('YYYY-MM-DD') : '',
				dateTo: dateTo ? dateTo.format('YYYY-MM-DD') : '',
			}).toString(),
		});

		refetchReportsDriver();
	};

	// ____________ SNACKBAR ______________________
	const handleCloseSnackbar = () => {
		setOpenSnackbar(false);
	};

	//  ____________________ SELECT OBJECT CONTRACT USER - overi userId jestli je opravdovy zaznam v DB ______________________________________
	const searchDriver = contractUsers && contractUsers.find(item => item.userId === parseInt(userId));
	const contractUser = userId && contractUsers && searchDriver ? searchDriver : null;

	return (
		<>
			<Loading isLoading={isLoading || isLoadingReport} />

			<SnackbarAfterAction
				severity={severitySnackbar}
				openSnackbar={openSnackbar}
				onClose={handleCloseSnackbar}
				msgSnackbar={msgSnackbar}
			/>

			<SelectDriver
				onChangeWorker={handleDriverChange}
				contractUsers={contractUsers}
				userId={userId}
				contractUser={contractUser}
			/>

			<FilterReport
				filterStatus={filterStatus}
				handleFilterDate={handleFilterDate}
				handleFilterStatus={handleFilterStatus}
				dateFrom={dateFrom}
				dateTo={dateTo}
			/>

			<ShowReportDriver filterStatus={filterStatus} reportsDriver={reportsDriver} />
		</>
	);
};

export default ReportDriver;
