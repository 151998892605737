import React, { useState } from 'react';

import { useAppContext } from '../../../../context/appContext/appContext';
import { UserDB } from '../../types';
import TableCellView from '../../../../components/pageContent/TableView/TableCellView';
import TableCellEditIcon from '../../../../components/pageContent/TableView/TableCellEditIcon';
import TableCellDeleteIcon from '../../../../components/pageContent/TableView/TableCellDeleteIcon';
import TableCellIsActiveIcon from '../../../../components/pageContent/TableView/TableCellIsActiveIcon';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';

import {
	Avatar,
	Box,
	Collapse,
	LinearProgress,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	useTheme,
} from '@mui/material';
import { useUpdate } from '../../../../utils/useUpdate';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { ContractDB } from '../../../contracts/edit/types';
import TableCellLoupeIcon from '../../../../components/pageContent/TableView/TableCellLoupeIcon';

type Props = {
	users: UserDB[] | null;
	onEdit: (id: number) => void;
	onReport: (id: number) => void;
	onDeleteConfirm: (id: number, name: string) => void;
	onActive: (id: number, name: string, isActive: boolean) => void;
	rowsPerPage: number;
	page: number;
};

const DataTableRows = ({ users, onEdit, onDeleteConfirm, onActive, onReport, rowsPerPage, page }: Props) => {
	const [open, setOpen] = useState(false);
	const [openId, setOpenId] = useState(0);
	const [contracts, setContracts] = useState<ContractDB[] | null>(null);

	const { state } = useAppContext();
	const theme = useTheme();

	// ____________ USE UPDATE - NACTENI CONTRACTS - USERS  ______________________
	const {
		isLoading: isLoading,
		data: dataEditing,
		update: updateContractUser,
		error: updateError,
	} = useUpdate<{ status: string; error?: string; contracts?: ContractDB[] }>('');

	const handleOpen = async (id: number) => {
		setOpen(false);
		setOpenId(0);
		setContracts(null);

		if (openId !== id) {
			setOpen(true);
			setOpenId(id);

			const response = await updateContractUser(null, 'GET', `/contracts/user/${id}`);

			if (!response) {
				return;
			}

			if (response?.status === 'success') {
				if (response.contracts) {
					setContracts(response.contracts);
				}
			}
		}
	};

	return (
		<>
			{!users && (
				<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
					<TableCellView align="left" w="auto" colspan={9}>
						Nebyl nalezen žádný záznam.
					</TableCellView>
				</TableRow>
			)}

			{users &&
				(rowsPerPage > 0 ? users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : users).map(
					(user: UserDB, index: number) => {
						return (
							<React.Fragment key={user.id}>
								<TableRow hover>
									<TableCellView align="center" w={50} isActive={user.isActive}>
										{rowsPerPage > 0 ? page * rowsPerPage + index + 1 : index + 1}
									</TableCellView>

									<TableCellLoupeIcon
										onOpen={handleOpen}
										openId={user.id}
										color={open && openId === user.id ? 'warning' : 'info'}
									/>

									{/* <TableCellReportIcon onReport={onReport} idReport={user.id!} /> */}

									<TableCellEditIcon
										onEdit={onEdit}
										idEdit={user.id}
										isDisabled={
											state.user?.role === 'admin'
												? false
												: (state.user?.role === 'editor' || 'user') && user.role === 'admin'
												? true
												: state.user?.role === 'user' && user.role === 'editor'
												? true
												: false
										}
									/>
									<TableCellView align="left" mW={200} w="auto" isActive={user.isActive}>
										{`${user.lastName} ${user.firstName}`}
									</TableCellView>

									<TableCellView align="left" mW={200} w="auto" isActive={user.isActive}>
										{user.email}
									</TableCellView>

									<TableCellDeleteIcon
										onDeleteConfirm={onDeleteConfirm}
										idDelete={user.id}
										nameDelete={`${user.lastName} ${user.firstName}`}
										isDisabled={
											state.user?.id === user.id
												? true
												: state.user?.role === 'admin'
												? false
												: (state.user?.role === 'editor' || 'user') && user.role === 'admin'
												? true
												: state.user?.role === 'user' && user.role === 'editor'
												? true
												: false
										}
									/>

									<TableCellIsActiveIcon
										onActive={onActive}
										idActive={user.id}
										nameActive={`${user.lastName} ${user.firstName}`}
										isActive={user.isActive}
										isDisabled={
											state.user?.id === user.id
												? true
												: state.user?.role === 'admin'
												? false
												: (state.user?.role === 'editor' || 'user') && user.role === 'admin'
												? true
												: state.user?.role === 'user' && user.role === 'editor'
												? true
												: false
										}
									/>

									<TableCellView
										align="right"
										w={state.user?.role === 'admin' ? 60 : '5px'}
										pX={state.user?.role === 'admin' ? undefined : '5px'}
										isActive={user.isActive}>
										{state.user?.role === 'admin' ? user.id : ''}
									</TableCellView>
								</TableRow>

								{/* ________________ COLLAPSE ROW __________________________ */}

								<TableRow>
									<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
										<Collapse in={open && openId === user.id} timeout="auto" unmountOnExit>
											<Box sx={{ marginY: 1, marginBottom: 3 }}>
												<Table size="small" aria-label="purchases">
													<TableBody>
														<TableRow>
															<TableCell align="left" width={100} rowSpan={4}>
																<Avatar
																	variant="rounded"
																	alt={`${user.firstName} ${user.lastName}`}
																	src={
																		user.imageUrl
																			? `${process.env.REACT_APP_BASE_IMAGE_URL}/${user.imageUrl}`
																			: undefined
																	}
																	sx={{ width: 100, height: 100 }}>
																	{`${user.firstName[0]}${user.lastName[0]}`}
																</Avatar>
															</TableCell>
															<TableCell align="left" colSpan={2}>
																{`${user.lastName} ${user.firstName}`}

																<Typography
																	component={'span'}
																	variant="body2"
																	sx={{
																		color: 'GrayText',
																		ml: 1,
																		fontSize: '0.8em',
																	}}>
																	{`role: `}
																	{user.role === 'user' ? 'uživatel' : user.role}
																</Typography>
																{!user.isActive && (
																	<Typography
																		component={'span'}
																		variant="body2"
																		sx={{
																			color: theme.palette.error.dark,
																			ml: 1,
																			fontSize: '0.8em',
																		}}>
																		neaktivní
																	</Typography>
																)}
															</TableCell>
														</TableRow>

														{user.phone && (
															<TableRow>
																{/* <TableCell align="left" width={100}></TableCell> */}
																<TableCell align="left" width={100}>
																	Kontakt:
																</TableCell>

																<TableCell align="left">
																	<a href={`tel:${user.phone}`}>{user.phone}</a>
																</TableCell>
															</TableRow>
														)}

														<TableRow>
															{/* <TableCell align="left" width={100}></TableCell> */}
															<TableCell align="left" width={100}>
																Email:
															</TableCell>
															<TableCell align="left">{user.email}</TableCell>
														</TableRow>

														{(user.street || user.zipCode || user.city) && (
															<TableRow>
																{/* <TableCell align="left" width={100}></TableCell> */}
																<TableCell align="left" width={100}>
																	Adresa:
																</TableCell>
																<TableCell align="left">
																	{user.street && `${user.street}`}
																	{(user.zipCode || user.city) && ', '}
																	{user.zipCode && `${user.zipCode} `}
																	{user.city && `${user.city}`}
																</TableCell>
															</TableRow>
														)}
													</TableBody>
												</Table>
											</Box>

											{isLoading && (
												<Box
													sx={{
														marginY: 4,
														width: '100%',
													}}>
													<LinearProgress color="info" />
												</Box>
											)}

											{contracts && contracts?.length > 0 && (
												<Box sx={{ margin: 1, marginBottom: 3 }}>
													<Table size="small" aria-label="purchases">
														<TableHead>
															<TableRow
																sx={{
																	backgroundColor: theme.palette.grey[200],
																}}>
																<TableCell align="left" width={100}></TableCell>
																<TableCell align="left" colSpan={4}>
																	Zakázky
																</TableCell>
															</TableRow>
														</TableHead>
														<TableBody>
															{contracts.map((contract: ContractDB) => {
																return (
																	<TableRow key={`contract_${contract.id}`}>
																		<TableCell align="left" width={100}></TableCell>
																		<TableCell align="left" width={250}>
																			{contract.name}
																		</TableCell>
																		<TableCell align="left">
																			{dayjs(contract.dateFrom).format(
																				'DD.MM.YYYY'
																			)}
																		</TableCell>
																		<TableCell align="left">
																			{dayjs(contract.dateTo).format(
																				'DD.MM.YYYY'
																			)}
																		</TableCell>

																		<TableCell align="center">
																			<Link
																				to={`/contracts/report/${contract.id}`}
																				target="_blank"
																				rel="noopener noreferrer">
																				<OpenInNewOutlinedIcon
																					fontSize="small"
																					color="primary"
																				/>
																			</Link>
																		</TableCell>
																	</TableRow>
																);
															})}
														</TableBody>
													</Table>
												</Box>
											)}
										</Collapse>
									</TableCell>
								</TableRow>
							</React.Fragment>
						);
					}
				)}
		</>
	);
};

export default DataTableRows;
