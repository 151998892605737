import { useCallback, useEffect, useState } from 'react';
import customFetcher from './fetchInstance';

export const useData = <T, Y = T>(url: string, transform?: (data: Y) => T) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [data, setData] = useState<T | null>(null);
	const [error, setError] = useState<any>(null);

	const refetch = useCallback(async () => {
		try {
			setIsLoading(true);
			const { data, response } = await customFetcher(url);

			if (response.ok) {
				if (transform) {
					setData(transform(data));
				} else {
					setData(data);
				}
			} else {
				setError(data);
			}
		} catch (error) {
			console.log('Error: ', error);
			setError(error);
		} finally {
			setIsLoading(false);
		}
	}, [url, transform]);

	useEffect(() => {
		if (navigator.onLine) {
			refetch();
		} else {
			setError('Není připojení k internetu!');
			console.log('Není připojení k internetu!');
		}
	}, [url]);

	return {
		isLoading,
		data,
		error,
		refetch,
	};
};
