import { IconButton, TableCell, useMediaQuery, useTheme } from '@mui/material';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import PauseOutlinedIcon from '@mui/icons-material/PauseOutlined';
import { useAppContext } from '../../../context/appContext/appContext';

type Props = {
	onActive: (id: number, name: string, isActive: boolean) => void;
	idActive: number;
	nameActive: string;
	isActive: boolean;
	isDisabled?: boolean;
};

const TableCellIsActiveIcon = ({ onActive, idActive, nameActive, isActive, isDisabled }: Props) => {
	const theme = useTheme();
	const { state } = useAppContext();

	const sizeButton = useMediaQuery(theme.breakpoints.up('sm')) ? 'small' : 'medium';

	return (
		<TableCell align="center" width={30} size="small" sx={{ paddingX: '5px' }}>
			{isDisabled && (
				<IconButton
					disabled
					sx={{
						borderRadius: 1,
						border: 0,
						color: theme.palette.grey[100],
						borderColor: theme.palette.grey[100],
						padding: '0.15em',
					}}
					// onClick={() => onActive(idActive, nameActive, isActive)}
					aria-label="nastavení aktivity"
					size="small">
					{/* {!isActive && <PlayArrowOutlinedIcon fontSize="small" />}
					{isActive && <PauseOutlinedIcon fontSize="small" />} */}
				</IconButton>
			)}
			{!isDisabled && (
				<IconButton
					sx={{
						borderRadius: 1,
						border: 0,
						color: !isActive ? theme.palette.success.light : theme.palette.error.light,
						borderColor: !isActive ? theme.palette.error.dark : theme.palette.success.dark,
						padding: '0.15em',
					}}
					onClick={() => onActive(idActive, nameActive, isActive)}
					aria-label="nastavení aktivity"
					size="small">
					{!isActive && <PlayArrowOutlinedIcon fontSize={sizeButton} />}
					{isActive && <PauseOutlinedIcon fontSize={sizeButton} />}
				</IconButton>
			)}
		</TableCell>
	);
};

export default TableCellIsActiveIcon;
