import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useAppContext } from '../../../../context/appContext/appContext';
import { dict } from '../../../../translate/translate-dictionary';
import { ContractCarFormInput, ContractCarDB } from '../types';

import { Box, TextField, IconButton, CircularProgress, Divider, Stack, Alert, Button } from '@mui/material';
import { carAddSchema } from '../../../../utils/joiSchema';
import { joiResolver } from '@hookform/resolvers/joi';
import { useUpdate } from '../../../../utils/useUpdate';

type Props = {
	editID: number | null;
	editData: ContractCarDB | null;
	firstCarData: ContractCarDB | null;
	carSelected: string;
	onCancel: () => void;

	onFormSubmit: (data: ContractCarFormInput) => Promise<string | undefined>;
};

const FormEditCar = ({ editID, editData, carSelected, onCancel, onFormSubmit, firstCarData }: Props) => {
	const [masterError, setMasterError] = useState<string>('');

	const { state } = useAppContext();
	const lang = state.lang;

	const setFormData = editID ? editData : firstCarData ? firstCarData : null;

	// ____________ INIT FORM ______________________
	const {
		register,
		handleSubmit,
		reset,
		control,
		getValues,
		formState: { errors },
	} = useForm<ContractCarFormInput>({
		defaultValues: {
			dailyRate: setFormData ? setFormData.dailyRate * 1 : '',
			includedKm: setFormData ? setFormData.includedKm * 1 : '',
			ratePerKm: setFormData ? setFormData.ratePerKm * 1 : '',
		},
		resolver: joiResolver(carAddSchema),
		mode: 'onChange',
		delayError: 2000,
	});

	const onSubmit = async (data: ContractCarFormInput) => {
		const response = await onFormSubmit(data);
		if (response) setMasterError(response);
	};

	return (
		<>
			<Box
				component="form"
				noValidate
				onSubmit={handleSubmit(onSubmit)}
				sx={{
					mt: 1,
					marginBottom: 0,
				}}>
				{/* ________ dailyRate _______ */}
				<Box
					sx={{
						display: 'flex',
						justifyContent: { sm: 'space-between' },
						flexDirection: { xs: 'column', sm: 'row' },
						alignItems: 'center',
						marginBottom: 0,
					}}>
					<Controller
						name="dailyRate"
						control={control}
						render={({ field: { ref, ...field } }) => (
							<TextField
								{...field}
								inputRef={ref}
								required
								type="number"
								label="Denní sazba"
								variant="filled"
								size="medium"
								margin="normal"
								inputProps={{ maxLength: 20 }}
								sx={{ width: { xs: '100%', sm: '32%' } }}
								error={!!errors?.dailyRate}
								helperText={
									errors?.dailyRate?.message
										? dict[errors.dailyRate.message]
											? dict[errors.dailyRate.message][lang]
											: errors?.dailyRate?.message
										: ' '
								}
							/>
						)}
					/>

					<Controller
						name="includedKm"
						control={control}
						render={({ field: { ref, ...field } }) => (
							<TextField
								{...field}
								inputRef={ref}
								required
								type="number"
								label="Km v ceně"
								variant="filled"
								size="medium"
								margin="normal"
								inputProps={{ maxLength: 20 }}
								sx={{ width: { xs: '100%', sm: '32%' } }}
								error={!!errors?.includedKm}
								helperText={
									errors?.includedKm?.message
										? dict[errors.includedKm.message]
											? dict[errors.includedKm.message][lang]
											: errors?.includedKm?.message
										: ' '
								}
							/>
						)}
					/>
					<Controller
						name="ratePerKm"
						control={control}
						render={({ field: { ref, ...field } }) => (
							<TextField
								{...field}
								inputRef={ref}
								required
								type="number"
								label="cena za Km"
								variant="filled"
								size="medium"
								margin="normal"
								inputProps={{ maxLength: 20, step: 0.1 }}
								sx={{ width: { xs: '100%', sm: '32%' } }}
								error={!!errors?.ratePerKm}
								helperText={
									errors?.ratePerKm?.message
										? dict[errors.ratePerKm.message]
											? dict[errors.ratePerKm.message][lang]
											: errors?.ratePerKm?.message
										: ' '
								}
							/>
						)}
					/>
				</Box>

				<Stack sx={{ width: '100%', marginTop: 2 }} spacing={3}>
					{masterError && (
						<Alert sx={{ borderRadius: '0.5em' }} severity="error">
							{dict[masterError] ? dict[masterError][lang] : masterError ? masterError : ' '}
						</Alert>
					)}
				</Stack>

				<Stack direction="row" justifyContent="center" alignItems="center" spacing={2} mt={3}>
					<Button variant="contained" size="large" color="inherit" onClick={onCancel}>
						zrušit
						<Box component={'span'} sx={{ marginLeft: 0.5, display: { xs: 'none', md: 'inline' } }}>
							{editID ? 'editaci' : 'přidávání'} auta
						</Box>
					</Button>
					<Button variant="contained" type="submit" size="large">
						{`${editID ? 'Editovat' : 'Přidat'}`}
						<Box component={'span'} sx={{ display: { xs: 'none', sm: 'inline' } }}>
							&nbsp;auto
						</Box>
					</Button>
				</Stack>
			</Box>
		</>
	);
};

export default FormEditCar;
