import React from 'react';
import {
	AppBar,
	Avatar,
	Box,
	Divider,
	IconButton,
	ListItemIcon,
	Menu,
	MenuItem,
	styled,
	Theme,
	Toolbar,
	Typography,
	useTheme,
} from '@mui/material';
import { Logout, Menu as MenuIcon, Person, PersonAdd, Settings } from '@mui/icons-material';

import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useAppContext } from '../../../context/appContext/appContext';
import { Link, useNavigate } from 'react-router-dom';

type Props = {
	handleDrawerToggle: () => void;
	handleDrawerMinToggle: () => void;
};

const Navigation = ({ handleDrawerToggle, handleDrawerMinToggle }: Props) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const theme = useTheme();
	const navigate = useNavigate();

	const { state, logout } = useAppContext();

	const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<AppBar
				position="fixed"
				sx={{
					// backgroundColor: '#777',
					backgroundColor: theme.palette.grey[900],
					// backgroundImage: 'linear-gradient(71deg, #151415 0%, #81367b 46%, #37312b 100%)',
					paddingX: { xs: 0, sm: 0 },
				}}>
				<Box
					sx={{
						width: '100%',
						maxWidth: theme.breakpoints.values.lg,
						marginX: 'auto',
						paddingX: 0,
					}}>
					<Toolbar sx={{ justifyContent: 'space-between', paddingX: { xs: 0, sm: 0 } }}>
						{state.user && (
							<Toolbar sx={{ justifyContent: 'center', paddingX: { xs: 0, sm: 0 } }}>
								<IconButton
									onClick={handleDrawerToggle}
									size="large"
									edge="start"
									color="inherit"
									aria-label="menu"
									sx={{
										ml: 1,
										mr: 2,
										[theme.breakpoints.up('sm')]: {
											display: 'none',
										},
									}}>
									<MenuIcon />
								</IconButton>
								<IconButton
									onClick={handleDrawerMinToggle}
									size="large"
									edge="start"
									color="inherit"
									aria-label="menu"
									sx={{
										ml: 1,
										mr: 2,
										[theme.breakpoints.down('sm')]: {
											display: 'none',
										},
									}}>
									<MenuIcon />
								</IconButton>
								<Typography variant="h6" component="div">
									<Link to={'/'} style={{ color: 'white', textDecoration: 'none' }}>
										justeasy
									</Link>
								</Typography>
							</Toolbar>
						)}

						{!state.user && (
							<Toolbar sx={{ justifyContent: 'center', paddingX: { xs: 0, sm: 0 } }}>
								<Typography
									variant="h6"
									component="div"
									sx={{
										ml: 2,
										mr: 2,
									}}>
									<Link to={'/'} style={{ color: 'white', textDecoration: 'none' }}>
										justeasy
									</Link>
								</Typography>
							</Toolbar>
						)}

						{!state.user && (
							<IconButton onClick={() => navigate('/login')}>
								<Avatar sx={{ bgcolor: theme.palette.secondary.main }}>
									<PersonOutlineOutlinedIcon />
								</Avatar>
							</IconButton>
						)}

						{state.user && (
							<IconButton
								onClick={handleClickMenu}
								aria-controls={open ? 'account-menu' : undefined}
								aria-haspopup="true"
								aria-expanded={open ? 'true' : undefined}>
								<Avatar sx={{ bgcolor: theme.palette.secondary.main }}>
									{`${state.user.firstName[0]}${state.user.lastName[0]}`}
								</Avatar>
							</IconButton>
						)}

						<Menu
							anchorEl={anchorEl}
							id="account-menu"
							open={open}
							onClose={handleCloseMenu}
							onClick={handleCloseMenu}
							PaperProps={{
								elevation: 0,
								sx: {
									overflow: 'visible',
									filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
									mt: 1.5,
									'& .MuiAvatar-root': {
										width: 32,
										height: 32,
										ml: -0.5,
										mr: 1,
									},
									'&:before': {
										content: '""',
										display: 'block',
										position: 'absolute',
										top: 0,
										right: 14,
										width: 10,
										height: 10,
										bgcolor: 'background.paper',
										transform: 'translateY(-50%) rotate(45deg)',
										zIndex: 0,
									},
								},
							}}
							transformOrigin={{ horizontal: 'right', vertical: 'top' }}
							anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
							{/* <MenuItem>{`${state.user?.firstName} ${state.user?.lastName}`}</MenuItem>
							<Divider /> */}
							<MenuItem onClick={() => navigate('/my-account')}>
								<ListItemIcon>
									<Person fontSize="small" />
								</ListItemIcon>
								{`${state.user?.firstName} ${state.user?.lastName}`}
							</MenuItem>

							<MenuItem onClick={logout}>
								<ListItemIcon>
									<Logout fontSize="small" />
								</ListItemIcon>
								Logout
							</MenuItem>
						</Menu>
					</Toolbar>
				</Box>
			</AppBar>
		</>
	);
};

export default Navigation;
