import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import TableView from '../../../../../components/pageContent/TableView/TableView';
import ButtonDocumentReport from '../../../../../components/uiComponents/ButtonDocumentReport';
import InfoRecords from '../../../../../components/uiComponents/InfoRecords';
import { ReportToWorkerDB } from '../../types';
import DataTableRows from './DataTableRows';
import TitleTableRow from './TitleTableRow';

type Props = {
	reportsWorker: ReportToWorkerDB[] | null;
	reportsClosedWorker: ReportToWorkerDB[] | null;
	filterStatus: string;
	onEdit: (id: number) => void;
	onDeleteConfirm: (id: number, name: string) => void;
	onReportConfirm: () => void;
	onClose: (id: number, name: string, isClosed: boolean) => void;
};

const ShowReportWorker = ({
	reportsWorker,
	reportsClosedWorker,
	onEdit,
	onDeleteConfirm,
	onReportConfirm,
	onClose,
	filterStatus,
}: Props) => {
	const records = reportsWorker ? reportsWorker.length : 0;
	const recordsClosed = reportsClosedWorker ? reportsClosedWorker.length : 0;

	return (
		<>
			<Box sx={{ marginY: 2 }}>
				<InfoRecords records={records} />

				{filterStatus === 'closed' && recordsClosed > 0 && (
					<ButtonDocumentReport
						title={`Vytvořit vyúčtování všech uzavřených výkazů (${recordsClosed})`}
						onReportConfirm={onReportConfirm}
					/>
				)}

				{reportsWorker &&
					reportsWorker.map(report => {
						return (
							<React.Fragment key={report.id}>
								<TableView
									titleRow={<TitleTableRow report={report} onClose={onClose} />}
									dataRow={
										<DataTableRows
											report={report}
											onEdit={onEdit}
											onDeleteConfirm={onDeleteConfirm}
										/>
									}
									colspan={8}
								/>
							</React.Fragment>
						);
					})}
			</Box>
		</>
	);
};

export default ShowReportWorker;
