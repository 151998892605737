import { TabPanel } from '@mui/lab';
import { Box, Tabs, Tab } from '@mui/material';
import React from 'react';

type Props = {
	tabsName: string[];
	tabValue: number;
	onTabChange: (event: React.SyntheticEvent, newValue: number) => void;
	children: JSX.Element;
};

const TabNavigator = ({ tabsName, tabValue, onTabChange, children }: Props) => {
	return (
		<Box mb={2} sx={{ width: '100%', padding: '0.3em 0em 0em 0em', backgroundColor: 'white', borderRadius: 2 }}>
			<Box sx={{ borderBottom: 1, borderColor: 'divider', paddingX: 2 }}>
				<Tabs value={tabValue} onChange={onTabChange} aria-label="tabs">
					{tabsName.map((tabName, index) => (
						<Tab key={index} label={tabName} />
					))}
				</Tabs>
			</Box>
			<Box sx={{ paddingX: 2, paddingY: 3 }}>{children}</Box>
		</Box>
	);
};

export default TabNavigator;
