import { Typography } from '@mui/material';
import React from 'react';

type Props = {
	records: number | undefined;
	// children: JSX.Element;
};

const InfoRecords = ({ records }: Props) => {
	return (
		<Typography
			component={'div'}
			variant="subtitle1"
			sx={{
				background: '#eeeeee',
				paddingX: 2,
				paddingY: 1,
				borderRadius: 2,
				marginBottom: 2,
				borderBottom: 1,
				borderColor: '#8B8B8B',
			}}>
			{records ? `Celkem záznamů: ${records}` : 'Bohužel nebyly nalezeny žádné data.'}
		</Typography>
	);
};

export default InfoRecords;
