import { Box, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import React from 'react';
import { CarCategoryDB, CarDB } from '../../../cars/types';

type Props = {
	categoryFilter: string;
	carSelected: string;
	usedCars: number[] | null;
	onChangeCar: (event: SelectChangeEvent) => void;
	carSelect: CarDB[] | null;
};

const SelectCar = ({ categoryFilter, onChangeCar, carSelect, carSelected, usedCars }: Props) => {
	const newCarselect = carSelect?.filter(item => !usedCars?.includes(item.id));

	return (
		// <Box
		// 	component="div"
		// 	sx={{ background: 'white', paddingX: 1, paddingY: 1, marginTop: 2, marginBottom: 1, borderRadius: 2 }}>
		<FormControl
			variant="filled"
			size="small"
			sx={{ maxWidth: { sx: '100%', sm: '48%' }, marginRight: { sx: 0, sm: 0 }, marginY: 1, width: '100%' }}>
			<InputLabel id="car">Auto</InputLabel>
			<Select labelId="car" value={carSelected} onChange={onChangeCar} label="Výběr auta">
				<MenuItem value="">žádné auto</MenuItem>
				{newCarselect &&
					newCarselect
						.filter(item => {
							if (categoryFilter) return item.carCategory.id === parseInt(categoryFilter);
							return item.carCategory.id !== 0;
						})

						.map((item: CarDB) => {
							return (
								<MenuItem key={item.id} value={item.id} sx={{ color: item.isActive ? '' : 'red' }}>
									{`${item.name}, ${item.spz}`}
								</MenuItem>
							);
						})}
			</Select>
		</FormControl>
		// </Box>
	);
};

export default SelectCar;
