import { AlertColor, TablePagination } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useLocation, useNavigate, useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import DocumentReportDeleteDialog from '../../../../components/pageContent/DocumentReportDeleteDialog';
import Loading from '../../../../components/pageContent/Loading';
import DeleteDialog from '../../../../components/pageContent/TableView/DeleteDialog';
import TableView from '../../../../components/pageContent/TableView/TableView';
import SnackbarAfterAction from '../../../../components/uiComponents/SnackbarAfterAction';
import { useAppContext } from '../../../../context/appContext/appContext';
import { dict } from '../../../../translate/translate-dictionary';
import { useData } from '../../../../utils/useData';
import { useUpdate } from '../../../../utils/useUpdate';
import { DocumentReportDB, ReportToWorkerDB } from '../types';
import DataTableRows from './dataTable/DataTableRows';
import TitleTableRow from './dataTable/TitleTableRow';

import ReportWorkers from '../../../../components/pdf/ReportWorkers';

import customFetcher from '../../../../utils/fetchInstance';
import { blob } from 'stream/consumers';

type Props = {};

const ReportOverview = (props: Props) => {
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [idPdf, setIdPdf] = useState<number>(0);
	const [page, setPage] = useState<number>(0);

	const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
	const [msgSnackbar, setMsgSnackbar] = useState<string>('');
	const [severitySnackbar, setSeveritySnackbar] = useState<AlertColor>('success');

	const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
	const [idDelete, setIdDelete] = React.useState<number | null>(null);
	const [nameDelete, setNameDelete] = React.useState<string>('');

	const { state, setRowsPerPage } = useAppContext();
	const lang = state.lang;

	const status: string = useOutletContext();

	const { contractId } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();

	// ____________ ZISKANI PARAMETRU Z URL ______________________
	const carId = searchParams.get('carId')! ? searchParams.get('carId')! : '';
	const userId = searchParams.get('userId')! ? searchParams.get('userId')! : '';

	const filterStatus = searchParams.get('filterStatus')! ? searchParams.get('filterStatus')! : '';
	const filterType = searchParams.get('filterType')! ? searchParams.get('filterType')! : '';

	const dateFrom = searchParams.get('dateFrom')!
		? dayjs(searchParams.get('dateFrom')!)
		: dayjs(new Date().setDate(new Date().getDate() - 30));
	const dateTo = searchParams.get('dateTo')!
		? dayjs(searchParams.get('dateTo')!)
		: dayjs(new Date().setDate(new Date().getDate()));

	const navigate = useNavigate();
	const location = useLocation();

	// ____________ SKLADANI PARAMETRU PRO DOTAZ DO DB ______________________
	const params: string[] = [];

	dateFrom ? params.push(`dateFrom=${dateFrom.format('YYYY-MM-DD')}`) : params.push(`dateFrom=`);
	dateTo ? params.push(`dateTo=${dateTo.format('YYYY-MM-DD')}`) : params.push(`dateTo=`);
	filterType ? params.push(`type=${filterType}`) : params.push(`type=`);

	// ____________ USE DATA - NACTENI DOCUMENT REPORTS ______________________
	const {
		isLoading: isLoading,
		data: documentsReport,
		refetch: refetchDocumentsReport,
	} = useData<DocumentReportDB[], { documentsReport: DocumentReportDB[] }>(
		`/contracts/report-document/${contractId}?${params.join('&')}`,
		data => data.documentsReport
	);

	// ____________ USE UPDATE - ostatní metody ______________________
	const {
		isLoading: isUpdateLoading,
		data: dataEditing,
		update: updateClient,
		error: updateError,
	} = useUpdate<{ status: string; error?: string; documentsReport?: DocumentReportDB }>('');

	// ____________ USE UPDATE - WORKER CLOSED ______________________
	const {
		isLoading: isWorkersLoading,
		data: dataWorkers,
		update: updateWorkersReport,
		error: updateWorkersError,
	} = useUpdate<{ status: string; error?: string }>('');

	// ____________ DELETE ______________________
	const handleDelete = async (): Promise<void> => {
		const response = await updateClient(null, 'DELETE', `/contracts/report-document/${contractId}/${idDelete}`);
		if (!response) {
			return;
		}

		if (response.status === 'error') {
			if (response.error === 'RECORD_CANNOT_BE_DELETED') {
				setMsgSnackbar(`Vyúčtování "${nameDelete}" nelze odstranit!`);
				setSeveritySnackbar('error');
				setOpenSnackbar(true);
			}
		} else {
			refetchDocumentsReport();
			setMsgSnackbar(`Vyúčtování "${nameDelete}" bylo odstraněno.`);
			setSeveritySnackbar('success');
			setOpenSnackbar(true);
		}

		setIdDelete(null);
		setNameDelete('');
		handleCloseDeleteDialog();
	};

	// ____________ DELETE CONFIRM ______________________
	const handleDeleteConfirm = (id: number, name: string) => {
		setIdDelete(id);
		setNameDelete(name);
		setOpenDeleteDialog(true);
	};

	// ____________ DELETE CLOSE DIALOG ______________________
	const handleCloseDeleteDialog = () => {
		setIdDelete(null);
		setOpenDeleteDialog(false);
	};

	const handlePdfDownload = async (id: number, type: string): Promise<void> => {
		let urlSend = 'report-worker-pdf';
		if (type === 'car') urlSend = 'report-car-pdf';
		if (type === 'driver') urlSend = 'report-driver-pdf';

		// const response = await customFetcher(`/contracts/${urlSend}/${id}`, 'GET');
		// return;

		const response = await customFetcher(`/contracts/${urlSend}/${id}`, 'PDF');
		const filename = response.response.headers.get('Content-Disposition')?.split('filename=')[1];

		const blob = await response.response.blob();

		const url = URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', filename ? filename : 'report.pdf');
		document.body.appendChild(link);
		link.click();
		link.remove();
	};

	// ____________ HANDLE OPEN PDF ______________________
	const handleOpenPdf = (id: number) => {
		setIdPdf(id);
		setOpenModal(true);
	};

	// ____________ HANDLE CLOSE PDF ______________________
	const handleClosePdf = () => {
		setOpenModal(false);
	};

	// ____________ SNACKBAR ______________________
	const handleCloseSnackbar = () => {
		setOpenSnackbar(false);
	};

	// ____________ SET PAGE ______________________
	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	// ____________ SET ROWS PER PAGE ______________________
	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	return (
		<>
			<Loading isLoading={isLoading} />

			<SnackbarAfterAction
				severity={severitySnackbar}
				openSnackbar={openSnackbar}
				onClose={handleCloseSnackbar}
				msgSnackbar={msgSnackbar}
			/>
			<DocumentReportDeleteDialog
				onOpen={openDeleteDialog}
				nameDelete={nameDelete}
				handleClose={handleCloseDeleteDialog}
				onDelete={handleDelete}
			/>

			<TableView
				titleRow={<TitleTableRow />}
				dataRow={
					<DataTableRows
						documentsReport={documentsReport}
						onDeleteConfirm={handleDeleteConfirm}
						onShowPdf={handleOpenPdf}
						onPdfDownload={handlePdfDownload}
						rowsPerPage={state.rowsPerPage}
						page={page}
						status={status}
					/>
				}
				tablePagination={
					<TablePagination
						count={documentsReport ? documentsReport.length : 0}
						component="div"
						rowsPerPage={state.rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						rowsPerPageOptions={[5, 10, 15]}
						labelRowsPerPage={dict['ROWS_PER_PAGE'][lang]}
					/>
				}
				colspan={8}
			/>
		</>
	);
};

export default ReportOverview;
