import React from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { ContractUserDB } from '../types';
import ButtonWithStartIconNonResponsive from '../../../../components/uiComponents/ButtonWithStartIconNonResponsive';

type Props = {
	status: string;
	onChangeWorker: (event: SelectChangeEvent) => void;
	onAdd: () => void;
	contractUsers: ContractUserDB[] | null;
	contractUser: ContractUserDB | null;
	userId: string;
};

const SelectWorker = ({ onChangeWorker, onAdd, contractUsers, contractUser, userId, status }: Props) => {
	const theme = useTheme();
	return (
		<>
			<Box
				component="div"
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					flexDirection: { xs: 'column', sm: 'row' },
					alignItems: 'center',

					background: 'white',
					paddingX: 0,
					paddingY: 0,
					marginTop: 0,
					marginBottom: 0,
					borderRadius: 2,
				}}>
				<FormControl variant="filled" sx={{ width: { xs: '100%', sm: '60%' } }}>
					<InputLabel id="car">Výběr pracovníka</InputLabel>
					<Select labelId="user" value={userId} onChange={onChangeWorker} label="Výběr auta">
						<MenuItem value="">žádný pracovník</MenuItem>
						{contractUsers &&
							contractUsers.map((item: ContractUserDB) => {
								return (
									<MenuItem
										key={item.id}
										value={item.userId}
										sx={{ color: !item.user.isActive ? theme.palette.error.dark : '' }}>
										{`${item.user.lastName}, ${item.user.firstName}`}
									</MenuItem>
								);
							})}
					</Select>
				</FormControl>
				{status !== 'closed' && (
					<ButtonWithStartIconNonResponsive
						title="přidat výkaz"
						icon={<AddIcon />}
						onDisabled={userId && contractUser ? false : true}
						bColor={userId ? 'primary' : 'grey'}
						onClickHeaderButton={onAdd}
					/>
				)}
			</Box>
		</>
	);
};

export default SelectWorker;
