import { debounce } from 'lodash';
import React, { useCallback, useState } from 'react';

import {
	Box,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	SelectChangeEvent,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Radio,
	TextField,
	InputAdornment,
	IconButton,
} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useUsersContext } from '../../../context/usersContext/UsersContext';

type Props = {
	onSearchCHange: (search: string) => void;
	search: string;
};

const FilterUser = ({ onSearchCHange, search }: Props) => {
	const [searchFilter, setSearchFilter] = useState(search);

	const handleFilterSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchFilter(event.target.value);
		debouncedFilterTextChange(event.target.value);
	};

	const debouncedFilterTextChange = useCallback(
		debounce(criteria => {
			onSearchCHange(criteria);
		}, 500),
		[]
	);

	React.useEffect(() => {
		return () => {
			debouncedFilterTextChange.cancel();
		};
	}, [debouncedFilterTextChange]);

	return (
		<Box
			component="div"
			sx={{ background: 'white', paddingX: 3, paddingY: 2, marginTop: 2, marginBottom: 2, borderRadius: 2 }}>
			<Box sx={{ marginY: 2 }}>
				<TextField
					id="fulltext"
					label="Hledání uživatele"
					variant="standard"
					value={searchFilter}
					onChange={handleFilterSearchChange}
					fullWidth
					InputProps={{
						endAdornment: search && (
							<InputAdornment position="end">
								<IconButton
									onClick={() => {
										setSearchFilter('');
										debouncedFilterTextChange('');
									}}
									edge="end">
									<CloseOutlinedIcon />
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			</Box>
		</Box>
	);
};

export default FilterUser;
