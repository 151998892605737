import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TableRow,
	useTheme,
} from '@mui/material';
import React from 'react';

type Props = {
	titleRow: JSX.Element;
	dataRow: JSX.Element;
	tablePagination?: JSX.Element | undefined;
	colspan?: number | undefined;
};

const TableView = ({ titleRow, dataRow, tablePagination, colspan }: Props) => {
	const theme = useTheme();

	return (
		<>
			<TableContainer
				component={Paper}
				sx={{ minWidth: '200px', marginRight: -500, marginBottom: 4, borderRadius: 2 }}>
				<Table sx={{ minWidth: '400px' }} aria-label="simple table">
					<TableHead sx={{ backgroundColor: theme.palette.grey[400] }}>{titleRow}</TableHead>

					<TableBody>{dataRow}</TableBody>
					{tablePagination && (
						<TableFooter>
							<TableRow>
								<TableCell colSpan={colspan}>{tablePagination}</TableCell>
							</TableRow>
						</TableFooter>
					)}
				</Table>
			</TableContainer>
		</>
	);
};

export default TableView;
