import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ContractDB } from './types';
import { tabsName } from './tabsName';

import { useUpdate } from '../../../utils/useUpdate';
import { useContractsContext } from '../../../context/contractsContext/ContractsContext';

import TabContract from './TabContract';
import TabCars from './TabCars';
import TabUser from './TabUser';

import PageTitle from '../../../components/pageContent/PageTitle';
import TabNavigator from '../../../components/uiComponents/TabNavigator/TabNavigator';
import SnackbarAfterAction from '../../../components/uiComponents/SnackbarAfterAction';
import { AlertColor } from '@mui/material';
import Loading from '../../../components/pageContent/Loading';

import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import PermissionAlert from '../../../components/pageContent/PermissionAlert';
import { useAppContext } from '../../../context/appContext/appContext';
import { useData } from '../../../utils/useData';

type Props = {};

const ContractEditDetail = (props: Props) => {
	const { id } = useParams();
	const { state } = useAppContext();
	const { state: stateContract, setPage, setInitContracts, setFilterStatus } = useContractsContext();
	const navigate = useNavigate();
	const [tabValue, setTabValue] = useState<number>(0);
	const [isChanged, setIsChanged] = useState<boolean>(false);

	const [contractId, setContractId] = useState<number>(id ? parseInt(id, 10) : 0);
	const [contractEdit, setContractEdit] = useState<ContractDB | null>(null);

	const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
	const [msgSnackbar, setMsgSnackbar] = useState<string>('');
	const [severitySnackbar, setSeveritySnackbar] = useState<AlertColor>('success');

	// ____________ USE DATA - načtení zakazky ______________________
	const {
		isLoading,
		data: contract,
		refetch,
		error: contractError,
	} = useData<ContractDB, { contract: ContractDB }>(`/contracts/${contractId}`, data => {
		return data.contract;
	});

	// ____________ USE UPDATE - OSTATNÍ METODY ______________________
	const {
		isLoading: isUpdateLoading,
		data: dataEditing,
		update: updateContract,
		error: updateError,
	} = useUpdate<{ status: string; error?: string; contract?: ContractDB }>('');

	// ____________ NACTE DATA UPDATOVANE ZAKAZKY ______________________
	const getEditData = useCallback(async () => {
		if (contractId) {
			const response = await updateContract(null, 'GET', `/contracts/${contractId}`);

			if (response) {
				if (response.error === 'DB_NOT_FOUND') {
					setContractEdit(null);
					setContractId(0);
					navigate('/contracts/edit-detail');
					return undefined;
				}

				if (response.status === 'success') {
					const data = {
						id: response.contract?.id,
						name: response.contract?.name,
						clientId: response.contract?.clientId,
						dateFrom: response.contract?.dateFrom,
						dateTo: response.contract?.dateTo,
						description: response.contract?.description,
						status: response.contract?.status,
					};

					setContractEdit(data);
					return data;
				}

				return undefined;
			}
		}
	}, [contractId, updateContract, navigate]);

	// ____________ BUTTON ZPET NA PREHLED ______________________
	const handleHeaderButton = () => {
		navigate(`/contracts`);
	};

	// ____________ TAB NAVIGATOR - ZAKÁZAT, POVOLENI ______________________
	const handleIsChanged = () => {
		setIsChanged(true);
	};
	const handleClearIsChanged = () => {
		setIsChanged(false);
	};

	// ____________ TAB NAVIGATOR - KONTROLA JESTLI JE ULOŽENÝ FORM ______________________
	const handleChangeTabNavigator = (event: React.SyntheticEvent, newValue: number) => {
		// isChanged?: boolean;
		// contractId?: number;
		if (contractId > 0) {
			if (!isChanged) setTabValue(newValue);
			else {
				// varning uloz form
				setSeveritySnackbar('warning');
				setMsgSnackbar('Nejdříve uložte změny ve formuláři!');
				setOpenSnackbar(true);
			}
		} else {
			setSeveritySnackbar('warning');
			setMsgSnackbar('Nejdříve vytvořte zakázku!');
			setOpenSnackbar(true);
		}
	};

	// ____________ NASTAVANI ID NA EDITACI PRI VYTVORENI NOVE ZAKAZKY ______________________
	const handleEdit = (id: number | undefined) => {
		id && setContractId(id);
	};

	// ____________ SNACKBAR ______________________
	const handleCloseSnackbar = () => {
		setOpenSnackbar(false);
	};

	if (!state.user?.roles.includes('editor')) {
		return <PermissionAlert />;
	}

	return (
		<>
			<Loading isLoading={isUpdateLoading} />

			<SnackbarAfterAction
				severity={severitySnackbar}
				openSnackbar={openSnackbar}
				onClose={handleCloseSnackbar}
				msgSnackbar={msgSnackbar}
			/>

			<PageTitle
				title={`${contractId ? 'Editace' : 'Vytvoření'} zakázky`}
				buttonText="zpět na přehled"
				onClickHeaderButton={handleHeaderButton}
				icon={<ArrowBackOutlinedIcon />}
			/>

			<TabNavigator tabValue={tabValue} tabsName={tabsName} onTabChange={handleChangeTabNavigator}>
				<>
					{tabValue === 0 && (
						<TabContract
							contractId={contractId}
							isChanged={isChanged}
							isLoadingParent={isUpdateLoading}
							getEditData={getEditData}
							onEdit={handleEdit}
							onIsChanged={handleIsChanged}
							onClearIsChanged={handleClearIsChanged}
							onCloseEdit={handleHeaderButton}
						/>
					)}
					{tabValue === 1 && (
						<TabCars
							contractId={contractId}
							contractEdit={contractEdit}
							isLoadingParent={isUpdateLoading}
							onCloseEdit={handleHeaderButton}
						/>
					)}
					{tabValue === 2 && (
						<TabUser
							contractId={contractId}
							contractEdit={contractEdit}
							isLoadingParent={isUpdateLoading}
							onCloseEdit={handleHeaderButton}
						/>
					)}
				</>
			</TabNavigator>
		</>
	);
};

export default ContractEditDetail;
