import React from 'react';
import { dict } from '../../../../translate/translate-dictionary';

import { useAppContext } from '../../../../context/appContext/appContext';

import { StateUser } from '../../../../context/appContext/types';

import {
	Box,
	Divider,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material';

// import data pro menu
import { MENU_LIST_USER, MENU_LIST_EDITOR, MENU_LIST_ADMIN, SideMenu, SideMenuItems } from './dataMenuList';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

type Props = {
	drawerMinOpen: boolean;
	onClickSideBar: (nav: string) => void;
};

const getMenu = (user: StateUser | null): SideMenu => {
	switch (user?.role) {
		case 'admin':
			return { ...MENU_LIST_ADMIN };
		case 'editor':
			return { ...MENU_LIST_EDITOR };
		case 'user':
			return { ...MENU_LIST_USER };
		default:
			return {};
	}
};

const MenuList = ({ drawerMinOpen, onClickSideBar }: Props): JSX.Element => {
	const location = useLocation();
	const { state } = useAppContext();
	const theme = useTheme();
	const { user } = state;
	const lang = state.lang;

	const showMenu = getMenu(user);

	return (
		<React.Fragment>
			{Object.values(showMenu).map((array: any, index): JSX.Element => {
				return (
					<React.Fragment key={index}>
						{array.length && (
							<React.Fragment>
								{drawerMinOpen && (
									<Box sx={{ margin: 1, marginTop: 1 }}>
										<Typography variant="button" sx={{ color: 'white', fontSize: '0.8em' }}>
											{dict[Object.keys(showMenu)[index]][lang]}
										</Typography>
									</Box>
								)}
								<List sx={{ py: 0 }}>
									{array.map((item: SideMenuItems, indexItem: number): JSX.Element => {
										return (
											<React.Fragment key={item.id}>
												<Link to={item.url} style={{ textDecoration: 'none' }}>
													<ListItem
														disablePadding
														divider={array.length > indexItem + 1}
														sx={{ borderColor: '#888' }}>
														<ListItemButton
															// onClick={() => onClickSideBar(item.url)}
															sx={{
																minHeight: 48,
																justifyContent: drawerMinOpen ? 'initial' : 'center',
																px: 2.5,
															}}>
															<Tooltip title={item.title}>
																<ListItemIcon
																	sx={{
																		color:
																			(item.url &&
																				location.pathname.startsWith(
																					`${item.baseUrl}`
																				)) ||
																			(!item.url && location.pathname === '/')
																				? theme.palette.warning.light
																				: theme.palette.grey[400],
																		minWidth: 0,
																		[theme.breakpoints.down('sm')]: {
																			mr: 3,
																		},
																		[theme.breakpoints.up('sm')]: {
																			mr: drawerMinOpen ? 3 : 'auto',
																		},
																		justifyContent: 'center',
																	}}>
																	{item.icon}
																</ListItemIcon>
															</Tooltip>
															<ListItemText
																// primary={`${item.title} path:${location.pathname} url:${item.url}`}
																primary={item.title}
																sx={{
																	color: theme.palette.grey[200],
																	[theme.breakpoints.down('sm')]: {
																		opacity: 1,
																	},
																	[theme.breakpoints.up('sm')]: {
																		opacity: drawerMinOpen ? 1 : 0,
																	},
																}}
															/>
														</ListItemButton>
													</ListItem>
												</Link>
											</React.Fragment>
										);
									})}
								</List>
								<Divider sx={{ backgroundColor: 'white' }} />
							</React.Fragment>
						)}
					</React.Fragment>
				);
			})}
			{/* <List>
				<ListItem>
					<ListItemText></ListItemText>
				</ListItem>
			</List>
			<List>
				<ListItem>
					<ListItemText></ListItemText>
				</ListItem>
			</List> */}
		</React.Fragment>
	);
};

export default MenuList;
