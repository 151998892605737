import { useState } from 'react';
import { Box, TableRow, Typography } from '@mui/material';

import { useAppContext } from '../../../../../context/appContext/appContext';
import TableCellView from '../../../../../components/pageContent/TableView/TableCellView';

import React from 'react';

import { DriverWorkedHours, ReportToCarDB } from '../../types';
import LoupeIcon from '../../../../../components/uiComponents/LoupeIcon';
import DataCollapse from './DataCollapse';
import { WORKING_HOURS } from '../../../../../constants';

type Props = {
	status: string;
	report: ReportToCarDB;
	onEdit: (id: number) => void;
	onDeleteConfirm: (id: number, name: string) => void;
};

const DataTableRows = ({ report, onEdit, onDeleteConfirm, status }: Props) => {
	const [open, setOpen] = useState(false);
	const [openId, setOpenId] = useState(0);

	const { state } = useAppContext();

	const handleOpen = async (id: number) => {
		setOpen(false);
		setOpenId(0);

		if (openId !== id) {
			setOpen(true);
			setOpenId(id);
		}
	};

	const col1 = 120;
	const reportNumber = report.reportNumber * 1;
	// const normalWHours = WORKING_HOURS;

	const costs = report.costs * 1;

	// spocitame KM
	const totalKm = report.driveKm * 1;
	const includeKm = reportNumber > 0 ? report.includedKm * 1 : report.car.contractToCars[0].includedKm * 1;
	const ratePerKm = reportNumber > 0 ? report.ratePerKm * 1 : report.car.contractToCars[0].ratePerKm * 1;
	const dailyRate = reportNumber > 0 ? report.dailyRate * 1 : report.car.contractToCars[0].dailyRate * 1;

	let overLimitKm = 0;
	let sumOverKm = 0;
	let totalCarDay = 0;

	if (totalKm > includeKm) {
		overLimitKm = totalKm - includeKm;
		sumOverKm = overLimitKm * 1 * ratePerKm;
	}

	totalCarDay = dailyRate + sumOverKm + costs;

	// spocitame hodiny pro drivera
	const driverWorkedHours: DriverWorkedHours = [];

	report.reportCarDrivers.map((driver): void => {
		driverWorkedHours[driver.userId] = {
			workedHours: driver.workedHours * 1,
		};
	});

	return (
		<>
			{/* nazev auta */}
			<TableRow>
				<TableCellView align="left" w="auto" colspan={2} background="subTitle">
					<>
						<Typography sx={{ fontWeight: 'bold' }} component="span" variant="body2">
							{`${report.car.name}, ${report.car.spz}`}
						</Typography>
					</>
				</TableCellView>
				<TableCellView align="left" w="auto" background="subTitle">
					{' '}
				</TableCellView>
			</TableRow>

			{/* Celkem km */}
			<TableRow>
				<TableCellView align="left" mW={col1}>
					<Typography sx={{ fontWeight: 'bold' }} variant="body2">
						Celkem km
					</Typography>
				</TableCellView>

				<TableCellView align="left" mW={200}>
					<>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'flex-start',
								flexDirection: 'row',
								alignItems: 'center',
							}}>
							<Typography sx={{ fontWeight: 'bold', marginRight: 1 }} component="span" variant="body2">
								{report.driveKm * 1}
							</Typography>
							<LoupeIcon
								onOpen={handleOpen}
								openId={report.id}
								color={open && openId === report.id ? 'warning' : 'info'}
							/>
						</Box>
					</>
				</TableCellView>
				<TableCellView align="left" w="auto">
					{' '}
				</TableCellView>
			</TableRow>

			{/* ________________ COLLAPSE ROW __________________________ */}

			<DataCollapse
				status={status}
				report={report}
				onEdit={onEdit}
				onDeleteConfirm={onDeleteConfirm}
				onOpen={handleOpen}
				open={open}
				openId={openId}
				driverWorkedHours={driverWorkedHours}
			/>

			{/* Pausal KM */}

			<TableRow>
				<TableCellView align="left" mW={col1} w={150}>
					<>
						<Typography sx={{ fontWeight: 'bold' }} component="span" variant="body2">
							Paušál
						</Typography>
						<Typography component="span" variant="caption" sx={{ marginLeft: 1 }}>
							{reportNumber > 0 ? report.includedKm * 1 : report.car.contractToCars[0].includedKm * 1} Km
						</Typography>
					</>
				</TableCellView>

				<TableCellView align="left" mW={200} w="auto">
					<Typography sx={{ fontWeight: 'bold' }} component="div" variant="body2">
						{reportNumber > 0
							? `${(report.dailyRate * 1).toLocaleString()}`
							: `${(report.car.contractToCars[0].dailyRate * 1).toLocaleString()}`}
					</Typography>
				</TableCellView>
				<TableCellView align="left" w="auto">
					{' '}
				</TableCellView>
			</TableRow>

			{/* nad limit KM */}

			{overLimitKm > 0 && (
				<TableRow>
					<TableCellView align="left" mW={col1} w={150}>
						<>
							<Typography sx={{ fontWeight: 'bold' }} component="span" variant="body2">
								Nad limit
							</Typography>

							<Typography component="span" variant="caption" sx={{ marginLeft: 1 }}>
								{overLimitKm * 1} Km
							</Typography>
						</>
					</TableCellView>

					<TableCellView align="left" mW={200} w="auto">
						<>
							<Typography sx={{ fontWeight: 'bold' }} component="span" variant="body2">
								{`${sumOverKm.toLocaleString()} = `}
							</Typography>
							<Typography component="span" variant="body2">
								{reportNumber > 0
									? `${overLimitKm} x ${(report.ratePerKm * 1).toLocaleString()} `
									: `${overLimitKm} x ${(
											report.car.contractToCars[0].ratePerKm * 1
									  ).toLocaleString()} `}
							</Typography>
						</>
					</TableCellView>
					<TableCellView align="left" w="auto">
						{' '}
					</TableCellView>
				</TableRow>
			)}

			{costs > 0 && (
				<TableRow>
					<TableCellView align="left" mW={col1} w={150}>
						<>
							<Typography sx={{ fontWeight: 'bold' }} component="span" variant="body2">
								Vícenáklady auto
							</Typography>
						</>
					</TableCellView>

					<TableCellView align="left" mW={200} w="auto">
						<>
							<Typography sx={{ fontWeight: 'bold' }} component="span" variant="body2">
								{`${costs.toLocaleString()}`}
							</Typography>
						</>
					</TableCellView>
					<TableCellView align="left" w="auto">
						{' '}
					</TableCellView>
				</TableRow>
			)}

			{/* CELKEM AUTO */}

			<TableRow>
				<TableCellView align="left" mW={col1} w={150}>
					<Typography sx={{ fontWeight: 'bold' }} component="span" variant="body2">
						CENA CELKEM
					</Typography>
				</TableCellView>

				<TableCellView align="left" mW={200} w="auto">
					<Typography sx={{ fontWeight: 'bold' }} component="span" variant="body2">
						{totalCarDay.toLocaleString()}
					</Typography>
				</TableCellView>
				<TableCellView align="left" w="auto">
					{' '}
				</TableCellView>
			</TableRow>
		</>
	);
};

export default DataTableRows;
