import React from 'react';
import ModalHeader from './ModalHeader';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import { useAppContext } from '../../context/appContext/appContext';

const style = {
	position: 'relative',
	top: '50%',
	left: '50%',

	transform: 'translate(-50%, -50%)',
	minWidth: 200,
	maxWidth: 600,
	maxHeight: '80vh',
	width: 'calc(100% - 64px)',

	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	paddingTop: 2,
	paddingBottom: 4,
	paddingX: 4,

	overflow: 'auto',
};
type Props = {
	modalTitle: string;
	openModal: boolean;
	onClose: () => void;
	children: JSX.Element;
};

const FormModal = ({ modalTitle, openModal, onClose, children }: Props) => {
	const { state } = useAppContext();
	const lang = state.lang;

	return (
		<Modal open={openModal}>
			<Box sx={style}>
				<ModalHeader title={modalTitle} onClose={onClose} />
				{children}
			</Box>
		</Modal>
	);
};

export default FormModal;
