import React, { useState } from 'react';
import {
	Box,
	Collapse,
	LinearProgress,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	useTheme,
} from '@mui/material';

import { useAppContext } from '../../../../context/appContext/appContext';
import { CarCategoryDB, CarDB } from '../../types';

import TableCellView from '../../../../components/pageContent/TableView/TableCellView';
import TableCellEditIcon from '../../../../components/pageContent/TableView/TableCellEditIcon';
import TableCellDeleteIcon from '../../../../components/pageContent/TableView/TableCellDeleteIcon';
import TableCellIsActiveIcon from '../../../../components/pageContent/TableView/TableCellIsActiveIcon';
import { ContractDB } from '../../../contracts/edit/types';
import { useUpdate } from '../../../../utils/useUpdate';
import TableCellLoupeIcon from '../../../../components/pageContent/TableView/TableCellLoupeIcon';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';

type Props = {
	cars: CarDB[] | null;
	category: CarCategoryDB[] | null;
	onEdit: (id: number) => void;
	onReport: (id: number) => void;
	onDeleteConfirm: (id: number, name: string) => void;
	onActive: (id: number, name: string, isActive: boolean) => void;
	rowsPerPage: number;
	page: number;
};

const DataTableRows = ({ cars, category, onEdit, onReport, onDeleteConfirm, onActive, rowsPerPage, page }: Props) => {
	const [open, setOpen] = useState(false);
	const [openId, setOpenId] = useState(0);
	const [contracts, setContracts] = useState<ContractDB[] | null>(null);

	const theme = useTheme();
	const { state } = useAppContext();

	// ____________ USE UPDATE - NACTENI CONTRACTS - CARS  ______________________
	const {
		isLoading: isLoading,
		data: dataEditing,
		update: updateContractCar,
		error: updateError,
	} = useUpdate<{ status: string; error?: string; contracts?: ContractDB[] }>('');

	const handleOpen = async (id: number) => {
		setOpen(false);
		setOpenId(0);
		setContracts(null);

		if (openId !== id) {
			setOpen(true);
			setOpenId(id);

			const response = await updateContractCar(null, 'GET', `/contracts/car/${id}`);

			if (!response) {
				return;
			}

			if (response?.status === 'success') {
				if (response.contracts) {
					setContracts(response.contracts);
				}
			}
		}
	};

	return (
		<>
			{cars?.length === 0 && (
				<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
					<TableCellView align="left" w="auto" colspan={9}>
						Nebyl nalezen žádný záznam.
					</TableCellView>
				</TableRow>
			)}

			{cars &&
				cars?.length > 0 &&
				(rowsPerPage > 0 ? cars.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : cars).map(
					(car: CarDB, index: number) => {
						return (
							<React.Fragment key={car.id}>
								<TableRow hover>
									<TableCellView align="center" w={50} isActive={car.isActive}>
										{index + 1}
									</TableCellView>

									<TableCellLoupeIcon
										onOpen={handleOpen}
										openId={car.id}
										color={open && openId === car.id ? 'warning' : 'info'}
									/>

									{/* <TableCellReportIcon onReport={onReport} idReport={car.id!} /> */}

									<TableCellEditIcon onEdit={onEdit} idEdit={car.id} />

									<TableCellView align="left" mW={150} w="auto" isActive={car.isActive}>
										{car.name}
									</TableCellView>

									<TableCellView align="left" mW={150} w="auto" isActive={car.isActive}>
										{car.spz}
									</TableCellView>

									<TableCellDeleteIcon
										onDeleteConfirm={onDeleteConfirm}
										idDelete={car.id}
										nameDelete={car.name}
									/>

									<TableCellIsActiveIcon
										onActive={onActive}
										idActive={car.id}
										nameActive={car.name}
										isActive={car.isActive}
									/>

									<TableCellView
										align="right"
										w={state.user?.role === 'admin' ? 60 : '5px'}
										pX={state.user?.role === 'admin' ? undefined : '5px'}
										isActive={car.isActive}>
										{state.user?.role === 'admin' ? car.id : ''}
									</TableCellView>
								</TableRow>

								{/* ________________ COLLAPSE ROW __________________________ */}

								<TableRow>
									<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
										<Collapse in={open && openId === car.id} timeout="auto" unmountOnExit>
											<Box sx={{ margin: 1, marginBottom: 3 }}>
												<Table size="small" aria-label="purchases">
													<TableHead>
														<TableRow>
															<TableCell align="left" width={100}></TableCell>
															<TableCell align="left" colSpan={2}>
																{car.name}

																<Typography
																	component={'span'}
																	variant="body2"
																	sx={{
																		color: 'GrayText',
																		ml: 1,
																		fontSize: '0.8em',
																	}}>
																	{`Kat: `}
																	{car.carCategory.name}
																</Typography>
																{!car.isActive && (
																	<Typography
																		component={'span'}
																		variant="body2"
																		sx={{
																			color: theme.palette.error.dark,
																			ml: 1,
																			fontSize: '0.8em',
																		}}>
																		neaktivní
																	</Typography>
																)}
															</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														<TableRow>
															<TableCell align="left" width={100}></TableCell>
															<TableCell align="left" width={100}>
																SPZ:
															</TableCell>
															<TableCell align="left">{car.spz}</TableCell>
														</TableRow>
													</TableBody>
												</Table>
											</Box>

											{isLoading && (
												<Box
													sx={{
														marginY: 4,
														width: '100%',
													}}>
													<LinearProgress color="info" />
												</Box>
											)}

											{contracts && contracts?.length > 0 && (
												<Box sx={{ margin: 1, marginBottom: 3 }}>
													<Table size="small" aria-label="purchases">
														<TableHead>
															<TableRow
																sx={{
																	backgroundColor: theme.palette.grey[200],
																}}>
																<TableCell align="left" width={100}></TableCell>
																<TableCell align="left" colSpan={4}>
																	Zakázky
																</TableCell>
															</TableRow>
														</TableHead>
														<TableBody>
															{contracts.map((contract: ContractDB) => {
																return (
																	<TableRow key={`contract_${contract.id}`}>
																		<TableCell align="left" width={100}></TableCell>
																		<TableCell align="left" width={250}>
																			{contract.name}
																		</TableCell>
																		<TableCell align="left">
																			{dayjs(contract.dateFrom).format(
																				'DD.MM.YYYY'
																			)}
																		</TableCell>
																		<TableCell align="left">
																			{dayjs(contract.dateTo).format(
																				'DD.MM.YYYY'
																			)}
																		</TableCell>

																		<TableCell align="center">
																			<Link
																				to={`/contracts/report/${contract.id}`}
																				target="_blank"
																				rel="noopener noreferrer">
																				<OpenInNewOutlinedIcon
																					fontSize="small"
																					color="primary"
																				/>
																			</Link>
																		</TableCell>
																	</TableRow>
																);
															})}
														</TableBody>
													</Table>
												</Box>
											)}
										</Collapse>
									</TableCell>
								</TableRow>
							</React.Fragment>
						);
					}
				)}
		</>
	);
};

export default DataTableRows;
