import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import ButtonWithStartIconNonResponsive from '../../../../components/uiComponents/ButtonWithStartIconNonResponsive';

type Props = {
	onAdd: () => void;
};

const AddReport = ({ onAdd }: Props) => {
	return (
		<>
			<Box
				component="div"
				sx={{
					display: 'flex',
					justifyContent: 'center',
					flexDirection: { xs: 'column', sm: 'row' },
					alignItems: 'center',

					background: 'white',
					paddingX: 0,
					paddingY: 0,
					marginTop: 0,
					marginBottom: 0,
					borderRadius: 2,
				}}>
				<ButtonWithStartIconNonResponsive title="přidat výkaz" icon={<AddIcon />} onClickHeaderButton={onAdd} />
			</Box>
		</>
	);
};

export default AddReport;
