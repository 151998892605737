import {
	Box,
	Collapse,
	IconButton,
	LinearProgress,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	useTheme,
} from '@mui/material';

import { useAppContext } from '../../../../../context/appContext/appContext';
import TableCellView from '../../../../../components/pageContent/TableView/TableCellView';
import TableCellEditIcon from '../../../../../components/pageContent/TableView/TableCellEditIcon';
import TableCellDeleteIcon from '../../../../../components/pageContent/TableView/TableCellDeleteIcon';

import { useState } from 'react';

import React from 'react';
import dayjs from 'dayjs';
import locale from 'dayjs/locale/cs';
import { Link } from 'react-router-dom';
import { DocumentReportDB } from '../../types';
import TableCellDownloadIcon from '../../../../../components/pageContent/TableView/TableCellDownloadIcon';
import ReportWorkers from '../../../../../components/pdf/ReportWorkers';
import TableCellPdfIcon from '../../../../../components/pageContent/TableView/TableCellPdfIcon';

type Props = {
	documentsReport: DocumentReportDB[] | null;
	onDeleteConfirm: (id: number, name: string) => void;
	onShowPdf: (id: number) => void;
	onPdfDownload: (id: number, type: string) => Promise<void>;
	rowsPerPage: number;
	page: number;
	status: string;
};

const DataTableRows = ({
	documentsReport,
	onDeleteConfirm,
	onShowPdf,
	onPdfDownload,
	rowsPerPage,
	page,
	status,
}: Props) => {
	const { state } = useAppContext();
	const theme = useTheme();

	return (
		<>
			{!documentsReport?.length && (
				<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
					<TableCellView align="left" w="auto" colspan={6}>
						Nebyl nalezen žádný záznam.
					</TableCellView>
				</TableRow>
			)}

			{documentsReport &&
				(rowsPerPage > 0
					? documentsReport.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
					: documentsReport
				).map((report: DocumentReportDB, index: number) => {
					return (
						<React.Fragment key={report.id}>
							{report.type === 'worker' && (
								<TableRow hover>
									<TableCellView align="center" w={50}>
										{rowsPerPage > 0 ? page * rowsPerPage + index + 1 : index + 1}
									</TableCellView>
									<TableCellView align="left" mW={120} w="auto">
										{dayjs(report.date)
											.locale({ ...locale })
											.format('DD.MM.YYYY')}
									</TableCellView>
									<TableCellView align="left" mW={100} w="auto">
										{report.documentNumber}
									</TableCellView>
									<TableCellView align="left" mW={200} w="auto">
										<>{report.type === 'worker' && 'pracovníci'}</>
									</TableCellView>

									{/* <TableCellPdfIcon onShowPdf={onShowPdf} idPdf={report.id} /> */}

									<TableCellDownloadIcon
										name={report.documentNumber}
										type={report.type}
										idDownload={report.id}
										onPdfDownload={onPdfDownload}
									/>

									{status === 'closed' && (
										<TableCellView align="center" w={30}>
											{' '}
										</TableCellView>
									)}
									{status !== 'closed' && (
										<TableCellDeleteIcon
											onDeleteConfirm={onDeleteConfirm}
											idDelete={report.id}
											nameDelete={`č. ${report.id} ze dne ${dayjs(report.date)
												.locale({ ...locale })
												.format('DD.MM.YYYY')}, pracovníci`}
										/>
									)}

									<TableCellView
										align="right"
										w={state.user?.role === 'admin' ? 60 : '5px'}
										pX={state.user?.role === 'admin' ? undefined : '5px'}>
										{state.user?.role === 'admin' ? report.id : ''}
									</TableCellView>
								</TableRow>
							)}

							{report.type === 'car' && (
								<>
									<TableRow hover>
										<TableCellView align="center" w={50}>
											{rowsPerPage > 0 ? page * rowsPerPage + index + 1 : index + 1}
										</TableCellView>
										<TableCellView align="left" mW={120} w="auto">
											{dayjs(report.date)
												.locale({ ...locale })
												.format('DD.MM.YYYY')}
										</TableCellView>
										<TableCellView align="left" mW={100} w="auto">
											{report.documentNumber}
										</TableCellView>
										<TableCellView align="left" mW={200} w="auto">
											vozidla
										</TableCellView>

										{/* <TableCellPdfIcon onShowPdf={onShowPdf} idPdf={report.id} /> */}

										<TableCellDownloadIcon
											name={report.documentNumber}
											type={'car'}
											idDownload={report.id}
											onPdfDownload={onPdfDownload}
										/>

										{status === 'closed' && (
											<TableCellView align="center" w={30}>
												{' '}
											</TableCellView>
										)}

										{status !== 'closed' && (
											<TableCellDeleteIcon
												onDeleteConfirm={onDeleteConfirm}
												idDelete={report.id}
												nameDelete={`č. ${report.id} ze dne ${dayjs(report.date)
													.locale({ ...locale })
													.format('DD.MM.YYYY')}, auta + řidiči`}
											/>
										)}

										<TableCellView
											align="right"
											w={state.user?.role === 'admin' ? 60 : '5px'}
											pX={state.user?.role === 'admin' ? undefined : '5px'}>
											{state.user?.role === 'admin' ? report.id : ''}
										</TableCellView>
									</TableRow>

									<TableRow hover>
										<TableCellView align="center" w={50}>
											{' '}
										</TableCellView>
										<TableCellView align="left" mW={120} w="auto">
											{dayjs(report.date)
												.locale({ ...locale })
												.format('DD.MM.YYYY')}
										</TableCellView>
										<TableCellView align="left" mW={100} w="auto">
											{report.documentNumber}
										</TableCellView>
										<TableCellView align="left" mW={200} w="auto">
											řidiči
										</TableCellView>

										{/* <TableCellPdfIcon onShowPdf={onShowPdf} idPdf={report.id} /> */}

										<TableCellDownloadIcon
											name={report.documentNumber}
											type={'driver'}
											idDownload={report.id}
											onPdfDownload={onPdfDownload}
										/>

										<TableCellView align="right" w={30}>
											{' '}
										</TableCellView>

										<TableCellView
											align="right"
											w={state.user?.role === 'admin' ? 60 : '5px'}
											pX={state.user?.role === 'admin' ? undefined : '5px'}>
											{' '}
										</TableCellView>
									</TableRow>
								</>
							)}

							{/* {report.type === 'car' && 'auta + řidiči'} */}
						</React.Fragment>
					);
				})}
		</>
	);
};

export default DataTableRows;
