import { TableRow } from '@mui/material';
import TableCellViewTitle from '../../../../components/pageContent/TableView/TableCellViewTitle';
import { useAppContext } from '../../../../context/appContext/appContext';

type Props = {};

const TitleTableRow = (props: Props) => {
	const { state } = useAppContext();

	return (
		<TableRow>
			<TableCellViewTitle align="center" w={50}>
				#
			</TableCellViewTitle>

			<TableCellViewTitle align="center" w={30}>
				{' '}
			</TableCellViewTitle>

			<TableCellViewTitle align="left" w={'auto'}>
				Název zakázky
			</TableCellViewTitle>

			<TableCellViewTitle
				align="right"
				w={state.user?.role === 'admin' ? 40 : '5px'}
				pX={state.user?.role === 'admin' ? undefined : '5px'}>
				{state.user?.role === 'admin' ? 'ID' : ' '}
			</TableCellViewTitle>
		</TableRow>
	);
};

export default TitleTableRow;
