import React, { useCallback, useState } from 'react';

import { MobileDatePicker } from '@mui/x-date-pickers';

import csCZ from 'dayjs/locale/cs';
import dayjs, { Dayjs } from 'dayjs';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { Box, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, TextField } from '@mui/material';

type Props = {
	handleFilterDate: (dateFromForm: Dayjs, dateToForm: Dayjs) => void;
	handleFilterStatus: (filterStatus: string) => void;
	dateFrom: Dayjs;
	dateTo: Dayjs;
	filterStatus: string;
	isMyContract?: boolean;
};

const FilterReport = ({
	handleFilterDate,
	handleFilterStatus,
	dateFrom,
	dateTo,
	filterStatus,
	isMyContract = false,
}: Props) => {
	const handleFilterStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		handleFilterStatus(event.target.value);
	};

	return (
		<Box
			component="div"
			sx={{
				display: 'flex',
				justifyContent: 'flex-start',
				flexDirection: { xs: 'column', sm: 'column', lg: 'row' },
				alignItems: { xs: 'flex-start', sm: 'flex-start', lg: 'center' },
				width: '100%',
				background: '#eee',
				paddingX: 2,
				paddingY: 2,
				marginTop: 2,
				marginBottom: 2,
				borderRadius: 2,
			}}>
			<Box
				sx={{
					marginRight: { lg: 3 },
					display: 'flex',
					justifyContent: 'space-between',
					flexDirection: 'row',
					alignItems: 'center',
					maxWidth: 300,
					marginBottom: 0,
					paddingBottom: 0,
				}}>
				<LocalizationProvider adapterLocale={csCZ} dateAdapter={AdapterDayjs}>
					<MobileDatePicker
						label="Datum od"
						inputFormat="DD.MM.YYYY"
						value={dateFrom}
						onChange={newValue => {
							handleFilterDate(newValue!, dateTo);
						}}
						renderInput={params => (
							<TextField
								variant="filled"
								sx={{
									width: '48%',
									marginBottom: 0,
									paddingBottom: 0,
								}}
								{...params}
							/>
						)}
					/>

					<MobileDatePicker
						label="Datum do"
						inputFormat="DD.MM.YYYY"
						value={dateTo}
						onChange={newValue => {
							handleFilterDate(dateFrom, newValue!);
						}}
						renderInput={params => (
							<TextField
								variant="filled"
								sx={{
									width: '48%',
									marginBottom: 0,
									paddingBottom: 0,
								}}
								{...params}
							/>
						)}
					/>
				</LocalizationProvider>
			</Box>

			<Box
				component="div"
				sx={{
					marginTop: { xs: 2, lg: 0 },
					marginBottom: 0,
					paddingBottom: 0,
				}}>
				<FormControl
					sx={{
						marginBottom: 0,
						paddingBottom: 0,
					}}>
					<FormLabel id="status">Výkazy</FormLabel>
					<RadioGroup
						row
						aria-labelledby="status"
						defaultValue={''}
						name="filterStatus"
						value={filterStatus}
						onChange={handleFilterStatusChange}>
						<FormControlLabel value="" control={<Radio />} label="aktivní" />
						<FormControlLabel value="closed" control={<Radio />} label="uzavřené" />
						<FormControlLabel value="finished" control={<Radio />} label="vyúčtované" />
					</RadioGroup>
				</FormControl>
			</Box>
		</Box>
	);
};

export default FilterReport;
