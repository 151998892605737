import React, { useState } from 'react';
import { useAppContext } from '../../context/appContext/appContext';

import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { resetMyPasswordSchema, userProfileSchema, userSchema } from '../../utils/joiSchema';
import { dict } from '../../translate/translate-dictionary';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';

import { MyPasswordFormInputs, UserProfileFormInputs } from '../users/types';

type Props = {
	onClose: () => void;
	onFormSubmit: (data: MyPasswordFormInputs) => Promise<string | undefined>;
};

const MyPasswordForm = ({ onClose, onFormSubmit }: Props) => {
	const [masterError, setMasterError] = useState<string>('');
	const { state } = useAppContext();

	const lang = state.lang;

	const {
		register,
		handleSubmit,

		formState: { errors },
	} = useForm<MyPasswordFormInputs>({
		defaultValues: {
			oldPassword: '',
			password: '',
			passwordConfirm: '',
		},
		resolver: joiResolver(resetMyPasswordSchema),
		mode: 'onChange',
		delayError: 2000,
	});

	const onSubmit = async (data: MyPasswordFormInputs) => {
		const response = await onFormSubmit(data);

		if (response) setMasterError(response);
	};

	return (
		<>
			<Box
				component="form"
				noValidate
				onSubmit={handleSubmit(onSubmit)}
				sx={{
					mt: 1,
				}}>
				{/* ________ FIRST and LAST NAME _______ */}

				<TextField
					{...register('oldPassword')}
					margin="normal"
					label="Aktuální heslo"
					error={!!errors?.oldPassword}
					helperText={
						errors?.oldPassword?.message
							? dict[errors.oldPassword.message]
								? dict[errors.oldPassword.message][lang]
								: errors?.oldPassword?.message
							: ' '
					}
					variant="filled"
					size="medium"
					type="text"
					inputProps={{ maxLength: 100 }}
					sx={{ width: { xs: '100%' } }}
				/>

				<TextField
					{...register('password')}
					margin="normal"
					label="Nové heslo"
					error={!!errors?.password}
					helperText={
						errors?.password?.message
							? dict[errors.password.message]
								? dict[errors.password.message][lang]
								: errors?.password?.message
							: ' '
					}
					variant="filled"
					size="medium"
					type="text"
					inputProps={{ maxLength: 100 }}
					sx={{ width: { xs: '100%' } }}
				/>

				<TextField
					{...register('passwordConfirm')}
					margin="normal"
					label="Nové heslo potvrzení"
					error={!!errors?.passwordConfirm}
					helperText={
						errors?.passwordConfirm?.message
							? dict[errors.passwordConfirm.message]
								? dict[errors.passwordConfirm.message][lang]
								: errors?.passwordConfirm?.message
							: ' '
					}
					variant="filled"
					size="medium"
					type="text"
					inputProps={{ maxLength: 100 }}
					sx={{ width: { xs: '100%' } }}
				/>

				<Stack sx={{ width: '100%', marginTop: 2 }} spacing={3}>
					{masterError && (
						<Alert sx={{ borderRadius: '0.5em' }} severity="error">
							{dict[masterError] ? dict[masterError][lang] : masterError ? masterError : ' '}
						</Alert>
					)}
				</Stack>

				<Stack direction="row" justifyContent="center" alignItems="center" spacing={2} mt={3}>
					<Button variant="contained" size="large" color="inherit" onClick={onClose}>
						zrušit
					</Button>
					<Button variant="contained" type="submit" size="large">
						Změnit
						<Box component={'span'} sx={{ display: { xs: 'none', sm: 'inline' } }}>
							&nbsp;heslo
						</Box>
					</Button>
				</Stack>
			</Box>
		</>
	);
};

export default MyPasswordForm;
