import React, { useState } from 'react';
import Loading from '../../../../components/pageContent/Loading';
import PageTitle from '../../../../components/pageContent/PageTitle';
import DeleteDialog from '../../../../components/pageContent/TableView/DeleteDialog';
import SnackbarAfterAction from '../../../../components/uiComponents/SnackbarAfterAction';
import { useData } from '../../../../utils/useData';
import { useUpdate } from '../../../../utils/useUpdate';
import { UserDB } from '../../../users/types';
import { ContractDB, ContractUserDB, ContractUserFormInput } from '../types';

// _______________ mui ___________________
import AddIcon from '@mui/icons-material/Add';
import { AlertColor, Box, Button, Divider, SelectChangeEvent, Stack, Typography } from '@mui/material';
import SelectUser from './SelectUser';
import FormEditUser from './FormEditUser';
import TableView from '../../../../components/pageContent/TableView/TableView';
import TitleTableRow from './TitleTableRow';
import DataTableRows from './DataTableRows';

type Props = {
	contractId: number;
	contractEdit: ContractDB | null;
	isLoadingParent: boolean;
	onCloseEdit: () => void;
};

const TabUser = ({ contractId, contractEdit, isLoadingParent, onCloseEdit }: Props) => {
	const [openFormUser, setOpenFormUser] = useState<boolean>(false);
	const [editID, setEditID] = useState<number | null>(null);
	const [editData, setEditData] = useState<ContractUserDB | null>(null);
	const [editName, setEditName] = useState<string>('');

	const [contractUserData, setContractUserData] = useState<ContractUserDB[] | null>(null); // zobrazeni pro vypis table
	const [usedUser, setUsedUser] = useState<number[] | null>(null); // tyto se nam vyfiltrujou z nabidky pro pouziti

	const [contractFirstUserData, setContractFirstUserData] = useState<ContractUserDB | null>(null);

	const [userSelect, setUserSelect] = useState<UserDB[] | null>(null); // nabidka uzivatelu pro zakazku
	const [userSelected, setUserSelected] = useState<number | null>(null); // vybrane auto pro pridani do zakazky

	const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
	const [deleteId, setDeleteId] = React.useState<number | null>(null);
	const [deleteName, setDeleteName] = React.useState<string>('');

	const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
	const [msgSnackbar, setMsgSnackbar] = useState<string>('');
	const [severitySnackbar, setSeveritySnackbar] = useState<AlertColor>('success');

	// ____________ USE DATA - NACTENI CONTRACT USER ______________________
	const {
		isLoading: isLoading,
		data: contractUsers,
		refetch,
	} = useData<ContractUserDB[], { contractUsers: ContractUserDB[] }>(
		`/contracts/contract-to-user/${contractId}`,
		data => {
			setContractUserData(data.contractUsers);
			setUsedUser(data.contractUsers.map(item => item.userId));
			return data.contractUsers;
		}
	);

	// ____________ USE DATA - NACTENI CONTRACT FIRST USER ______________________
	const {
		isLoading: isLoadingFirstUser,
		data: contractFirstUser,
		refetch: refetchFirstUser,
	} = useData<ContractUserDB, { contractUser: ContractUserDB }>(
		`/contracts/contract-to-user-first/${contractId}`,
		data => {
			setContractFirstUserData(data.contractUser);
			return data.contractUser;
		}
	);

	// ____________ USE DATA - NACTENI USERS ______________________
	const { isLoading: isLoadingUsers, data: users } = useData<UserDB[], { users: UserDB[] }>('/users', data => {
		// const newData = data.cars.filter(item => !usedCars?.includes(item.id));

		setUserSelect(data.users);
		return data.users;
	});

	// ____________ USE UPDATE - OSTATNÍ METODY ______________________
	const {
		isLoading: isUpdateLoading,
		data: dataEditing,
		update: updateContractUser,
		error: updateError,
	} = useUpdate<{ status: string; error?: string; contractUser?: ContractUserDB }>('');

	// ____________ CANCEL ADD USER ______________________
	const handleCancelUserAdd = () => {
		setUserSelected(null);
		setOpenFormUser(false);
	};

	// ____________ SET ADD USER ______________________
	const handleAdd = (): void => {
		setOpenFormUser(true);
		setEditID(null);
		setEditData(null);
		setEditName('');
		setUserSelected(null);
	};

	// ____________ SET EDIT USER ______________________
	const handleEditUser = async (id: number): Promise<void> => {
		setUserSelected(null);
		setOpenFormUser(false);

		const response = await updateContractUser(null, 'GET', `/contracts/contract-to-user/${contractId}/${id}`);

		if (!response) {
			return;
		}

		if (response?.status === 'success') {
			if (response.contractUser) {
				setEditData(response.contractUser);
				setEditName(`${response.contractUser.user.lastName} ${response.contractUser.user.firstName}`);
				setUserSelected(response.contractUser.userId);
			}
		}

		setOpenFormUser(true);
		setEditID(id);
	};

	// ____________ USER CHANGE ______________________
	const handleUserChange = (id: number | null) => {
		setUserSelected(id);
	};

	// ____________ DELETE ______________________
	const handleDeleteUser = async (): Promise<void> => {
		const response = await updateContractUser(null, 'DELETE', `/contracts/contract-to-user/${deleteId}`);
		if (!response) {
			return;
		}

		if (response.status === 'error') {
			if (response.error === 'RECORD_CANNOT_BE_DELETED') {
				setMsgSnackbar(`Pracovník "${deleteName}" nelze ze zakázky smazat!`);
				setSeveritySnackbar('error');
				setOpenSnackbar(true);
			}
		} else {
			await refetch();
			await refetchFirstUser();
			setMsgSnackbar(`Pracovník "${deleteName}" byl vymazán.`);
			setSeveritySnackbar('success');
			setOpenSnackbar(true);
		}

		setDeleteId(null);
		setDeleteName('');
		handleCloseDeleteDialog();
	};

	// ____________ DELETE CONFIRM ______________________
	const handleDeleteConfirm = (id: number, name: string) => {
		setDeleteId(id);
		setDeleteName(name);
		setOpenDeleteDialog(true);
	};

	// ____________ DELETE CLOSE DIALOG ______________________
	const handleCloseDeleteDialog = () => {
		setDeleteId(null);
		setDeleteName('');
		setOpenDeleteDialog(false);
	};

	// ____________ SUBMIT FORM ______________________
	const handleFormUserSubmit = async (data: ContractUserFormInput) => {
		if (editID) {
			const response = await updateContractUser(
				{
					buyDriver: data.buyDriver,
					saleDriver: data.saleDriver,
					buyOverDriver: data.buyOverDriver,
					saleOverDriver: data.saleOverDriver,
					buyWorker: data.buyWorker,
					saleWorker: data.saleWorker,
					buyOverWorker: data.buyOverWorker,
					saleOverWorker: data.saleOverWorker,
				},
				'PATCH',
				`/contracts/contract-to-user/${editID}`
			);

			if (response?.status === 'error') {
				return response.error;
			}

			if (!response) {
				return 'ERROR_NETWORK';
			}
		} else {
			const response = await updateContractUser(
				{
					userId: userSelected,
					buyDriver: data.buyDriver,
					saleDriver: data.saleDriver,
					buyOverDriver: data.buyOverDriver,
					saleOverDriver: data.saleOverDriver,
					buyWorker: data.buyWorker,
					saleWorker: data.saleWorker,
					buyOverWorker: data.buyOverWorker,
					saleOverWorker: data.saleOverWorker,
				},
				'POST',
				`/contracts/contract-to-user/${contractId}`
			);

			if (response?.status === 'error') {
				return response.error;
			}

			if (!response) {
				return 'ERROR_NETWORK';
			}
		}

		await refetch();
		await refetchFirstUser();

		setMsgSnackbar(editID ? `Pracovník byl editován.` : `Pracovník byl přidán.`);
		setSeveritySnackbar('success');
		setOpenSnackbar(true);

		setUserSelected(null);
		setOpenFormUser(false);

		setEditID(null);
		setEditName('');

		return undefined;
	};

	// ____________ SNACKBAR ______________________
	const handleCloseSnackbar = () => {
		setOpenSnackbar(false);
	};

	return (
		<>
			<Loading isLoading={isLoading || isLoadingUsers || isUpdateLoading || isLoadingFirstUser} />

			<SnackbarAfterAction
				severity={severitySnackbar}
				openSnackbar={openSnackbar}
				onClose={handleCloseSnackbar}
				msgSnackbar={msgSnackbar}
			/>
			<DeleteDialog onOpen={openDeleteDialog} handleClose={handleCloseDeleteDialog} onDelete={handleDeleteUser} />
			{contractEdit?.status === 'closed' && <PageTitle title={contractEdit?.name ? contractEdit?.name : ''} />}

			{contractEdit?.status !== 'closed' && (
				<PageTitle
					title={contractEdit?.name ? contractEdit?.name : ''}
					buttonText={!openFormUser ? 'přidat pracovníka' : undefined}
					icon={!openFormUser ? <AddIcon /> : undefined}
					onClickHeaderButton={!openFormUser ? handleAdd : undefined}
				/>
			)}

			<Divider sx={{ mb: 3 }} />

			{openFormUser && (
				<>
					<Box
						sx={{
							borderRadius: 2,
							border: 0,
							borderColor: '#ccc',
							marginTop: 1,
							marginBottom: 0,
							paddingX: 1,
							width: '100%',
						}}>
						{editName && (
							<Typography component={'h3'} variant="h5" sx={{ marginTop: 0, marginBottom: 1 }}>
								{editName}
							</Typography>
						)}
						{!editName && (
							<Box
								sx={{
									display: 'flex',
									flexDirection: { xs: 'column', sm: 'row' },
									justifyContent: 'space-between',
									alignItems: 'center',
									width: '100%',
								}}>
								<SelectUser
									userSelect={userSelect}
									usedUser={usedUser}
									onChangeUser={handleUserChange}
									userSelected={userSelected}
								/>
							</Box>
						)}
						{!userSelected && (
							<Stack direction="row" justifyContent="center" alignItems="center" spacing={2} mt={3}>
								<Button variant="contained" size="large" color="inherit" onClick={handleCancelUserAdd}>
									zrušit
									<Box
										component={'span'}
										sx={{ marginLeft: 0.5, display: { xs: 'none', sm: 'inline' } }}>
										{editID ? 'editaci' : 'přidávání'} pracovníka
									</Box>
								</Button>
							</Stack>
						)}

						{/* formular pro editaci cen */}
						{userSelected && (
							<FormEditUser
								onCancel={handleCancelUserAdd}
								onFormSubmit={handleFormUserSubmit}
								userSelected={userSelected}
								editID={editID}
								editData={editData}
								firstUserData={contractFirstUserData}
							/>
						)}
					</Box>

					<Divider sx={{ marginTop: 2, marginBottom: 3 }} />
				</>
			)}

			<TableView
				titleRow={<TitleTableRow />}
				dataRow={
					<DataTableRows
						status={contractEdit?.status}
						contractUsers={contractUsers}
						onEdit={handleEditUser}
						onDeleteConfirm={handleDeleteConfirm}
					/>
				}
				colspan={9}
			/>
		</>
	);
};

export default TabUser;
