import { IconButton, TableCell, useMediaQuery, useTheme } from '@mui/material';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { useAppContext } from '../../../context/appContext/appContext';

type Props = {
	onDeleteConfirm: (id: number, name: string) => void;
	idDelete: number;
	nameDelete: string;
	isDisabled?: boolean;
};

const TableCellDeleteIcon = ({ onDeleteConfirm, idDelete, nameDelete, isDisabled }: Props) => {
	const theme = useTheme();
	const sizeButton = useMediaQuery(theme.breakpoints.up('sm')) ? 'small' : 'medium';
	const { state } = useAppContext();

	return (
		<TableCell align="center" width={30} size="small" sx={{ paddingX: '5px' }}>
			{isDisabled && (
				<IconButton
					disabled
					sx={{
						borderRadius: 1,
						border: 0,
						color: theme.palette.grey[200],
						borderColor: theme.palette.grey[200],
						padding: '0.15em',
					}}
					// onClick={() => onDeleteConfirm(idDelete, nameDelete)}
					aria-label="smazání záznamu"
					size="small">
					{/* <DeleteForeverOutlinedIcon fontSize="small" /> */}
				</IconButton>
			)}
			{!isDisabled && (
				<IconButton
					sx={{
						borderRadius: 1,
						border: 0,
						color: theme.palette.secondary.light,
						borderColor: theme.palette.error.dark,
						padding: '0.15em',
					}}
					onClick={() => onDeleteConfirm(idDelete, nameDelete)}
					aria-label="smazání záznamu"
					size="small">
					<DeleteForeverOutlinedIcon fontSize={sizeButton} />
				</IconButton>
			)}
		</TableCell>
	);
};

export default TableCellDeleteIcon;
