import React from 'react';
import { IconButton, TableRow, useMediaQuery, useTheme } from '@mui/material';
import ContactEmergencyOutlinedIcon from '@mui/icons-material/ContactEmergencyOutlined';

import { useAppContext } from '../../../../context/appContext/appContext';

import TableCellView from '../../../../components/pageContent/TableView/TableCellView';

import { ContractUserDB } from '../../types';

type Props = {
	contractUsers: ContractUserDB[] | null;
	rowsPerPage: number;
	page: number;
	onDetail: (id: number) => void;
};

const DataTableRows = ({ contractUsers, rowsPerPage, page, onDetail }: Props) => {
	const { state } = useAppContext();
	const colspan = 6;

	const theme = useTheme();
	const sizeButton = useMediaQuery(theme.breakpoints.up('sm')) ? 'small' : 'medium';

	return (
		<>
			{!contractUsers?.length && (
				<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
					<TableCellView align="left" w="auto" colspan={colspan}>
						Nebyl nalezen žádný záznam.
					</TableCellView>
				</TableRow>
			)}

			{contractUsers &&
				(rowsPerPage > 0
					? contractUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
					: contractUsers
				).map((conUser: ContractUserDB, index) => {
					return (
						<React.Fragment key={conUser.id}>
							<TableRow hover>
								<TableCellView align="center" w={50}>
									{index + 1}
								</TableCellView>

								<TableCellView align="center" w={30}>
									<IconButton
										onClick={() => onDetail(conUser.id)}
										sx={{
											borderRadius: 1,
											border: 0,
											color: theme.palette.grey[500],
											borderColor: theme.palette.info.dark,
											padding: '0.15em',
										}}
										size="small">
										<ContactEmergencyOutlinedIcon color={'info'} fontSize={sizeButton} />
									</IconButton>
								</TableCellView>

								<TableCellView align="left" mW={150} w="auto">
									<>
										{conUser.user.lastName} {conUser.user.firstName}
									</>
								</TableCellView>

								<TableCellView align="left" mW={150} w="auto">
									{conUser.user.phone}
								</TableCellView>

								<TableCellView align="left" mW={150} w="auto">
									{conUser.user.email}
								</TableCellView>

								<TableCellView align="center" w={30}>
									{' '}
								</TableCellView>
							</TableRow>
						</React.Fragment>
					);
				})}
		</>
	);
};

export default DataTableRows;
