import React, { useEffect, useState } from 'react';
import { useContractsContext } from '../../../context/contractsContext/ContractsContext';

import PageTitle from '../../../components/pageContent/PageTitle';

import AddIcon from '@mui/icons-material/Add';

import { useNavigate } from 'react-router-dom';

import PermissionAlert from '../../../components/pageContent/PermissionAlert';
import { useAppContext } from '../../../context/appContext/appContext';

import { useData } from '../../../utils/useData';
import Loading from '../../../components/pageContent/Loading';
import SnackbarAfterAction from '../../../components/uiComponents/SnackbarAfterAction';
import DeleteDialog from '../../../components/pageContent/TableView/DeleteDialog';
import { AlertColor, TablePagination } from '@mui/material';
import { useUpdate } from '../../../utils/useUpdate';
import TableView from '../../../components/pageContent/TableView/TableView';

import { dict } from '../../../translate/translate-dictionary';

import FilterContract from './FilterContract';
import { ContractCountDB, ContractDB } from '../edit/types';
import TitleTableRow from './dataTable/TitleTableRow';
import DataTableRows from './dataTable/DataTableRows';
import ActiveDialog from '../../../components/pageContent/TableView/ActiveDialog';

type Props = {};

const ContractsList = (props: Props) => {
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [deleteId, setDeleteId] = useState<number | null>(null);
	const [deleteName, setDeleteName] = useState<string>('');

	const [openActiveDialog, setOpenActiveDialog] = useState(false);
	const [activeId, setActiveId] = useState<number | null>(null);
	const [activeName, setActiveName] = useState<string>('');
	const [activeStatus, setActiveStatus] = useState<string>('');

	const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
	const [msgSnackbar, setMsgSnackbar] = useState<string>('');
	const [severitySnackbar, setSeveritySnackbar] = useState<AlertColor>('success');

	const { state, setRowsPerPage } = useAppContext();

	const { state: stateContract, setPage, setInitContracts, refetchContracts } = useContractsContext();

	const navigate = useNavigate();

	const lang = state.lang;

	// ____________ USE UPDATE - OSTATNÍ METODY ______________________
	const {
		isLoading: isUpdateLoading,
		data: dataEditing,
		update: updateContract,
		error: updateError,
	} = useUpdate<{ status: string; error?: string; contract?: ContractDB }>('');

	// ____________ SET ADD CONTRACT ______________________
	const handleAddContract = (): void => {
		navigate(`/contracts/detail`);
	};

	// ____________ EDIT CONTRACT ______________________
	const handleEditContract = (id: number): void => {
		navigate(`/contracts/detail/${id}`);
	};

	// ____________ OPEN ACTIVE CONFIRM ______________________
	const handleActiveConfirm = (id: number, name: string, status: string): void => {
		setActiveId(id);
		setActiveName(name);
		setActiveStatus(status);
		setOpenActiveDialog(true);
	};

	// ____________ CLOSE ACTIVE CONFIRM ______________________
	const handleCloseActiveConfirm = (): void => {
		setActiveId(null);
		setActiveName('');
		setActiveStatus('');
		setOpenActiveDialog(false);
	};

	// ____________ HANDLE STATUS ______________________
	const handleSetStatus = async (): Promise<void> => {
		const response = await updateContract(
			{ contractId: activeId, status: activeStatus },
			'POST',
			`/contracts/set-active`
		);
		if (!response) {
			setMsgSnackbar(`Zakázku "${activeName}" nelze smazat! Pravděpodobně není připojení k internetu.`);
			setSeveritySnackbar('error');
		} else {
			if (response.status === 'error') {
				if (response.error === 'RECORD_CANNOT_BE_CLOSED_CAR') {
					setMsgSnackbar(
						`Zakázka "${activeName}" nelze uzavřít, protože minimálně jedno vozidlo není vyúčtované.`
					);
					setSeveritySnackbar('error');
				} else if (response.error === 'RECORD_CANNOT_BE_CLOSED_DRIVER') {
					setMsgSnackbar(
						`Zakázka "${activeName}" nelze uzavřít, protože minimálně jeden řidič není vyúčtovaný.`
					);
					setSeveritySnackbar('error');
				} else if (response.error === 'RECORD_CANNOT_BE_CLOSED_WORKER') {
					setMsgSnackbar(
						`Zakázka "${activeName}" nelze uzavřít, protože minimálně jeden pracovník není vyúčtovaný.`
					);
					setSeveritySnackbar('error');
				}
			} else {
				refetchContracts();
				activeStatus === 'active' && setMsgSnackbar(`Zakázka "${activeName}" byla uzavřena.`);
				activeStatus === 'closed' && setMsgSnackbar(`Zakázka "${activeName}" byla otevřena.`);
				setSeveritySnackbar('success');
			}
		}

		setOpenSnackbar(true);
		setActiveId(null);
		setActiveName('');
		setActiveStatus('');
		setOpenActiveDialog(false);
	};

	// ____________ DELETE ______________________
	const handleDelete = async (): Promise<void> => {
		const response = await updateContract(null, 'DELETE', `/contracts/${deleteId}`);
		if (!response) {
			return;
		}
		if (response.status === 'error') {
			if (response.error === 'RECORD_CANNOT_BE_DELETED') {
				setMsgSnackbar(`Zakázku "${deleteName}" nelze smazat!`);
				setSeveritySnackbar('error');
				setOpenSnackbar(true);
			}
		} else {
			refetchContracts();
			setMsgSnackbar(`Zakázka "${deleteName}" byla vymazána.`);
			setSeveritySnackbar('success');
			setOpenSnackbar(true);
		}
		setDeleteId(null);
		setDeleteName('');
		handleCloseDeleteDialog();
	};

	// ____________ DELETE CONFIRM ______________________
	const handleDeleteConfirm = (id: number, name: string) => {
		setDeleteId(id);
		setDeleteName(name);
		setOpenDeleteDialog(true);
	};

	// ____________ DELETE CLOSE DIALOG ______________________
	const handleCloseDeleteDialog = () => {
		setDeleteId(null);
		setOpenDeleteDialog(false);
	};

	// ____________ SNACKBAR ______________________
	const handleCloseSnackbar = () => {
		setOpenSnackbar(false);
	};

	// ____________ SET PAGE ______________________
	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	// ____________ SET ROWS PER PAGE ______________________
	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	// ____________ REPORT  ______________________
	const handleReport = (id: number): void => {
		navigate(`/contracts/report/${id}`);
	};

	// ____________ NASTAVENI INICIALIZACE NEBO PO NAVRATU REFETCH ______________________
	useEffect(() => {
		if (stateContract.isInit === false) {
			setInitContracts();
		} else {
			refetchContracts();
		}
	}, [setInitContracts, refetchContracts, stateContract.isInit]);

	// _______________________ VYSTUP ________________________

	if (!state.user?.roles.includes('editor')) {
		return <PermissionAlert />;
	}

	return (
		<>
			<Loading isLoading={stateContract.isLoading || isUpdateLoading} />
			<SnackbarAfterAction
				severity={severitySnackbar}
				openSnackbar={openSnackbar}
				onClose={handleCloseSnackbar}
				msgSnackbar={msgSnackbar}
			/>
			<DeleteDialog onOpen={openDeleteDialog} handleClose={handleCloseDeleteDialog} onDelete={handleDelete} />
			<ActiveDialog
				onOpen={openActiveDialog}
				handleClose={handleCloseActiveConfirm}
				onSetStatus={handleSetStatus}
				activeName={activeName}
				status={activeStatus}
			/>

			<PageTitle
				title="Zakázky"
				buttonText="vytvořit zakázku"
				icon={<AddIcon />}
				onClickHeaderButton={handleAddContract}
			/>
			<FilterContract />
			<TableView
				titleRow={<TitleTableRow />}
				dataRow={
					<DataTableRows
						contracts={stateContract.contracts}
						onReport={handleReport}
						onEdit={handleEditContract}
						onDeleteConfirm={handleDeleteConfirm}
						onActiveConfirm={handleActiveConfirm}
						rowsPerPage={state.rowsPerPage}
						page={stateContract.page}
					/>
				}
				tablePagination={
					// stateContract.contracts?.length ? (
					// 	stateContract.contracts?.length > state.rowsPerPage ? (
					<TablePagination
						count={stateContract.contracts ? stateContract.contracts.length : 0}
						component="div"
						rowsPerPage={state.rowsPerPage}
						page={stateContract.page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						rowsPerPageOptions={[5, 10, 15]}
						labelRowsPerPage={dict['ROWS_PER_PAGE'][lang]}
					/>
					// 	) : undefined
					// ) : undefined
				}
				colspan={9}
			/>
		</>
	);
};

export default ContractsList;
