import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CancelPresentationOutlinedIcon from '@mui/icons-material/CancelPresentationOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DisabledByDefaultOutlinedIcon from '@mui/icons-material/DisabledByDefaultOutlined';

import { IconButton, useTheme } from '@mui/material';

type Props = {
	title: string;
	onClose: () => void;
};

const ModalHeader = ({ title, onClose }: Props) => {
	const theme = useTheme();
	return (
		<Box mb={2} component="header">
			<Box
				sx={{
					paddingBottom: 0,
					alignItems: 'center',
					display: 'flex',
					justifyContent: 'space-between',
					borderBottom: 1,
					borderColor: theme.palette.info.main,
				}}>
				<Typography
					color={theme.palette.info.main}
					sx={{ typography: { md: 'h6', sm: 'subtitle1', xs: 'subtitle1' } }}
					component="h2">
					{title}
				</Typography>
				<IconButton onClick={onClose} color="error" size="large">
					<CloseOutlinedIcon />
				</IconButton>
			</Box>
		</Box>
	);
};

export default ModalHeader;
