import { Box, Collapse, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import TableCellDeleteIcon from '../../../../../components/pageContent/TableView/TableCellDeleteIcon';
import TableCellEditIcon from '../../../../../components/pageContent/TableView/TableCellEditIcon';
import { DriverWorkedHours, ReportToCarDB, ReportToDriverDB, ReportToWorkerDB } from '../../types';

type Props = {
	report: ReportToDriverDB;
	onOpen: (id: number) => {};
	open: boolean;
	openId: number;
};

const DataCollapse = ({ report, onOpen, open, openId }: Props) => {
	const theme = useTheme();

	return (
		<>
			{/* ________________ COLLAPSE ROW __________________________ */}

			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
					<Collapse in={open && openId === report.id} timeout="auto" unmountOnExit>
						<Box sx={{ margin: 1, marginBottom: 3 }}>
							{report.reportDetails.map(detail => {
								return (
									<React.Fragment key={detail.id}>
										<Table size="small" aria-label="purchases" sx={{ marginTop: 2 }}>
											<TableBody sx={{ marginBottom: 2 }}>
												<TableRow>
													<TableCell align="left" colSpan={2}>
														<Typography
															sx={{ fontWeight: 800 }}
															component={'span'}
															variant={'body2'}>
															{`${detail.car.name}`}
														</Typography>
														<Typography
															sx={{ marginLeft: 1 }}
															component={'span'}
															variant={'caption'}>
															{`SPZ: ${detail.car.spz}`}
														</Typography>
													</TableCell>
													<TableCell align="right" width={100}>
														{detail.status === 'active' ? (
															<Typography
																sx={{ color: theme.palette.success.main }}
																component={'span'}
																variant={'caption'}>
																aktivní
															</Typography>
														) : (
															<Typography
																sx={{ color: theme.palette.error.main }}
																component={'span'}
																variant={'caption'}>
																uzavřeno
															</Typography>
														)}
													</TableCell>
												</TableRow>

												<TableRow
													sx={{
														borderBottom: detail.costsDriver > 0 ? 0 : 2,
														borderColor: '#ccc',
													}}>
													<TableCell align="left" colSpan={3}>
														{`${dayjs(detail.timeFrom).format('HH:mm')} - ${dayjs(
															detail.timeTo
														).format('HH:mm')} (${detail.workedHours * 1}), ${
															detail.driveKm * 1
														} km, ${detail.description}`}
													</TableCell>
												</TableRow>

												{detail.costsDriver > 0 && (
													<TableRow
														sx={{
															borderBottom: 2,
															borderColor: '#ccc',
														}}>
														{/* <TableCell align="left" width={100}></TableCell> */}
														<TableCell align="left" width={100}>
															Vícenáklady:
														</TableCell>
														<TableCell align="left" colSpan={2}>
															{`${(detail.costsDriver * 1).toLocaleString()}, ${
																detail.descriptionCostsDriver
															}`}
														</TableCell>
													</TableRow>
												)}
											</TableBody>
										</Table>
									</React.Fragment>
								);
							})}
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
};

export default DataCollapse;
