type DictKey = {
	[index: string]: string;
};

type Dict = {
	[index: string]: {
		cs: string;
		en: string;
	};
};

export const dictKey: DictKey = {
	INVALID_CREDENTIALS: 'INVALID_CREDENTIALS',
	ENTER_EMAIL_IN_THE_CORRECT_FORMAT: 'ENTER_EMAIL_IN_THE_CORRECT_FORMAT',
	PLEASE_ENTER_EMAIL: 'PLEASE_ENTER_EMAIL',
	FIELD_REQUIRES_MIN_2_CHARACTERS: 'FIELD_REQUIRES_MIN_2_CHARACTERS',
	FIELD_REQUIRES_MIN_5_CHARACTERS: 'FIELD_REQUIRES_MIN_5_CHARACTERS',

	FIELD_REQUIRES_MIN_8_CHARACTERS: 'FIELD_REQUIRES_MIN_8_CHARACTERS',
	FIELD_REQUIRES_MAX_6_CHARACTERS: 'FIELD_REQUIRES_MAX_6_CHARACTERS',

	FIELD_REQUIRES_MAX_20_CHARACTERS: 'FIELD_REQUIRES_MAX_20_CHARACTERS',
	FIELD_REQUIRES_MAX_100_CHARACTERS: 'FIELD_REQUIRES_MAX_100_CHARACTERS',
	FIELD_REQUIRES_MAX_250_CHARACTERS: 'FIELD_REQUIRES_MAX_250_CHARACTERS',
	PLEASE_ENTER_PASSWORD: 'PLEASE_ENTER_PASSWORD',
	LOGIN: 'LOGIN',
	LOGIN_SUCCESSFUL_REDIRECTING: 'LOGIN_SUCCESSFUL_REDIRECTING',
	ENTER_EMAIL_AND_PASSWORD: 'ENTER_EMAIL_AND_PASSWORD',
	CORRECT_THE_MARKED_FIELDS: 'CORRECT_THE_MARKED_FIELDS',
	ENTER_THE_REQUIRED_FIELDS: 'ENTER_THE_REQUIRED_FIELDS',
	DB_ERROR: 'DB_ERROR',
	SOMETHING_WRONG: 'SOMETHING_WRONG',
	REFRESH_TOKEN_IS_NOT_VALID: 'REFRESH_TOKEN_IS_NOT_VALID',
	YOUR_LOGIN_HAS_EXPIRED: 'YOUR_LOGIN_HAS_EXPIRED',
	YOU_DO_NOT_HAVE_PERMISSION_FOR_THIS_PAGE: 'YOU_DO_NOT_HAVE_PERMISSION_FOR_THIS_PAGE',
	YOU_DO_NOT_HAVE_PERMISSION_TO_VIEW_THAT_USER: 'YOU_DO_NOT_HAVE_PERMISSION_TO_VIEW_THAT_USER',
	YOU_DO_NOT_HAVE_PERMISSION: 'YOU_DO_NOT_HAVE_PERMISSION',
	USER_IS_NOT_VALID: 'USER_IS_NOT_VALID',
	ROUTE_DOES_NOT_EXIST: 'ROUTE_DOES_NOT_EXIST',
	DB_NOT_FOUND: 'DB_NOT_FOUND',
	ERROR_NETWORK: 'ERROR_NETWORK',
	SELECT_A_VALUE_FROM_SELECT: 'SELECT_A_VALUE_FROM_SELECT',
	SELECT_A_VALUE_FROM_SELECT_CLIENT: 'SELECT_A_VALUE_FROM_SELECT_CLIENT',
	SELECT_A_VALUE_FROM_SELECT_DRIVER: 'SELECT_A_VALUE_FROM_SELECT_DRIVER',
	SELECT_A_VALUE_FROM_SELECT_CAR: 'SELECT_A_VALUE_FROM_SELECT_CAR',
	ROWS_PER_PAGE: 'ROWS_PER_PAGE',
	PASSWORD_DOES_NOT_MATCH: 'PASSWORD_DOES_NOT_MATCH',
	ENTER_DATE: 'ENTER_DATE',
	VALUE_MUST_BE_GRATER_THEN_ZERO: 'VALUE_MUST_BE_GRATER_THEN_ZERO',

	DASHBOARDS: 'DASHBOARDS',
	MY_CONTRACTS: 'MY_CONTRACTS',
	CONTRACTS: 'CONTRACTS',
	DIALS: 'DIALS',
	ADMIN: 'ADMIN',
};
export const dict: Dict = {
	INVALID_CREDENTIALS: {
		cs: 'Přihlašovací údaje nejsou platné!',
		en: 'Invalid Credentials!',
	},
	ENTER_EMAIL_AND_PASSWORD: {
		cs: 'Zadejte prosím email i heslo!',
		en: 'Enter the email and the password!',
	},
	ENTER_EMAIL_IN_THE_CORRECT_FORMAT: {
		cs: 'Zadejte email ve správném tvaru.',
		en: 'Enter the email in the correct format!',
	},
	PLEASE_ENTER_EMAIL: {
		cs: 'Zadejte prosím email.',
		en: 'Please enter an email.',
	},
	FIELD_REQUIRES_MIN_2_CHARACTERS: {
		cs: 'Vložte alespoň 2 znaky.',
		en: 'Field requires minimum 2 characters.',
	},
	FIELD_REQUIRES_MIN_5_CHARACTERS: {
		cs: 'Vložte alespoň 5 znaků.',
		en: 'Field requires minimum 5 characters.',
	},

	FIELD_REQUIRES_MIN_8_CHARACTERS: {
		cs: 'Vložte alespoň 8 znaků.',
		en: 'Field requires minimum 8 characters.',
	},

	FIELD_REQUIRES_MAX_6_CHARACTERS: {
		cs: 'Zadejte maximálně 6 znaků.',
		en: 'Enter a maximum of 6 characters.',
	},
	FIELD_REQUIRES_MAX_20_CHARACTERS: {
		cs: 'Zadejte maximálně 20 znaků.',
		en: 'Enter a maximum of 20 characters.',
	},
	FIELD_REQUIRES_MAX_100_CHARACTERS: {
		cs: 'Zadejte maximálně 100 znaků.',
		en: 'Enter a maximum of 100 characters.',
	},
	FIELD_REQUIRES_MAX_250_CHARACTERS: {
		cs: 'Zadejte maximálně 250 znaků.',
		en: 'Enter a maximum of 250 characters.',
	},

	SELECT_A_VALUE_FROM_SELECT: {
		cs: 'Vyberte hodnotu ze selectu.',
		en: 'Select a value from select.',
	},
	SELECT_A_VALUE_FROM_SELECT_CLIENT: {
		cs: 'Vyberte klienta.',
		en: 'Select a client.',
	},
	SELECT_A_VALUE_FROM_SELECT_DRIVER: {
		cs: 'Vyberte řidiče.',
		en: 'Select a driver.',
	},

	SELECT_A_VALUE_FROM_SELECT_CAR: {
		cs: 'Vyberte auto.',
		en: 'Select a car.',
	},

	PLEASE_ENTER_PASSWORD: {
		cs: 'Zadejte prosím heslo.',
		en: 'Please enter an password.',
	},
	LOGIN: {
		cs: 'Přihlásit',
		en: 'LOGIN',
	},
	VALUE_MUST_BE_GRATER_THEN_ZERO: {
		cs: 'Hodnota musí být větší než nula!',
		en: 'Value must be greater than zero!',
	},
	LOGIN_SUCCESSFUL_REDIRECTING: {
		cs: 'Úspěšné přihlášení. Přesměrovávám...',
		en: 'Login Successful. Redirecting...',
	},
	CORRECT_THE_MARKED_FIELDS: {
		cs: 'Opravte prosím označené pole.',
		en: 'Correct the highlighted fields.',
	},
	ENTER_THE_REQUIRED_FIELDS: {
		cs: 'Zadejte prosím povinné pole.',
		en: 'Enter the required fields.',
	},
	ENTER_ANOTHER_EMAIL: {
		cs: 'Zadejte prosím jiný email.',
		en: 'Please enter another email.',
	},

	DB_ERROR: {
		cs: 'Chyba databáze, zkuste to prosím za chvíli znovu.',
		en: 'Sorry, any DB Error...',
	},
	SOMETHING_WRONG: {
		cs: 'Bohužel se něco pokazilo, zkuste to prosím za chvíli znovu.',
		en: 'Sorry, something wrong...',
	},
	REFRESH_TOKEN_IS_NOT_VALID: {
		cs: 'Refresh token není validní.',
		en: 'Refresh token is not valid.',
	},
	YOUR_LOGIN_HAS_EXPIRED: {
		cs: 'Vaše přihlášení vypršelo, prosím přihlaste se znovu.',
		en: 'Your login has expired, please login again.',
	},
	YOU_DO_NOT_HAVE_PERMISSION_FOR_THIS_PAGE: {
		cs: 'Bohužel, pro tuto stránku nemáte dostatečné oprávnění.',
		en: 'Unfortunately, you do not have permission for this page.',
	},
	YOU_DO_NOT_HAVE_PERMISSION: {
		cs: 'Bohužel, nemáte dostatečné oprávnění.',
		en: 'Unfortunately, you do not have permission.',
	},
	YOU_DO_NOT_HAVE_PERMISSION_TO_VIEW_THAT_USER: {
		cs: 'Pro zobrazení toho uživatele nemáte dostatečné oprávnění.',
		en: 'You do not have permissions to view that user.',
	},
	USER_IS_NOT_VALID: {
		cs: 'Uživatel není validní!',
		en: 'User is not valid!',
	},
	ROUTE_DOES_NOT_EXIST: {
		cs: 'Bohužel, taková trasa neexistuje!',
		en: 'Route does not exist!',
	},
	DB_NOT_FOUND: {
		cs: 'Bohužel, záznam neexistuje!',
		en: 'Record does not exist!',
	},
	ERROR_NETWORK: {
		cs: 'Asi vypadlo připojení k internetu, zkuste to za chvíli!',
		en: 'Internet is disconnected, try again later!',
	},
	ROWS_PER_PAGE: {
		cs: 'Řádků na stránce',
		en: 'Rows per page',
	},
	ENTER_DATE: {
		cs: 'Zadejte datum',
		en: 'Enter the date',
	},

	PASSWORD_DOES_NOT_MATCH: {
		cs: 'Hesla se neshodují',
		en: 'Password does not match',
	},

	DASHBOARDS: {
		cs: 'Přehled',
		en: 'DASHBOARDS',
	},
	MY_JOBS: {
		cs: 'Moje práce',
		en: 'MY Jobs',
	},
	CONTRACTS: {
		cs: 'Zakázky',
		en: 'CONTRACTS',
	},
	DIALS: {
		cs: 'Číselníky',
		en: 'DIALS',
	},
	ADMIN: {
		cs: 'Admin rozhraní',
		en: 'Admin',
	},
};
