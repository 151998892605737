import { Box, LinearProgress, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { relative } from 'path';

type Props = {
	isOnline: boolean;
};

const NoConnection = ({ isOnline }: Props) => {
	return (
		<Backdrop
			sx={{
				top: 0,
				left: 0,
				bottom: 0,
				// width: '100%',
				height: '100vh',
				borderRadius: 2,
				backgroundColor: 'rgba(0,0,0,0.2)',
				position: 'absolute',
				zIndex: theme => theme.zIndex.drawer + 1,
			}}
			open={!isOnline}>
			<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
				<CircularProgress color="inherit" />
				<Typography component={'div'} sx={{ marginTop: 5 }} variant="h5">
					Bohužel vypadlo internetové spojení.
				</Typography>
			</Box>
			{/* <LinearProgress color="inherit" /> */}
		</Backdrop>
	);
};

export default NoConnection;
