import { Navigate, Route, Routes } from 'react-router-dom';

import Dashboard from '../pages/dashboard/Dashboard';
import Login from '../pages/login/Login';
import Logout from '../pages/logout/Logout';

import Layout from '../components/Layout';

import ProtectedRoutes from './ProtectedRoutes';
import PublicRoutes from './PublicRoutes';

import ForgotPassword from '../pages/forgotPassword/ForgotPassword';
import ResetPassword from '../pages/resetPassword/ResetPassword';

import MyAccount from '../pages/myAccount/MyAccount';

import ContractEditDetail from '../pages/contracts/edit/ContractEditDetail';
import { ContractsProvider } from '../context/contractsContext/ContractsContext';

import ContractsList from '../pages/contracts/list/ContractsList';
import UsersList from '../pages/users/list/UsersList';
import { UsersProvider } from '../context/usersContext/UsersContext';
import ClientsList from '../pages/clients/list/ClientsList';
import { ClientsProvider } from '../context/clientsContext/clientsContext';
import CarsCategoryList from '../pages/carsCategory/list/CarsCategoryList';
import { CarsProvider } from '../context/carsContext/CarsContext';
import CarsList from '../pages/cars/list/CarsList';
import ContractReport from '../pages/contracts/report/ContractReport';
import ReportOverview from '../pages/contracts/report/overview/ReportOverview';
import ReportCars from '../pages/contracts/report/cars/ReportCars';

import ReportWorker from '../pages/contracts/report/workers/ReportWorker';
import ReportDriver from '../pages/contracts/report/drivers/ReportDriver';
import MyContractsList from '../pages/myContracts/list/MyContractsList';
import MyContractReport from '../pages/myContracts/report/MyContractReport';

import MyReportWorker from '../pages/myContracts/report/worker/MyReportWorker';
import MyReportDriver from '../pages/myContracts/report/driver/MyReportDriver';
import { MyContractsProvider } from '../context/myContractsContext/MyContractsContext';
import AllContractsList from '../pages/myContractsAll/list/AllContractsList';
import MyReportsList from '../pages/myReports/list/MyReportsList';
import MyContractDetail from '../pages/myContracts/detail/MyContractDetail';
import MyUsersList from '../pages/myUsers/list/MyUsersList';

const MainRoutes = () => (
	<Routes>
		{/** Protected Routes */}
		<Route
			path="/"
			element={
				<ProtectedRoutes>
					<Layout />
				</ProtectedRoutes>
			}>
			<Route index element={<Dashboard />} />

			<Route path="contracts">
				<Route path="">
					<Route
						index
						element={
							<ContractsProvider>
								<ContractsList />
							</ContractsProvider>
						}
					/>
					<Route
						path="detail"
						element={
							<ContractsProvider>
								<ContractEditDetail />
							</ContractsProvider>
						}
					/>
					<Route
						path="detail/:id"
						element={
							<ContractsProvider>
								<ContractEditDetail />
							</ContractsProvider>
						}
					/>
					<Route
						path="report/:contractId"
						element={
							<ContractsProvider>
								<ContractReport />
							</ContractsProvider>
						}>
						<Route
							index
							element={
								<ContractsProvider>
									<ReportOverview />
								</ContractsProvider>
							}
						/>
						<Route
							path="cars"
							element={
								<ContractsProvider>
									<ReportCars />
								</ContractsProvider>
							}
						/>

						<Route
							path="workers"
							element={
								<ContractsProvider>
									<ReportWorker />
								</ContractsProvider>
							}
						/>
						<Route
							path="drivers"
							element={
								<ContractsProvider>
									<ReportDriver />
								</ContractsProvider>
							}
						/>
					</Route>
				</Route>
			</Route>

			<Route path="users">
				<Route
					index
					element={
						<UsersProvider>
							<UsersList />
						</UsersProvider>
					}
				/>
				<Route
					path="report/:userId"
					element={
						<UsersProvider>
							<div>report user</div>
							{/* <ContractReportDetail /> */}
						</UsersProvider>
					}
				/>
			</Route>

			<Route path="clients">
				<Route
					index
					element={
						<ClientsProvider>
							<ClientsList />
						</ClientsProvider>
					}
				/>
				<Route
					path="report/:clientId"
					element={
						<ClientsProvider>
							<div>report client</div>
							{/* <ContractReportDetail /> */}
						</ClientsProvider>
					}
				/>
			</Route>

			<Route path="cars">
				<Route
					index
					element={
						<CarsProvider>
							<CarsList />
						</CarsProvider>
					}
				/>
				<Route
					path="report/:carId"
					element={
						<CarsProvider>
							<div>report car</div>
							{/* <ContractReportDetail /> */}
						</CarsProvider>
					}
				/>
			</Route>

			<Route path="my-contracts">
				<Route path="">
					<Route
						index
						element={
							<MyContractsProvider>
								<MyContractsList />
							</MyContractsProvider>
						}
					/>
					<Route
						path="report/:contractId"
						element={
							<MyContractsProvider>
								<MyContractReport />
							</MyContractsProvider>
						}>
						{/* <Route
							index
							element={
								<MyContractsProvider>
									<MyReportDriver />
								</MyContractsProvider>
							}
						/> */}
						<Route
							path="driver"
							element={
								<MyContractsProvider>
									<MyReportDriver />
								</MyContractsProvider>
							}
						/>

						<Route
							path="worker"
							element={
								<MyContractsProvider>
									<MyReportWorker />
								</MyContractsProvider>
							}
						/>
					</Route>

					<Route
						path="detail/:contractId"
						element={
							<MyContractsProvider>
								<MyContractDetail />
							</MyContractsProvider>
						}
					/>
				</Route>
			</Route>

			<Route path="my-reports">
				<Route path="">
					<Route
						index
						element={
							// <MyContractsAllProvider>
							<MyReportsList />
							// </MyContractsAllProvider>
						}
					/>
				</Route>
			</Route>

			<Route path="all-contracts">
				<Route path="">
					<Route
						index
						element={
							// <MyContractsAllProvider>
							<AllContractsList />
							// </MyContractsAllProvider>
						}
					/>
				</Route>
			</Route>

			<Route path="my-users">
				<Route path="">
					<Route index element={<MyUsersList />} />
				</Route>
			</Route>

			<Route path="dials">
				<Route index element={<Navigate to={'cars-category'} />} />
				<Route path="cars-category" element={<CarsCategoryList />} />
			</Route>

			{/* <Route path="cars-category" element={<CarsCategoryList />} /> */}

			<Route path="my-account" element={<MyAccount />} />
			<Route path="logout" element={<Logout />} />

			<Route path="*" element={<h1>404</h1>} />
		</Route>

		{/** Public Routes */}
		<Route
			path="/login"
			element={
				<PublicRoutes>
					<Layout />
				</PublicRoutes>
			}>
			<Route index element={<Login />} />
			<Route path="forgot-password" element={<ForgotPassword />} />
			<Route path="reset-password/:token" element={<ResetPassword />} />
		</Route>

		{/** Not Found */}
	</Routes>
);

export default MainRoutes;
