import { Box, Collapse, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import TableCellDeleteIcon from '../../../../../components/pageContent/TableView/TableCellDeleteIcon';
import TableCellEditIcon from '../../../../../components/pageContent/TableView/TableCellEditIcon';
import { DriverWorkedHours, ReportToCarDB } from '../../types';

type Props = {
	status: string;
	report: ReportToCarDB;
	onEdit: (id: number) => void;
	onDeleteConfirm: (id: number, name: string) => void;
	onOpen: (id: number) => {};
	open: boolean;
	openId: number;
	driverWorkedHours: DriverWorkedHours;
};

const DataCollapse = ({ report, onEdit, onDeleteConfirm, onOpen, open, openId, driverWorkedHours, status }: Props) => {
	return (
		<>
			{/* ________________ COLLAPSE ROW __________________________ */}

			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
					<Collapse in={open && openId === report.id} timeout="auto" unmountOnExit>
						<Box sx={{ margin: 1, marginBottom: 3 }}>
							{report.reportCarDrivers.map(driver => {
								return (
									<React.Fragment key={driver.id}>
										<Table size="small" aria-label="purchases" sx={{ marginTop: 2 }}>
											<TableHead>
												<TableRow sx={{ borderBottom: 2, borderColor: '#a4ceab' }}>
													<TableCell align="left" colSpan={2}>
														{`${driver.user.lastName} ${driver.user.firstName}`}
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{driver.reportDetails.map(detail => {
													return (
														<React.Fragment key={detail.id}>
															<TableRow>
																{/* <TableCell align="left" width={100}></TableCell> */}
																<TableCell align="left" width={100}>
																	Čas:
																</TableCell>
																<TableCell align="left">
																	{`${dayjs(detail.timeFrom).format(
																		'HH:mm'
																	)} - ${dayjs(detail.timeTo).format('HH:mm')} (${
																		detail.workedHours * 1
																	})`}
																</TableCell>

																{report.status === 'active' &&
																	detail.reportDriver.status === 'active' && (
																		<TableCellEditIcon
																			onEdit={onEdit}
																			idEdit={detail.id}
																		/>
																	)}
															</TableRow>

															<TableRow
																sx={{
																	borderBottom:
																		(detail.costs && detail.costs > 0) ||
																		(detail.costsDriver && detail.costsDriver > 0)
																			? 0
																			: 2,
																	borderColor: '#ccc',
																}}>
																{/* <TableCell align="left" width={100}></TableCell> */}
																<TableCell align="left" width={100}>
																	Km, trasa:
																</TableCell>
																<TableCell align="left">
																	{`${detail.driveKm * 1} km, ${detail.description}`}
																</TableCell>
																{report.status === 'active' &&
																	detail.reportDriver.status === 'active' && (
																		<TableCellDeleteIcon
																			onDeleteConfirm={onDeleteConfirm}
																			idDelete={detail.id}
																			nameDelete={`${driver.user.lastName} ${
																				driver.user.firstName
																			}, ${dayjs(detail.timeFrom).format(
																				'HH:mm'
																			)} - ${dayjs(detail.timeTo).format(
																				'HH:mm'
																			)}`}
																		/>
																	)}
															</TableRow>

															{detail.costs && detail.costs > 0 && (
																<TableRow
																	sx={{
																		borderBottom:
																			detail.costsDriver && detail.costsDriver > 0
																				? 0
																				: 2,
																		borderColor: '#ccc',
																	}}>
																	{/* <TableCell align="left" width={100}></TableCell> */}
																	<TableCell align="left" width={100}>
																		Vícenáklady auto:
																	</TableCell>
																	<TableCell align="left">
																		{`${(detail.costs * 1).toLocaleString()}, ${
																			detail.descriptionCosts
																		}`}
																	</TableCell>
																	{report.status === 'active' &&
																		detail.reportDriver.status === 'active' && (
																			<TableCell align="left"> </TableCell>
																		)}
																</TableRow>
															)}

															{detail.costsDriver && detail.costsDriver > 0 && (
																<TableRow
																	sx={{
																		borderBottom: 2,
																		borderColor: '#ccc',
																	}}>
																	{/* <TableCell align="left" width={100}></TableCell> */}
																	<TableCell align="left" width={100}>
																		Vícenáklady řidič:
																	</TableCell>
																	<TableCell align="left">
																		{`${(
																			detail.costsDriver * 1
																		).toLocaleString()}, ${
																			detail.descriptionCostsDriver
																		}`}
																	</TableCell>
																	{report.status === 'active' &&
																		detail.reportDriver.status === 'active' && (
																			<TableCell align="left"> </TableCell>
																		)}
																</TableRow>
															)}
														</React.Fragment>
													);
												})}
											</TableBody>

											<TableHead>
												<TableRow>
													<TableCell align="left" width={150}>
														Hodiny:
													</TableCell>
													<TableCell align="left">
														{`${driverWorkedHours[driver.userId].workedHours}`}
													</TableCell>
												</TableRow>
											</TableHead>
										</Table>
									</React.Fragment>
								);
							})}
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
};

export default DataCollapse;
