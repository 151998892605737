import Alert, { AlertColor } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import React from 'react';

type Props = {
	openSnackbar: boolean;
	onClose: () => void;
	msgSnackbar: string;
	severity?: AlertColor;
};

const SuccessSnackbar = ({ openSnackbar, onClose, msgSnackbar, severity }: Props) => {
	return (
		<Snackbar
			open={openSnackbar}
			autoHideDuration={6000}
			onClose={onClose}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
			<Alert onClose={onClose} severity={severity ? severity : 'success'} variant="filled" elevation={6}>
				{msgSnackbar}
			</Alert>
		</Snackbar>
	);
};

export default SuccessSnackbar;
