import { useState } from 'react';
import { Box, TableRow, Typography } from '@mui/material';

import { useAppContext } from '../../../../../context/appContext/appContext';
import TableCellView from '../../../../../components/pageContent/TableView/TableCellView';

import React from 'react';

import { DriverWorkedHours, ReportToDriverDB, ReportToWorkerDB, WorkerWorkedHours } from '../../types';
import LoupeIcon from '../../../../../components/uiComponents/LoupeIcon';
import DataCollapse from './DataCollapse';
import { WORKING_HOURS } from '../../../../../constants';

type Props = {
	report: ReportToDriverDB;
};

const DataTableRows = ({ report }: Props) => {
	const [open, setOpen] = useState(false);
	const [openId, setOpenId] = useState(0);

	const { state } = useAppContext();

	const handleOpen = async (id: number) => {
		setOpen(false);
		setOpenId(0);

		if (openId !== id) {
			setOpen(true);
			setOpenId(id);
		}
	};

	const col1 = 120;
	const reportNumber = report.reportNumber * 1;

	const basicWorkingHours = reportNumber > 0 ? report.dailyWorkingHours : WORKING_HOURS;

	const costs = report.costs * 1;

	// spocitame hodiny
	const workedHours = report.workedHours * 1;

	const buy = reportNumber > 0 ? report.buyDriver * 1 : report.user.contractToUsers[0].buyDriver * 1;
	const buyOverDB = reportNumber > 0 ? report.buyOverDriver * 1 : report.user.contractToUsers[0].buyOverDriver * 1;

	const sale = reportNumber > 0 ? report.saleDriver * 1 : report.user.contractToUsers[0].saleDriver * 1;
	const saleOverDB = reportNumber > 0 ? report.saleOverDriver * 1 : report.user.contractToUsers[0].saleOverDriver * 1;

	// spocitam prehled
	let normalHours = 0;
	let overLimitHours = 0;

	let saleOver = sale * 1 + saleOverDB * 1;
	let buyOver = buy * 1 + buyOverDB * 1;

	let priceNormalHours = 0;
	let priceOverHours = 0;
	let totalPriceHoursDay = 0;

	let buyPriceNormalHours = 0;
	let buyPriceOverHours = 0;
	let buyTotalPriceHoursDay = 0;

	if (workedHours > basicWorkingHours) {
		overLimitHours = workedHours - basicWorkingHours;

		priceOverHours = overLimitHours * saleOver;
		buyPriceOverHours = overLimitHours * buyOver;
	}

	normalHours = workedHours * 1 - overLimitHours * 1;
	priceNormalHours = normalHours * sale;
	buyPriceNormalHours = normalHours * buy;
	totalPriceHoursDay = priceOverHours + priceNormalHours + costs;
	buyTotalPriceHoursDay = buyPriceOverHours + buyPriceNormalHours + costs;

	return (
		<>
			{/* nazev pracovnika */}
			<TableRow>
				<TableCellView align="left" w="auto" colspan={reportNumber > 0 ? 3 : 2} background="subTitle">
					<>
						<Typography sx={{ fontWeight: 'bold' }} component="span" variant="body2">
							{`${report.user.lastName} ${report.user.firstName}`}
						</Typography>
					</>
				</TableCellView>
			</TableRow>

			{/* Celkem hodiny */}
			<TableRow>
				<TableCellView align="left" mW={col1}>
					<Typography sx={{ fontWeight: 'bold' }} variant="body2">
						Počet hodin
					</Typography>
				</TableCellView>

				<TableCellView align="left" mW={200} colspan={reportNumber > 0 ? 2 : 0}>
					<>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'flex-start',
								flexDirection: 'row',
								alignItems: 'center',
							}}>
							<Typography sx={{ fontWeight: 'bold', marginRight: 1 }} component="span" variant="body2">
								{`${report.workedHours * 1} hod.`}
							</Typography>
							<LoupeIcon
								onOpen={handleOpen}
								openId={report.id}
								color={open && openId === report.id ? 'warning' : 'info'}
							/>
						</Box>
					</>
				</TableCellView>
			</TableRow>

			{/* ________________ COLLAPSE ROW __________________________ */}

			<DataCollapse report={report} onOpen={handleOpen} open={open} openId={openId} />

			{/* Pausal  */}

			<TableRow>
				<TableCellView align="left" mW={col1} w={150}>
					<>
						<Typography sx={{ fontWeight: 'bold' }} component="span" variant="body2">
							Cena
						</Typography>
					</>
				</TableCellView>

				<TableCellView align="left" mW={200} w="auto" colspan={reportNumber > 0 ? 2 : 0}>
					<>
						<Typography sx={{ fontWeight: 'bold' }} component="span" variant="body2">
							{`${(priceNormalHours * 1).toLocaleString()} = `}
						</Typography>
						<Typography component="span" variant="body2">
							{`${normalHours} x ${sale}`}
						</Typography>{' '}
						<Typography sx={{ fontWeight: 'bold', color: '#aaa' }} component="span" variant="caption">
							{`(${(buyPriceNormalHours * 1).toLocaleString()} = `}
						</Typography>
						<Typography sx={{ color: '#aaa' }} component="span" variant="caption">
							{`${normalHours} x ${buy})`}
						</Typography>
					</>
				</TableCellView>
			</TableRow>

			{/* nad limit */}

			{overLimitHours > 0 && (
				<TableRow>
					<TableCellView align="left" mW={col1} w={150}>
						<>
							<Typography sx={{ fontWeight: 'bold' }} component="span" variant="body2">
								Cena
							</Typography>
							<Typography sx={{ marginLeft: 1 }} component="span" variant="caption">
								nad limit
							</Typography>
						</>
					</TableCellView>

					<TableCellView align="left" mW={200} w="auto" colspan={reportNumber > 0 ? 2 : 0}>
						<>
							<Typography sx={{ fontWeight: 'bold' }} component="span" variant="body2">
								{`${(priceOverHours * 1).toLocaleString()} = `}
							</Typography>
							<Typography component="span" variant="body2">
								{`${overLimitHours.toLocaleString()} x ${(saleOver * 1).toLocaleString()} `}
							</Typography>{' '}
							<Typography sx={{ fontWeight: 'bold', color: '#aaa' }} component="span" variant="caption">
								{`(${(buyPriceOverHours * 1).toLocaleString()} = `}
							</Typography>
							<Typography sx={{ color: '#aaa' }} component="span" variant="caption">
								{`${overLimitHours.toLocaleString()} x ${(buyOver * 1).toLocaleString()})`}
							</Typography>
						</>
					</TableCellView>
				</TableRow>
			)}

			{/* Vícenáklady */}

			{costs > 0 && (
				<TableRow>
					<TableCellView align="left" mW={col1} w={150}>
						<Typography sx={{ fontWeight: 'bold' }} component="span" variant="body2">
							Vícenáklady
						</Typography>
					</TableCellView>

					<TableCellView align="left" mW={200} w="auto" colspan={reportNumber > 0 ? 2 : 0}>
						<>
							<Typography sx={{ fontWeight: 'bold', marginRight: 1 }} component="span" variant="body2">
								{costs.toLocaleString()}
							</Typography>{' '}
						</>
					</TableCellView>
				</TableRow>
			)}

			{/* CELKEM HODINY */}

			<TableRow>
				<TableCellView align="left" mW={col1} w={150}>
					<Typography sx={{ fontWeight: 'bold' }} component="span" variant="body2">
						CENA CELKEM
					</Typography>
				</TableCellView>

				<TableCellView align="left" mW={200} w="auto" colspan={reportNumber > 0 ? 2 : 0}>
					<>
						<Typography sx={{ fontWeight: 'bold', marginRight: 1 }} component="span" variant="body2">
							{totalPriceHoursDay.toLocaleString()}
						</Typography>{' '}
						<Typography sx={{ fontWeight: 'bold', color: '#aaa' }} component="span" variant="caption">
							({buyTotalPriceHoursDay.toLocaleString()})
						</Typography>
					</>
				</TableCellView>
			</TableRow>
		</>
	);
};

export default DataTableRows;
