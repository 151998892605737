import { Button, IconButton, TableCell, useMediaQuery, useTheme } from '@mui/material';
import PlaylistAddOutlinedIcon from '@mui/icons-material/PlaylistAddOutlined';
import AddchartOutlinedIcon from '@mui/icons-material/AddchartOutlined';
type Props = {
	onReport: (id: number) => void;
	idReport: number;
};

const TableCellReportIcon = ({ onReport, idReport }: Props) => {
	const theme = useTheme();
	const sizeButton = useMediaQuery(theme.breakpoints.up('sm')) ? 'medium' : 'large';

	return (
		<TableCell align="center" width={30} size="small" sx={{ paddingX: '5px' }}>
			<IconButton
				sx={{
					borderRadius: 1,
					border: 0,

					borderColor: theme.palette.info.dark,
					padding: '0.15em',
				}}
				onClick={() => onReport(idReport)}
				size="large">
				<AddchartOutlinedIcon fontSize={sizeButton} color="success" />
			</IconButton>
		</TableCell>
	);
};

export default TableCellReportIcon;
