import {
	Box,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	SelectChangeEvent,
	Autocomplete,
	TextField,
} from '@mui/material';
import React from 'react';

import { UserDB } from '../../../users/types';

type Props = {
	userSelected: number | null;
	usedUser: number[] | null;
	onChangeUser: (id: number | null) => void;
	userSelect: UserDB[] | null;
};

interface IOptionsUser {
	label: string;
	id: number;
}

const SelectUser = ({ userSelected, usedUser, onChangeUser, userSelect }: Props) => {
	const [value, setValue] = React.useState<{ label: string; id: number } | null>(null);

	const newUserSelect = userSelect?.filter(item => !usedUser?.includes(item.id));

	let optionsUser: IOptionsUser[] = [{ label: '', id: 0 }];

	if (newUserSelect) {
		optionsUser = newUserSelect.map(user => {
			return { label: `${user.lastName} ${user.firstName}`, id: user.id };
		});
	}

	return (
		// <Box
		// 	component="div"
		// 	sx={{ background: 'white', paddingX: 1, paddingY: 1, marginTop: 2, marginBottom: 1, borderRadius: 2 }}>
		<Autocomplete
			value={value}
			onChange={(event: any, newValue: { label: string; id: number } | null) => {
				setValue(newValue);
				onChangeUser(newValue ? newValue.id : null);
			}}
			options={optionsUser}
			sx={{ maxWidth: { sx: '100%', sm: '100%' }, marginRight: { sx: 0, sm: 0 }, marginY: 1, width: '100%' }}
			renderOption={(props, optionsUser) => {
				return (
					<li {...props} key={optionsUser.id}>
						{optionsUser.label}
					</li>
				);
			}}
			renderInput={params => <TextField {...params} variant="filled" label="Výběr pracovníka" />}
		/>
		// </Box>
	);
};

export default SelectUser;
