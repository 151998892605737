import React from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import ButtonWithStartIconNonResponsive from '../../../../components/uiComponents/ButtonWithStartIconNonResponsive';
import { ContractCarDB } from '../types';

type Props = {
	onChangeCar: (event: SelectChangeEvent) => void;
	onAdd: () => void;
	contractCars: ContractCarDB[] | null;
	contractCar: ContractCarDB | null;
	carId: string;
	status: string;
};

const SelectCar = ({ onChangeCar, onAdd, contractCars, contractCar, carId, status }: Props) => {
	const theme = useTheme();
	return (
		<>
			<Box
				component="div"
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					flexDirection: { xs: 'column', sm: 'row' },
					alignItems: 'center',

					background: 'white',
					paddingX: 0,
					paddingY: 0,
					marginTop: 0,
					marginBottom: 0,
					borderRadius: 2,
				}}>
				<FormControl variant="filled" sx={{ width: { xs: '100%', sm: '60%' } }}>
					<InputLabel id="car">Výběr auta</InputLabel>
					<Select
						labelId="car"
						MenuProps={{
							style: {
								maxWidth: 50,
							},
						}}
						value={carId}
						onChange={onChangeCar}
						label="Výběr auta">
						<MenuItem value="">žádné auto</MenuItem>
						{contractCars &&
							contractCars.map((item: ContractCarDB) => {
								return (
									<MenuItem
										key={item.id}
										value={item.carId}
										sx={{ color: !item.car.isActive ? theme.palette.error.dark : '' }}>
										{`${item.car.name}, ${item.car.spz}`}
									</MenuItem>
								);
							})}
					</Select>
				</FormControl>

				{status !== 'closed' && (
					<ButtonWithStartIconNonResponsive
						title="přidat výkaz"
						icon={<AddIcon />}
						onDisabled={carId && contractCar ? false : true}
						bColor={carId ? 'primary' : 'grey'}
						onClickHeaderButton={onAdd}
					/>
				)}
			</Box>
		</>
	);
};

export default SelectCar;
