import { debounce } from 'lodash';
import React, { useCallback, useState } from 'react';

import {
	Box,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	SelectChangeEvent,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Radio,
	TextField,
	InputAdornment,
	IconButton,
} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

type Props = {
	onStatusCHange: (status: string) => void;
	onSearchCHange: (search: string) => void;
	status: string;
	search: string;
};

const FilterContract = ({ onStatusCHange, onSearchCHange, status, search }: Props) => {
	const [searchFilter, setSearchFilter] = useState(search);

	const handleFilterStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		onStatusCHange(event.target.value);
	};

	const handleFilterSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchFilter(event.target.value);
		debouncedFilterTextChange(event.target.value);
	};

	const debouncedFilterTextChange = useCallback(
		debounce(criteria => {
			onSearchCHange(criteria);
		}, 500),
		[]
	);

	React.useEffect(() => {
		return () => {
			debouncedFilterTextChange.cancel();
		};
	}, [debouncedFilterTextChange]);

	return (
		<Box
			component="div"
			sx={{ background: 'white', paddingX: 3, paddingY: 2, marginTop: 2, marginBottom: 2, borderRadius: 2 }}>
			{/* <FormControl>
				<FormLabel id="filter-status">Zakázky</FormLabel>
				<RadioGroup
					row
					aria-labelledby="filter-status"
					defaultValue={''}
					name="filter-status"
					value={status}
					onChange={handleFilterStatusChange}>
					<FormControlLabel value="" control={<Radio />} label="všechny" />
					<FormControlLabel value="active" control={<Radio />} label="aktivní" />
					<FormControlLabel value="closed" control={<Radio />} label="uzavřené" />
				</RadioGroup>
			</FormControl> */}
			<Box sx={{ marginY: 2 }}>
				<TextField
					id="fulltext"
					label="Hledání zakázky"
					variant="standard"
					value={searchFilter}
					onChange={handleFilterSearchChange}
					fullWidth
					InputProps={{
						endAdornment: search && (
							<InputAdornment position="end">
								<IconButton
									onClick={() => {
										setSearchFilter('');
										debouncedFilterTextChange('');
									}}
									edge="end">
									<CloseOutlinedIcon />
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			</Box>
		</Box>
	);
};

export default FilterContract;
