import React from 'react';
import ModalHeader from './ModalHeader';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import WebcamCapture from '../../pages/myAccount/WebcamCapture';

const style = {
	position: 'relative',
	top: '50%',
	left: '50%',

	transform: 'translate(-50%, -50%)',
	minWidth: 200,
	maxWidth: 600,
	maxHeight: '80vh',
	width: 'calc(100% - 64px)',

	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	paddingTop: 2,
	paddingBottom: 4,
	paddingX: 4,

	overflow: 'auto',
};
type Props = {
	modalTitle: string;
	openModal: boolean;
	onClose: () => void;
	onImportImage: (image: any) => void;
};

const WebCamModal = ({ modalTitle, openModal, onClose, onImportImage }: Props) => {
	return (
		<Modal open={openModal} onClose={onClose}>
			<Box sx={style}>
				<ModalHeader title={modalTitle} onClose={onClose} />
				<WebcamCapture onClose={onClose} onImportImage={onImportImage} />
			</Box>
		</Modal>
	);
};

export default WebCamModal;
