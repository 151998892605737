import React, { ReactNode } from 'react';
import AirportShuttleOutlinedIcon from '@mui/icons-material/AirportShuttleOutlined';
import Person4OutlinedIcon from '@mui/icons-material/Person4Outlined';
import VideoCameraFrontOutlinedIcon from '@mui/icons-material/VideoCameraFrontOutlined';
import RequestPageOutlinedIcon from '@mui/icons-material/RequestPageOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';

// { [key: string]: Menu }
// { [key in "Menukey" | "MenuKey2"]: Menu }
// Record<string, Menu>
// Record<"Menukey" | "MenuKey2", Menu>
// export const menuListContracts: { [key: string]: Menu } = {

// export type Menu = {
// 	myContracts: MenuItems | undefined;
// 	dials: MenuItems | undefined;
// 	contracts: MenuItems | undefined;
// };

export interface SideMenu {
	DASHBOARDS?: SideMenuItems[];
	MY_JOBS?: SideMenuItems[];
	CONTRACTS?: SideMenuItems[];
	DIALS?: SideMenuItems[];
	ADMIN?: SideMenuItems[];
}

export interface SideMenuItems {
	id: string;
	title: string;
	url: string;
	baseUrl: string;
	icon: JSX.Element;
	role: string;
}

const MENU_LIST_ALL: SideMenu = {
	DASHBOARDS: [
		{
			id: 'dashboard',
			title: 'Dashboard',
			url: '',
			baseUrl: '',
			icon: <DashboardOutlinedIcon />,
			role: 'user',
		},
	],
};

export const MENU_LIST_USER: SideMenu = {
	// ...MENU_LIST_ALL,
	MY_JOBS: [
		{
			id: 'myContracts',
			title: 'Moje zakázky',
			url: 'my-contracts',
			baseUrl: '/my-contracts',
			icon: <BallotOutlinedIcon />,
			role: 'user',
		},
		{
			id: 'myReports',
			title: 'Moje výkazy',
			url: 'my-reports',
			baseUrl: '/my-reports',
			icon: <SummarizeOutlinedIcon />,
			role: 'user',
		},
		{
			id: 'myContractsAll',
			title: 'Přehled zakázek',
			url: 'all-contracts',
			baseUrl: '/all-contracts',
			icon: <ListAltOutlinedIcon />,
			role: 'user',
		},
		{
			id: 'myUsers',
			title: 'Přehled uživatelů',
			url: 'my-users',
			baseUrl: '/my-users',
			icon: <Person4OutlinedIcon />,
			role: 'user',
		},
	],
};

export const MENU_LIST_EDITOR: SideMenu = {
	// ...MENU_LIST_ALL,
	CONTRACTS: [
		{
			id: 'contracts-list',
			title: 'Přehled zakázek',
			url: 'contracts',
			baseUrl: '/contracts',
			icon: <ListAltOutlinedIcon />,
			role: 'editor',
		},
		{
			id: 'users',
			title: 'Uživatelé',
			url: 'users',
			baseUrl: '/users',
			icon: <Person4OutlinedIcon />,
			role: 'editor',
		},
		{
			id: 'cars',
			title: 'Vozidla',
			url: 'cars',
			baseUrl: '/cars',
			icon: <AirportShuttleOutlinedIcon />,
			role: 'editor',
		},
		{
			id: 'clients',
			title: 'Klienti',
			url: 'clients',
			baseUrl: '/clients',
			icon: <VideoCameraFrontOutlinedIcon />,
			role: 'editor',
		},
	],
	...MENU_LIST_USER,
	DIALS: [
		{
			id: 'carsCategory',
			title: 'Kategorie aut',
			url: 'dials/cars-category',
			baseUrl: '/dials/cars-category',
			icon: <AirportShuttleOutlinedIcon />,
			role: 'editor',
		},
	],
	// DIALS: [
	// 	{
	// 		id: 'clients',
	// 		title: 'Klienti',
	// 		url: 'clients',
	// 		baseUrl: '/clients',
	// 		icon: <VideoCameraFrontOutlinedIcon />,
	// 		role: 'editor',
	// 	},
	// 	{
	// 		id: 'users',
	// 		title: 'Uživatelé',
	// 		url: 'users',
	// 		baseUrl: '/users',
	// 		icon: <Person4OutlinedIcon />,
	// 		role: 'editor',
	// 	},
	// 	{
	// 		id: 'cars',
	// 		title: 'Automobily',
	// 		url: 'cars',
	// 		baseUrl: '/cars',
	// 		icon: <AirportShuttleOutlinedIcon />,
	// 		role: 'editor',
	// 	},
	// ],
};

export const MENU_LIST_ADMIN: SideMenu = {
	// ...MENU_LIST_ALL,
	...MENU_LIST_EDITOR,
	// ADMIN: [
	// 	{
	// 		id: 'viewLog',
	// 		title: 'Zobrazení logu',
	// 		url: 'viewlog',
	// 		baseUrl: '/view-log',
	// 		icon: <DescriptionOutlinedIcon />,
	// 		role: 'admin',
	// 	},
	// ],
};

// type Test = typeof menuListContracts
