import dayjs from 'dayjs';

export const calculateWorkedTime = (
	date: string,
	timeFrom: string,
	timeTo: string
): { workedHours: number; timeFromObj: dayjs.Dayjs; timeToObj: dayjs.Dayjs } => {
	let workedHours = 0;

	let timeToObj = dayjs(date + timeTo);
	const timeFromObj = dayjs(date + timeFrom);

	// pokud je konec mensi nez zacatek, pridame 1 den. tzn konci po pulnoci
	if (timeToObj.diff(timeFromObj, 'hour', true) < 0) {
		timeToObj = dayjs(timeToObj).add(1, 'day');
	}

	if (timeToObj && timeFromObj) {
		workedHours = Math.ceil(timeToObj.diff(timeFromObj, 'hour', true));
	}

	return { workedHours, timeFromObj, timeToObj };
};
