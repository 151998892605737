import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';

import { useAppContext } from '../../../../context/appContext/appContext';

import TableCellView from '../../../../components/pageContent/TableView/TableCellView';
import TableCellEditIcon from '../../../../components/pageContent/TableView/TableCellEditIcon';
import TableCellDeleteIcon from '../../../../components/pageContent/TableView/TableCellDeleteIcon';
import TableCellIsActiveIcon from '../../../../components/pageContent/TableView/TableCellIsActiveIcon';
import TableCellInfoIcon from '../../../../components/pageContent/TableView/TableCellInfoIcon';

import React, { useState } from 'react';
import dayjs from 'dayjs';

import locale from 'dayjs/locale/cs';

import TableCellLoupeIcon from '../../../../components/pageContent/TableView/TableCellLoupeIcon';
import TableCellReportIcon from '../../../../components/pageContent/TableView/TableCellReportIcon';

import { Document } from '../../types';
import TableCellDownloadIcon from '../../../../components/pageContent/TableView/TableCellDownloadIcon';

type Props = {
	documents: Document[] | null;
	rowsPerPage: number;
	page: number;
	onPdfDownload: (id: number, type: string) => Promise<void>;
};

const DataTableRows = ({ documents, rowsPerPage, page, onPdfDownload }: Props) => {
	const { state } = useAppContext();

	return (
		<>
			{!documents?.length && (
				<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
					<TableCellView align="left" w="auto" colspan={7}>
						Nebyl nalezen žádný záznam.
					</TableCellView>
				</TableRow>
			)}

			{documents &&
				(rowsPerPage > 0
					? documents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
					: documents
				).map((report: Document, index: number) => {
					return (
						<React.Fragment key={report.id}>
							{report.type === 'worker' && (
								<TableRow hover>
									<TableCellView align="center" w={50}>
										{rowsPerPage > 0 ? page * rowsPerPage + index + 1 : index + 1}
									</TableCellView>
									<TableCellView align="left" mW={120} w="auto">
										{dayjs(report.date)
											.locale({ ...locale })
											.format('DD.MM.YYYY')}
									</TableCellView>
									<TableCellView align="left" mW={100} w="auto">
										{report.documentNumber}
									</TableCellView>

									<TableCellView align="left" mW={100} w="auto">
										<>{report.type === 'worker' && 'pracovník'}</>
									</TableCellView>

									<TableCellView align="left" mW={200} w="auto">
										{report.contractName}
									</TableCellView>

									{/* <TableCellPdfIcon onShowPdf={onShowPdf} idPdf={report.id} /> */}

									<TableCellDownloadIcon
										name={report.documentNumber}
										type={report.type}
										idDownload={report.id}
										onPdfDownload={onPdfDownload}
									/>

									<TableCellView
										align="right"
										w={state.user?.role === 'admin' ? 60 : '5px'}
										pX={state.user?.role === 'admin' ? undefined : '5px'}>
										{state.user?.role === 'admin' ? report.id : ''}
									</TableCellView>
								</TableRow>
							)}

							{report.type === 'car' && (
								<TableRow hover>
									<TableCellView align="center" w={50}>
										{rowsPerPage > 0 ? page * rowsPerPage + index + 1 : index + 1}
									</TableCellView>
									<TableCellView align="left" mW={120} w="auto">
										{dayjs(report.date)
											.locale({ ...locale })
											.format('DD.MM.YYYY')}
									</TableCellView>
									<TableCellView align="left" mW={100} w="auto">
										{report.documentNumber}
									</TableCellView>

									<TableCellView align="left" mW={100} w="auto">
										řidič
									</TableCellView>

									<TableCellView align="left" mW={200} w="auto">
										{report.contractName}
									</TableCellView>

									{/* <TableCellPdfIcon onShowPdf={onShowPdf} idPdf={report.id} /> */}

									<TableCellDownloadIcon
										name={report.documentNumber}
										type={'driver'}
										idDownload={report.id}
										onPdfDownload={onPdfDownload}
									/>

									<TableCellView
										align="right"
										w={state.user?.role === 'admin' ? 60 : '5px'}
										pX={state.user?.role === 'admin' ? undefined : '5px'}>
										{state.user?.role === 'admin' ? report.id : ''}
									</TableCellView>
								</TableRow>
							)}

							{/* {report.type === 'car' && 'auta + řidiči'} */}
						</React.Fragment>
					);
				})}
		</>
	);
};

export default DataTableRows;
