import { IconButton, TableCell, useMediaQuery, useTheme } from '@mui/material';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { useAppContext } from '../../../context/appContext/appContext';

import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';

type Props = {
	onActiveConfirm: (id: number, name: string, status: string) => void;
	idRecord: number;
	status: string;
	nameDelete: string;
};

const TableCellActive = ({ onActiveConfirm, idRecord, nameDelete, status }: Props) => {
	const theme = useTheme();
	const sizeButton = useMediaQuery(theme.breakpoints.up('sm')) ? 'medium' : 'medium';
	const { state } = useAppContext();

	return (
		<TableCell align="center" width={30} size="small" sx={{ paddingX: '5px' }}>
			{status === 'active' && (
				<IconButton
					sx={{
						borderRadius: 1,
						border: 0,
						color: theme.palette.success.main,
						borderColor: theme.palette.error.dark,
						padding: '0.15em',
					}}
					onClick={() => onActiveConfirm(idRecord, nameDelete, status)}
					aria-label="aktivní"
					size="small">
					<ToggleOnIcon fontSize={sizeButton} />
				</IconButton>
			)}
			{status === 'closed' && (
				<IconButton
					sx={{
						borderRadius: 1,
						border: 0,
						color: theme.palette.grey[400],
						borderColor: theme.palette.error.dark,
						padding: '0.15em',
					}}
					onClick={() => onActiveConfirm(idRecord, nameDelete, status)}
					aria-label="neaktivní"
					size="small">
					<ToggleOffIcon fontSize={sizeButton} />
				</IconButton>
			)}
		</TableCell>
	);
};

export default TableCellActive;
