import React, { useState } from 'react';
import { useAppContext } from '../../../context/appContext/appContext';
import { UserDB, UserFormInputs } from '../types';

import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { userSchema } from '../../../utils/joiSchema';
import { dict } from '../../../translate/translate-dictionary';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import {
	FilledInput,
	FormControl,
	FormHelperText,
	IconButton,
	InputAdornment,
	InputLabel,
	ToggleButton,
	ToggleButtonGroup,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

type Props = {
	editID: number | null;
	editData: UserDB | null;
	onClose: () => void;
	onFormSubmit: (data: UserFormInputs) => Promise<string | undefined>;
};

const UserForm = ({ editData, editID, onClose, onFormSubmit }: Props) => {
	const [masterError, setMasterError] = useState<string>('');
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [role, setRole] = useState<string>(editData ? editData.role : 'user');
	const { state } = useAppContext();

	const lang = state.lang;

	const {
		register,
		handleSubmit,

		formState: { errors },
	} = useForm<UserFormInputs>({
		defaultValues: {
			firstName: editData ? editData.firstName : '',
			lastName: editData ? editData.lastName : '',
			email: editData ? editData.email : '',
			phone: editData ? editData.phone : '',
			street: editData ? editData.street : '',
			city: editData ? editData.city : '',
			zipCode: editData ? editData.zipCode : '',
			password: '',
			role: editData ? editData.role : 'user',
		},
		resolver: joiResolver(userSchema),
		mode: 'onChange',
		delayError: 2000,
	});

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const onSubmit = async (data: UserFormInputs) => {
		let newZipCode = '';
		if (data.zipCode && data.zipCode.length === 5)
			newZipCode = `${data.zipCode.substring(0, 3)} ${data.zipCode.substring(3, data.zipCode.length)}`;

		console.log(newZipCode);

		const newData = { ...data, role: role, zipCode: newZipCode ? newZipCode : data.zipCode };

		const response = await onFormSubmit(newData);

		if (response) setMasterError(response);
	};

	const handleChangeRole = (event: React.MouseEvent<HTMLElement>, newRole: string) => {
		if (newRole !== null) {
			setRole(newRole);
		}
	};

	return (
		<>
			<Box
				component="form"
				noValidate
				onSubmit={handleSubmit(onSubmit)}
				sx={{
					mt: 1,
				}}>
				{/* ________ FIRST and LAST NAME _______ */}

				<Box
					sx={{
						display: 'flex',
						justifyContent: { sm: 'space-between' },
						flexDirection: { xs: 'column', sm: 'row' },
						alignItems: 'center',
					}}>
					<TextField
						{...register('firstName')}
						required
						margin="normal"
						label="Jméno"
						autoFocus
						error={!!errors?.firstName}
						helperText={
							errors?.firstName?.message
								? dict[errors.firstName.message]
									? dict[errors.firstName.message][lang]
									: errors?.firstName?.message
								: ' '
						}
						variant="filled"
						size="medium"
						inputProps={{ maxLength: 100 }}
						sx={{ width: { xs: '100%', sm: '48%' } }}
					/>

					<TextField
						{...register('lastName')}
						required
						margin="normal"
						label="Příjmení"
						error={!!errors?.lastName}
						helperText={
							errors?.lastName?.message
								? dict[errors.lastName.message]
									? dict[errors.lastName.message][lang]
									: errors?.lastName?.message
								: ' '
						}
						variant="filled"
						size="medium"
						inputProps={{ maxLength: 100 }}
						sx={{ width: { xs: '100%', sm: '48%' } }}
					/>
				</Box>

				{/* ________ EMAIL and PHONE _______ */}

				<Box
					sx={{
						display: 'flex',
						justifyContent: { sm: 'space-between' },
						flexDirection: { xs: 'column', sm: 'row' },
						alignItems: 'center',
					}}>
					<TextField
						{...register('email')}
						required
						margin="normal"
						label="Email"
						error={!!errors?.email}
						helperText={
							errors?.email?.message
								? dict[errors.email.message]
									? dict[errors.email.message][lang]
									: errors?.email?.message
								: ' '
						}
						variant="filled"
						size="medium"
						inputProps={{ maxLength: 100 }}
						sx={{ width: { xs: '100%', sm: '48%' } }}
					/>
					<TextField
						{...register('phone')}
						margin="normal"
						label="Telefon"
						error={!!errors?.phone}
						helperText={
							errors?.phone?.message
								? dict[errors.phone.message]
									? dict[errors.phone.message][lang]
									: errors?.phone?.message
								: ' '
						}
						variant="filled"
						size="medium"
						inputProps={{ maxLength: 20 }}
						sx={{ width: { xs: '100%', sm: '48%' } }}
					/>
				</Box>

				{/* ________ STREET _______ */}

				<Box
					sx={{
						display: 'flex',
						justifyContent: { sm: 'space-between' },
						flexDirection: { xs: 'column', sm: 'column' },
						alignItems: 'center',
					}}>
					<TextField
						{...register('street')}
						margin="normal"
						label="Ulice a číslo popisné"
						error={!!errors?.street}
						helperText={
							errors?.street?.message
								? dict[errors.street.message]
									? dict[errors.street.message][lang]
									: errors?.street?.message
								: ' '
						}
						variant="filled"
						size="medium"
						inputProps={{ maxLength: 100 }}
						sx={{ width: { xs: '100%', sm: '100%' } }}
					/>
				</Box>

				{/* ________ ZIP CODE and CITY _______ */}

				<Box
					sx={{
						display: 'flex',
						justifyContent: { xs: 'space-between' },
						flexDirection: { xs: 'column', sm: 'row' },
						alignItems: 'center',
					}}>
					<TextField
						{...register('zipCode')}
						margin="normal"
						label="PSČ"
						error={!!errors?.zipCode}
						helperText={
							errors?.zipCode?.message
								? dict[errors.zipCode.message]
									? dict[errors.zipCode.message][lang]
									: errors?.zipCode?.message
								: ' '
						}
						variant="filled"
						size="medium"
						inputProps={{ maxLength: 6 }}
						sx={{ width: { xs: '100%', sm: '38%' } }}
					/>

					<TextField
						{...register('city')}
						margin="normal"
						label="Město"
						error={!!errors?.city}
						helperText={
							errors?.city?.message
								? dict[errors.city.message]
									? dict[errors.city.message][lang]
									: errors?.city?.message
								: ' '
						}
						variant="filled"
						size="medium"
						inputProps={{ maxLength: 100 }}
						sx={{ width: { xs: '100%', sm: '58%' } }}
					/>
				</Box>

				{/* ________ PASSWORD and ROLE _______ */}

				<Box
					sx={{
						display: 'flex',
						justifyContent: { sm: 'space-between' },
						flexDirection: { xs: 'column', sm: 'row' },
						alignItems: 'center',
						marginTop: 2,
					}}>
					{!editID && (
						<FormControl
							sx={{ width: { xs: '100%', sm: '48%' } }}
							variant="filled"
							error={!!errors?.password}>
							<InputLabel htmlFor="filled-adornment-password">Password</InputLabel>
							<FilledInput
								{...register('password')}
								id="filled-adornment-password"
								type={showPassword ? 'text' : 'password'}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowPassword}
											edge="end">
											{showPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								}
							/>
							<FormHelperText>
								{errors?.password?.message
									? dict[errors.password.message]
										? dict[errors.password.message][lang]
										: errors?.password?.message
									: ' '}
							</FormHelperText>
						</FormControl>
					)}
					<ToggleButtonGroup
						disabled={editID === state.user?.id}
						sx={{ marginBottom: { xs: 2, sm: 3 }, marginTop: { xs: 2, sm: 0 } }}
						color="primary"
						value={role}
						exclusive
						onChange={handleChangeRole}
						aria-label="Platform">
						{state.user?.role === 'admin' && <ToggleButton value="admin">Admin</ToggleButton>}
						<ToggleButton value="editor">Editor</ToggleButton>
						<ToggleButton value="user">Uživatel</ToggleButton>
					</ToggleButtonGroup>
				</Box>

				<Stack sx={{ width: '100%', marginTop: 2 }} spacing={3}>
					{masterError && (
						<Alert sx={{ borderRadius: '0.5em' }} severity="error">
							{dict[masterError] ? dict[masterError][lang] : masterError ? masterError : ' '}
						</Alert>
					)}
				</Stack>

				<Stack direction="row" justifyContent="center" alignItems="center" spacing={2} mt={3}>
					<Button variant="contained" size="large" color="inherit" onClick={onClose}>
						zrušit
					</Button>
					<Button variant="contained" type="submit" size="large">
						{`${editID ? 'Editovat' : 'Přidat'}`}
						<Box component={'span'} sx={{ display: { xs: 'none', sm: 'inline' } }}>
							&nbsp;uživatele
						</Box>
					</Button>
				</Stack>
			</Box>
		</>
	);
};

export default UserForm;
