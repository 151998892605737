export enum ActionType {
	DISPLAY_ALERT = 'SHOW_ALERT',
	CLEAR_ALERT = 'CLEAR_ALERT',

	SET_USER = 'SET_USER',
	LOGIN_USER_BEGIN = 'LOGIN_USER_BEGIN',
	LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS',
	LOGIN_USER_ERROR = 'LOGIN_USER_ERROR',
	LOGOUT_USER = 'LOGOUT_USER',

	GET_CURRENT_USER_BEGIN = 'GET_CURRENT_USER_BEGIN',
	GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS',
	GET_CURRENT_USER_ERROR = 'GET_CURRENT_USER_ERROR',

	HANDLE_MASTER_ERROR = 'HANDLE_MASTER_ERROR',
	SET_ROWS_PER_PAGE = 'SET_ROWS_PER_PAGE',
}
