import { TableRow, Typography, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import locale from 'dayjs/locale/cs';

import TableCellViewTitle from '../../../../../components/pageContent/TableView/TableCellViewTitle';
import { useAppContext } from '../../../../../context/appContext/appContext';
import { ReportToWorkerDB } from '../../../types';

type Props = {
	report: ReportToWorkerDB;
};

const TitleTableRow = ({ report }: Props) => {
	const { state } = useAppContext();

	return (
		<TableRow>
			<TableCellViewTitle align="left" w={'auto'} colspan={3}>
				<>
					<Typography sx={{ fontWeight: 'bold' }} component="span" variant="body2">
						Datum:
					</Typography>

					<Typography sx={{ fontWeight: 'bold', marginLeft: 2 }} component="span" variant="subtitle1">
						{dayjs(report.date)
							.locale({ ...locale })
							.format('DD.MM.YYYY, dddd')}
					</Typography>
				</>
			</TableCellViewTitle>
		</TableRow>

		// 	<TableRow>

		// 	<TableCellViewTitle align="right" colspan={5}>
		// 			{`${report.car.name}, SPZ: ${report.car.spz}`}
		// 		</TableCellViewTitle>
		// 	</TableRow>
	);
};

export default TitleTableRow;
