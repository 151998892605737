import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';

import { useAppContext } from '../../../../context/appContext/appContext';

import TableCellView from '../../../../components/pageContent/TableView/TableCellView';
import TableCellEditIcon from '../../../../components/pageContent/TableView/TableCellEditIcon';
import TableCellDeleteIcon from '../../../../components/pageContent/TableView/TableCellDeleteIcon';
import TableCellIsActiveIcon from '../../../../components/pageContent/TableView/TableCellIsActiveIcon';
import TableCellInfoIcon from '../../../../components/pageContent/TableView/TableCellInfoIcon';

import React, { useState } from 'react';
import dayjs from 'dayjs';

import { ContractCountDB, ContractDB } from '../../edit/types';
import TableCellLoupeIcon from '../../../../components/pageContent/TableView/TableCellLoupeIcon';
import TableCellReportIcon from '../../../../components/pageContent/TableView/TableCellReportIcon';
import TableCellActive from '../../../../components/pageContent/TableView/TableCellActive';

type Props = {
	contracts: ContractDB[] | null;
	onReport: (id: number) => void;
	onEdit: (id: number) => void;
	onDeleteConfirm: (id: number, name: string) => void;
	onActiveConfirm: (id: number, name: string, status: string) => void;
	rowsPerPage: number;
	page: number;
};

const DataTableRows = ({ contracts, onReport, onEdit, onDeleteConfirm, onActiveConfirm, rowsPerPage, page }: Props) => {
	const [open, setOpen] = useState(false);
	const [openId, setOpenId] = useState(0);
	const { state } = useAppContext();

	const handleOpen = (id: number) => {
		if (open) {
			setOpen(false);
			setOpenId(0);
		} else {
			setOpen(true);
			setOpenId(id);
		}
	};

	return (
		<>
			{!contracts?.length && (
				<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
					<TableCellView align="left" w="auto" colspan={7}>
						Nebyl nalezen žádný záznam.
					</TableCellView>
				</TableRow>
			)}

			{contracts &&
				(rowsPerPage > 0
					? contracts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
					: contracts
				).map((contract: ContractDB, index: number) => {
					return (
						<React.Fragment key={contract.id}>
							<TableRow hover>
								<TableCellView align="center" w={50}>
									{rowsPerPage > 0 ? page * rowsPerPage + index + 1 : index + 1}
								</TableCellView>

								<TableCellLoupeIcon
									onOpen={handleOpen}
									openId={contract.id}
									color={open && openId === contract.id ? 'warning' : 'info'}
								/>

								<TableCellReportIcon
									onReport={onReport}
									idReport={contract.id!}
									url={`/contracts/report/${contract.id!}`}
								/>
								<TableCellEditIcon
									onEdit={onEdit}
									idEdit={contract.id!}
									url={`/contracts/detail/${contract.id!}`}
								/>

								<TableCellView align="left" mW={150} w="auto">
									{contract.name}
								</TableCellView>

								<TableCellView align="center" mW={50} w="auto">
									{contract.contractToCars && contract.contractToCars.length > 0
										? contract.contractToCars.length
										: '-'}
								</TableCellView>

								<TableCellView align="center" mW={50} w="auto">
									{contract.contractToUsers && contract.contractToUsers.length > 0
										? contract.contractToUsers.length
										: '-'}
								</TableCellView>

								<TableCellActive
									onActiveConfirm={onActiveConfirm}
									idRecord={contract.id!}
									status={contract.status!}
									nameDelete={`${contract.name}`}
								/>

								{(contract.contractToCars && contract.contractToCars.length > 0) ||
								(contract.contractToUsers && contract.contractToUsers.length > 0) ? (
									<TableCellView align="right" w={'5px'} pX={'5px'}>
										{''}
									</TableCellView>
								) : (
									<TableCellDeleteIcon
										onDeleteConfirm={onDeleteConfirm}
										idDelete={contract.id!}
										nameDelete={`${contract.name}`}
									/>
								)}

								<TableCellView
									align="right"
									w={state.user?.role === 'admin' ? 40 : '5px'}
									pX={state.user?.role === 'admin' ? undefined : '5px'}>
									{state.user?.role === 'admin' ? contract.id : ''}
								</TableCellView>
							</TableRow>

							{/* hidden */}

							<TableRow>
								<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
									<Collapse in={open && openId === contract.id} timeout="auto" unmountOnExit>
										<Box sx={{ margin: 1, marginBottom: 3 }}>
											<Table size="small" aria-label="purchases">
												{/* <TableHead>
													<TableRow>
														<TableCell align="left" width={30}></TableCell>
														<TableCell align="left" width={30}></TableCell>

														<TableCell align="left">Klient</TableCell>
													</TableRow>
												</TableHead> */}
												<TableBody>
													<TableRow>
														<TableCell align="left" width={100}></TableCell>
														<TableCell align="left" width={100}>
															Klient:
														</TableCell>
														<TableCell align="left">
															{contract.client?.companyName}
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align="left" width={100}></TableCell>
														<TableCell align="left" width={100}>
															Zahájení:
														</TableCell>
														<TableCell align="left">
															{dayjs(contract.dateFrom).format('DD.MM.YYYY')}
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align="left" width={100}></TableCell>
														<TableCell align="left" width={100}>
															Ukončení:
														</TableCell>
														<TableCell align="left">
															{dayjs(contract.dateTo).format('DD.MM.YYYY')}
														</TableCell>
													</TableRow>

													{contract.description && (
														<TableRow>
															<TableCell align="left" width={100}></TableCell>
															<TableCell align="left" width={100}>
																Popis:
															</TableCell>
															<TableCell align="left">{contract.description}</TableCell>
														</TableRow>
													)}
												</TableBody>
											</Table>
										</Box>
									</Collapse>
								</TableCell>
							</TableRow>
						</React.Fragment>
					);
				})}
		</>
	);
};

export default DataTableRows;
