export const tabsName = [
	{
		label: 'Přehled',
		url: '',
	},
	{
		label: 'Auta',
		url: '/cars',
	},

	{
		label: 'Pracovníci',
		url: '/workers',
	},
	{
		label: 'Řidiči',
		url: '/drivers',
	},
];
