import React, { useEffect, useState } from 'react';

// _______________ mui ___________________
import AddIcon from '@mui/icons-material/Add';

import { AlertColor, TablePagination } from '@mui/material';
// _______________ components ___________________
import FormModal from '../../../components/modal/FormModal';
import Loading from '../../../components/pageContent/Loading';
import PageTitle from '../../../components/pageContent/PageTitle';
import DeleteDialog from '../../../components/pageContent/TableView/DeleteDialog';
import SnackbarAfterAction from '../../../components/uiComponents/SnackbarAfterAction';
// _______________ dict ___________________
import { dict } from '../../../translate/translate-dictionary';
// _______________ app context ___________________
import { useAppContext } from '../../../context/appContext/appContext';

import { useClientsContext } from '../../../context/clientsContext/clientsContext';

// _______________ fetch ___________________
import { useUpdate } from '../../../utils/useUpdate';
// _______________ types ___________________
import { ClientDB, ClientFormInputs } from '../types';
import ClientForm from '../edit/ClientForm';

import TitleTableRow from './dataTable/TitleTableRow';
import DataTableRows from './dataTable/DataTableRows';
import TableView from '../../../components/pageContent/TableView/TableView';
import PermissionAlert from '../../../components/pageContent/PermissionAlert';
import { useNavigate } from 'react-router-dom';
import FilterClient from './FilterClient';
import customFetcher from '../../../utils/fetchInstance';

type Props = {};

const ClientsList = (props: Props) => {
	const [openModal, setOpenModal] = useState<boolean>(false);

	const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
	const [msgSnackbar, setMsgSnackbar] = useState<string>('');
	const [severitySnackbar, setSeveritySnackbar] = useState<AlertColor>('success');

	const [editID, setEditID] = useState<number | null>(null);
	const [editData, setEditData] = useState<ClientDB | null>(null);

	const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
	const [idDelete, setIdDelete] = React.useState<number | null>(null);
	const [nameDelete, setNameDelete] = React.useState<string>('');

	const { state: stateClient, setPage, setInitClients, refetchClients, removeIsInit } = useClientsContext();
	const { state, setRowsPerPage } = useAppContext();

	const navigate = useNavigate();
	const lang = state.lang;

	// ____________ USE UPDATE - ostatní metody ______________________
	const {
		isLoading: isUpdateLoading,
		data: dataEditing,
		update: updateClient,
		error: updateError,
	} = useUpdate<{ status: string; error?: string; client?: ClientDB }>('');

	// ____________ SET ADD ______________________
	const handleAdd = (): void => {
		setOpenModal(true);
		setEditID(null);
	};

	// ____________ SET EDIT ______________________
	const handleEdit = async (id: number): Promise<void> => {
		const response = await updateClient(null, 'GET', `/clients/${id}`);

		if (!response) {
			return;
		}

		if (response?.status === 'success') {
			if (response.client) setEditData(response.client);
		}

		setOpenModal(true);
		setEditID(id);
	};

	// ____________ DELETE ______________________
	const handleDelete = async (): Promise<void> => {
		const response = await updateClient(null, 'DELETE', `/clients/${idDelete}`);
		if (!response) {
			return;
		}

		if (response.status === 'error') {
			if (response.error === 'RECORD_CANNOT_BE_DELETED') {
				setMsgSnackbar(`Klienta "${nameDelete}" nelze smazat!`);
				setSeveritySnackbar('error');
				setOpenSnackbar(true);
			}
		} else {
			removeIsInit();
			setMsgSnackbar(`Klient "${nameDelete}" byl vymazán.`);
			setSeveritySnackbar('success');
			setOpenSnackbar(true);
		}

		setIdDelete(null);
		setNameDelete('');
		handleCloseDeleteDialog();
	};

	// ____________ DELETE CONFIRM ______________________
	const handleDeleteConfirm = (id: number, name: string) => {
		setIdDelete(id);
		setNameDelete(name);
		setOpenDeleteDialog(true);
	};

	// ____________ DELETE CLOSE DIALOG ______________________
	const handleCloseDeleteDialog = () => {
		setIdDelete(null);
		setOpenDeleteDialog(false);
	};

	const handleFile = async (fileLogo: any, id: string) => {
		const form = new FormData();

		form.append('idClient', id);
		form.append('fileLogo', fileLogo);

		const response = await customFetcher(`/clients/logo-upload`, 'POSTIMAGE', form);
		console.log(response);

		// const response = await updateClient(form, 'POSTIMAGE', `/clients/logo-upload`);
		return false;
	};

	// ____________ SUBMIT FORM MODAL ______________________
	const handleFormSubmit = async (data: ClientFormInputs, fileLogo?: any) => {
		let newId = 0;

		if (editID) {
			const response = await updateClient(
				{
					companyName: data.companyName,
					idNum: data.idNum,
					VAT: data.VAT,
					companyEmail: data.companyEmail,
					companyPhone: data.companyPhone,
					street: data.street,
					city: data.city,
					zipCode: data.zipCode,
					country: data.country,
					firstName: data.firstName,
					lastName: data.lastName,
					email: data.email,
					phone: data.phone,
					note: data.note,
				},
				'PATCH',
				`/clients/${editID}`
			);

			if (!response) {
				return 'ERROR_NETWORK';
			}

			if (response?.status === 'error') {
				return response.error;
			}
		} else {
			const response = await updateClient(
				{
					companyName: data.companyName,
					idNum: data.idNum,
					VAT: data.VAT,
					companyEmail: data.companyEmail,
					companyPhone: data.companyPhone,
					street: data.street,
					city: data.city,
					zipCode: data.zipCode,
					country: data.country,
					firstName: data.firstName,
					lastName: data.lastName,
					email: data.email,
					phone: data.phone,
					note: data.note,
				},
				'POST',
				`/clients`
			);

			if (response?.status === 'error') {
				return response.error;
			}

			if (!response) {
				return 'ERROR_NETWORK';
			}

			if (response.client?.id) {
				newId = response.client.id;
			}
		}

		if (fileLogo) {
			if (newId || editID) {
				await handleFile(fileLogo, editID ? editID.toString() : newId.toString());
			}

			fileLogo = null;

			// await fetch('http://localhost:5000/api/v1/api/upload', { method: 'POST', body: form });
		}

		editData ? refetchClients() : removeIsInit();

		setOpenModal(false);

		setMsgSnackbar(
			editData ? `Klient ${data.companyName} byl editován.` : `Klient ${data.companyName} byla přidán.`
		);
		setSeveritySnackbar('success');
		setOpenSnackbar(true);

		setEditData(null);
		setEditID(null);
		return undefined;
	};

	// ____________ Close Modal ______________________
	const handleCloseModal = (): void => {
		setOpenModal(false);
		setEditData(null);
		setEditID(null);
		refetchClients();
	};

	// ____________ SNACKBAR ______________________
	const handleCloseSnackbar = () => {
		setOpenSnackbar(false);
	};

	// ____________ SET PAGE ______________________
	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	// ____________ SET ROWS PER PAGE ______________________
	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	// ____________ REPORT CONTRACT ______________________
	// const handleReport = (id: number): void => {
	// 	navigate(`/clients/report/${id}`);
	// };

	// ____________ NASTAVENI INICIALIZACE NEBO PO NAVRATU REFETCH ______________________
	useEffect(() => {
		if (stateClient.isInit === false) {
			setInitClients();
		} else {
			refetchClients();
		}
	}, [setInitClients, refetchClients, stateClient.isInit]);

	// _______________________ VYSTUP ________________________

	if (!state.user?.roles.includes('editor')) {
		return <PermissionAlert />;
	}

	return (
		<>
			<Loading isLoading={stateClient.isLoading || isUpdateLoading} />
			<SnackbarAfterAction
				severity={severitySnackbar}
				openSnackbar={openSnackbar}
				onClose={handleCloseSnackbar}
				msgSnackbar={msgSnackbar}
			/>

			<DeleteDialog onOpen={openDeleteDialog} handleClose={handleCloseDeleteDialog} onDelete={handleDelete} />

			<FormModal
				modalTitle={`${editID ? 'Editace' : 'Vytvoření'} klienta`}
				openModal={openModal}
				onClose={handleCloseModal}>
				<ClientForm
					editID={editID}
					editData={editData}
					onFormSubmit={handleFormSubmit}
					onClose={handleCloseModal}
				/>
			</FormModal>

			<PageTitle
				title="Klienti"
				buttonText="vytvořit klienta"
				icon={<AddIcon />}
				onClickHeaderButton={handleAdd}
			/>

			<FilterClient />

			<TableView
				titleRow={<TitleTableRow />}
				dataRow={
					<DataTableRows
						clients={stateClient.clients}
						onEdit={handleEdit}
						onDeleteConfirm={handleDeleteConfirm}
						rowsPerPage={state.rowsPerPage}
						page={stateClient.page}
					/>
				}
				tablePagination={
					<TablePagination
						count={stateClient.clients ? stateClient.clients.length : 0}
						component="div"
						rowsPerPage={state.rowsPerPage}
						page={stateClient.page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						rowsPerPageOptions={[5, 10, 15]}
						labelRowsPerPage={dict['ROWS_PER_PAGE'][lang]}
					/>
				}
				colspan={8}
			/>
		</>
	);
};

export default ClientsList;
