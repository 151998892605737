export const tabsName = [
	{
		label: 'Řidič',
		url: '/driver',
	},

	{
		label: 'Pracovník',
		url: '/worker',
	},
];
