import { Box, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import React from 'react';
import { CarCategoryDB } from '../../../cars/types';

type Props = {
	categoryFilter: string;
	onChangeCategory: (event: SelectChangeEvent) => void;
	categorySelect: CarCategoryDB[] | null;
};

const SelectCarCategory = ({ categoryFilter, onChangeCategory, categorySelect }: Props) => {
	return (
		// <Box
		// 	component="div"
		// 	sx={{ background: 'white', paddingX: 1, paddingY: 1, marginTop: 2, marginBottom: 1, borderRadius: 2 }}>
		<FormControl
			variant="filled"
			size="small"
			sx={{ maxWidth: { sx: '100%', sm: '48%' }, marginRight: { sx: 0, sm: 0 }, marginY: 1, width: '100%' }}>
			<InputLabel id="categoryCar">Kategorie auta</InputLabel>
			<Select labelId="categoryCar" value={categoryFilter} onChange={onChangeCategory} label="Filtr kategorie">
				<MenuItem value="">žádná kategorie</MenuItem>
				{categorySelect &&
					categorySelect.map((item: CarCategoryDB) => {
						return (
							<MenuItem key={item.id} value={item.id}>
								{item.name}
							</MenuItem>
						);
					})}
			</Select>
		</FormControl>
		// </Box>
	);
};

export default SelectCarCategory;
