import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import ButtonWithStartIconResponsive from '../uiComponents/ButtonWithStartIconResponsive';

type Props = {
	title: string;
	icon?: JSX.Element;
	onClickHeaderButton?: () => void;
	buttonText?: string;
	isBorder?: boolean;
};

const PageTitle = ({ title, icon, buttonText, isBorder, onClickHeaderButton }: Props): JSX.Element => {
	return (
		<Box mb={2} component="header">
			<Box
				color={isBorder ? '#ccc' : ''}
				sx={{
					padding: icon ? '0.3em 1em' : '0.8em 1em',
					borderRadius: 2,
					border: isBorder ? 1 : 0,

					backgroundColor: 'white',
					alignItems: 'center',
					display: 'flex',
					justifyContent: 'space-between',
				}}>
				<Typography color={'black'} sx={{ typography: { md: 'h5', sm: 'h6', xs: 'subtitle1' } }} component="h1">
					{title}
				</Typography>

				{icon && (
					<ButtonWithStartIconResponsive
						title={buttonText}
						icon={icon}
						onClickHeaderButton={onClickHeaderButton}
					/>
				)}
			</Box>
		</Box>
	);
};

export default PageTitle;
