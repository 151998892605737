import { TableCell } from '@mui/material';
import React from 'react';

type Props = {
	align: 'inherit' | 'left' | 'center' | 'right' | 'justify' | undefined;
	size?: 'small' | 'medium' | undefined;
	mW?: number;
	w?: string | number;
	pX?: string | number;
	colspan?: number;
	bgr?: string;
	children: JSX.Element | string | number;
};

const TableCellViewTitle = ({ align, size, mW, w, pX, colspan, children, bgr }: Props) => {
	return (
		<TableCell
			colSpan={colspan ? colspan : undefined}
			variant="head"
			align={align}
			size={size ? size : 'small'}
			sx={{
				minWidth: mW ? mW : undefined,
				width: w ? w : 'auto',
				paddingX: pX ? pX : undefined,
				color: 'black',
				background: bgr ? bgr : undefined,
			}}>
			{children}
		</TableCell>
	);
};

export default TableCellViewTitle;
