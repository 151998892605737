import React, { useState } from 'react';
import { ContractCarDB, ContractCarFormInput, ContractDB, ContractToCarDB } from '../types';

import { dict } from '../../../../translate/translate-dictionary';

import PageTitle from '../../../../components/pageContent/PageTitle';

// _______________ mui ___________________
import AddIcon from '@mui/icons-material/Add';
import { AlertColor, Box, Button, Divider, SelectChangeEvent, Stack, TablePagination, Typography } from '@mui/material';
import TableView from '../../../../components/pageContent/TableView/TableView';
import TitleTableRow from './TitleTableRow';
import DataTableRows from './DataTableRows';
import { CarCategoryDB, CarDB } from '../../../cars/types';
import { useData } from '../../../../utils/useData';
import Loading from '../../../../components/pageContent/Loading';
import SelectCarCategory from './SelectCarCategory';

import SelectCar from './SelectCar';
import FormEditCar from './FormEditCar';
import { useUpdate } from '../../../../utils/useUpdate';
import SnackbarAfterAction from '../../../../components/uiComponents/SnackbarAfterAction';
import DeleteDialog from '../../../../components/pageContent/TableView/DeleteDialog';

type Props = {
	contractId: number;
	contractEdit: ContractDB | null;
	isLoadingParent: boolean;
	onCloseEdit: () => void;
};

const TabCars = ({ contractId, contractEdit, isLoadingParent, onCloseEdit }: Props) => {
	const [openFormCar, setOpenFormCar] = useState<boolean>(false);
	const [editID, setEditID] = useState<number | null>(null);
	const [editData, setEditData] = useState<ContractCarDB | null>(null);
	const [editName, setEditName] = useState<string>('');

	const [contractCarsData, setContractCarsData] = useState<ContractCarDB[] | null>(null); // zobrazeni pro vypis table
	const [usedCars, setUsedCars] = useState<number[] | null>(null); // tyto se nam vyfiltrujou z nabidky pro pouziti

	const [contractFirstCarData, setContractFirstCarData] = useState<ContractCarDB | null>(null);

	const [categoryFilter, setCategoryFilter] = useState<string>('');
	const [categorySelect, setCategorySelect] = useState<CarCategoryDB[] | null>(null);

	const [carSelect, setCarSelect] = useState<CarDB[] | null>(null); // nabidka aut pro zakazku
	const [carSelected, setCarSelected] = useState<string>(''); // vybrane auto pro pridani do zakazky

	const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
	const [deleteId, setDeleteId] = React.useState<number | null>(null);
	const [deleteName, setDeleteName] = React.useState<string>('');

	const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
	const [msgSnackbar, setMsgSnackbar] = useState<string>('');
	const [severitySnackbar, setSeveritySnackbar] = useState<AlertColor>('success');

	// ____________ USE DATA - NACTENI CONTRACT CARS ______________________
	const {
		isLoading: isLoading,
		data: contractCars,
		refetch,
	} = useData<ContractCarDB[], { contractCars: ContractCarDB[] }>(
		`/contracts/contract-to-car/${contractId}`,
		data => {
			setContractCarsData(data.contractCars);
			setUsedCars(data.contractCars.map(item => item.carId));
			return data.contractCars;
		}
	);

	// ____________ USE DATA - NACTENI FIRST CONTRACT CARS ______________________
	const {
		isLoading: isLoadingFirst,
		data: contractFirstCar,
		refetch: refetchFirst,
	} = useData<ContractCarDB, { contractCar: ContractCarDB }>(
		`/contracts/contract-to-car-first/${contractId}`,
		data => {
			setContractFirstCarData(data.contractCar);
			return data.contractCar;
		}
	);

	// ____________ USE DATA - NACTENI CATEGORY CARS ______________________
	const { isLoading: isLoadingCat, data: carsCategory } = useData<CarCategoryDB[], { carsCategory: CarCategoryDB[] }>(
		'/dials/cars/category',
		data => {
			setCategorySelect(data.carsCategory);
			return data.carsCategory;
		}
	);

	// ____________ USE DATA - NACTENI CARS ______________________
	const { isLoading: isLoadingCars, data: cars } = useData<CarDB[], { cars: CarDB[] }>('/dials/cars', data => {
		// const newData = data.cars.filter(item => !usedCars?.includes(item.id));

		setCarSelect(data.cars);
		return data.cars;
	});

	// ____________ USE UPDATE - OSTATNÍ METODY ______________________
	const {
		isLoading: isUpdateLoading,
		data: dataEditing,
		update: updateContractCar,
		error: updateError,
	} = useUpdate<{ status: string; error?: string; contractCar?: ContractCarDB }>('');

	// ____________ CATEGORY CHANGE ______________________
	const handleCategoryChange = (event: SelectChangeEvent) => {
		setCarSelected('');
		setCategoryFilter(event.target.value);
	};

	// ____________ CAR CHANGE ______________________
	const handleCarChange = (event: SelectChangeEvent) => {
		setCarSelected(event.target.value);
	};

	// ____________ CANCEL ADD CAR ______________________
	const handleCancelCarAdd = () => {
		setCarSelected('');
		setCategoryFilter('');
		setOpenFormCar(false);
	};

	// ____________ SET ADD CAR ______________________
	const handleAdd = (): void => {
		setOpenFormCar(true);
		setEditID(null);
		setEditData(null);
		setEditName('');
		setCarSelected('');
		setCategoryFilter('');
	};

	// ____________ SET EDIT CAR ______________________
	const handleEditCar = async (id: number): Promise<void> => {
		setCarSelected('');
		setCategoryFilter('');
		setOpenFormCar(false);

		const response = await updateContractCar(null, 'GET', `/contracts/contract-to-car/${contractId}/${id}`);

		if (!response) {
			return;
		}

		if (response?.status === 'success') {
			if (response.contractCar) {
				setEditData(response.contractCar);
				setEditName(`${response.contractCar.car.name}, ${response.contractCar.car.spz}`);
				setCarSelected(response.contractCar.carId.toString());
			}
		}

		setOpenFormCar(true);
		setEditID(id);
	};

	// ____________ DELETE ______________________
	const handleDeleteCar = async (): Promise<void> => {
		const response = await updateContractCar(null, 'DELETE', `/contracts/contract-to-car/${deleteId}`);
		if (!response) {
			return;
		}

		if (response.status === 'error') {
			if (response.error === 'RECORD_CANNOT_BE_DELETED') {
				setMsgSnackbar(`Auto "${deleteName}" nelze ze zakázky smazat!`);
				setSeveritySnackbar('error');
				setOpenSnackbar(true);
			}
		} else {
			await refetch();
			await refetchFirst();
			setMsgSnackbar(`Auto "${deleteName}" bylo vymazáno.`);
			setSeveritySnackbar('success');
			setOpenSnackbar(true);
		}

		setDeleteId(null);
		setDeleteName('');
		handleCloseDeleteDialog();
	};

	// ____________ DELETE CONFIRM ______________________
	const handleDeleteConfirm = (id: number, name: string) => {
		setDeleteId(id);
		setDeleteName(name);
		setOpenDeleteDialog(true);
	};

	// ____________ DELETE CLOSE DIALOG ______________________
	const handleCloseDeleteDialog = () => {
		setDeleteId(null);
		setDeleteName('');
		setOpenDeleteDialog(false);
	};

	// ____________ SUBMIT FORM ______________________
	const handleFormCarSubmit = async (data: ContractCarFormInput) => {
		if (editID) {
			const response = await updateContractCar(
				{
					dailyRate: data.dailyRate,
					includedKm: data.includedKm,
					ratePerKm: data.ratePerKm,
				},
				'PATCH',
				`/contracts/contract-to-car/${editID}`
			);

			if (response?.status === 'error') {
				return response.error;
			}

			if (!response) {
				return 'ERROR_NETWORK';
			}
		} else {
			const response = await updateContractCar(
				{
					carId: carSelected,
					dailyRate: data.dailyRate,
					includedKm: data.includedKm,
					ratePerKm: data.ratePerKm,
				},
				'POST',
				`/contracts/contract-to-car/${contractId}`
			);

			if (response?.status === 'error') {
				return response.error;
			}

			if (!response) {
				return 'ERROR_NETWORK';
			}
		}

		await refetch();
		await refetchFirst();

		setMsgSnackbar(editID ? `Auto bylo editováno.` : `Auto bylo přidáno.`);
		setSeveritySnackbar('success');
		setOpenSnackbar(true);

		setCarSelected('');
		setCategoryFilter('');
		setOpenFormCar(false);

		setEditID(null);
		setEditName('');

		return undefined;
	};

	// ____________ SNACKBAR ______________________
	const handleCloseSnackbar = () => {
		setOpenSnackbar(false);
	};

	return (
		<>
			<Loading isLoading={isLoading || isLoadingCat || isLoadingCars || isUpdateLoading || isLoadingFirst} />

			<SnackbarAfterAction
				severity={severitySnackbar}
				openSnackbar={openSnackbar}
				onClose={handleCloseSnackbar}
				msgSnackbar={msgSnackbar}
			/>
			<DeleteDialog onOpen={openDeleteDialog} handleClose={handleCloseDeleteDialog} onDelete={handleDeleteCar} />
			{contractEdit?.status === 'closed' && <PageTitle title={contractEdit?.name ? contractEdit?.name : ''} />}
			{contractEdit?.status !== 'closed' && (
				<PageTitle
					title={contractEdit?.name ? contractEdit?.name : ''}
					buttonText={!openFormCar ? 'přidat auto' : undefined}
					icon={!openFormCar ? <AddIcon /> : undefined}
					onClickHeaderButton={!openFormCar ? handleAdd : undefined}
				/>
			)}
			<Divider sx={{ mb: 3 }} />
			{openFormCar && (
				<>
					<Box
						sx={{
							borderRadius: 2,
							border: 0,
							borderColor: '#ccc',
							marginTop: 1,
							marginBottom: 0,
							paddingX: 1,
							width: '100%',
						}}>
						{editName && (
							<Typography component={'h3'} variant="h5" sx={{ marginTop: 0, marginBottom: 1 }}>
								{editName}
							</Typography>
						)}
						{!editName && (
							<Box
								sx={{
									display: 'flex',
									flexDirection: { xs: 'column', sm: 'row' },
									justifyContent: 'space-between',
									alignItems: 'center',
									width: '100%',
								}}>
								<SelectCarCategory
									categorySelect={categorySelect}
									categoryFilter={categoryFilter}
									onChangeCategory={handleCategoryChange}
								/>
								<SelectCar
									carSelect={carSelect}
									usedCars={usedCars}
									categoryFilter={categoryFilter}
									onChangeCar={handleCarChange}
									carSelected={carSelected}
								/>
							</Box>
						)}
						{!carSelected && (
							<Stack direction="row" justifyContent="center" alignItems="center" spacing={2} mt={3}>
								<Button variant="contained" size="large" color="inherit" onClick={handleCancelCarAdd}>
									zrušit
									<Box
										component={'span'}
										sx={{ marginLeft: 0.5, display: { xs: 'none', sm: 'inline' } }}>
										{editID ? 'editaci' : 'přidávání'} auta
									</Box>
								</Button>
							</Stack>
						)}

						{/* formular pro editaci cen */}
						{carSelected && (
							<FormEditCar
								onCancel={handleCancelCarAdd}
								onFormSubmit={handleFormCarSubmit}
								carSelected={carSelected}
								editID={editID}
								editData={editData}
								firstCarData={contractFirstCarData}
							/>
						)}
					</Box>

					<Divider sx={{ marginTop: 2, marginBottom: 3 }} />
				</>
			)}
			<TableView
				titleRow={<TitleTableRow />}
				dataRow={
					<DataTableRows
						status={contractEdit?.status}
						contractCars={contractCars}
						onEdit={handleEditCar}
						onDeleteConfirm={handleDeleteConfirm}
					/>
				}
				colspan={9}
			/>
		</>
	);
};

export default TabCars;
