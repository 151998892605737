import React, { useReducer, useContext, ReactNode, useState, useMemo, useCallback, useEffect } from 'react';
import { ContextData, ContextState } from './types';
import { useNavigate } from 'react-router-dom';
import reducer from './reducer';
import { ActionType } from './actions';

import { getRefreshToken, getRowsPerPage, setLocalStorage } from './handle-local-storage';

import customFetcher, { originalRequest } from '../../utils/fetchInstance';
import { dictKey } from '../../translate/translate-dictionary';

type Props = {
	children: ReactNode;
};

/** CREATE CONTEXT */
const AppContext = React.createContext<ContextData>(null as any);

const rowsPerPageStorage = getRowsPerPage();

const initialState: ContextState = {
	isLoading: false,
	userLoading: true,
	showAlert: false,
	rowsPerPage: rowsPerPageStorage ? rowsPerPageStorage : parseInt(process.env.REACT_APP_ROWS_PER_PAGE!),
	alertText: '',
	alertType: 'error',
	lang: 'cs',
	user: null,
};

/** PROVIDER */
const AppProvider: React.FC<Props> = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, null, () => initialState);

	const navigate = useNavigate();

	const clearAlert = useCallback(() => {
		setTimeout(() => {
			dispatch({ type: ActionType.CLEAR_ALERT });
		}, 4000);
	}, [dispatch]);

	const displayAlert = useCallback(() => {
		dispatch({ type: ActionType.DISPLAY_ALERT });
		clearAlert();
	}, [dispatch, clearAlert]);

	const setRowsPerPage = useCallback(
		(rowsPerPage: number): void => {
			dispatch({ type: ActionType.SET_ROWS_PER_PAGE, payload: { rowsPerPage } });
			setLocalStorage('rowsPerPage', rowsPerPage.toString());
		},
		[dispatch]
	);

	const loginUser = useCallback(
		async (payload: object) => {
			dispatch({ type: ActionType.LOGIN_USER_BEGIN });
			try {
				const { data, response } = await originalRequest('/auth/login', 'POST', payload, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					credentials: 'include',
				});

				if (response.ok) {
					setLocalStorage('refreshToken', data.refreshToken);

					const user = {
						id: data.user.id,
						firstName: data.user.firstName,
						lastName: data.user.lastName,
						email: data.user.email,
						role: data.user.roles[0],
						roles: data.user.roles,
					};

					dispatch({
						type: ActionType.LOGIN_USER_SUCCESS,
						payload: { user },
					});

					navigate('/');
				} else {
					dispatch({ type: ActionType.LOGIN_USER_ERROR });
					return data.error;
				}
			} catch (error) {
				console.log(error);
			}
		},
		[dispatch, navigate]
	);

	const logout = useCallback(async () => {
		try {
			await customFetcher('/auth/logout', 'POST', {
				refreshToken: getRefreshToken(),
			});

			await localStorage.removeItem('refreshToken');

			dispatch({ type: ActionType.LOGOUT_USER });
		} catch (error) {
			console.log(error);
		}
	}, [dispatch, customFetcher]);

	const getCurrentUser = useCallback(async () => {
		try {
			dispatch({ type: ActionType.GET_CURRENT_USER_BEGIN });

			const refreshToken = getRefreshToken();

			if (refreshToken) {
				const { data } = await customFetcher(`/auth/getCurrentUser`);
				const { user } = data;

				dispatch({
					type: ActionType.GET_CURRENT_USER_SUCCESS,
					payload: { user },
				});
			} else {
				dispatch({ type: ActionType.GET_CURRENT_USER_ERROR });
			}
		} catch (error) {}
	}, [dispatch, customFetcher]);

	useEffect(() => {
		getCurrentUser();
	}, []);

	const value = useMemo(
		(): ContextData => ({
			state,
			displayAlert,
			loginUser,
			logout,
			getCurrentUser,
			setRowsPerPage,
		}),
		[state, displayAlert, loginUser, logout, getCurrentUser, setRowsPerPage]
	);

	return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

const useAppContext = () => {
	return useContext(AppContext);
};

export { AppProvider, initialState, useAppContext };
