import { Box, Collapse, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import TableCellDeleteIcon from '../../../../../components/pageContent/TableView/TableCellDeleteIcon';
import TableCellEditIcon from '../../../../../components/pageContent/TableView/TableCellEditIcon';
import { DriverWorkedHours, ReportToCarDB, ReportToDriverDB } from '../../../types';

type Props = {
	report: ReportToDriverDB;
	onOpen: (id: number) => {};
	onEdit: (id: number) => void;
	onDeleteConfirm: (id: number, name: string) => void;
	open: boolean;
	openId: number;
};

const DataCollapse = ({ report, onOpen, open, openId, onEdit, onDeleteConfirm }: Props) => {
	const theme = useTheme();
	return (
		<>
			{/* ________________ COLLAPSE ROW __________________________ */}

			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
					<Collapse in={open && openId === report.id} timeout="auto" unmountOnExit>
						<Box sx={{ margin: 1, marginBottom: 3 }}>
							{report.reportDetails.map(detail => {
								return (
									<React.Fragment key={detail.id}>
										<Table size="small" aria-label="purchases" sx={{ marginTop: 2 }}>
											<TableBody sx={{ marginBottom: 2 }}>
												<TableRow>
													<TableCell align="left">
														<Typography
															sx={{
																fontWeight: 800,
																color: !detail.car.isActive
																	? theme.palette.error.dark
																	: '',
															}}
															component={'span'}
															variant={'body2'}>
															{`${detail.car.name}`}
														</Typography>
														<Typography
															sx={{
																marginLeft: 1,
																color: !detail.car.isActive
																	? theme.palette.error.dark
																	: '',
															}}
															component={'span'}
															variant={'caption'}>
															{`SPZ: ${detail.car.spz}`}
														</Typography>
														{!detail.car.isActive && (
															<Typography
																sx={{
																	marginLeft: 1,
																	color: !detail.car.isActive
																		? theme.palette.error.dark
																		: '',
																}}
																component={'span'}
																variant={'caption'}>
																{`auto je pozastaveno`}
															</Typography>
														)}
													</TableCell>
													{detail.reportCar.status === 'active' && (
														<>
															<TableCellEditIcon onEdit={onEdit} idEdit={detail.id} />
															<TableCellDeleteIcon
																onDeleteConfirm={onDeleteConfirm}
																idDelete={detail.id}
																nameDelete={`${detail.car.name}, ${dayjs(
																	detail.timeFrom
																).format('HH:mm')} - ${dayjs(detail.timeTo).format(
																	'HH:mm'
																)}`}
															/>
														</>
													)}
													{detail.reportCar.status === 'closed' && (
														<TableCell align="right" colSpan={2}>
															<Typography
																sx={{ color: theme.palette.error.main }}
																component={'span'}
																variant={'caption'}>
																uzavřeno
															</Typography>
														</TableCell>
													)}
												</TableRow>

												<TableRow
													sx={{
														borderBottom:
															detail.costs > 0 || detail.costsDriver > 0 ? 0 : 2,
														borderColor: '#ccc',
													}}>
													<TableCell align="left" colSpan={3}>
														{`${dayjs(detail.timeFrom).format('HH:mm')} - ${dayjs(
															detail.timeTo
														).format('HH:mm')} (${detail.workedHours * 1}), ${
															detail.driveKm * 1
														} km, ${detail.description}`}
													</TableCell>
												</TableRow>

												{detail.costs > 0 && (
													<TableRow
														sx={{
															borderBottom: detail.costsDriver > 0 ? 0 : 2,
															borderColor: '#ccc',
														}}>
														{/* <TableCell align="left" width={100}></TableCell> */}
														<TableCell align="left" colSpan={3}>
															Vícenáklady auto:{' '}
															{`${(detail.costs * 1).toLocaleString()} Kč, ${
																detail.descriptionCosts
															}`}
														</TableCell>
													</TableRow>
												)}

												{detail.costsDriver > 0 && (
													<TableRow
														sx={{
															borderBottom: 2,
															borderColor: '#ccc',
														}}>
														{/* <TableCell align="left" width={100}></TableCell> */}
														<TableCell align="left" colSpan={3}>
															Vícenáklady řidič:{' '}
															{`${(detail.costsDriver * 1).toLocaleString()} Kč, ${
																detail.descriptionCostsDriver
															}`}
														</TableCell>
													</TableRow>
												)}
											</TableBody>
										</Table>
									</React.Fragment>
								);
							})}
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
};

export default DataCollapse;
