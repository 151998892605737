import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useAppContext } from '../../../../context/appContext/appContext';
import { dict } from '../../../../translate/translate-dictionary';
import { ContractUserFormInput, ContractUserDB } from '../types';

import { Box, TextField, Stack, Alert, Button, Divider } from '@mui/material';
import { userAddSchema } from '../../../../utils/joiSchema';
import { joiResolver } from '@hookform/resolvers/joi';

type Props = {
	editID: number | null;
	editData: ContractUserDB | null;
	firstUserData: ContractUserDB | null;
	userSelected: number;
	onCancel: () => void;

	onFormSubmit: (data: ContractUserFormInput) => Promise<string | undefined>;
};

const FormEditUser = ({ editID, editData, firstUserData, userSelected, onCancel, onFormSubmit }: Props) => {
	const [masterError, setMasterError] = useState<string>('');

	const { state } = useAppContext();
	const lang = state.lang;

	const setFormData = editID ? editData : firstUserData ? firstUserData : null;

	// ____________ INIT FORM ______________________
	const {
		register,
		handleSubmit,
		reset,
		control,
		getValues,
		formState: { errors },
	} = useForm<ContractUserFormInput>({
		defaultValues: {
			buyDriver: setFormData ? (setFormData.buyDriver > 0 ? setFormData.buyDriver.toString() : '') : '',
			saleDriver: setFormData ? (setFormData.saleDriver > 0 ? setFormData.saleDriver.toString() : '') : '',
			buyOverDriver: setFormData
				? setFormData.buyOverDriver > 0
					? setFormData.buyOverDriver.toString()
					: ''
				: '',
			saleOverDriver: setFormData
				? setFormData.saleOverDriver > 0
					? setFormData.saleOverDriver.toString()
					: ''
				: '',
			buyWorker: setFormData ? (setFormData.buyWorker > 0 ? setFormData.buyWorker.toString() : '') : '',
			saleWorker: setFormData ? (setFormData.saleWorker > 0 ? setFormData.saleWorker.toString() : '') : '',
			buyOverWorker: setFormData
				? setFormData.buyOverWorker > 0
					? setFormData.buyOverWorker.toString()
					: ''
				: '',
			saleOverWorker: setFormData
				? setFormData.saleOverWorker > 0
					? setFormData.saleOverWorker.toString()
					: ''
				: '',
		},
		resolver: joiResolver(userAddSchema),
		mode: 'onChange',
		delayError: 2000,
	});

	const onSubmit = async (data: ContractUserFormInput) => {
		const response = await onFormSubmit(data);
		if (response) setMasterError(response);
	};

	return (
		<>
			<Box
				component="form"
				noValidate
				onSubmit={handleSubmit(onSubmit)}
				sx={{
					mt: 1,
					marginBottom: 0,
				}}>
				{/* ________ buyDriver, saleDriver _______ */}
				<Box
					sx={{
						display: 'flex',
						justifyContent: { sm: 'space-between' },
						flexDirection: { xs: 'column', sm: 'row' },
						alignItems: 'center',
						marginBottom: 0,
					}}>
					<Controller
						name="buyDriver"
						control={control}
						render={({ field: { ref, ...field } }) => (
							<TextField
								{...field}
								inputRef={ref}
								required
								type="number"
								label="Řidič, nákup/hod."
								variant="filled"
								size="medium"
								margin="normal"
								inputProps={{ maxLength: 20, step: 1 }}
								sx={{ width: { xs: '100%', sm: '48%' } }}
								error={!!errors?.buyDriver}
								helperText={
									errors?.buyDriver?.message
										? dict[errors.buyDriver.message]
											? dict[errors.buyDriver.message][lang]
											: errors?.buyDriver?.message
										: ' '
								}
							/>
						)}
					/>
					<Controller
						name="saleDriver"
						control={control}
						render={({ field: { ref, ...field } }) => (
							<TextField
								{...field}
								inputRef={ref}
								required
								type="number"
								label="Řidič, prodej/hod."
								variant="filled"
								size="medium"
								margin="normal"
								inputProps={{ maxLength: 20, step: 1 }}
								sx={{ width: { xs: '100%', sm: '48%' } }}
								error={!!errors?.saleDriver}
								helperText={
									errors?.saleDriver?.message
										? dict[errors.saleDriver.message]
											? dict[errors.saleDriver.message][lang]
											: errors?.saleDriver?.message
										: ' '
								}
							/>
						)}
					/>
				</Box>

				{/* ________ buyWorker, saleWorker _______ */}
				<Box
					sx={{
						display: 'flex',
						justifyContent: { sm: 'space-between' },
						flexDirection: { xs: 'column', sm: 'row' },
						alignItems: 'center',
						marginBottom: 0,
					}}>
					<Controller
						name="buyWorker"
						control={control}
						render={({ field: { ref, ...field } }) => (
							<TextField
								{...field}
								inputRef={ref}
								required
								type="number"
								label="Pracovník, nákup/hod."
								variant="filled"
								size="medium"
								margin="normal"
								inputProps={{ maxLength: 20, step: 1 }}
								sx={{ width: { xs: '100%', sm: '48%' } }}
								error={!!errors?.buyWorker}
								helperText={
									errors?.buyWorker?.message
										? dict[errors.buyWorker.message]
											? dict[errors.buyWorker.message][lang]
											: errors?.buyWorker?.message
										: ' '
								}
							/>
						)}
					/>
					<Controller
						name="saleWorker"
						control={control}
						render={({ field: { ref, ...field } }) => (
							<TextField
								{...field}
								inputRef={ref}
								required
								type="number"
								label="Pracovník, prodej/hod."
								variant="filled"
								size="medium"
								margin="normal"
								inputProps={{ maxLength: 20, step: 1 }}
								sx={{ width: { xs: '100%', sm: '48%' } }}
								error={!!errors?.saleWorker}
								helperText={
									errors?.saleWorker?.message
										? dict[errors.saleWorker.message]
											? dict[errors.saleWorker.message][lang]
											: errors?.saleWorker?.message
										: ' '
								}
							/>
						)}
					/>
				</Box>

				<Divider sx={{ mt: 1, mb: 2 }} />

				{/* ________ buyOverDriver, saleOverDriver _______ */}
				<Box
					sx={{
						display: 'flex',
						justifyContent: { sm: 'space-between' },
						flexDirection: { xs: 'column', sm: 'row' },
						alignItems: 'center',
						marginBottom: 0,
					}}>
					<Controller
						name="buyOverDriver"
						control={control}
						render={({ field: { ref, ...field } }) => (
							<TextField
								{...field}
								inputRef={ref}
								// required
								type="number"
								label="Řidič, příplatek nákup/hod."
								variant="filled"
								size="medium"
								margin="normal"
								inputProps={{ maxLength: 20, step: 1 }}
								sx={{ width: { xs: '100%', sm: '48%' } }}
								error={!!errors?.buyOverDriver}
								helperText={
									errors?.buyOverDriver?.message
										? dict[errors.buyOverDriver.message]
											? dict[errors.buyOverDriver.message][lang]
											: errors?.buyOverDriver?.message
										: ' '
								}
							/>
						)}
					/>
					<Controller
						name="saleOverDriver"
						control={control}
						render={({ field: { ref, ...field } }) => (
							<TextField
								{...field}
								inputRef={ref}
								// required
								type="number"
								label="Řidič, příplatek prodej/hod."
								variant="filled"
								size="medium"
								margin="normal"
								inputProps={{ maxLength: 20, step: 1 }}
								sx={{ width: { xs: '100%', sm: '48%' } }}
								error={!!errors?.saleOverDriver}
								helperText={
									errors?.saleOverDriver?.message
										? dict[errors.saleOverDriver.message]
											? dict[errors.saleOverDriver.message][lang]
											: errors?.saleOverDriver?.message
										: ' '
								}
							/>
						)}
					/>
				</Box>

				{/* ________ buyOverWorker, saleOverWorker _______ */}
				<Box
					sx={{
						display: 'flex',
						justifyContent: { sm: 'space-between' },
						flexDirection: { xs: 'column', sm: 'row' },
						alignItems: 'center',
						marginBottom: 0,
					}}>
					<Controller
						name="buyOverWorker"
						control={control}
						render={({ field: { ref, ...field } }) => (
							<TextField
								{...field}
								inputRef={ref}
								// required
								type="number"
								label="Pracovník, příplatek nákup/hod."
								variant="filled"
								size="medium"
								margin="normal"
								inputProps={{ maxLength: 20, step: 1 }}
								sx={{ width: { xs: '100%', sm: '48%' } }}
								error={!!errors?.buyOverWorker}
								helperText={
									errors?.buyOverWorker?.message
										? dict[errors.buyOverWorker.message]
											? dict[errors.buyOverWorker.message][lang]
											: errors?.buyOverWorker?.message
										: ' '
								}
							/>
						)}
					/>
					<Controller
						name="saleOverWorker"
						control={control}
						render={({ field: { ref, ...field } }) => (
							<TextField
								{...field}
								inputRef={ref}
								// required
								type="number"
								label="Pracovník, příplatek prodej/hod."
								variant="filled"
								size="medium"
								margin="normal"
								inputProps={{ maxLength: 20, step: 1 }}
								sx={{ width: { xs: '100%', sm: '48%' } }}
								error={!!errors?.saleOverWorker}
								helperText={
									errors?.saleOverWorker?.message
										? dict[errors.saleOverWorker.message]
											? dict[errors.saleOverWorker.message][lang]
											: errors?.saleOverWorker?.message
										: ' '
								}
							/>
						)}
					/>
				</Box>

				<Stack sx={{ width: '100%', marginTop: 2 }} spacing={3}>
					{masterError && (
						<Alert sx={{ borderRadius: '0.5em' }} severity="error">
							{dict[masterError] ? dict[masterError][lang] : masterError ? masterError : ' '}
						</Alert>
					)}
				</Stack>

				<Stack direction="row" justifyContent="center" alignItems="center" spacing={2} mt={3}>
					<Button variant="contained" size="large" color="inherit" onClick={onCancel}>
						zrušit
						<Box component={'span'} sx={{ marginLeft: 0.5, display: { xs: 'none', md: 'inline' } }}>
							{editID ? 'editaci' : 'přidávání'} pracovníka
						</Box>
					</Button>
					<Button variant="contained" type="submit" size="large">
						{`${editID ? 'Editovat' : 'Přidat'}`}
						<Box component={'span'} sx={{ display: { xs: 'none', sm: 'inline' } }}>
							&nbsp;pracovníka
						</Box>
					</Button>
				</Stack>
			</Box>
		</>
	);
};

export default FormEditUser;
