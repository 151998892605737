import {
	Box,
	Collapse,
	LinearProgress,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	useTheme,
} from '@mui/material';

import { useAppContext } from '../../../../context/appContext/appContext';
import { ClientDB } from '../../types';
import TableCellView from '../../../../components/pageContent/TableView/TableCellView';
import TableCellEditIcon from '../../../../components/pageContent/TableView/TableCellEditIcon';
import TableCellDeleteIcon from '../../../../components/pageContent/TableView/TableCellDeleteIcon';
import React, { useState } from 'react';

import { useUpdate } from '../../../../utils/useUpdate';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { ContractDB } from '../../../contracts/edit/types';
import TableCellLoupeIcon from '../../../../components/pageContent/TableView/TableCellLoupeIcon';

type Props = {
	clients: ClientDB[] | null;
	onEdit: (id: number) => void;

	onDeleteConfirm: (id: number, name: string) => void;
	rowsPerPage: number;
	page: number;
};

const DataTableRows = ({ clients, onEdit, onDeleteConfirm, rowsPerPage, page }: Props) => {
	const [open, setOpen] = useState(false);
	const [openId, setOpenId] = useState(0);
	const [contracts, setContracts] = useState<ContractDB[] | null>(null);

	const { state } = useAppContext();
	const theme = useTheme();

	// ____________ USE UPDATE - NACTENI CONTRACTS - clientId  ______________________
	const {
		isLoading: isLoading,
		data: dataEditing,
		update: updateContract,
		error: updateError,
	} = useUpdate<{ status: string; error?: string; contracts?: ContractDB[] }>('');

	const handleOpen = async (id: number) => {
		setOpen(false);
		setOpenId(0);
		setContracts(null);

		if (openId !== id) {
			setOpen(true);
			setOpenId(id);

			const response = await updateContract(null, 'GET', `/contracts/client/${id}`);

			if (!response) {
				return;
			}

			if (response?.status === 'success') {
				if (response.contracts) {
					setContracts(response.contracts);
				}
			}
		}
	};

	return (
		<>
			{!clients?.length && (
				<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
					<TableCellView align="left" w="auto" colspan={8}>
						Nebyl nalezen žádný záznam.
					</TableCellView>
				</TableRow>
			)}

			{clients &&
				(rowsPerPage > 0 ? clients.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : clients).map(
					(client: ClientDB, index: number) => {
						return (
							<React.Fragment key={client.id}>
								<TableRow hover>
									<TableCellView align="center" w={50}>
										{rowsPerPage > 0 ? page * rowsPerPage + index + 1 : index + 1}
									</TableCellView>

									<TableCellLoupeIcon
										onOpen={handleOpen}
										openId={client.id}
										color={open && openId === client.id ? 'warning' : 'info'}
									/>

									{/* <TableCellReportIcon onReport={onReport} idReport={client.id!} /> */}

									<TableCellEditIcon onEdit={onEdit} idEdit={client.id} />

									<TableCellView align="left" mW={200} w="auto">
										{client.companyName}
									</TableCellView>

									<TableCellView align="left" mW={200} w="auto">
										{client.idNum}
									</TableCellView>

									<TableCellDeleteIcon
										onDeleteConfirm={onDeleteConfirm}
										idDelete={client.id}
										nameDelete={`${client.companyName}`}
									/>

									<TableCellView
										align="right"
										w={state.user?.role === 'admin' ? 60 : '5px'}
										pX={state.user?.role === 'admin' ? undefined : '5px'}>
										{state.user?.role === 'admin' ? client.id : ''}
									</TableCellView>
								</TableRow>

								{/* ________________ COLLAPSE ROW __________________________ */}

								<TableRow>
									<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
										<Collapse in={open && openId === client.id} timeout="auto" unmountOnExit>
											<Box sx={{ margin: 1, marginBottom: 3 }}>
												<Table size="small" aria-label="purchases">
													<TableHead>
														<TableRow>
															<TableCell align="left" width={100}></TableCell>
															<TableCell align="left" colSpan={2}>
																{`${client.companyName}`}
																<Typography
																	component={'span'}
																	variant="body2"
																	sx={{
																		color: 'GrayText',
																		ml: 1,
																		fontSize: '0.8em',
																	}}>
																	{`IČO: ${client.idNum}`}
																	{client.VAT && `, DIČ: ${client.VAT}`}
																</Typography>
															</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{(client.street ||
															client.zipCode ||
															client.city ||
															client.country) && (
															<TableRow>
																<TableCell align="left" width={100}></TableCell>
																<TableCell align="left" width={100}>
																	Adresa:
																</TableCell>
																<TableCell align="left">
																	{client.street && `${client.street}`}
																	{(client.zipCode || client.city) && ', '}
																	{client.zipCode && `${client.zipCode} `}
																	{client.city && `${client.city}`}
																	{client.country && `, ${client.country}`}
																</TableCell>
															</TableRow>
														)}
														{(client.companyEmail || client.companyPhone) && (
															<TableRow>
																<TableCell align="left" width={100}></TableCell>
																<TableCell align="left" width={100}>
																	{/* {client.companyEmail && 'Email'}
																	{client.companyEmail && client.companyPhone && ', '}
																	{client.companyPhone && 'tel.'}: */}
																</TableCell>
																<TableCell align="left">
																	{client.companyEmail && client.companyEmail}
																	{client.companyEmail && client.companyPhone && ', '}
																	{client.companyEmail && client.companyPhone}
																</TableCell>
															</TableRow>
														)}

														{(client.firstName || client.lastName) && (
															<TableRow>
																<TableCell align="left" width={100}></TableCell>
																<TableCell align="left" width={100}>
																	Kontakt:
																</TableCell>
																<TableCell align="left">
																	{client.lastName && `${client.lastName} `}

																	{client.firstName && `${client.firstName}`}
																</TableCell>
															</TableRow>
														)}
														{(client.email || client.phone) && (
															<TableRow>
																<TableCell align="left" width={100}></TableCell>
																<TableCell align="left" width={100}>
																	{/* {client.email && 'Email'}
																	{client.email && client.phone && ', '}
																	{client.phone && 'tel.'}: */}
																</TableCell>
																<TableCell align="left">
																	{client.email && client.email}
																	{client.email && client.phone && ', '}
																	{client.email && client.phone}
																</TableCell>
															</TableRow>
														)}
														{client.note && (
															<TableRow>
																<TableCell align="left" width={100}></TableCell>
																<TableCell align="left" width={100}>
																	Poznámka:
																</TableCell>
																<TableCell align="left">{client.note}</TableCell>
															</TableRow>
														)}
													</TableBody>
												</Table>
											</Box>

											{isLoading && (
												<Box
													sx={{
														marginY: 4,
														width: '100%',
													}}>
													<LinearProgress color="info" />
												</Box>
											)}

											{contracts && contracts?.length > 0 && (
												<Box sx={{ margin: 1, marginBottom: 3 }}>
													<Table size="small" aria-label="purchases">
														<TableHead>
															<TableRow
																sx={{
																	backgroundColor: theme.palette.grey[200],
																}}>
																<TableCell align="left" width={100}></TableCell>
																<TableCell align="left" colSpan={4}>
																	Zakázky
																</TableCell>
															</TableRow>
														</TableHead>
														<TableBody>
															{contracts.map((contract: ContractDB) => {
																return (
																	<TableRow key={`contract_${contract.id}`}>
																		<TableCell align="left" width={100}></TableCell>
																		<TableCell align="left" width={250}>
																			{contract.name}
																		</TableCell>
																		<TableCell align="left">
																			{dayjs(contract.dateFrom).format(
																				'DD.MM.YYYY'
																			)}
																		</TableCell>
																		<TableCell align="left">
																			{dayjs(contract.dateTo).format(
																				'DD.MM.YYYY'
																			)}
																		</TableCell>

																		<TableCell align="center">
																			<Link
																				to={`/contracts/report/${contract.id}`}
																				target="_blank"
																				rel="noopener noreferrer">
																				<OpenInNewOutlinedIcon
																					fontSize="small"
																					color="primary"
																				/>
																			</Link>
																		</TableCell>
																	</TableRow>
																);
															})}
														</TableBody>
													</Table>
												</Box>
											)}
										</Collapse>
									</TableCell>
								</TableRow>
							</React.Fragment>
						);
					}
				)}
		</>
	);
};

export default DataTableRows;
