import { getRefreshToken, setLocalStorage } from '../context/appContext/handle-local-storage';

export const refreshQueue: QueueItem[] = [];
export let refreshLock = false;

class QueueItem {
	resolve: (token: string | null) => void;
	promise: Promise<string | null>;

	constructor() {
		this.resolve = () => {};
		this.promise = new Promise<string | null>(res => {
			this.resolve = res;
		});
	}
}

const fetchRefreshToken = async () => {
	try {
		let response: any = await fetch(`${process.env.REACT_APP_BASE_API_URL}/auth/refreshToken`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			credentials: 'include',
			body: JSON.stringify({
				refreshToken: getRefreshToken(),
			}),
		});

		if (response.status === 'error') {
			localStorage.removeItem('refreshToken');
			window.location.href = `${process.env.REACT_APP_BASE_URL}/logout`;
			return null;
		}

		let data = await response.json();

		setLocalStorage('refreshToken', data.refreshToken);

		return data;
	} catch (error) {
		console.log(error);
		localStorage.removeItem('refreshToken');
		window.location.href = `${process.env.REACT_APP_BASE_URL}/logout`;
		return null;
	}
};

export const refreshToken = async () => {
	if (refreshLock) {
		const queueItem = new QueueItem();
		refreshQueue.push(queueItem);
		return queueItem.promise;
	}

	refreshLock = true;

	const responseRefresh = await fetchRefreshToken();

	if (responseRefresh.status === 'error') {
		while (refreshQueue.length > 0) {
			const item = refreshQueue.pop();
			item!.resolve(null);
		}

		localStorage.removeItem('refreshToken');
		window.location.href = `${process.env.REACT_APP_BASE_URL}/logout`;
	}

	while (refreshQueue.length > 0) {
		const item = refreshQueue.pop();
		item!.resolve(responseRefresh.refreshToken);
	}

	refreshLock = false;

	return responseRefresh.refreshToken;
};
