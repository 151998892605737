import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppContext } from '../context/appContext/appContext';

type Props = {
	children: JSX.Element;
};

const PublicRoutes = (props: Props) => {
	const { state } = useAppContext();

	return state.user ? <Navigate to="/" /> : props.children;
};

export default PublicRoutes;
