import { Box, Collapse, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import TableCellDeleteIcon from '../../../../../components/pageContent/TableView/TableCellDeleteIcon';
import TableCellEditIcon from '../../../../../components/pageContent/TableView/TableCellEditIcon';
import { DriverWorkedHours, ReportToCarDB, ReportToWorkerDB } from '../../types';

type Props = {
	report: ReportToWorkerDB;
	onEdit: (id: number) => void;
	onDeleteConfirm: (id: number, name: string) => void;
	onOpen: (id: number) => {};
	open: boolean;
	openId: number;
};

const DataCollapse = ({ report, onEdit, onDeleteConfirm, onOpen, open, openId }: Props) => {
	return (
		<>
			{/* ________________ COLLAPSE ROW __________________________ */}

			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
					<Collapse in={open && openId === report.id} timeout="auto" unmountOnExit>
						<Box sx={{ margin: 1, marginBottom: 3 }}>
							<Table size="small" aria-label="purchases" sx={{ marginTop: 2 }}>
								<TableBody>
									{report.reportDetails.map(detail => {
										return (
											<React.Fragment key={detail.id}>
												<TableRow
													sx={{
														borderBottom: detail.costs && detail.costs > 0 ? 0 : 2,
														borderColor: '#ccc',
													}}>
													<TableCell align="left">
														{`${dayjs(detail.timeFrom).format('HH:mm')} - ${dayjs(
															detail.timeTo
														).format('HH:mm')} (${detail.workedHours * 1}), ${
															detail.description
														}`}
													</TableCell>

													{report.status === 'active' && (
														<TableCellEditIcon onEdit={onEdit} idEdit={detail.id} />
													)}
													{report.status === 'active' && (
														<TableCellDeleteIcon
															onDeleteConfirm={onDeleteConfirm}
															idDelete={detail.id}
															nameDelete={`${report.user.lastName} ${
																report.user.firstName
															}, ${dayjs(detail.timeFrom).format('HH:mm')} - ${dayjs(
																detail.timeTo
															).format('HH:mm')}`}
														/>
													)}
												</TableRow>

												{detail.costs && detail.costs > 0 && (
													<TableRow
														sx={{
															borderBottom: 2,
															borderColor: '#ccc',
														}}>
														{/* <TableCell align="left" width={100}></TableCell> */}
														<TableCell align="left">
															Vícenáklady:
															{`${(detail.costs * 1).toLocaleString()}, ${
																detail.descriptionCosts
															}`}
														</TableCell>
														{report.status === 'active' && (
															<>
																<TableCell align="left"> </TableCell>
																<TableCell align="left"> </TableCell>
															</>
														)}
													</TableRow>
												)}
											</React.Fragment>
										);
									})}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
};

export default DataCollapse;
