import React, { ReactNode, useCallback, useContext, useMemo, useState } from 'react';

import customFetcher from '../../utils/fetchInstance';

import { ContextData, ContextState } from './types';

type Props = {
	children: ReactNode;
};

const ClientsContext = React.createContext<ContextData>(null as any);

const initialState: ContextState = {
	page: 0,
	isInit: false,
	isLoading: false,
	filterStatus: '',
	clients: null,
	filterText: '',
};

const ClientsProvider: React.FC<Props> = ({ children }) => {
	const [state, setState] = useState(initialState);

	const getClients = useCallback(async (filterStatus: string, filterText: string) => {
		try {
			const { data } = await customFetcher(
				`/clients?status=${encodeURI(filterStatus)}&search=${encodeURI(filterText)}`
			);
			const { clients } = data;
			setState(prevState => {
				return { ...prevState, clients };
			});
		} catch (error) {
			console.log(error);
		}
	}, []);

	const setInitClients = useCallback(async () => {
		setState(prevState => {
			return {
				...prevState,
				isInit: true,
				isLoading: true,
				page: 0,
				filterStatus: '',
				filterText: '',
			};
		});
		await getClients('', '');

		setState(prevState => {
			return { ...prevState, isLoading: false };
		});
	}, [getClients]);

	const removeIsInit = useCallback(async () => {
		setState({ ...state, isInit: false });
	}, [state]);

	const setPage = useCallback(
		(page: number): void => {
			setState({ ...state, page });
		},
		[state]
	);

	const setFilterStatus = useCallback(
		async (filterStatus: string) => {
			setState(prevState => {
				return { ...prevState, isLoading: true, page: 0, filterStatus };
			});

			await getClients(filterStatus, state.filterText);

			setState(prevState => {
				return { ...prevState, isLoading: false };
			});
		},
		[getClients, state.filterText]
	);

	const setFilterText = useCallback(
		async (filterText: string) => {
			setState(prevState => {
				return { ...prevState, isLoading: true, page: 0, filterText };
			});

			await getClients(state.filterStatus, filterText);

			setState(prevState => {
				return { ...prevState, isLoading: false };
			});
		},
		[getClients, state.filterStatus]
	);

	const refetchClients = useCallback(async () => {
		setState(prevState => {
			return { ...prevState, isLoading: true };
		});

		await getClients(state.filterStatus, state.filterText);

		setState(prevState => {
			return { ...prevState, isLoading: false };
		});
	}, [getClients, state.filterStatus, state.filterText]);

	const value = useMemo(
		(): ContextData => ({
			state,
			setInitClients,
			removeIsInit,
			setFilterStatus,
			setPage,
			refetchClients,
			setFilterText,
		}),
		[state, setInitClients, removeIsInit, setFilterStatus, setPage, refetchClients, setFilterText]
	);
	return <ClientsContext.Provider value={value}>{children}</ClientsContext.Provider>;
};

const useClientsContext = () => {
	return useContext(ClientsContext);
};

export { ClientsProvider, useClientsContext };
