import { joiResolver } from '@hookform/resolvers/joi';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useAppContext } from '../../../../context/appContext/appContext';
import { reportContractWorkerSchema } from '../../../../utils/joiSchema';
import { ReportContractWorkerFormInputs, ReportWorkerDetail } from '../../types';

import { MobileDatePicker, MobileTimePicker } from '@mui/x-date-pickers';

import csCZ from 'dayjs/locale/cs';
import dayjs from 'dayjs';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { calculateWorkedTime } from '../../../../utils/calculateWorkedTime';
import { Alert, Box, Button, Stack, TextField, Typography } from '@mui/material';
import { dict } from '../../../../translate/translate-dictionary';
import { useParams } from 'react-router-dom';
import { MINUTES_STEP_TIME_PICKER } from '../../../../constants';

type Props = {
	editID: number | null;
	editData: ReportWorkerDetail | null;
	onClose: () => void;
	onFormSubmit: (data: ReportContractWorkerFormInputs) => Promise<string | undefined>;
};

const FormReportWorker = ({ editID, editData, onClose, onFormSubmit }: Props) => {
	const [isSendButton, setIsSendButton] = useState<boolean>(false);
	const [isCosts, setIsCosts] = useState<Boolean>(false);
	const [masterError, setMasterError] = useState<string>('');
	const [workedHours, setWorkedHours] = React.useState<number>(editData ? editData.workedHours * 1 : 0);

	const { contractId } = useParams();
	const { state } = useAppContext();
	const lang = state.lang;

	const {
		handleSubmit,
		getValues,
		control,
		formState: { errors },
	} = useForm<ReportContractWorkerFormInputs>({
		defaultValues: {
			date: editData ? editData.date : dayjs().toString(),
			timeFrom: editData ? editData.timeFrom : '', //dayjs(dayjs().set('hour', 7).set('minute', 0)).toString(),
			timeTo: editData ? editData.timeTo : '', //dayjs(dayjs().set('hour', 7).set('minute', 0)).toString(),
			description: editData ? editData.description : '',
			costs: editData ? (editData.costs > 0 ? editData.costs * 1 : '') : '',
			descriptionCosts: editData ? (editData.descriptionCosts ? editData.descriptionCosts : '') : '',
		},
		resolver: joiResolver(reportContractWorkerSchema),
		mode: 'onChange',
		delayError: 2000,
	});

	const onSubmit = async (data: ReportContractWorkerFormInputs) => {
		setIsSendButton(true);
		const newDate = dayjs(data.date).format('YYYY-MM-DD');
		const newTimeFrom = data.timeFrom ? dayjs(data.timeFrom).format('HH:mm') : '';
		const newTimeTo = data.timeTo ? dayjs(data.timeTo).format('HH:mm') : '';

		const { workedHours, timeFromObj, timeToObj } = calculateWorkedTime(newDate, newTimeFrom, newTimeTo);

		if (!data.costs) {
			data.costs = 0;
			data.descriptionCosts = null;
		}

		const saveData = {
			contractId: contractId ? parseInt(contractId) : 0,
			userId: state.user!.id,
			date: dayjs(data.date).format('YYYY-MM-DD'),
			timeFrom: timeFromObj.format('YYYY-MM-DD HH:mm:ss'),
			timeTo: timeToObj.format('YYYY-MM-DD HH:mm:ss'),
			workedHours: workedHours,
			description: data.description,
			costs: data.costs,
			descriptionCosts: data.descriptionCosts,
		};

		// console.log(saveData);

		const response = await onFormSubmit(saveData);
		if (response) {
			setIsSendButton(false);
			setMasterError(response);
		}
	};

	const showWorkedHour = () => {
		const date = dayjs(getValues('date')).format('YYYY-MM-DD');
		const formFrom = getValues('timeFrom') ? dayjs(getValues('timeFrom')).format('HH:mm') : '';
		const formTo = getValues('timeFrom') ? dayjs(getValues('timeTo')).format('HH:mm') : '';

		const { workedHours } = calculateWorkedTime(date, formFrom, formTo);

		setWorkedHours(workedHours ? workedHours : 0);
	};

	return (
		<>
			<Typography component={'h6'} variant="h6" sx={{ marginBottom: 2 }}>
				{state?.user?.lastName} {state?.user?.firstName}
			</Typography>

			<Box
				component="form"
				noValidate
				onSubmit={handleSubmit(onSubmit)}
				sx={{
					mt: 1,
				}}>
				{/* ________ Date  _______ */}
				<Box
					sx={{
						display: 'flex',

						justifyContent: { xs: 'flex-start', sm: 'space-between' },
						flexDirection: { xs: 'column', sm: 'row' },
						alignItems: 'center',
					}}>
					<LocalizationProvider adapterLocale={csCZ} dateAdapter={AdapterDayjs}>
						<Controller
							name="date"
							control={control}
							render={({ field: { onChange, value, ...field }, fieldState: { error } }) => (
								<MobileDatePicker
									readOnly={editID ? true : false}
									label="Datum"
									inputFormat="DD.MM.YYYY"
									value={value}
									onChange={event => {
										onChange(event);
										// console.log(event);
									}}
									{...field}
									renderInput={params => (
										<TextField
											variant="filled"
											sx={{
												marginRight: 0,
												marginBottom: { xs: 1, sm: 3 },
												width: { xs: '100%', sm: '48%' },
											}}
											{...params}
											error={!!errors.date}
											helperText={errors.date?.message}
										/>
									)}
								/>
							)}
						/>
					</LocalizationProvider>
					<Box sx={{ marginTop: 0, marginBottom: 1 }}>
						<Typography variant="h6" sx={{ margin: 0, color: workedHours < 0 ? 'red' : '' }}>
							Hodiny: {workedHours.toFixed(0)}
						</Typography>
					</Box>
				</Box>

				{/* ________ timeFrom timeTo _______ */}
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						flexDirection: 'row',
						alignItems: 'center',
					}}>
					<LocalizationProvider adapterLocale={csCZ} dateAdapter={AdapterDayjs}>
						<Controller
							name="timeFrom"
							control={control}
							render={({ field: { onChange, value, ...field }, fieldState: { error } }) => (
								<MobileTimePicker
									ampm={false}
									minutesStep={MINUTES_STEP_TIME_PICKER}
									label="Čas od"
									value={value}
									onChange={event => {
										onChange(event);
										showWorkedHour();
									}}
									{...field}
									renderInput={params => (
										<TextField
											variant="filled"
											sx={{ marginRight: 0, marginBottom: 2, width: '48%' }}
											{...params}
											error={!!errors.timeFrom}
											helperText={errors.timeFrom?.message}
										/>
									)}
								/>
							)}
						/>

						<Controller
							name="timeTo"
							control={control}
							render={({ field: { onChange, value, ...field }, fieldState: { error } }) => (
								<MobileTimePicker
									ampm={false}
									minutesStep={MINUTES_STEP_TIME_PICKER}
									label="Čas do"
									value={value}
									onChange={event => {
										onChange(event);
										showWorkedHour();
									}}
									{...field}
									renderInput={params => (
										<TextField
											variant="filled"
											sx={{ marginRight: 0, marginBottom: 2, width: '48%' }}
											{...params}
											error={!!errors.timeFrom}
											helperText={errors.timeFrom?.message}
										/>
									)}
								/>
							)}
						/>
					</LocalizationProvider>
				</Box>

				{/* ________  descriptions _______ */}
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						flexDirection: 'column',
						alignItems: 'flex-start',
						marginTop: 1,
					}}>
					<Controller
						name="description"
						control={control}
						render={({ field: { ref, ...field } }) => (
							<TextField
								{...field}
								inputRef={ref}
								// required
								multiline
								rows={3}
								type="text"
								label="Popis práce"
								variant="filled"
								size="medium"
								margin="normal"
								inputProps={{ maxLength: 250 }}
								sx={{ width: { xs: '100%', sm: '100%' }, margin: 0 }}
								error={!!errors?.description}
								helperText={
									errors?.description?.message
										? dict[errors.description.message]
											? dict[errors.description.message][lang]
											: errors?.description?.message
										: ' '
								}
							/>
						)}
					/>
				</Box>

				{!isCosts && (!editData?.costs || editData?.costs <= 0) && (
					<Stack direction="row" justifyContent="left" alignItems="center" spacing={2} mt={3}>
						<Button
							variant="contained"
							type="button"
							size="medium"
							color="inherit"
							onClick={() => setIsCosts(true)}>
							Přidat vícenáklady
						</Button>
					</Stack>
				)}

				{/* ________ costs descriptionsCosts _______ */}
				{(isCosts || (editData?.costs && editData?.costs > 0)) && (
					<Box
						sx={{
							display: 'flex',
							justifyContent: { sm: 'space-between' },
							flexDirection: { xs: 'column', sm: 'row' },
							alignItems: 'flex-start',
							marginTop: 1,
						}}>
						<Controller
							name="costs"
							control={control}
							render={({ field: { ref, ...field } }) => (
								<TextField
									{...field}
									inputRef={ref}
									// required
									type="text"
									label="Vícenáklady"
									variant="filled"
									size="medium"
									margin="normal"
									inputProps={{ maxLength: 20 }}
									sx={{ width: { xs: '100%', sm: '28%' }, margin: 0, marginBottom: 1 }}
									error={!!errors?.costs}
									helperText={
										errors?.costs?.message
											? dict[errors.costs.message]
												? dict[errors.costs.message][lang]
												: errors?.costs?.message
											: ' '
									}
								/>
							)}
						/>

						<Controller
							name="descriptionCosts"
							control={control}
							render={({ field: { ref, ...field } }) => (
								<TextField
									{...field}
									inputRef={ref}
									// required
									multiline
									rows={1}
									type="text"
									label="Popis vícenákladů"
									variant="filled"
									size="medium"
									margin="normal"
									inputProps={{ maxLength: 250 }}
									sx={{ width: { xs: '100%', sm: '68%' }, margin: 0 }}
									error={!!errors?.descriptionCosts}
									helperText={
										errors?.descriptionCosts?.message
											? dict[errors.descriptionCosts.message]
												? dict[errors.descriptionCosts.message][lang]
												: errors?.descriptionCosts?.message
											: ' '
									}
								/>
							)}
						/>
					</Box>
				)}

				<Stack sx={{ width: '100%', marginTop: 2 }} spacing={3}>
					{masterError && (
						<Alert sx={{ borderRadius: '0.5em' }} severity="error">
							{dict[masterError] ? dict[masterError][lang] : masterError ? masterError : ' '}
						</Alert>
					)}
				</Stack>

				<Stack direction="row" justifyContent="center" alignItems="center" spacing={2} mt={3}>
					<Button
						variant="contained"
						size="large"
						color="inherit"
						onClick={onClose}
						disabled={isSendButton ? true : false}>
						zrušit
					</Button>
					<Button variant="contained" type="submit" size="large" disabled={isSendButton ? true : false}>
						{`${editID ? 'Editovat' : 'Vytvořit'}`}
						<Box component={'span'} sx={{ display: { xs: 'none', sm: 'inline' } }}>
							&nbsp;výkaz
						</Box>
					</Button>
				</Stack>
			</Box>
		</>
	);
};

export default FormReportWorker;
