import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAppContext } from '../../context/appContext/appContext';

type Props = {};

const Dashboard = (props: Props) => {
	const { state, logout } = useAppContext();
	const { user } = state;
	const navigation = useNavigate();

	return user?.role === 'admin' || user?.role === 'editor' ? (
		<Navigate to="/contracts" />
	) : (
		<Navigate to="/my-contracts" />
	);

	// return (
	// 	<>
	// 		<h1>Dashboard</h1>
	// 		<p>
	// 			Lorem ipsum, dolor sit amet consectetur adipisicing elit. Illum corporis quisquam distinctio ipsum, rem
	// 			dicta recusandae sequi! Explicabo vitae illo quidem voluptatibus, aspernatur maxime modi possimus
	// 			repudiandae necessitatibus culpa. Hic, soluta. Provident, nemo minus vero ipsa odio voluptate vitae
	// 			nulla molestias sed doloremque dolorum tempora ducimus architecto cum rem quisquam placeat quod.
	// 			Quibusdam vel eius a natus molestiae. Maiores ea maxime esse, magni commodi.
	// 		</p>

	// 	</>
	// );
};

export default Dashboard;
