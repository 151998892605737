import { AlertColor, SelectChangeEvent } from '@mui/material';
import React, { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import {
	useLocation,
	useNavigate,
	useParams,
	useSearchParams,
	createSearchParams,
	useOutletContext,
} from 'react-router-dom';
import FormModal from '../../../../components/modal/FormModal';
import Loading from '../../../../components/pageContent/Loading';
import DeleteDialog from '../../../../components/pageContent/TableView/DeleteDialog';
import TableView from '../../../../components/pageContent/TableView/TableView';
import SnackbarAfterAction from '../../../../components/uiComponents/SnackbarAfterAction';
import { useAppContext } from '../../../../context/appContext/appContext';
import { useData } from '../../../../utils/useData';
import { useUpdate } from '../../../../utils/useUpdate';
import { ContractCarDB, ReportCarDetail, ReportContractCarFormInputs, ReportToCarDB } from '../types';
import DataTableRows from './dataTable/DataTableRows';
import ShowReportCar from './dataTable/ShowReportCar';
import TitleTableRow from './dataTable/TitleTableRow';

import FormReportCar from './FormReportCar';
import SelectCar from './SelectCar';
import FilterReport from '../../../../components/pageContent/FilterReport';
import DocumentReportDialog from '../../../../components/pageContent/DocumentReportDialog';

type Props = {};

const ReportCars = (props: Props) => {
	const [openModal, setOpenModal] = useState<boolean>(false);

	const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
	const [msgSnackbar, setMsgSnackbar] = useState<string>('');
	const [severitySnackbar, setSeveritySnackbar] = useState<AlertColor>('success');

	const [editID, setEditID] = useState<number | null>(null);
	const [editData, setEditData] = useState<any | null>(null);
	const [editCar, setEditCar] = useState<ContractCarDB | undefined>();

	const [documentReportDialog, setDocumentReportDialog] = React.useState(false);

	const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
	const [idDelete, setIdDelete] = React.useState<number | null>(null);
	const [nameDelete, setNameDelete] = React.useState<string>('');

	const status: string = useOutletContext();

	const [contractCars, setContractCars] = useState<ContractCarDB[] | null>(null);

	const { contractId } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();

	// ____________ ZISKANI PARAMETRU Z URL ______________________
	const carId = searchParams.get('carId')! ? searchParams.get('carId')! : '';
	const userId = searchParams.get('userId')! ? searchParams.get('userId')! : '';

	const filterStatus = searchParams.get('filterStatus')! ? searchParams.get('filterStatus')! : '';

	const dateFrom = searchParams.get('dateFrom')!
		? dayjs(searchParams.get('dateFrom')!)
		: dayjs(new Date().setDate(new Date().getDate() - 30));
	const dateTo = searchParams.get('dateTo')!
		? dayjs(searchParams.get('dateTo')!)
		: dayjs(new Date().setDate(new Date().getDate()));

	const navigate = useNavigate();
	const location = useLocation();

	const { state } = useAppContext();

	// ____________ SKLADANI PARAMETRU PRO DOTAZ DO DB ______________________
	const params: string[] = [];

	dateFrom ? params.push(`dateFrom=${dateFrom.format('YYYY-MM-DD')}`) : params.push(`dateFrom=`);
	dateTo ? params.push(`dateTo=${dateTo.format('YYYY-MM-DD')}`) : params.push(`dateTo=`);
	filterStatus ? params.push(`status=${filterStatus}`) : params.push(`status=`);

	// ____________ USE DATA - NACTENI CONTRACT CARS ______________________
	const {
		isLoading: isLoading,
		data: contractCarsData,
		refetch,
	} = useData<ContractCarDB[], { contractCars: ContractCarDB[] }>(
		`/contracts/contract-to-car/${contractId}/`,
		data => {
			setContractCars(data.contractCars);
			return data.contractCars;
		}
	);

	// ____________ USE DATA - NACTENI REPORTU PRO CAR  ______________________
	const {
		isLoading: isLoadingReport,
		data: reportsCar,
		refetch: refetchReportsCar,
	} = useData<ReportToCarDB[], { reportsCar: ReportToCarDB[] }>(
		`/contracts/report-car-editor/${contractId}/${carId ? carId : 0}?${params.join('&')}`,
		data => data.reportsCar
	);

	// ____________ USE DATA - NACTENI CLOSED REPORTU PRO CAR  ______________________
	const {
		isLoading: isLoadingClosedReport,
		data: reportsClosedCar,
		refetch: refetchReportsClosedCar,
	} = useData<ReportToCarDB[], { reportsCar: ReportToCarDB[] }>(
		`/contracts/report-car-editor/${contractId}/0?status=closed`,
		data => data.reportsCar
	);

	// ____________ USE UPDATE - ostatni metody ______________________
	const {
		isLoading: isUpdateLoading,
		data: dataEditing,
		update: updateReport,
		error: updateError,
	} = useUpdate<{ status: string; error?: string; reportCarDetail?: ReportCarDetail }>('');

	// ____________ HANDLE FILTER DATE ______________________
	const handleFilterDate = (dateFromForm: Dayjs, dateToForm: Dayjs) => {
		if (dateFromForm.diff(dateToForm, 'second') > 0) {
			dateToForm = dateFromForm;
		}

		const dateFromSend = dateFromForm ? dateFromForm.format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD');
		const dateToSend = dateToForm ? dateToForm.format('YYYY-MM-DD') : dateFromForm.format('YYYY-MM-DD');

		navigate({
			pathname: location.pathname,
			search: createSearchParams({
				carId: carId,
				userId: userId,
				filterStatus: filterStatus,
				dateFrom: dateFromSend,
				dateTo: dateToSend,
			}).toString(),
		});
	};

	// ____________ HANDLE FILTER STATUS ______________________
	const handleFilterStatus = (filterStatus: string) => {
		navigate({
			pathname: location.pathname,
			search: createSearchParams({
				carId: carId,
				userId: userId,
				filterStatus: filterStatus,
				dateFrom: dateFrom.format('YYYY-MM-DD'),
				dateTo: dateTo.format('YYYY-MM-DD'),
			}).toString(),
		});
	};

	// ____________ HANDLE CAR CHANGE CONTRACT CARS ______________________
	const handleCarChange = (event: SelectChangeEvent) => {
		navigate({
			pathname: location.pathname,
			search: createSearchParams({
				carId: event.target.value,
				userId: userId,
				filterStatus: filterStatus,
				dateFrom: dateFrom.format('YYYY-MM-DD'),
				dateTo: dateTo.format('YYYY-MM-DD'),
			}).toString(),
		});

		refetchReportsCar();
		refetchReportsClosedCar();
	};

	// ____________ SET ADD ______________________
	const handleAdd = async (): Promise<void> => {
		const response = await updateReport(null, 'GET', `/contracts/contract-is-active/${contractId}`);

		if (response?.status === 'error') {
			if (response.error === 'INACTIVE') {
				navigate(0);
				return;
			}
		}

		setOpenModal(true);
		setEditID(null);
	};

	// ____________ SET EDIT ______________________
	const handleEdit = async (id: number): Promise<void> => {
		const response = await updateReport(null, 'GET', `/contracts/report-car-editor/detail/${id}`);
		if (!response) {
			return;
		}

		if (response?.status === 'error') {
			if (response.error === 'Výkaz již nelze editovat.') {
				refetchReportsCar();
				refetchReportsClosedCar();
				return;
			}
		}
		if (response?.status === 'success') {
			if (response.reportCarDetail) {
				setEditData(response.reportCarDetail);

				setEditCar(contractCars?.find(item => item.carId === response.reportCarDetail?.carId));
			}
		}

		setOpenModal(true);
		setEditID(id);
	};

	// ____________ DELETE ______________________
	const handleDelete = async (): Promise<void> => {
		const response = await updateReport(null, 'DELETE', `/contracts/report-car-editor/${idDelete}`);
		if (!response) {
			return;
		}
		if (response.status === 'error') {
			if (response.error === 'Výkaz již nelze smazat.') {
				setMsgSnackbar(`Výkaz již nelze smazat.`);
				setSeveritySnackbar('error');
				setOpenSnackbar(true);
				refetchReportsCar();
				refetchReportsClosedCar();
			}
			if (response.error === 'RECORD_CANNOT_BE_DELETED') {
				setMsgSnackbar(`"${nameDelete}" nelze smazat!`);
				setSeveritySnackbar('error');
				setOpenSnackbar(true);
			}
		} else {
			// removeIsInit();
			setMsgSnackbar(`Smazáno: "${nameDelete}"`);
			setSeveritySnackbar('success');
			setOpenSnackbar(true);
			refetchReportsCar();
			refetchReportsClosedCar();
		}
		setIdDelete(null);
		setNameDelete('');
		handleCloseDeleteDialog();
	};

	// ____________ SUBMIT FORM MODAL ______________________
	const handleFormSubmit = async (data: ReportContractCarFormInputs) => {
		// console.log(data);

		if (editID) {
			const response = await updateReport(
				{
					contractId: data.contractId,
					carId: data.carId,
					userId: data.userId,
					date: data.date,
					timeFrom: data.timeFrom,
					timeTo: data.timeTo,
					workedHours: data.workedHours,
					driveKm: data.driveKm,
					description: data.description,
					costs: data.costs,
					descriptionCosts: data.descriptionCosts,
					costsDriver: data.costsDriver,
					descriptionCostsDriver: data.descriptionCostsDriver,
				},
				'PATCH',
				`/contracts/report-car-editor/${editID}`
			);
			if (!response) {
				return 'ERROR_NETWORK';
			}
			if (response?.status === 'error') {
				return response.error;
			}
		} else {
			const response = await updateReport(
				{
					contractId: data.contractId,
					carId: data.carId,
					userId: data.userId,
					date: data.date,
					timeFrom: data.timeFrom,
					timeTo: data.timeTo,
					workedHours: data.workedHours,
					driveKm: data.driveKm,
					description: data.description,
					costs: data.costs,
					descriptionCosts: data.descriptionCosts,
					costsDriver: data.costsDriver,
					descriptionCostsDriver: data.descriptionCostsDriver,
				},
				'POST',
				`/contracts/report-car-editor`
			);

			if (response?.status === 'error') {
				return response.error;
			}

			if (!response) {
				return 'ERROR_NETWORK';
			}
		}

		setOpenModal(false);

		refetchReportsCar();
		refetchReportsClosedCar();

		setMsgSnackbar(editData ? `Výkaz byl editován.` : `Výkaz byl přidán.`);
		setSeveritySnackbar('success');
		setOpenSnackbar(true);

		setEditData(null);
		setEditID(null);

		return undefined;
	};

	// ____________ HANDLE CLOSED CONTRACTS CAR ______________________
	const handleClose = async (id: number, name: string, isClosed: boolean) => {
		const response = await updateReport(
			{ isClosed: isClosed },
			'POST',
			`/contracts/report-car-editor/set-closed/${id}`
		);
		if (!response) {
			return;
		}

		refetchReportsCar();
		refetchReportsClosedCar();

		if (isClosed === true) {
			setMsgSnackbar(`UZAVŘENO: ${name}`);
			setSeveritySnackbar('success');
		} else {
			setMsgSnackbar(`OTEVŘENO: ${name}`);
			setSeveritySnackbar('warning');
		}
		setOpenSnackbar(true);
	};

	// ____________ DOCUMENT REPORT CONFIRM DIALOG ______________________
	const handleDocumentReportConfirm = () => {
		setDocumentReportDialog(true);
	};

	// ____________ DOCUMENT REPORT CLOSE DIALOG ______________________
	const handleCloseDocumentReportDialog = () => {
		setDocumentReportDialog(false);
	};

	// ____________ HANDLE DOCUMENT REPORT CREATE ______________________
	const handleDocumentReport = async () => {
		const response = await updateReport(
			{
				contractId: contractId,
			},
			'POST',
			`/contracts/report-document-car`
		);

		if (response?.status === 'error') {
			if (response.error === 'Výkaz nelze vytvořit.') {
				setMsgSnackbar(`Výkaz nelze vytvořit.`);
				setSeveritySnackbar('error');
				setDocumentReportDialog(false);
				setOpenSnackbar(true);
				await refetchReportsCar();
				await refetchReportsClosedCar();
			}
			return response.error;
		}

		if (!response) {
			// return 'ERROR_NETWORK';
			setMsgSnackbar(`Došlo k neznámé chybě. Zkontrolujte si připojení k internetu.`);
			setSeveritySnackbar('warning');
		}

		await refetchReportsCar();
		await refetchReportsClosedCar();

		if (response?.status === 'success') {
			setMsgSnackbar(`Vyúčtování bylo provedeno.`);
			setSeveritySnackbar('success');
		}

		setDocumentReportDialog(false);

		setOpenSnackbar(true);
	};

	// ____________ DELETE CONFIRM ______________________
	const handleDeleteConfirm = (id: number, name: string) => {
		setIdDelete(id);
		setNameDelete(name);
		setOpenDeleteDialog(true);
	};

	// ____________ DELETE CLOSE DIALOG ______________________
	const handleCloseDeleteDialog = () => {
		setIdDelete(null);
		setOpenDeleteDialog(false);
	};

	// ____________ CLOSE MODAL ______________________
	const handleCloseModal = (): void => {
		setOpenModal(false);
		setEditData(null);
		setEditID(null);
		refetchReportsCar();
		refetchReportsClosedCar();
	};

	// ____________ SNACKBAR ______________________
	const handleCloseSnackbar = () => {
		setOpenSnackbar(false);
	};

	//  ____________________ SELECT OBJECT CONTRACT CAR - overi carId jestli je opravdovy zaznam v DB ______________________________________
	const searchCar = contractCars && contractCars.find(item => item.carId === parseInt(carId));
	const contractCar = carId && contractCars && searchCar ? searchCar : null;

	return (
		<>
			<Loading isLoading={isLoading || isLoadingReport || isUpdateLoading || isLoadingClosedReport} />

			<SnackbarAfterAction
				severity={severitySnackbar}
				openSnackbar={openSnackbar}
				onClose={handleCloseSnackbar}
				msgSnackbar={msgSnackbar}
			/>

			<DocumentReportDialog
				onOpen={documentReportDialog}
				handleClose={handleCloseDocumentReportDialog}
				onDocumentReport={handleDocumentReport}
				typeDialog="car"
			/>

			<DeleteDialog
				onOpen={openDeleteDialog}
				handleClose={handleCloseDeleteDialog}
				onDelete={handleDelete}
				nameDelete={nameDelete}
			/>

			<FormModal
				modalTitle={`${editID ? 'Editace' : 'Vytvoření'} výkazu`}
				openModal={openModal}
				onClose={handleCloseModal}>
				<FormReportCar
					editID={editID}
					editData={editData}
					onFormSubmit={handleFormSubmit}
					contractCar={editCar ? editCar : contractCar}
					onClose={handleCloseModal}
				/>
			</FormModal>

			<SelectCar
				onChangeCar={handleCarChange}
				contractCars={contractCars}
				carId={carId}
				onAdd={handleAdd}
				contractCar={contractCar}
				status={status}
			/>

			<FilterReport
				handleFilterDate={handleFilterDate}
				handleFilterStatus={handleFilterStatus}
				dateFrom={dateFrom}
				dateTo={dateTo}
				filterStatus={filterStatus}
			/>

			<ShowReportCar
				status={status}
				filterStatus={filterStatus}
				onClose={handleClose}
				reportsCar={reportsCar}
				reportsClosedCar={reportsClosedCar}
				onEdit={handleEdit}
				onDeleteConfirm={handleDeleteConfirm}
				onReportConfirm={handleDocumentReportConfirm}
			/>
		</>
	);
};

export default ReportCars;
