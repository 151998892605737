import { IconButton, TableRow, Typography, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import locale from 'dayjs/locale/cs';

import TableCellViewTitle from '../../../../../components/pageContent/TableView/TableCellViewTitle';
import { useAppContext } from '../../../../../context/appContext/appContext';
import { ReportToCarDB } from '../../types';

import AddTaskOutlinedIcon from '@mui/icons-material/AddTaskOutlined';
import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined';

type Props = {
	report: ReportToCarDB;
	onClose: (id: number, name: string, isClosed: boolean) => void;
};

const TitleTableRow = ({ report, onClose }: Props) => {
	const { state } = useAppContext();
	const theme = useTheme();

	const reportNumber = report.reportNumber * 1;

	return (
		<TableRow>
			<TableCellViewTitle align="left" w={'auto'} colspan={2}>
				<>
					<Typography sx={{ fontWeight: 'bold' }} component="span" variant="body2">
						Datum:
					</Typography>

					<Typography sx={{ fontWeight: 'bold', marginLeft: 2 }} component="span" variant="subtitle1">
						{dayjs(report.date)
							.locale({ ...locale })
							.format('DD.MM.YYYY, dddd')}
					</Typography>
				</>
			</TableCellViewTitle>
			{report.status === 'active' && (
				<TableCellViewTitle align="right" w={10} bgr="#e4e6d5">
					<IconButton
						sx={{ margin: 0, padding: 0 }}
						onClick={() =>
							onClose(
								report.id,
								`${report.car.name} - ${dayjs(report.date)
									.locale({ ...locale })
									.format('DD.MM.YYYY, dddd')}`,
								true
							)
						}>
						<AddTaskOutlinedIcon
							fontSize={'medium'}
							sx={{ margin: 0, color: theme.palette.success.main }}
						/>
					</IconButton>
				</TableCellViewTitle>
			)}
			{report.status === 'closed' && reportNumber === 0 && (
				<TableCellViewTitle align="right" w={10} bgr="#fae8db">
					<IconButton
						sx={{ margin: 0, padding: 0 }}
						onClick={() =>
							onClose(
								report.id,
								`${report.car.name} - ${dayjs(report.date)
									.locale({ ...locale })
									.format('DD.MM.YYYY, dddd')}`,
								false
							)
						}>
						<BackspaceOutlinedIcon
							fontSize={'medium'}
							sx={{ margin: 0, color: theme.palette.warning.main }}
						/>
					</IconButton>
				</TableCellViewTitle>
			)}

			{report.status === 'closed' && reportNumber > 0 && (
				<TableCellViewTitle align="right" w={10} bgr="#398943">
					<Typography color={'white'} component="span" variant="button">
						{report.reportNumber}
					</Typography>
				</TableCellViewTitle>
			)}
		</TableRow>

		// 	<TableRow>

		//
		// 	</TableRow>
	);
};

export default TitleTableRow;
