import { Box, Alert } from '@mui/material';
import React from 'react';

type Props = {
	children: JSX.Element;
};

const PageAlert = ({ children }: Props) => {
	return (
		<Box mb={2} component="header" sx={{ flexShrink: 1, width: '100%', marginX: 10 }}>
			<Box
				sx={{
					padding: '1.5em 2em',
					borderRadius: 2,
					backgroundColor: 'white',
					alignItems: 'center',
				}}>
				<Alert severity="error">{children}</Alert>
			</Box>
		</Box>
	);
};

export default PageAlert;
