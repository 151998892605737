import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import dayjs from 'dayjs';

type Props = {
	title: string;
	client?: string;
	status?: string;
	dateFrom?: Date | undefined;
	dateTo?: Date | undefined;
	description?: string | undefined;
};

const PageSubtitle = ({ title, client, status, dateFrom, dateTo, description }: Props): JSX.Element => {
	let statusColor = '';
	let statusText = '';

	if (status === 'active') {
		statusColor = 'green';
		statusText = 'aktivní';
	}
	return (
		<Box
			mb={2}
			component="header"
			sx={{
				padding: '0.8em',
				backgroundColor: 'white',
				border: 0,
				borderRadius: 2,
			}}>
			<Box
				sx={{
					padding: 0,
					alignItems: 'center',
					display: 'flex',
					justifyContent: 'space-between',
				}}>
				<Typography color={'black'} sx={{ typography: { md: 'h5', sm: 'h6', xs: 'subtitle1' } }} component="h3">
					{title}

					{client && (
						<Typography
							color={'black'}
							sx={{ marginLeft: 2, typography: { md: 'overline', sm: 'overline', xs: 'overline' } }}
							component="span">
							{`${client}`}
						</Typography>
					)}
				</Typography>
				{status && (
					<Typography
						color={statusColor}
						sx={{ marginLeft: 2, typography: { md: 'overline', sm: 'overline', xs: 'overline' } }}
						component="span">
						{`${statusText}`}
					</Typography>
				)}
			</Box>

			{dateFrom && dateTo && (
				<Box
					sx={{
						paddingTop: 1,
						paddingX: 1,
						border: 0,
					}}>
					<Typography
						color={'black'}
						sx={{ typography: { md: 'body2', sm: 'body2', xs: 'body2' } }}
						component="div">
						{`Datum zahájení: ${dayjs(dateFrom).format('DD.MM.YYYY')}`}
					</Typography>

					<Typography
						color={'black'}
						sx={{ typography: { md: 'body2', sm: 'body2', xs: 'body2' } }}
						component="div">
						{`Datum ukončení: ${dayjs(dateFrom).format('DD.MM.YYYY')}`}
					</Typography>

					{description && (
						<Typography
							color={'black'}
							sx={{ marginTop: 1, typography: { md: 'body2', sm: 'body2', xs: 'body2' } }}
							component="div">
							{`Popis: ${description}`}
						</Typography>
					)}
				</Box>
			)}
		</Box>


	);
};

export default PageSubtitle;
