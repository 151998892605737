import { Box, Typography } from '@mui/material';
import React from 'react';
import TableView from '../../../../../components/pageContent/TableView/TableView';
import ButtonDocumentReport from '../../../../../components/uiComponents/ButtonDocumentReport';
import ButtonBilled from '../../../../../components/uiComponents/ButtonDocumentReport';
import InfoRecords from '../../../../../components/uiComponents/InfoRecords';
import { ReportToCarDB } from '../../types';
import DataTableRows from './DataTableRows';
import TitleTableRow from './TitleTableRow';

type Props = {
	status: string;
	reportsCar: ReportToCarDB[] | null;
	reportsClosedCar: ReportToCarDB[] | null;
	filterStatus: string;
	onEdit: (id: number) => void;
	onDeleteConfirm: (id: number, name: string) => void;
	onReportConfirm: () => void;
	onClose: (id: number, name: string, isClosed: boolean) => void;
};

const ShowReportCar = ({
	status,
	reportsCar,
	reportsClosedCar,
	onEdit,
	onDeleteConfirm,
	onClose,
	onReportConfirm,
	filterStatus,
}: Props) => {
	const records = reportsCar ? reportsCar.length : 0;
	const recordsClosed = reportsClosedCar ? reportsClosedCar.length : 0;

	return (
		<>
			<Box sx={{ marginY: 2 }}>
				<InfoRecords records={records} />

				{filterStatus === 'closed' && recordsClosed > 0 && (
					<ButtonDocumentReport
						title={`Vytvořit vyúčtování všech uzavřených výkazů (${recordsClosed})`}
						onReportConfirm={onReportConfirm}
					/>
				)}

				{reportsCar &&
					reportsCar.map(report => {
						return (
							<React.Fragment key={report.id}>
								<TableView
									titleRow={<TitleTableRow report={report} onClose={onClose} />}
									dataRow={
										<DataTableRows
											report={report}
											onEdit={onEdit}
											onDeleteConfirm={onDeleteConfirm}
											status={status}
										/>
									}
									colspan={8}
								/>
							</React.Fragment>
						);
					})}
			</Box>
		</>
	);
};

export default ShowReportCar;
