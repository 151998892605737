import { joiResolver } from '@hookform/resolvers/joi';
import {
	Alert,
	Box,
	Button,
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
	useTheme,
} from '@mui/material';

import { MobileDatePicker, MobileTimePicker } from '@mui/x-date-pickers';

import csCZ from 'dayjs/locale/cs';
import dayjs from 'dayjs';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useAppContext } from '../../../../context/appContext/appContext';
import { dict } from '../../../../translate/translate-dictionary';
import { reportContractCarSchema, reportMyContractCarSchema } from '../../../../utils/joiSchema';
import { useData } from '../../../../utils/useData';

import { ContractCarDB, ContractUserDB, ReportCarDetail, ReportContractCarFormInputs } from '../../types';
import Loading from '../../../../components/pageContent/Loading';
import { calculateWorkedTime } from '../../../../utils/calculateWorkedTime';
import { useParams } from 'react-router-dom';
import { MINUTES_STEP_TIME_PICKER } from '../../../../constants';

type Props = {
	editID: number | null;
	editData: ReportCarDetail | null;
	onClose: () => void;
	onFormSubmit: (data: ReportContractCarFormInputs) => Promise<string | undefined>;
};

const FormReportCar = ({ editID, editData, onClose, onFormSubmit }: Props) => {
	const [isSendButton, setIsSendButton] = useState<boolean>(false);
	const [isCosts, setIsCosts] = useState<Boolean>(false);
	const [isCostsDriver, setIsCostsDriver] = useState<Boolean>(false);
	// const [contractCars, setContractCars] = useState<ContractCarDB[] | null>(null);
	// const [contractUserData, setContractUserData] = useState<ContractUserDB[] | null>(null); // zobrazeni pro vypis table
	// const [contractUser, setContractUser] = useState<string>(editData ? editData.userId.toString() : ''); // vybrany user
	const [masterError, setMasterError] = useState<string>('');
	const [workedHours, setWorkedHours] = React.useState<number>(editData ? editData.workedHours * 1 : 0);

	const { contractId } = useParams();

	const { state } = useAppContext();
	const lang = state.lang;
	const theme = useTheme();

	// ____________ USE DATA - NACTENI CONTRACT CARS ______________________
	const {
		isLoading,
		data: contractCars,
		error: messageError,
	} = useData<ContractCarDB[], { contractCars: ContractCarDB[] }>(
		`/contracts/contract-to-car/${contractId}/`,
		data => {
			const newData = data.contractCars.filter(
				car => (editID ? car.car.id === editData?.carId : car.car.id !== 0)
				// editID ? car.car.id === editData?.carId : car.car.isActive === true
			);
			return newData;
		}
	);

	const {
		handleSubmit,
		getValues,
		control,
		formState: { errors },
	} = useForm<ReportContractCarFormInputs>({
		defaultValues: {
			// userId: state.user?.id,
			carId: editData ? editData.carId : 0,
			date: editData ? editData.date : dayjs().toString(),
			timeFrom: editData ? editData.timeFrom : '', //dayjs(dayjs().set('hour', 7).set('minute', 0)).toString(),
			timeTo: editData ? editData.timeTo : '', //dayjs(dayjs().set('hour', 7).set('minute', 0)).toString(),
			driveKm: editData ? editData.driveKm * 1 : '',
			description: editData ? editData.description : '',
			costs: editData ? (editData.costs > 0 ? editData.costs * 1 : '') : '',
			descriptionCosts: editData ? (editData.descriptionCosts ? editData.descriptionCosts : '') : '',
			costsDriver: editData ? (editData.costsDriver > 0 ? editData.costsDriver * 1 : '') : '',
			descriptionCostsDriver: editData
				? editData.descriptionCostsDriver
					? editData.descriptionCostsDriver
					: ''
				: '',
		},
		resolver: joiResolver(reportMyContractCarSchema),
		mode: 'onChange',
		delayError: 2000,
	});

	const onSubmit = async (data: ReportContractCarFormInputs) => {
		setIsSendButton(true);
		const newDate = dayjs(data.date).format('YYYY-MM-DD');
		const newTimeFrom = data.timeFrom ? dayjs(data.timeFrom).format('HH:mm') : '';
		const newTimeTo = data.timeTo ? dayjs(data.timeTo).format('HH:mm') : '';

		const { workedHours, timeFromObj, timeToObj } = calculateWorkedTime(newDate, newTimeFrom, newTimeTo);

		if (!data.costs) {
			data.costs = 0;
			data.descriptionCosts = null;
		}

		if (!data.costsDriver) {
			data.costsDriver = 0;
			data.descriptionCostsDriver = null;
		}

		const saveData = {
			contractId: contractId ? parseInt(contractId) : 0,
			carId: data.carId,
			userId: state.user!.id,
			date: dayjs(data.date).format('YYYY-MM-DD'),
			timeFrom: timeFromObj.format('YYYY-MM-DD HH:mm:ss'),
			timeTo: timeToObj.format('YYYY-MM-DD HH:mm:ss'),
			workedHours: workedHours,
			driveKm: data.driveKm,
			description: data.description,
			costs: data.costs,
			descriptionCosts: data.descriptionCosts,
			costsDriver: data.costsDriver,
			descriptionCostsDriver: data.descriptionCostsDriver,
		};

		// console.log(saveData);

		const response = await onFormSubmit(saveData);
		if (response) {
			setIsSendButton(false);
			setMasterError(response);
		}
	};

	const showWorkedHour = () => {
		const date = dayjs(getValues('date')).format('YYYY-MM-DD');
		const formFrom = getValues('timeFrom') ? dayjs(getValues('timeFrom')).format('HH:mm') : '';
		const formTo = getValues('timeFrom') ? dayjs(getValues('timeTo')).format('HH:mm') : '';

		const { workedHours } = calculateWorkedTime(date, formFrom, formTo);

		setWorkedHours(workedHours ? workedHours : 0);
	};

	return (
		<>
			<Loading isLoading={isLoading} />

			<Typography component={'h6'} variant="h6" sx={{ marginBottom: 2 }}>
				{state?.user?.lastName} {state?.user?.firstName}
			</Typography>
			<Box
				component="form"
				noValidate
				onSubmit={handleSubmit(onSubmit)}
				sx={{
					mt: 1,
				}}>
				{/* ________ Date  _______ */}
				<Box
					sx={{
						display: 'flex',
						justifyContent: { xs: 'flex-start', sm: 'space-between' },
						flexDirection: { xs: 'column', sm: 'row' },
						alignItems: 'center',
					}}>
					<LocalizationProvider adapterLocale={csCZ} dateAdapter={AdapterDayjs}>
						<Controller
							name="date"
							control={control}
							render={({ field: { onChange, value, ...field }, fieldState: { error } }) => (
								<MobileDatePicker
									// disabled={editID ? true : false}
									readOnly={editID ? true : false}
									label="Datum"
									inputFormat="DD.MM.YYYY"
									value={value}
									onChange={event => {
										onChange(event);
									}}
									{...field}
									renderInput={params => (
										<TextField
											variant="filled"
											sx={{
												marginRight: 0,
												marginBottom: { xs: 1, sm: 3 },
												width: { xs: '100%', sm: '48%' },
											}}
											{...params}
											error={!!errors.date}
											helperText={errors.date?.message}
										/>
									)}
								/>
							)}
						/>
					</LocalizationProvider>
					<Box sx={{ marginTop: 0, marginBottom: 1 }}>
						<Typography variant="h6" sx={{ margin: 0, color: workedHours < 0 ? 'red' : '' }}>
							Hodiny: {workedHours.toFixed(0)}
						</Typography>
					</Box>
				</Box>

				{/* ________ timeFrom timeTo _______ */}
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						flexDirection: 'row',
						alignItems: 'center',
					}}>
					<LocalizationProvider adapterLocale={csCZ} dateAdapter={AdapterDayjs}>
						<Controller
							name="timeFrom"
							control={control}
							render={({ field: { onChange, value, ...field }, fieldState: { error } }) => (
								<MobileTimePicker
									ampm={false}
									minutesStep={MINUTES_STEP_TIME_PICKER}
									label="Čas od"
									value={value}
									onChange={event => {
										onChange(event);
										showWorkedHour();
									}}
									{...field}
									renderInput={params => (
										<TextField
											variant="filled"
											sx={{ marginRight: 0, marginBottom: 2, width: '48%' }}
											{...params}
											error={!!errors.timeFrom}
											helperText={errors.timeFrom?.message}
										/>
									)}
								/>
							)}
						/>

						<Controller
							name="timeTo"
							control={control}
							render={({ field: { onChange, value, ...field }, fieldState: { error } }) => (
								<MobileTimePicker
									ampm={false}
									minutesStep={MINUTES_STEP_TIME_PICKER}
									label="Čas do"
									value={value}
									onChange={event => {
										onChange(event);
										showWorkedHour();
									}}
									{...field}
									renderInput={params => (
										<TextField
											variant="filled"
											sx={{ marginRight: 0, marginBottom: 2, width: '48%' }}
											{...params}
											error={!!errors.timeFrom}
											helperText={errors.timeFrom?.message}
										/>
									)}
								/>
							)}
						/>
					</LocalizationProvider>
				</Box>

				{/* ________ CAR _______ */}
				<Box
					sx={{
						display: 'flex',
						justifyContent: { sm: 'space-between' },
						flexDirection: { xs: 'column', sm: 'column' },
						alignItems: 'center',
					}}>
					<FormControl variant="filled" sx={{ marginTop: 1, width: '100%' }} error={!!errors?.carId}>
						<InputLabel id="user">Auto</InputLabel>
						<Controller
							name="carId"
							control={control}
							render={({ field: { value, ...field } }) => (
								<Select
									{...field}
									readOnly={editID ? true : false}
									labelId="car"
									label="Auto"
									value={value}
									onChange={event => field.onChange(event)}>
									{/* <MenuItem value={0}>--- vyberte prosím auto ---</MenuItem> */}

									<MenuItem value={0}>žádné auto</MenuItem>
									{!contractCars && editData?.carId && <MenuItem value={editData?.carId}></MenuItem>}

									{contractCars &&
										contractCars.map((contCar: ContractCarDB) => {
											return (
												<MenuItem
													key={contCar.car.id}
													value={contCar.car.id}
													sx={{
														color: !contCar.car.isActive ? theme.palette.error.dark : '',
														alignItems: 'baseline',
													}}>
													{contCar.car.name}, {contCar.car.spz}
													{!contCar.car.isActive && (
														<Typography
															sx={{ marginLeft: 1 }}
															component={'span'}
															variant={'caption'}>
															pozastaveno
														</Typography>
													)}
												</MenuItem>
											);
										})}
								</Select>
							)}
						/>
						<FormHelperText>
							{errors?.carId?.message
								? dict[errors.carId.message]
									? dict[errors.carId.message][lang]
									: errors?.carId?.message
								: ' '}
						</FormHelperText>
					</FormControl>
				</Box>

				{/* ________ driveKm descriptions _______ */}
				<Box
					sx={{
						display: 'flex',
						justifyContent: { sm: 'space-between' },
						flexDirection: { xs: 'column', sm: 'row' },
						alignItems: 'flex-start',
						marginTop: 1,
					}}>
					<Controller
						name="driveKm"
						control={control}
						render={({ field: { ref, ...field } }) => (
							<TextField
								{...field}
								inputRef={ref}
								required
								type="text"
								label="Najeté km"
								variant="filled"
								size="medium"
								margin="normal"
								inputProps={{ maxLength: 20 }}
								sx={{ width: { xs: '100%', sm: '28%' }, margin: 0, marginBottom: 1 }}
								error={!!errors?.driveKm}
								helperText={
									errors?.driveKm?.message
										? dict[errors.driveKm.message]
											? dict[errors.driveKm.message][lang]
											: errors?.driveKm?.message
										: ' '
								}
							/>
						)}
					/>

					<Controller
						name="description"
						control={control}
						render={({ field: { ref, ...field } }) => (
							<TextField
								{...field}
								inputRef={ref}
								// required
								multiline
								rows={3}
								type="text"
								label="Popis trasy"
								variant="filled"
								size="medium"
								margin="normal"
								inputProps={{ maxLength: 250 }}
								sx={{ width: { xs: '100%', sm: '68%' }, margin: 0 }}
								error={!!errors?.description}
								helperText={
									errors?.description?.message
										? dict[errors.description.message]
											? dict[errors.description.message][lang]
											: errors?.description?.message
										: ' '
								}
							/>
						)}
					/>
				</Box>

				{/* ________ BUTTON COSTS CAR _______ */}
				{!isCosts && (!editData?.costs || editData?.costs <= 0) && (
					<Stack direction="row" justifyContent="left" alignItems="center" spacing={2} mt={1}>
						<Button
							variant="contained"
							type="button"
							size="medium"
							color="warning"
							onClick={() => setIsCosts(true)}>
							Auto - vícenáklady
						</Button>
					</Stack>
				)}

				{/* ________ costs descriptionsCosts _______ */}
				{(isCosts || (editData?.costs && editData?.costs > 0)) && (
					<Box
						sx={{
							display: 'flex',
							justifyContent: { sm: 'space-between' },
							flexDirection: { xs: 'column', sm: 'row' },
							alignItems: 'flex-start',
							marginTop: 1,
						}}>
						<Controller
							name="costs"
							control={control}
							render={({ field: { ref, ...field } }) => (
								<TextField
									{...field}
									inputRef={ref}
									// required
									type="text"
									label="Auto - vícenáklady"
									variant="filled"
									size="medium"
									margin="normal"
									inputProps={{ maxLength: 20 }}
									sx={{ width: { xs: '100%', sm: '28%' }, margin: 0, marginBottom: 1 }}
									error={!!errors?.costs}
									helperText={
										errors?.costs?.message
											? dict[errors.costs.message]
												? dict[errors.costs.message][lang]
												: errors?.costs?.message
											: ' '
									}
								/>
							)}
						/>

						<Controller
							name="descriptionCosts"
							control={control}
							render={({ field: { ref, ...field } }) => (
								<TextField
									{...field}
									inputRef={ref}
									// required
									multiline
									rows={1}
									type="text"
									label="Popis vícenákladů pro auto"
									variant="filled"
									size="medium"
									margin="normal"
									inputProps={{ maxLength: 250 }}
									sx={{ width: { xs: '100%', sm: '68%' }, margin: 0 }}
									error={!!errors?.descriptionCosts}
									helperText={
										errors?.descriptionCosts?.message
											? dict[errors.descriptionCosts.message]
												? dict[errors.descriptionCosts.message][lang]
												: errors?.descriptionCosts?.message
											: ' '
									}
								/>
							)}
						/>
					</Box>
				)}

				{/* ________ BUTTON COSTS DRIVER _______ */}
				{!isCostsDriver && (!editData?.costsDriver || editData?.costsDriver <= 0) && (
					<Stack direction="row" justifyContent="left" alignItems="center" spacing={2} mt={1}>
						<Button
							variant="contained"
							type="button"
							size="medium"
							color="secondary"
							onClick={() => setIsCostsDriver(true)}>
							Řidič - vícenáklady
						</Button>
					</Stack>
				)}

				{/* ________ costs descriptionsCosts _______ */}
				{(isCostsDriver || (editData?.costsDriver && editData?.costsDriver > 0)) && (
					<Box
						sx={{
							display: 'flex',
							justifyContent: { sm: 'space-between' },
							flexDirection: { xs: 'column', sm: 'row' },
							alignItems: 'flex-start',
							marginTop: 1,
						}}>
						<Controller
							name="costsDriver"
							control={control}
							render={({ field: { ref, ...field } }) => (
								<TextField
									{...field}
									inputRef={ref}
									// required
									type="text"
									label="Řidič - vícenáklady"
									variant="filled"
									size="medium"
									margin="normal"
									inputProps={{ maxLength: 20 }}
									sx={{ width: { xs: '100%', sm: '28%' }, margin: 0, marginBottom: 1 }}
									error={!!errors?.costsDriver}
									helperText={
										errors?.costsDriver?.message
											? dict[errors.costsDriver.message]
												? dict[errors.costsDriver.message][lang]
												: errors?.costsDriver?.message
											: ' '
									}
								/>
							)}
						/>

						<Controller
							name="descriptionCostsDriver"
							control={control}
							render={({ field: { ref, ...field } }) => (
								<TextField
									{...field}
									inputRef={ref}
									// required
									multiline
									rows={1}
									type="text"
									label="Popis vícenákladů pro řidiče"
									variant="filled"
									size="medium"
									margin="normal"
									inputProps={{ maxLength: 250 }}
									sx={{ width: { xs: '100%', sm: '68%' }, margin: 0 }}
									error={!!errors?.descriptionCostsDriver}
									helperText={
										errors?.descriptionCostsDriver?.message
											? dict[errors.descriptionCostsDriver.message]
												? dict[errors.descriptionCostsDriver.message][lang]
												: errors?.descriptionCostsDriver?.message
											: ' '
									}
								/>
							)}
						/>
					</Box>
				)}

				<Stack sx={{ width: '100%', marginTop: 2 }} spacing={3}>
					{masterError && (
						<Alert sx={{ borderRadius: '0.5em' }} severity="error">
							{dict[masterError] ? dict[masterError][lang] : masterError ? masterError : ' '}
						</Alert>
					)}
				</Stack>

				<Stack direction="row" justifyContent="center" alignItems="center" spacing={2} mt={3}>
					<Button
						variant="contained"
						size="large"
						color="inherit"
						onClick={onClose}
						disabled={isSendButton ? true : false}>
						zrušit
					</Button>
					<Button variant="contained" type="submit" size="large" disabled={isSendButton ? true : false}>
						{`${editID ? 'Editovat' : 'Vytvořit'}`}
						<Box component={'span'} sx={{ display: { xs: 'none', sm: 'inline' } }}>
							&nbsp;výkaz
						</Box>
					</Button>
				</Stack>
			</Box>
		</>
	);
};

export default FormReportCar;
