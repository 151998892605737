import React, { useState } from 'react';
import { useAppContext } from '../../../../context/appContext/appContext';
import { CarCategoryDB, CarCategoryFormInputs } from '../../../cars/types';

import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { carCategorySchema } from '../../../../utils/joiSchema';
import { dict, dictKey } from '../../../../translate/translate-dictionary';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';

type Props = {
	editID: number | null;
	editData: CarCategoryDB | null;
	onClose: () => void;
	onFormSubmit: (data: CarCategoryFormInputs) => Promise<string | undefined>;
};

const CarCategoryForm = ({ editID, editData, onClose, onFormSubmit }: Props) => {
	const [masterError, setMasterError] = useState<string>('');
	const { state } = useAppContext();
	const lang = state.lang;

	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm<CarCategoryFormInputs>({
		defaultValues: {
			name: editData ? editData.name : '',
		},
		resolver: joiResolver(carCategorySchema),
		mode: 'onChange',
		delayError: 2000,
	});

	const onSubmit = async (data: CarCategoryFormInputs) => {
		const response = await onFormSubmit(data);

		if (response) setMasterError(response);
	};
	return (
		<Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
			<TextField
				fullWidth
				{...register('name')}
				required
				margin="normal"
				label="Kategorie auta"
				autoFocus
				error={!!errors?.name}
				helperText={
					errors?.name?.message
						? dict[errors.name.message]
							? dict[errors.name.message][lang]
							: errors?.name?.message
						: ' '
				}
				variant="filled"
				size="medium"
			/>

			<Stack sx={{ width: '100%', marginTop: 2 }} spacing={3}>
				{masterError && (
					<Alert sx={{ borderRadius: '0.5em' }} severity="error">
						{dict[masterError] ? dict[masterError][lang] : masterError ? masterError : ' '}
					</Alert>
				)}
			</Stack>

			<Stack direction="row" justifyContent="center" alignItems="center" spacing={2} mt={3}>
				<Button variant="contained" size="large" color="inherit" onClick={onClose}>
					zrušit
				</Button>

				<Button variant="contained" type="submit" size="large">
					{`${editID ? 'Editovat' : 'Přidat'}`}
					<Box component={'span'} sx={{ display: { xs: 'none', sm: 'inline' } }}>
						&nbsp;kategorii
					</Box>
				</Button>
			</Stack>
		</Box>
	);
};

export default CarCategoryForm;
