import React, { useEffect, useState } from 'react';
import { useData } from '../../../utils/useData';

import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../../components/pageContent/Loading';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import PageTitle from '../../../components/pageContent/PageTitle';
import ContractPermissionAlert from '../../../components/pageContent/ContractPermissionAlert';
import PageSubtitle from '../../../components/pageContent/PageSubtitle';
import { ContractDB, ContractUserDB, UserModal } from '../types';

import TableView from '../../../components/pageContent/TableView/TableView';
import TitleTableRow from './dataTable/TitleTableRow';
import DataTableRows from './dataTable/DataTableRows';
import { useAppContext } from '../../../context/appContext/appContext';
import { TablePagination } from '@mui/material';
import { dict } from '../../../translate/translate-dictionary';
import UserCardModal from '../../../components/modal/UserCardModal';

type Props = {};

function MyContractDetail({}: Props) {
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [userData, setUserData] = useState<UserModal | null>(null);

	const [page, setPage] = useState(0);
	const { state, setRowsPerPage } = useAppContext();
	const { contractId } = useParams();
	const navigate = useNavigate();

	const lang = state.lang;

	// ____________ osetreni, ze existuje contractId, pokud ne, vrati na vyber ______________________

	useEffect(() => {
		contractId && isNaN(parseInt(contractId)) && navigate(`/my-contracts`);
	}, [contractId, navigate]);

	// ____________ USE DATA - načtení zakazky ______________________
	const {
		isLoading,
		data: contract,
		refetch,
		error: contractError,
	} = useData<ContractDB, { contract: ContractDB }>(`/my-contracts/${contractId}`, data => {
		return data.contract;
	});

	// ____________ USE DATA - načtení users k zakazce ______________________
	const {
		isLoading: isLoadingUsers,
		data: contractUsers,
		refetch: refetchUsers,
		error: contractUsersError,
	} = useData<ContractUserDB[], { contractUsers: ContractUserDB[] }>(`/my-contracts/users/${contractId}`, data => {
		return data.contractUsers;
	});

	// ____________ SET PAGE ______________________
	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	// ____________ SET ROWS PER PAGE ______________________
	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	// ____________ BUTTON ZPET NA PREHLED ______________________
	const handleHeaderButton = () => {
		navigate(`/my-contracts`);
	};

	const handleOpenModal = (id: number): void => {
		const contractUser = contractUsers?.find(item => item.id === id);

		if (contractUser) {
			setOpenModal(true);
			setUserData({
				firstName: contractUser.user.firstName,
				lastName: contractUser.user.lastName,
				email: contractUser.user.email,
				phone: contractUser.user.phone,
				imageUrl: contractUser.user.imageUrl,
			});
		}
	};

	const handleCloseModal = (): void => {
		setOpenModal(false);
	};

	if (isLoading) {
		return (
			<>
				<Loading isLoading={isLoading} />
			</>
		);
	}
	if (!contract) {
		return (
			<>
				<ContractPermissionAlert backUrl="/my-contracts" title="Nemáte oprávnění prohlížet tuto zakázku!" />
			</>
		);
	}

	return (
		<>
			<Loading isLoading={isLoading} />

			<PageTitle
				title={`Detail zakázky`}
				buttonText="zpět na přehled"
				onClickHeaderButton={handleHeaderButton}
				icon={<ArrowBackOutlinedIcon />}
			/>

			<UserCardModal onClose={handleCloseModal} openModal={openModal} user={userData} />

			{contract && (
				<PageSubtitle
					status={''}
					title={contract.name ? contract.name : ''}
					client={contract.client?.companyName}
					dateFrom={contract.dateFrom}
					dateTo={contract.dateTo}
					description={contract.description}
				/>
			)}

			{contract && (
				<TableView
					titleRow={<TitleTableRow />}
					dataRow={
						<DataTableRows
							contractUsers={contractUsers}
							rowsPerPage={state.rowsPerPage}
							page={page}
							onDetail={handleOpenModal}
						/>
					}
					tablePagination={
						<TablePagination
							count={contractUsers ? contractUsers.length : 0}
							component="div"
							rowsPerPage={state.rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							rowsPerPageOptions={[5, 10, 15]}
							labelRowsPerPage={dict['ROWS_PER_PAGE'][lang]}
						/>
					}
					colspan={5}
				/>
			)}
		</>
	);
}

export default MyContractDetail;
