import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import TableView from '../../../../../components/pageContent/TableView/TableView';
import InfoRecords from '../../../../../components/uiComponents/InfoRecords';
import { ReportToWorkerDB } from '../../../types';
import DataTableRows from './DataTableRows';
import TitleTableRow from './TitleTableRow';

type Props = {
	reportsWorker: ReportToWorkerDB[] | null;
	onEdit: (id: number) => void;
	onDeleteConfirm: (id: number, name: string) => void;
};

const ShowReportWorker = ({ reportsWorker, onEdit, onDeleteConfirm }: Props) => {
	const records = reportsWorker?.length;
	return (
		<>
			<Box sx={{ marginY: 3 }}>
				<InfoRecords records={records} />

				{reportsWorker &&
					reportsWorker.map(report => {
						return (
							<React.Fragment key={report.id}>
								<TableView
									titleRow={<TitleTableRow report={report} />}
									dataRow={
										<DataTableRows
											report={report}
											onEdit={onEdit}
											onDeleteConfirm={onDeleteConfirm}
										/>
									}
									colspan={8}
								/>
							</React.Fragment>
						);
					})}
			</Box>
		</>
	);
};

export default ShowReportWorker;
