import React, { useState } from 'react';
import { CarCategoryDB, CarCategoryFormInputs, CarDB, CarFormInputs } from '../../cars/types';

import { useData } from '../../../utils/useData';
import { useUpdate } from '../../../utils/useUpdate';
import { useAppContext } from '../../../context/appContext/appContext';

import PermissionAlert from '../../../components/pageContent/PermissionAlert';

import PageTitle from '../../../components/pageContent/PageTitle';

import PageTabNavigator from '../../../components/pageContent/PageTabNavigator';
import tabsCars from '../../cars/tabsCars';

import TableView from '../../../components/pageContent/TableView/TableView';
import TitleTableRow from './dataTable/TitleTableRow';
import DataTableRows from './dataTable/DataTableRows';
import FormModal from '../../../components/modal/FormModal';

import CarCategoryForm from './edit/CarCategoryForm';
import SnackbarAfterAction from '../../../components/uiComponents/SnackbarAfterAction';
import Loading from '../../../components/pageContent/Loading';

import DeleteDialog from '../../../components/pageContent/TableView/DeleteDialog';

import AddIcon from '@mui/icons-material/Add';
import { AlertColor } from '@mui/material';

type Props = {};

const CarsCategoryList = (props: Props) => {
	const [openModal, setOpenModal] = useState<boolean>(false);

	const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
	const [msgSnackbar, setMsgSnackbar] = useState<string>('');
	const [severitySnackbar, setSeveritySnackbar] = useState<AlertColor>('success');

	const [editID, setEditID] = useState<number | null>(null);
	const [editData, setEditData] = useState<CarCategoryDB | null>(null);

	const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
	const [idDelete, setIdDelete] = React.useState<number | null>(null);
	const [nameDelete, setNameDelete] = React.useState<string>('');

	// state pro usera a lang
	const { state } = useAppContext();

	// ____________ USE DATA - nacteni categoryCars ______________________
	const {
		isLoading,
		data: carsCategory,
		refetch,
	} = useData<CarCategoryDB[], { carsCategory: CarCategoryDB[] }>('/dials/cars/category', data => data.carsCategory);

	// ____________ USE UPDATE - ostatni metody ______________________
	const {
		isLoading: isUpdateLoading,
		data: dataEditing,
		update: updateCar,
		error: updateError,
	} = useUpdate<{ status: string; error?: string; carCategory?: CarCategoryDB }>('');

	// ____________ ADD ______________________
	const handleAdd = (): void => {
		setOpenModal(true);
		setEditID(null);
	};

	// ____________ EDIT ______________________
	const handleEdit = async (id: number): Promise<void> => {
		const response = await updateCar(null, 'GET', `/dials/cars/category/${id}`);

		if (!response) {
			return;
		}

		if (response?.status === 'success') {
			if (response.carCategory) setEditData(response.carCategory);
		}

		setOpenModal(true);
		setEditID(id);
	};

	// ____________ DELETE ______________________
	const handleDelete = async (): Promise<void> => {
		const response = await updateCar(null, 'DELETE', `/dials/cars/category/${idDelete}`);
		if (!response) {
			return;
		}

		if (response.status === 'error') {
			if (response.error === 'RECORD_CANNOT_BE_DELETED') {
				setMsgSnackbar(`Kategorie "${nameDelete}" nelze smazat!`);
				setSeveritySnackbar('error');
				setOpenSnackbar(true);
			}
		} else {
			await refetch();
			setMsgSnackbar(`Kategorie "${nameDelete}" byla vymazána.`);
			setSeveritySnackbar('success');
			setOpenSnackbar(true);
		}

		setIdDelete(null);
		setNameDelete('');
		handleCloseDeleteDialog();
	};

	const handleDeleteConfirm = (id: number, name: string) => {
		setIdDelete(id);
		setNameDelete(name);
		setOpenDeleteDialog(true);
	};

	const handleCloseDeleteDialog = () => {
		setIdDelete(null);
		setOpenDeleteDialog(false);
	};

	// ____________ SUBMIT FORM MODAL ______________________
	const handleFormSubmit = async (data: CarCategoryFormInputs) => {
		if (editID) {
			const response = await updateCar({ name: data.name }, 'PATCH', `/dials/cars/category/${editID}`);

			if (!response) {
				return 'ERROR_NETWORK';
			}

			if (response?.status === 'error') {
				return response.error;
			}
		} else {
			const response = await updateCar({ name: data.name }, 'POST', `/dials/cars/category`);

			if (response?.status === 'error') {
				return response.error;
			}

			if (!response) {
				return 'ERROR_NETWORK';
			}
		}

		await refetch();

		setOpenModal(false);

		setMsgSnackbar(editData ? `Kategorie ${data.name} byla editována.` : `Kategorie ${data.name} byla přidána.`);
		setSeveritySnackbar('success');
		setOpenSnackbar(true);

		setEditData(null);
		setEditID(null);
		return undefined;
	};

	const handleCloseModal = (): void => {
		setOpenModal(false);
		setEditData(null);
		setEditID(null);
	};

	// ____________ SNACKBAR ______________________
	const handleCloseSnackbar = () => {
		setOpenSnackbar(false);
	};

	// _______________________ VYSTUP ________________________

	if (!state.user?.roles.includes('editor')) {
		return <PermissionAlert />;
	}

	return (
		<>
			<Loading isLoading={isLoading || isUpdateLoading} />
			<SnackbarAfterAction
				severity={severitySnackbar}
				openSnackbar={openSnackbar}
				onClose={handleCloseSnackbar}
				msgSnackbar={msgSnackbar}
			/>

			<DeleteDialog onOpen={openDeleteDialog} handleClose={handleCloseDeleteDialog} onDelete={handleDelete} />

			<FormModal
				modalTitle={`${editID ? 'Editace' : 'Přidání'} kategorie`}
				openModal={openModal}
				onClose={handleCloseModal}>
				<CarCategoryForm
					editID={editID}
					editData={editData}
					onFormSubmit={handleFormSubmit}
					onClose={handleCloseModal}
				/>
			</FormModal>

			<PageTitle
				title="Kategorie aut"
				buttonText="přidat kategorii"
				icon={<AddIcon />}
				onClickHeaderButton={handleAdd}
			/>

			<TableView
				titleRow={<TitleTableRow />}
				dataRow={
					<DataTableRows
						carsCategory={carsCategory}
						onEdit={handleEdit}
						onDeleteConfirm={handleDeleteConfirm}
					/>
				}
			/>
		</>
	);
};

export default CarsCategoryList;
