import React from 'react';

import { useAppContext } from '../../../../context/appContext/appContext';
import { UserDB } from '../../types';
import TableCellView from '../../../../components/pageContent/TableView/TableCellView';

import ContactEmergencyOutlinedIcon from '@mui/icons-material/ContactEmergencyOutlined';

import { IconButton, TableRow, useMediaQuery, useTheme } from '@mui/material';

type Props = {
	users: UserDB[] | null;
	onDetail: (id: number) => void;
	rowsPerPage: number;
	page: number;
};

const DataTableRows = ({ users, onDetail, rowsPerPage, page }: Props) => {
	const { state } = useAppContext();
	const theme = useTheme();

	const sizeButton = useMediaQuery(theme.breakpoints.up('sm')) ? 'small' : 'medium';

	return (
		<>
			{!users ||
				(users.length === 0 && (
					<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
						<TableCellView align="left" w="auto" colspan={6}>
							Nebyl nalezen žádný záznam.
						</TableCellView>
					</TableRow>
				))}

			{users &&
				(rowsPerPage > 0 ? users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : users).map(
					(user: UserDB, index: number) => {
						return (
							<React.Fragment key={user.id}>
								<TableRow hover>
									<TableCellView align="center" w={50}>
										{rowsPerPage > 0 ? page * rowsPerPage + index + 1 : index + 1}
									</TableCellView>

									<TableCellView align="center" w={30}>
										<IconButton
											onClick={() => onDetail(user.id)}
											sx={{
												borderRadius: 1,
												border: 0,
												color: theme.palette.grey[500],
												borderColor: theme.palette.info.dark,
												padding: '0.15em',
											}}
											size="small">
											<ContactEmergencyOutlinedIcon color={'info'} fontSize={sizeButton} />
										</IconButton>
									</TableCellView>

									<TableCellView align="left" mW={200} w="auto">
										{`${user.lastName} ${user.firstName}`}
									</TableCellView>

									<TableCellView align="left" mW={200} w="auto">
										{user.email}
									</TableCellView>

									<TableCellView align="left" mW={200} w="auto">
										{user.phone}
									</TableCellView>

									<TableCellView
										align="right"
										w={state.user?.role === 'admin' ? 60 : '5px'}
										pX={state.user?.role === 'admin' ? undefined : '5px'}
										isActive={user.isActive}>
										{state.user?.role === 'admin' ? user.id : ''}
									</TableCellView>
								</TableRow>
							</React.Fragment>
						);
					}
				)}
		</>
	);
};

export default DataTableRows;
