import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type Props = {
	onOpen: boolean;
	activeName: string;
	handleClose: () => void;
	onSetStatus: () => void;
	status: string;
};

const ActiveDialog = ({ onOpen, activeName, handleClose, onSetStatus, status }: Props) => {
	const [isLoading, setIsLoading] = useState(false);

	const handleConfirm = async (): Promise<void> => {
		setIsLoading(true);
		await onSetStatus();
		setIsLoading(false);
	};

	return (
		<Dialog
			open={onOpen}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description">
			{status === 'active' && (
				<>
					<DialogTitle>Opravdu chcete zakázku "{activeName}" uzavřít?</DialogTitle>
					{/* <DialogContent>Zakázka {activeName} bude uzavřena.</DialogContent> */}
				</>
			)}
			{status === 'closed' && (
				<>
					<DialogTitle>Opravdu chcete zakázku "{activeName}" otevřít?</DialogTitle>
					{/* <DialogContent>Zakázka {activeName} bude otevřena.</DialogContent> */}
				</>
			)}

			<DialogActions>
				<Button onClick={handleClose}>Zrušit</Button>
				<Button onClick={handleConfirm} autoFocus>
					{status === 'active' && 'Ano, uzavřít'}
					{status === 'closed' && 'Ano, otevřít'}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ActiveDialog;
