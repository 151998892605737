import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';

import { useAppContext } from '../../../../context/appContext/appContext';

import TableCellView from '../../../../components/pageContent/TableView/TableCellView';
import TableCellEditIcon from '../../../../components/pageContent/TableView/TableCellEditIcon';
import TableCellDeleteIcon from '../../../../components/pageContent/TableView/TableCellDeleteIcon';
import TableCellIsActiveIcon from '../../../../components/pageContent/TableView/TableCellIsActiveIcon';
import TableCellInfoIcon from '../../../../components/pageContent/TableView/TableCellInfoIcon';

import React, { useState } from 'react';
import dayjs from 'dayjs';

import { ContractDB } from '../../types';
import TableCellLoupeIcon from '../../../../components/pageContent/TableView/TableCellLoupeIcon';
import TableCellReportIcon from '../../../../components/pageContent/TableView/TableCellReportIcon';
import DataCollapse from './DataCollapse';

type Props = {
	contracts: ContractDB[] | null;
	rowsPerPage: number;
	page: number;
};

const DataTableRows = ({
	contracts,

	rowsPerPage,
	page,
}: Props) => {
	const [open, setOpen] = useState(false);
	const [openId, setOpenId] = useState(0);
	const { state } = useAppContext();

	const handleOpen = async (id: number) => {
		setOpen(false);
		setOpenId(0);

		if (openId !== id) {
			setOpen(true);
			setOpenId(id);
		}
	};

	return (
		<>
			{!contracts?.length && (
				<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
					<TableCellView align="left" w="auto" colspan={7}>
						Nebyl nalezen žádný záznam.
					</TableCellView>
				</TableRow>
			)}

			{contracts &&
				(rowsPerPage > 0
					? contracts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
					: contracts
				).map((contract: ContractDB, index: number) => {
					return (
						<React.Fragment key={contract.id}>
							<TableRow hover>
								<TableCellView align="center" w={50}>
									{rowsPerPage > 0 ? page * rowsPerPage + index + 1 : index + 1}
								</TableCellView>

								<TableCellLoupeIcon
									onOpen={handleOpen}
									openId={contract.id}
									color={open && openId === contract.id ? 'warning' : 'info'}
								/>

								<TableCellView align="left" mW={150} w="auto">
									{contract.name}
								</TableCellView>

								<TableCellView
									align="right"
									w={state.user?.role === 'admin' ? 40 : '5px'}
									pX={state.user?.role === 'admin' ? undefined : '5px'}>
									{state.user?.role === 'admin' ? contract.id : ''}
								</TableCellView>
							</TableRow>

							{/* hidden */}

							{/* ________________ COLLAPSE ROW __________________________ */}

							<DataCollapse contract={contract} onOpen={handleOpen} open={open} openId={openId} />
						</React.Fragment>
					);
				})}
		</>
	);
};

export default DataTableRows;
