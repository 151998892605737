import React, { useEffect, useState } from 'react';
import { UserDB, UserFormInputs } from '../types';

import { useData } from '../../../utils/useData';
import { useUpdate } from '../../../utils/useUpdate';
import { useAppContext } from '../../../context/appContext/appContext';

import PermissionAlert from '../../../components/pageContent/PermissionAlert';

import PageTitle from '../../../components/pageContent/PageTitle';

import TableView from '../../../components/pageContent/TableView/TableView';
import TitleTableRow from './dataTable/TitleTableRow';
import DataTableRows from './dataTable/DataTableRows';
import FormModal from '../../../components/modal/FormModal';

import UserForm from '../edit/UserForm';
import SnackbarAfterAction from '../../../components/uiComponents/SnackbarAfterAction';
import Loading from '../../../components/pageContent/Loading';

import DeleteDialog from '../../../components/pageContent/TableView/DeleteDialog';

import AddIcon from '@mui/icons-material/Add';
import { AlertColor, TablePagination } from '@mui/material';

import { dict } from '../../../translate/translate-dictionary';
import { useUsersContext } from '../../../context/usersContext/UsersContext';
import { useNavigate } from 'react-router-dom';
import FilterUser from './FilterUser';

type Props = {};

const UsersList = (props: Props) => {
	const [openModal, setOpenModal] = useState<boolean>(false);

	const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
	const [msgSnackbar, setMsgSnackbar] = useState<string>('');
	const [severitySnackbar, setSeveritySnackbar] = useState<AlertColor>('success');

	const [editID, setEditID] = useState<number | null>(null);
	const [editData, setEditData] = useState<UserDB | null>(null);

	const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
	const [idDelete, setIdDelete] = React.useState<number | null>(null);
	const [nameDelete, setNameDelete] = React.useState<string>('');

	const { state: stateUser, setPage, setInitUsers, refetchUsers, removeIsInit } = useUsersContext();
	const { state, setRowsPerPage } = useAppContext();

	const navigate = useNavigate();
	const lang = state.lang;

	// ____________ USE UPDATE - ostatní metody ______________________
	const {
		isLoading: isUpdateLoading,
		data: dataEditing,
		update: updateUser,
		error: updateError,
	} = useUpdate<{ status: string; error?: string; user?: UserDB }>('');

	// ____________ SET ADD ______________________
	const handleAdd = (): void => {
		setOpenModal(true);
		setEditID(null);
	};

	// ____________ SET EDIT ______________________
	const handleEdit = async (id: number): Promise<void> => {
		const response = await updateUser(null, 'GET', `/users/${id}`);

		if (!response) {
			return;
		}

		if (response?.status === 'success') {
			if (response.user) setEditData(response.user);
		}

		setOpenModal(true);
		setEditID(id);
	};

	// ____________ DELETE ______________________
	const handleDelete = async (): Promise<void> => {
		const response = await updateUser(null, 'DELETE', `/users/${idDelete}`);
		if (!response) {
			return;
		}

		if (response.status === 'error') {
			if (response.error === 'RECORD_CANNOT_BE_DELETED') {
				setMsgSnackbar(`Uživatele "${nameDelete}" nelze smazat!`);
				setSeveritySnackbar('error');
				setOpenSnackbar(true);
			}
		} else {
			removeIsInit();
			setMsgSnackbar(`Uživatel "${nameDelete}" byl vymazán.`);
			setSeveritySnackbar('success');
			setOpenSnackbar(true);
		}

		setIdDelete(null);
		setNameDelete('');
		handleCloseDeleteDialog();
	};

	// ____________ DELETE CONFIRM ______________________
	const handleDeleteConfirm = (id: number, name: string) => {
		setIdDelete(id);
		setNameDelete(name);
		setOpenDeleteDialog(true);
	};

	// ____________ DELETE CLOSE DIALOG ______________________
	const handleCloseDeleteDialog = () => {
		setIdDelete(null);
		setOpenDeleteDialog(false);
	};

	// ____________ IS ACTIVE ______________________
	const handleActive = async (id: number, name: string, isActive: boolean) => {
		const response = await updateUser({ isActive: isActive }, 'POST', `/users/set-active/${id}`);
		if (!response) {
			return;
		}

		refetchUsers();

		setMsgSnackbar(`Uživatel "${name}" byl ${isActive ? 'pozastaven' : 'aktivován'}.`);
		setSeveritySnackbar(isActive ? 'warning' : 'success');
		setOpenSnackbar(true);
	};

	// ____________ SUBMIT FORM MODAL ______________________
	const handleFormSubmit = async (data: UserFormInputs) => {
		if (editID) {
			const response = await updateUser(
				{
					firstName: data.firstName,
					lastName: data.lastName,
					email: data.email,
					phone: data.phone,
					street: data.street,
					city: data.city,
					zipCode: data.zipCode,
					role: data.role,
				},
				'PATCH',
				`/users/${editID}`
			);

			if (!response) {
				return 'ERROR_NETWORK';
			}

			if (response?.status === 'error') {
				return response.error;
			}
		} else {
			const response = await updateUser(
				{
					firstName: data.firstName,
					lastName: data.lastName,
					email: data.email,
					phone: data.phone,
					street: data.street,
					city: data.city,
					zipCode: data.zipCode,
					password: data.password,
					role: data.role,
				},
				'POST',
				`/users`
			);

			if (response?.status === 'error') {
				return response.error;
			}

			if (!response) {
				return 'ERROR_NETWORK';
			}
		}

		editData ? refetchUsers() : removeIsInit();

		setOpenModal(false);

		setMsgSnackbar(editData ? `Uživatel ${data.lastName} byl editován.` : `Uživatel ${data.lastName} byla přidán.`);
		setSeveritySnackbar('success');
		setOpenSnackbar(true);

		setEditData(null);
		setEditID(null);
		return undefined;
	};

	// ____________ CLOSE MODAL ______________________
	const handleCloseModal = (): void => {
		setOpenModal(false);
		setEditData(null);
		setEditID(null);
		refetchUsers();
	};

	// ____________ SNACKBAR ______________________
	const handleCloseSnackbar = () => {
		setOpenSnackbar(false);
	};

	// ____________ SET PAGE ______________________
	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	// ____________ SET ROWS PER PAGE ______________________
	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	// ____________ REPORT CONTRACT ______________________
	const handleReport = (id: number): void => {
		navigate(`/users/report/${id}`);
	};

	// ____________ NASTAVENI INICIALIZACE NEBO PO NAVRATU REFETCH ______________________
	useEffect(() => {
		if (stateUser.isInit === false) {
			setInitUsers();
		} else {
			refetchUsers();
		}
	}, [setInitUsers, refetchUsers, stateUser.isInit]);

	// _______________________ VYSTUP ________________________

	if (!state.user?.roles.includes('editor')) {
		return <PermissionAlert />;
	}

	return (
		<>
			<Loading isLoading={stateUser.isLoading || isUpdateLoading} />
			<SnackbarAfterAction
				severity={severitySnackbar}
				openSnackbar={openSnackbar}
				onClose={handleCloseSnackbar}
				msgSnackbar={msgSnackbar}
			/>

			<DeleteDialog onOpen={openDeleteDialog} handleClose={handleCloseDeleteDialog} onDelete={handleDelete} />

			<FormModal
				modalTitle={`${editID ? 'Editace' : 'Vytvoření'} uživatele`}
				openModal={openModal}
				onClose={handleCloseModal}>
				<UserForm
					editID={editID}
					editData={editData}
					onFormSubmit={handleFormSubmit}
					onClose={handleCloseModal}
				/>
			</FormModal>

			<PageTitle
				title="Uživatelé"
				buttonText="vytvořit uživatele"
				icon={<AddIcon />}
				onClickHeaderButton={handleAdd}
			/>

			<FilterUser />

			<TableView
				titleRow={<TitleTableRow />}
				dataRow={
					<DataTableRows
						users={stateUser.users}
						onReport={handleReport}
						onEdit={handleEdit}
						onDeleteConfirm={handleDeleteConfirm}
						onActive={handleActive}
						rowsPerPage={state.rowsPerPage}
						page={stateUser.page}
					/>
				}
				tablePagination={
					// stateUser.users?.length ? (
					// 	stateUser.users?.length > state.rowsPerPage ? (
					<TablePagination
						count={stateUser.users ? stateUser.users.length : 0}
						component="div"
						rowsPerPage={state.rowsPerPage}
						page={stateUser.page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						rowsPerPageOptions={[5, 10, 15]}
						labelRowsPerPage={dict['ROWS_PER_PAGE'][lang]}
					/>
					// 	) : undefined
					// ) : undefined
				}
				colspan={9}
			/>
		</>
	);
};

export default UsersList;
