import {
	Box,
	Collapse,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableRow,
	useMediaQuery,
	useTheme,
} from '@mui/material';

import { useAppContext } from '../../../../context/appContext/appContext';

import TableCellView from '../../../../components/pageContent/TableView/TableCellView';

import React, { useState } from 'react';
import dayjs from 'dayjs';

import TableCellLoupeIcon from '../../../../components/pageContent/TableView/TableCellLoupeIcon';

import { MyContractDB } from '../../types';
import TableCellMyReportIcon from '../../../../components/pageContent/TableView/TableCellMyReportIcon';
import { NavLink } from 'react-router-dom';

import SupervisorAccountRoundedIcon from '@mui/icons-material/SupervisorAccountRounded';

type Props = {
	contracts: MyContractDB[] | null;
	onReport: (id: number) => void;
	rowsPerPage: number;
	page: number;
};

const DataTableRows = ({ contracts, onReport, rowsPerPage, page }: Props) => {
	const [open, setOpen] = useState(false);
	const [openId, setOpenId] = useState(0);
	const { state } = useAppContext();

	const theme = useTheme();
	const sizeButton = useMediaQuery(theme.breakpoints.up('sm')) ? 'small' : 'medium';

	const handleOpen = (id: number) => {
		if (open) {
			setOpen(false);
			setOpenId(0);
		} else {
			setOpen(true);
			setOpenId(id);
		}
	};

	return (
		<>
			{!contracts?.length && (
				<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
					<TableCellView align="left" w="auto" colspan={5}>
						Nebyl nalezen žádný záznam.
					</TableCellView>
				</TableRow>
			)}

			{contracts &&
				(rowsPerPage > 0
					? contracts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
					: contracts
				).map((data: MyContractDB, index: number) => {
					return (
						<React.Fragment key={data.contract.id}>
							<TableRow hover>
								<TableCellView align="center" w={50}>
									{rowsPerPage > 0 ? page * rowsPerPage + index + 1 : index + 1}
								</TableCellView>

								<TableCellMyReportIcon onReport={onReport} idReport={data.contract.id!} />

								<TableCellView align="left" mW={150} w="auto">
									{data.contract.name}
								</TableCellView>

								<TableCellLoupeIcon
									onOpen={handleOpen}
									openId={data.contract.id}
									color={open && openId === data.contract.id ? 'warning' : 'info'}
								/>

								<TableCellView align="center" mW={30} w="auto" size={'small'} pX={0}>
									<NavLink to={`/my-contracts/detail/${data.contract.id}`}>
										<IconButton
											sx={{
												borderRadius: 1,
												border: 0,
												color: theme.palette.grey[500],
												borderColor: theme.palette.info.dark,
												padding: '0.15em',
											}}
											size="small">
											<SupervisorAccountRoundedIcon color={'info'} fontSize={sizeButton} />
										</IconButton>
									</NavLink>
								</TableCellView>

								<TableCellView
									align="right"
									w={state.user?.role === 'admin' ? 40 : '5px'}
									pX={state.user?.role === 'admin' ? undefined : '5px'}>
									{state.user?.role === 'admin' ? data.contract.id : ''}
								</TableCellView>
							</TableRow>

							{/* hidden */}

							<TableRow>
								<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
									<Collapse in={open && openId === data.contract.id} timeout="auto" unmountOnExit>
										<Box sx={{ margin: 1, marginLeft: 3, marginBottom: 3 }}>
											<Table size="small" aria-label="purchases">
												<TableBody>
													<TableRow>
														{/* <TableCell align="left" width={10}></TableCell> */}
														<TableCell align="left" width={100}>
															Klient:
														</TableCell>
														<TableCell align="left">
															{data.contract.client?.companyName}
														</TableCell>
													</TableRow>
													<TableRow>
														{/* <TableCell align="left" width={10}></TableCell> */}
														<TableCell align="left" width={100}>
															Zahájení:
														</TableCell>
														<TableCell align="left">
															{dayjs(data.contract.dateFrom).format('DD.MM.YYYY')}
														</TableCell>
													</TableRow>
													<TableRow>
														{/* <TableCell align="left" width={10}></TableCell> */}
														<TableCell align="left" width={100}>
															Ukončení:
														</TableCell>
														<TableCell align="left">
															{dayjs(data.contract.dateTo).format('DD.MM.YYYY')}
														</TableCell>
													</TableRow>

													{data.contract.description && (
														<TableRow>
															{/* <TableCell align="left" width={10}></TableCell> */}
															<TableCell align="left" width={100}>
																Popis:
															</TableCell>
															<TableCell align="left">
																{data.contract.description}
															</TableCell>
														</TableRow>
													)}
												</TableBody>
											</Table>
										</Box>
									</Collapse>
								</TableCell>
							</TableRow>
						</React.Fragment>
					);
				})}
		</>
	);
};

export default DataTableRows;
