import { IconButton, TableCell, useMediaQuery, useTheme } from '@mui/material';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import { NavLink } from 'react-router-dom';

type Props = {
	onReport?: (id: number) => void;
	idReport?: number;
	url?: string;
};

const TableCellReportIcon = ({ onReport, idReport, url }: Props) => {
	const theme = useTheme();
	const sizeButton = useMediaQuery(theme.breakpoints.up('sm')) ? 'small' : 'medium';

	return (
		<TableCell align="center" width={30} size="small" sx={{ paddingX: '5px' }}>
			{url && (
				<NavLink to={url} style={{ margin: '0.15em', display: 'flex' }}>
					<AssessmentOutlinedIcon fontSize={sizeButton} color="success" />
				</NavLink>
			)}
			{!url && (
				<IconButton
					sx={{
						borderRadius: 1,
						border: 0,
						color: theme.palette.grey[500],
						borderColor: theme.palette.info.dark,
						padding: '0.15em',
					}}
					onClick={() => onReport && idReport && onReport(idReport)}
					size="large">
					<AssessmentOutlinedIcon fontSize={sizeButton} color="success" />
				</IconButton>
			)}
		</TableCell>
	);
};

export default TableCellReportIcon;
