import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Button } from '@mui/material';

const useStyles = makeStyles()(theme => ({
	buttonGrey: {
		// backgroundColor: theme.palette.grey[400],

		'&.MuiButton-root': {
			backgroundColor: theme.palette.grey[300],
			color: theme.palette.grey[500],
		},
		'&.MuiButton-root:hover': {
			backgroundColor: theme.palette.grey[600],
			color: theme.palette.grey[200],
		},
	},
	buttonPrimary: {
		backgroundColor: theme.palette.primary.main,
	},
	buttonNoTitle: {
		margin: theme.spacing(1),
		fontSize: '1em',
		marginRight: 0,
		borderRadius: '0.3em',
		mt: 0,
		mb: 0,
		paddingLeft: 8,
		paddingRight: 8,
		minWidth: 32,

		'& .MuiButton-startIcon': {
			margin: 0,
		},
	},
	button: {
		margin: theme.spacing(1),
		fontSize: '1em',
		marginRight: 0,
		borderRadius: '0.3em',
		mt: 0,
		mb: 0,

		'& .MuiSvgIcon-fontSizeMedium': {
			width: '1.2em',
			height: '1.2em',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '0.9em',
			marginLeft: 0,
			marginRight: 0,
			'& .MuiSvgIcon-fontSizeMedium': {
				width: '1.1em',
				height: '1.1em',
			},
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			paddingLeft: 16,
			paddingRight: 16,
			'& .MuiButton-startIcon': {
				// margin: 3,
			},
		},
	},

	buttonText: {
		whiteSpace: 'nowrap',
		[theme.breakpoints.down('sm')]: {
			// display: 'none',
		},
	},
}));

type Props = {
	title?: string;
	icon: JSX.Element;
	bColor?: 'grey' | 'primary';
	onClickHeaderButton?: () => void;
	onDisabled?: boolean;
};

const ButtonWithStartIconNonResponsive = ({ title, icon, onClickHeaderButton, bColor, onDisabled }: Props) => {
	const { classes, cx } = useStyles();

	return (
		<Button
			disabled={onDisabled ? true : false}
			variant="contained"
			onClick={onClickHeaderButton}
			className={cx(
				!title ? classes.buttonNoTitle : classes.button,
				bColor === 'grey' && classes.buttonGrey,
				!bColor && classes.buttonPrimary
			)}
			startIcon={icon}
			type="button">
			{title && <span className={classes.buttonText}>{title}</span>}
		</Button>
	);
};

export default ButtonWithStartIconNonResponsive;
