import React from 'react';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

type Props = {
	backUrl: string;
	title: string;
};

const ContractPermissionAlert = ({ backUrl, title }: Props) => {
	const navigate = useNavigate();
	return (
		<Box mb={2} component="header">
			<Box
				sx={{
					padding: '1.5em 2em',
					borderRadius: 2,
					backgroundColor: 'white',
					alignItems: 'center',
				}}>
				<Alert severity="error">{title}</Alert>
			</Box>
			<Box sx={{ margin: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				<Button variant="contained" onClick={() => navigate(backUrl)}>
					zpět na přehled
				</Button>
			</Box>
		</Box>
	);
};

export default ContractPermissionAlert;
