import React, { useState } from 'react';
import { useAppContext } from '../../../context/appContext/appContext';
import { CarCategoryDB, CarDB, CarFormInputs } from '../types';

import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { carSchema } from '../../../utils/joiSchema';
import { dict, dictKey } from '../../../translate/translate-dictionary';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, FormHelperText } from '@mui/material';

type Props = {
	editID: number | null;
	editData: CarDB | null;
	categorySelect: CarCategoryDB[] | null;
	categoryFilter?: string;
	onClose: () => void;
	onFormSubmit: (data: CarFormInputs) => Promise<string | undefined>;
};

const CarsForm = ({ editData, editID, categorySelect, categoryFilter, onClose, onFormSubmit }: Props) => {
	const [categoryCar, setCategoryCar] = useState<string>(
		editData
			? editData.carCategory.id.toString()
			: categoryFilter
			? categoryFilter
			: categorySelect
			? categorySelect[0].id.toString()
			: ''
	);
	const [masterError, setMasterError] = useState<string>('');
	const { state } = useAppContext();
	const lang = state.lang;

	const {
		register,
		handleSubmit,
		reset,
		setError,
		formState: { errors },
	} = useForm<CarFormInputs>({
		defaultValues: {
			name: editData ? editData.name : '',
			spz: editData ? editData.spz : '',
			carCategoryId: editData
				? editData.carCategory.id
				: categoryFilter
				? parseInt(categoryFilter)
				: categorySelect
				? categorySelect[0].id
				: undefined,
		},
		resolver: joiResolver(carSchema),
		mode: 'onChange',
		delayError: 2000,
	});

	const handleCategoryChange = (event: SelectChangeEvent) => {
		setCategoryCar(event.target.value);
	};

	const onSubmit = async (data: CarFormInputs) => {
		// console.log(data);

		const response = await onFormSubmit(data);

		if (response) setMasterError(response);
	};

	return (
		<>
			<Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
				<FormControl variant="filled" sx={{ marginY: 1, width: '100%' }} error={!!errors?.carCategoryId}>
					<InputLabel id="categoryCar">Kategorie</InputLabel>
					<Select
						{...register('carCategoryId')}
						labelId="categoryCar"
						label="Kategorie"
						value={categoryCar}
						onChange={handleCategoryChange}>
						{categorySelect &&
							categorySelect.map((item: CarCategoryDB) => {
								return (
									<MenuItem key={item.id} value={item.id}>
										{item.name}
									</MenuItem>
								);
							})}
					</Select>
					<FormHelperText>
						{errors?.carCategoryId?.message
							? dict[errors.carCategoryId.message]
								? dict[errors.carCategoryId.message][lang]
								: errors?.carCategoryId?.message
							: ' '}
					</FormHelperText>
				</FormControl>

				<TextField
					{...register('name')}
					required
					margin="normal"
					label="Název vozidla"
					autoFocus
					error={!!errors?.name}
					helperText={
						errors?.name?.message
							? dict[errors.name.message]
								? dict[errors.name.message][lang]
								: errors?.name?.message
							: ' '
					}
					variant="filled"
					size="medium"
					sx={{ width: { xs: '100%', md: '60%' }, mr: { xs: 0, md: 2 } }}
				/>

				<TextField
					{...register('spz')}
					required
					margin="normal"
					label="SPZ vozidla"
					error={!!errors?.spz}
					helperText={
						errors?.spz?.message
							? dict[errors.spz.message]
								? dict[errors.spz.message][lang]
								: errors?.spz?.message
							: ' '
					}
					variant="filled"
					size="medium"
					sx={{ width: { xs: '100%', md: 'auto' } }}
				/>

				<Stack sx={{ width: '100%', marginTop: 2 }} spacing={3}>
					{masterError && (
						<Alert sx={{ borderRadius: '0.5em' }} severity="error">
							{dict[masterError] ? dict[masterError][lang] : masterError ? masterError : ' '}
						</Alert>
					)}
				</Stack>

				<Stack direction="row" justifyContent="center" alignItems="center" spacing={2} mt={3}>
					<Button variant="contained" size="large" color="inherit" onClick={onClose}>
						zrušit
					</Button>
					<Button variant="contained" type="submit" size="large">
						{`${editID ? 'Editovat' : 'Přidat'}`}
						<Box component={'span'} sx={{ display: { xs: 'none', sm: 'inline' } }}>
							&nbsp;vozidlo
						</Box>
					</Button>
				</Stack>
			</Box>
		</>
	);
};

export default CarsForm;
