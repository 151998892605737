import { IconButton, TableCell, useMediaQuery, useTheme } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { NavLink } from 'react-router-dom';

type Props = {
	onEdit?: (id: number) => void;
	idEdit?: number;
	isDisabled?: boolean;
	url?: string;
};

const TableCellEditIcon = ({ onEdit, idEdit, isDisabled, url }: Props) => {
	const theme = useTheme();
	const sizeButton = useMediaQuery(theme.breakpoints.up('sm')) ? 'small' : 'medium';

	return (
		<TableCell align="center" width={30} size="small" sx={{ paddingX: '5px' }}>
			{isDisabled && (
				<IconButton
					disabled
					sx={{
						borderRadius: 1,
						border: 0,
						color: theme.palette.grey[500],
						borderColor: theme.palette.info.dark,
						padding: '0.15em',
					}}
					// onClick={() => onEdit(idEdit)}
					aria-label="editace záznamu"
					size="small">
					{/* <EditOutlinedIcon fontSize="small" /> */}
				</IconButton>
			)}
			{!isDisabled && url && (
				<NavLink to={url} style={{ margin: '0.15em', display: 'flex', color: theme.palette.grey[500] }}>
					<EditOutlinedIcon fontSize={sizeButton} />
				</NavLink>
			)}

			{!isDisabled && !url && (
				<IconButton
					sx={{
						borderRadius: 1,
						border: 0,
						color: theme.palette.grey[500],
						borderColor: theme.palette.info.dark,
						padding: '0.15em',
					}}
					onClick={() => onEdit && idEdit && onEdit(idEdit)}
					aria-label="editace záznamu"
					size="small">
					<EditOutlinedIcon fontSize={sizeButton} />
				</IconButton>
			)}
		</TableCell>
	);
};

export default TableCellEditIcon;
