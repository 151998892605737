import React, { useCallback, useEffect, useState } from 'react';

import { ContractDB, ContractFormInput } from '../types';
import { Control, Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import csCZ from 'dayjs/locale/cs';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, useTheme } from '@mui/material';
import { useAppContext } from '../../../../context/appContext/appContext';
import { dict } from '../../../../translate/translate-dictionary';
import { useData } from '../../../../utils/useData';
import { ClientDB } from '../../../clients/types';
import { contractSchema, dateTo } from '../../../../utils/joiSchema';
import { useUpdate } from '../../../../utils/useUpdate';
import Loading from '../../../../components/pageContent/Loading';
import { useContractsContext } from '../../../../context/contractsContext/ContractsContext';

type Props = {
	contractId: number;
	isChanged: boolean;
	isLoadingParent: boolean;
	getEditData: () => Promise<ContractDB | undefined>;
	onIsChanged: () => void;
	onClearIsChanged: () => void;
	onCloseEdit: () => void;
	onEdit: (id: number | undefined) => void;
};

const TabContract = ({
	contractId,
	isChanged,
	isLoadingParent,
	onIsChanged,
	onClearIsChanged,
	onCloseEdit,
	onEdit,
	getEditData,
}: Props) => {
	const [masterError, setMasterError] = useState<string>('');
	const [clientSelect, setClientSelect] = useState<string>('');
	const [status, setStatus] = useState<string>('');

	const { state: stateContract, removeIsInit } = useContractsContext();
	const { state } = useAppContext();
	const theme = useTheme();

	const lang = state.lang;

	// ____________ USE DATA - NACTENI KLIENTA PRO SELECT ______________________
	const {
		isLoading,
		data: clients,
		refetch,
	} = useData<ClientDB[], { clients: ClientDB[] }>('/clients', data => data.clients);

	// ____________ USE UPDATE - OSTATNÍ METODY ______________________
	const {
		isLoading: isUpdateLoading,
		data: dataEditing,
		update: updateContract,
		error: updateError,
	} = useUpdate<{ status: string; error?: string; contract?: ContractDB }>('');

	// ____________ INIT FORM ______________________
	const {
		register,
		handleSubmit,
		reset,
		control,
		getValues,
		formState: { errors },
	} = useForm<ContractFormInput>({
		defaultValues: {
			name: '',
			clientId: '',
			dateFrom: dayjs().toString(),
			dateTo: dayjs().add(1, 'M').toString(),
			description: '',

			// name: contractEdit ? (contractEdit.name ? contractEdit.name : '') : '',
			// clientId: contractEdit ? (contractEdit.clientId ? contractEdit.clientId : '') : '',
			// dateFrom: contractEdit ? contractEdit.dateFrom : dayjs().toString(),
			// dateTo: contractEdit ? contractEdit.dateTo : dayjs().add(1, 'M').toString(),
			// description: contractEdit ? contractEdit.description : '',
		},
		resolver: joiResolver(contractSchema),
		mode: 'onChange',
		delayError: 2000,
	});

	//  ____________ NACTENI FORMULAROVYCH DAT ______________________
	useEffect(() => {
		(async () => {
			const data = await getEditData();
			if (data) {
				reset(
					{
						name: data?.name,
						clientId: data?.clientId,
						dateFrom: data?.dateFrom,
						dateTo: data?.dateTo,
						description: data?.description,
					},
					{
						keepErrors: true,
						keepDirty: true,
						keepDefaultValues: true,
						keepDirtyValues: true,
						keepIsSubmitted: false,
						keepTouched: false,
						keepIsValid: false,
						keepSubmitCount: false,
					}
				);

				data.status && setStatus(data.status);
			}
		})();
	}, [contractId, getEditData, reset]);

	// ____________ SUBMIT FORM ______________________
	const onSubmit = async (data: ContractFormInput) => {
		const dateF = dayjs(data.dateFrom).format('YYYY-MM-DD');
		const dateT = dayjs(data.dateTo).format('YYYY-MM-DD');

		if (contractId) {
			const response = await updateContract(
				{
					name: data.name,
					clientId: data.clientId,
					dateFrom: dateF,
					dateTo: dateT,
					description: data.description,
				},
				'PATCH',
				`/contracts/${contractId}`
			);

			if (response?.status === 'success') {
				setMasterError('');
				onClearIsChanged(); // muzou se proklikavat taby
			}

			if (response?.status === 'error') {
				response.error && setMasterError(response.error);
			}

			if (!response) {
				setMasterError('ERROR_NETWORK');
			}
		} else {
			const response = await updateContract(
				{
					name: data.name,
					clientId: data.clientId,
					dateFrom: dateF,
					dateTo: dateT,
					description: data.description,
				},
				'POST',
				'/contracts'
			);

			if (response?.status === 'success') {
				setMasterError('');
				onClearIsChanged(); // muzou se proklikavat taby
				removeIsInit(); // po navratu se smaze filtr
				response.contract && onEdit(response.contract?.id); // nastavim nove ID pro editaci
			}

			if (response?.status === 'error') {
				response.error && setMasterError(response.error);
			}

			if (!response) {
				setMasterError('ERROR_NETWORK');
			}
		}
	};

	return (
		<>
			<Loading isLoading={isUpdateLoading || isLoadingParent || isLoading} />

			<Box
				component="form"
				noValidate
				onSubmit={handleSubmit(onSubmit)}
				sx={{
					mt: 1,
				}}>
				{/* ________ NAME _______ */}
				<Box
					sx={{
						display: 'flex',
						justifyContent: { sm: 'space-between' },
						flexDirection: { xs: 'column', sm: 'column' },
						alignItems: 'center',
					}}>
					<Controller
						name="name"
						control={control}
						render={({ field: { ref, ...field } }) => (
							<TextField
								{...field}
								inputRef={ref}
								required
								type="text"
								label="Název zakázky"
								variant="filled"
								size="medium"
								onChange={e => {
									field.onChange(e.target.value);
									onIsChanged();
								}}
								fullWidth
								inputProps={{ maxLength: 250, readOnly: status === 'closed' && true }}
								sx={{ width: { xs: '100%', sm: '100%' } }}
								error={!!errors?.name}
								helperText={
									errors?.name?.message
										? dict[errors.name.message]
											? dict[errors.name.message][lang]
											: errors?.name?.message
										: ' '
								}
							/>
						)}
					/>
				</Box>

				{/* ________ Client _______ */}
				<Box
					sx={{
						display: 'flex',
						justifyContent: { sm: 'space-between' },
						flexDirection: { xs: 'column', sm: 'column' },
						alignItems: 'center',
					}}>
					<FormControl variant="filled" sx={{ marginY: 1, width: '100%' }} error={!!errors?.clientId}>
						<InputLabel id="client">Výběr klienta</InputLabel>
						<Controller
							name="clientId"
							control={control}
							render={({ field: { value, ...field } }) => (
								<Select
									{...field}
									labelId="client"
									label="Výběr klienta..."
									value={value}
									readOnly={status === 'closed' && true}
									onChange={event => {
										field.onChange(event);
										onIsChanged();
									}}>
									{clients &&
										clients.map((client: ClientDB) => {
											return (
												<MenuItem key={client.id} value={client.id}>
													{client.companyName}
												</MenuItem>
											);
										})}
								</Select>
							)}
						/>
						<FormHelperText>
							{errors?.clientId?.message
								? dict[errors.clientId.message]
									? dict[errors.clientId.message][lang]
									: errors?.clientId?.message
								: ' '}
						</FormHelperText>
					</FormControl>
				</Box>

				{/* ________ Date Picker _______ */}
				<Box
					sx={{
						display: 'flex',
						justifyContent: { sm: 'flex-start' },
						flexDirection: { xs: 'column', sm: 'row' },
						alignItems: { xs: 'flex-start', sm: 'center' },
					}}>
					<LocalizationProvider adapterLocale={csCZ} dateAdapter={AdapterDayjs}>
						<Controller
							name="dateFrom"
							control={control}
							render={({ field: { onChange, value, ...field }, fieldState: { error } }) => (
								<MobileDatePicker
									label="Datum od"
									inputFormat="DD/MM/YYYY"
									value={value}
									onChange={event => {
										onChange(event);
										onIsChanged();
									}}
									{...field}
									readOnly={status === 'closed' && true}
									renderInput={params => (
										<TextField
											variant="filled"
											sx={{ marginRight: 2, marginBottom: 2 }}
											{...params}
											error={!!errors.dateFrom}
											helperText={errors.dateFrom?.message}
										/>
									)}
								/>
							)}
						/>
					</LocalizationProvider>

					<LocalizationProvider adapterLocale={csCZ} dateAdapter={AdapterDayjs}>
						<Controller
							name="dateTo"
							control={control}
							render={({ field: { onChange, value, ...field } }) => (
								<MobileDatePicker
									label="Datum do"
									inputFormat="DD/MM/YYYY"
									value={value}
									onChange={event => {
										onChange(event);
										onIsChanged();
									}}
									{...field}
									readOnly={status === 'closed' && true}
									renderInput={params => (
										<TextField
											variant="filled"
											sx={{ marginRight: 2, marginBottom: 2 }}
											{...params}
											error={!!errors.dateTo}
											helperText={errors.dateTo?.message}
										/>
									)}
								/>
							)}
						/>
					</LocalizationProvider>
				</Box>

				{/* ________ NOTE _______ */}
				<Box
					sx={{
						display: 'flex',
						justifyContent: { sm: 'space-between' },
						flexDirection: { xs: 'column', sm: 'column' },
						alignItems: 'center',
					}}>
					<Controller
						name="description"
						control={control}
						render={({ field: { ref, ...field } }) => (
							<TextField
								{...field}
								inputRef={ref}
								// required
								multiline
								rows={4}
								type="text"
								label="Popis zakázky"
								variant="filled"
								size="medium"
								margin="normal"
								onChange={e => {
									field.onChange(e.target.value);
									onIsChanged();
								}}
								inputProps={{ maxLength: 250, readOnly: status === 'closed' && true }}
								sx={{ width: { xs: '100%', sm: '100%' } }}
								error={!!errors?.description}
								helperText={
									errors?.description?.message
										? dict[errors.description.message]
											? dict[errors.description.message][lang]
											: errors?.description?.message
										: ' '
								}
							/>
						)}
					/>
				</Box>

				<Stack sx={{ width: '100%', marginTop: 2 }} spacing={3}>
					{masterError && (
						<Alert sx={{ borderRadius: '0.5em' }} severity="error">
							{dict[masterError] ? dict[masterError][lang] : masterError ? masterError : ' '}
						</Alert>
					)}
				</Stack>

				<Stack direction="row" justifyContent="center" alignItems="center" spacing={2} mt={3}>
					<Button variant="contained" size="large" color="inherit" onClick={onCloseEdit}>
						zrušit
					</Button>
					{status !== 'closed' && (
						<Button variant="contained" type="submit" size="large" disabled={!isChanged}>
							{`${contractId ? 'Editovat' : 'Vytvořit'}`}
							<Box component={'span'} sx={{ display: { xs: 'none', sm: 'inline' } }}>
								&nbsp;zakázku
							</Box>
						</Button>
					)}
				</Stack>
			</Box>
		</>
	);
};

export default TabContract;
