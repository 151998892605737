export const setLocalStorage = (name: string, data: string | null) => {
	// if (data && name) localStorage.setItem(name, JSON.stringify(data));
	if (data && name) localStorage.setItem(name, data);
};

export const getRefreshToken = (): string | null => {
	const refreshTokenStorage: string | null = localStorage.getItem('refreshToken');

	if (refreshTokenStorage) {
		return refreshTokenStorage;
		// return JSON.parse(refreshTokenStorage);
	}

	return null;
};

export const getRowsPerPage = (): number | null => {
	const rowsPerPageStorage: string | null = localStorage.getItem('rowsPerPage');

	if (rowsPerPageStorage) {
		return parseInt(rowsPerPageStorage, 10);
	}

	return null;
};

export const getVersionStorage = (): string | null => {
	const versionStorage: string | null = localStorage.getItem('version');

	if (versionStorage) {
		return versionStorage;
	}

	return null;
};

export const refreshTokenStorage: string | null = localStorage.getItem('refreshToken');
