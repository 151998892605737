import React, { useEffect, useState } from 'react';
import {
	TableBody,
	TableRow,
	TableCell,
	IconButton,
	useTheme,
	Tooltip,
	Collapse,
	Box,
	Table,
	TableHead,
	Avatar,
} from '@mui/material';

import { useAppContext } from '../../../../context/appContext/appContext';

import TableCellView from '../../../../components/pageContent/TableView/TableCellView';
import TableCellEditIcon from '../../../../components/pageContent/TableView/TableCellEditIcon';
import TableCellDeleteIcon from '../../../../components/pageContent/TableView/TableCellDeleteIcon';
import TableCellIsActiveIcon from '../../../../components/pageContent/TableView/TableCellIsActiveIcon';
import { ContractUserDB } from '../types';

import LoupeOutlinedIcon from '@mui/icons-material/LoupeOutlined';

type Props = {
	status: string | undefined;
	contractUsers: ContractUserDB[] | null;
	onEdit: (id: number) => void;
	onDeleteConfirm: (id: number, name: string) => void;
};

const DataTableRows = ({ contractUsers, onEdit, onDeleteConfirm, status }: Props) => {
	const [open, setOpen] = useState(false);
	const [openId, setOpenId] = useState(0);
	const theme = useTheme();
	const { state } = useAppContext();
	const colspan = 7;

	const handleOpen = (id: number) => {
		if (open) {
			setOpen(false);
			setOpenId(0);
		} else {
			setOpen(true);
			setOpenId(id);
		}
	};

	return (
		<>
			{!contractUsers?.length && (
				<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
					<TableCellView align="left" w="auto" colspan={colspan}>
						Nebyl nalezen žádný záznam.
					</TableCellView>
				</TableRow>
			)}

			{contractUsers &&
				contractUsers.map((conUser: ContractUserDB, index) => {
					return (
						<React.Fragment key={conUser.id}>
							<TableRow hover>
								<TableCellView align="center" w={50} isActive={conUser.user.isActive}>
									{index + 1}
								</TableCellView>

								<TableCellView align="center" w={30} isActive={conUser.user.isActive}>
									<IconButton
										aria-label="expand row"
										size="small"
										onClick={() => handleOpen(conUser.id)}>
										{open && openId === conUser.id ? (
											// <KeyboardArrowUpIcon />
											<LoupeOutlinedIcon color="warning" fontSize="small" />
										) : (
											<LoupeOutlinedIcon color="info" fontSize="small" />
										)}
									</IconButton>
								</TableCellView>

								<TableCellView align="left" mW={150} w="auto" isActive={conUser.user.isActive}>
									<>
										{conUser.user.lastName} {conUser.user.firstName}
									</>
								</TableCellView>

								<TableCellView align="right" mW={70} w="auto" isActive={conUser.user.isActive}>
									{Math.floor(conUser.saleDriver) > 0
										? `${Math.floor(conUser.saleDriver).toLocaleString()} Kč`
										: ' '}
									{/* <>{Math.floor(conUser.saleDriver).toLocaleString()} Kč</> */}
								</TableCellView>

								<TableCellView align="right" mW={70} w="auto" isActive={conUser.user.isActive}>
									{Math.floor(conUser.saleWorker) > 0
										? `${Math.floor(conUser.saleWorker).toLocaleString()} Kč`
										: ' '}
									{/* {Math.floor(conUser.saleWorker).toLocaleString()} Kč */}
								</TableCellView>

								{status === 'closed' && (
									<>
										<TableCellView align="center" w={30}>
											{' '}
										</TableCellView>
										<TableCellView align="center" w={30}>
											{' '}
										</TableCellView>
									</>
								)}

								{status !== 'closed' && (
									<>
										<TableCellEditIcon onEdit={onEdit} idEdit={conUser.id} />

										<TableCellDeleteIcon
											onDeleteConfirm={onDeleteConfirm}
											idDelete={conUser.id}
											nameDelete={`${conUser.user.lastName} ${conUser.user.firstName}`}
										/>
									</>
								)}

								<TableCellView
									align="right"
									w={state.user?.role === 'admin' ? 40 : '5px'}
									pX={state.user?.role === 'admin' ? undefined : '5px'}
									isActive={conUser.user.isActive}>
									{state.user?.role === 'admin' ? conUser.id : ''}
								</TableCellView>
							</TableRow>

							{/* hidden */}

							<TableRow>
								<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
									<Collapse in={open && openId === conUser.id} timeout="auto" unmountOnExit>
										<Box sx={{ margin: 1, marginBottom: 3 }}>
											<Table size="small" aria-label="purchases">
												<TableBody>
													<TableRow>
														<TableCell rowSpan={3}>
															<Avatar
																variant="rounded"
																alt={`${conUser.user.firstName} ${conUser.user.lastName}`}
																src={
																	conUser.user.imageUrl
																		? `${process.env.REACT_APP_BASE_IMAGE_URL}/${conUser.user.imageUrl}`
																		: undefined
																}
																sx={{
																	width: 100,
																	height: 100,
																}}>{`${conUser.user.firstName[0]}${conUser.user.lastName[0]}`}</Avatar>
														</TableCell>
														<TableCell></TableCell>
														<TableCell align="right">Nákup/hod.</TableCell>
														<TableCell align="right">Prodej/hod.</TableCell>
														<TableCell align="right">Přípl. nákup/hod.</TableCell>
														<TableCell align="right">Přípl. prodej/hod.</TableCell>
													</TableRow>

													<TableRow>
														<TableCell>Řidič</TableCell>
														<TableCell align="right">
															{Math.floor(conUser.buyDriver) > 0
																? `${Math.floor(conUser.buyDriver).toLocaleString()} Kč`
																: ' '}
															{/* {Math.floor(conUser.buyDriver).toLocaleString()} Kč */}
														</TableCell>
														<TableCell align="right">
															{Math.floor(conUser.saleDriver) > 0
																? `${Math.floor(
																		conUser.saleDriver
																  ).toLocaleString()} Kč`
																: ' '}
															{/* {Math.floor(conUser.saleDriver).toLocaleString()} Kč */}
														</TableCell>
														<TableCell align="right">
															{Math.floor(conUser.buyOverDriver) > 0
																? `${Math.floor(
																		conUser.buyOverDriver
																  ).toLocaleString()} Kč`
																: ' '}
															{/* {Math.floor(conUser.buyOverDriver).toLocaleString()} Kč */}
														</TableCell>
														<TableCell align="right">
															{Math.floor(conUser.saleOverDriver) > 0
																? `${Math.floor(
																		conUser.saleOverDriver
																  ).toLocaleString()} Kč`
																: ' '}
															{/* {Math.floor(conUser.saleOverDriver).toLocaleString()} Kč */}
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>Pracovník</TableCell>
														<TableCell align="right">
															{Math.floor(conUser.buyWorker) > 0
																? `${Math.floor(conUser.buyWorker).toLocaleString()} Kč`
																: ' '}
															{/* {Math.floor(conUser.buyWorker).toLocaleString()} Kč */}
														</TableCell>
														<TableCell align="right">
															{Math.floor(conUser.saleWorker) > 0
																? `${Math.floor(
																		conUser.saleWorker
																  ).toLocaleString()} Kč`
																: ' '}
															{/* {Math.floor(conUser.saleWorker).toLocaleString()} Kč */}
														</TableCell>
														<TableCell align="right">
															{Math.floor(conUser.buyOverWorker) > 0
																? `${Math.floor(
																		conUser.buyOverWorker
																  ).toLocaleString()} Kč`
																: ' '}
															{/* {Math.floor(conUser.buyOverWorker).toLocaleString()} Kč */}
														</TableCell>
														<TableCell align="right">
															{Math.floor(conUser.saleOverWorker) > 0
																? `${Math.floor(
																		conUser.saleOverWorker
																  ).toLocaleString()} Kč`
																: ' '}
															{/* {Math.floor(conUser.saleOverWorker).toLocaleString()} Kč */}
														</TableCell>
													</TableRow>
												</TableBody>
											</Table>
										</Box>
									</Collapse>
								</TableCell>
							</TableRow>
						</React.Fragment>
					);
				})}
		</>
	);
};

export default DataTableRows;
