import React, { useEffect, useState } from 'react';
import { TableBody, TableRow, TableCell, IconButton, useTheme } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { useAppContext } from '../../../../context/appContext/appContext';
import { CarCategoryDB, CarDB } from '../../../cars/types';
import TableCellView from '../../../../components/pageContent/TableView/TableCellView';
import TableCellEditIcon from '../../../../components/pageContent/TableView/TableCellEditIcon';
import TableCellDeleteIcon from '../../../../components/pageContent/TableView/TableCellDeleteIcon';

type Props = {
	carsCategory: CarCategoryDB[] | null;
	onEdit: (id: number) => void;
	onDeleteConfirm: (id: number, name: string) => void;
};

const DataTableRows = ({ carsCategory, onEdit, onDeleteConfirm }: Props) => {
	const theme = useTheme();
	const { state } = useAppContext();

	return (
		<>
			{!carsCategory && (
				<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
					<TableCellView align="left" w="auto" colspan={6}>
						Nebyl nalezen žádný záznam.
					</TableCellView>
				</TableRow>
			)}

			{carsCategory &&
				carsCategory.map((category: CarCategoryDB, index: number) => {
					return (
						<TableRow hover key={category.id}>
							<TableCellView align="center" w={50}>
								{index + 1}
							</TableCellView>

							<TableCellView align="left" w={'auto'}>
								{category.name}
							</TableCellView>

							<TableCellEditIcon onEdit={onEdit} idEdit={category.id} />

							<TableCellDeleteIcon
								onDeleteConfirm={onDeleteConfirm}
								idDelete={category.id}
								nameDelete={category.name}
							/>

							<TableCellView
								align="right"
								w={state.user?.role === 'admin' ? 60 : '5px'}
								pX={state.user?.role === 'admin' ? undefined : '5px'}>
								{state.user?.role === 'admin' ? category.id : ''}
							</TableCellView>
						</TableRow>
					);
				})}
		</>
	);
};

export default DataTableRows;
