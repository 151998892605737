import { ActionType } from './actions';
import { initialState } from './appContext';
import { ContextState } from './types';

const reducer = (state: ContextState, action: any): ContextState => {
	if (action.type === ActionType.DISPLAY_ALERT) {
		return {
			...state,
			showAlert: true,
			alertType: action.payload.type,
			alertText: action.payload.message,
		};
	}

	if (action.type === ActionType.CLEAR_ALERT) {
		return {
			...state,
			showAlert: false,
			alertText: '',
		};
	}

	if (action.type === ActionType.LOGIN_USER_BEGIN) {
		return {
			...state,
			isLoading: true,
			showAlert: false,
			alertText: '',
		};
	}

	if (action.type === ActionType.LOGIN_USER_SUCCESS) {
		return {
			...state,
			isLoading: false,
			user: action.payload.user,
		};
	}

	if (action.type === ActionType.LOGIN_USER_ERROR) {
		return {
			...state,
			isLoading: false,
		};
	}

	if (action.type === ActionType.LOGOUT_USER) {
		return {
			...initialState,
			isLoading: false,
			userLoading: false,
			user: null,
		};
	}

	if (action.type === ActionType.GET_CURRENT_USER_BEGIN) {
		return {
			...state,
			userLoading: true,
			showAlert: false,
		};
	}
	if (action.type === ActionType.GET_CURRENT_USER_ERROR) {
		return {
			...state,
			userLoading: false,
		};
	}

	if (action.type === ActionType.GET_CURRENT_USER_SUCCESS) {
		return {
			...state,
			userLoading: false,
			user: action.payload.user,
		};
	}

	if (action.type === ActionType.SET_ROWS_PER_PAGE) {
		return {
			...state,
			rowsPerPage: action.payload.rowsPerPage,
		};
	}

	throw new Error(`no such action: ${action.type}`);
};

export default reducer;
