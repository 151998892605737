import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';

import { useMediaQuery, useTheme } from '@mui/material';
import Sidebar from './Sidebar';
import { drawerSpacing } from '../../constants';
import Navigation from './Navigation';
import { useAppContext } from '../../context/appContext/appContext';
import NoConnection from '../pageContent/NoConnection';

type Props = {};

const Layout = (props: Props) => {
	const theme = useTheme();
	const navigation = useNavigate();
	const { state } = useAppContext();

	const drawerWidth = theme.spacing(drawerSpacing);

	const matchUpMd = useMediaQuery(theme.breakpoints.up('md')); //900
	const matchDownSm = useMediaQuery(theme.breakpoints.down('sm')); //900

	const [drawerOpen, setDrawerOpen] = useState(matchDownSm ? false : true);
	const [drawerMinOpen, setDrawerMinOpen] = useState(!!matchUpMd);

	const [isOnline, setIsOnline] = useState(navigator.onLine);

	useEffect(() => {
		function updateSize() {
			setDrawerOpen(matchDownSm ? false : true);
			setDrawerMinOpen(!!matchUpMd);
		}

		window.addEventListener('resize', updateSize);
		updateSize();

		return () => window.removeEventListener('resize', updateSize);
	}, [matchDownSm, matchUpMd]);

	// hlidani jestli nevypadl internet
	useEffect(() => {
		// Update network status
		const handleStatusChange = () => {
			setIsOnline(navigator.onLine);
		};

		// Listen to the online status
		window.addEventListener('online', handleStatusChange);

		// Listen to the offline status
		window.addEventListener('offline', handleStatusChange);

		// Specify how to clean up after this effect for performance improvment
		return () => {
			window.removeEventListener('online', handleStatusChange);
			window.removeEventListener('offline', handleStatusChange);
		};
	}, [isOnline]);

	const handleDrawerToggle = (): void => {
		setDrawerOpen(!drawerOpen);
	};

	const handleDrawerMinToggle = (): void => {
		setDrawerMinOpen(!drawerMinOpen);
	};

	const handleClickSideBar = (nav: string): void => {
		if (!matchUpMd && drawerMinOpen) setDrawerMinOpen(false);
		if (matchDownSm) setDrawerOpen(false);
		navigation(nav);
	};

	// ==============================|| MAIN LAYOUT ||============================== //

	return (
		<>
			<NoConnection isOnline={isOnline} />

			<Navigation handleDrawerToggle={handleDrawerToggle} handleDrawerMinToggle={handleDrawerMinToggle} />

			{/* <Container
				component="main"
				sx={{
					backgroundColor: 'red',
					display: 'flex',
					position: 'relative',
					marginLeft: 'auto',
					marginRight: 'auto',
					marginTop: '60px',
					minHeight: '100vh',

					maxWidth: theme.breakpoints.values.md,
					// marginX: 'auto',

					// boxShadow: '0px 10px 10px #777',
				}}> */}
			{/* SIDEBAR */}
			{state.user && (
				<Sidebar
					drawerMinOpen={drawerMinOpen}
					handleDrawerMinToggle={handleDrawerMinToggle}
					drawerOpen={drawerOpen}
					handleDrawerToggle={handleDrawerToggle}
					onClickSideBar={handleClickSideBar}
				/>
			)}

			{/* PAGES */}
			{state.user && isOnline && (
				<Box
					id="container-side-bar"
					component="section"
					sx={{
						position: 'relative',
						width: 'inherit',
						marginTop: theme.spacing(10),
						// marginLeft: '30px',
						minHeight: `calc(100vh - ${theme.spacing(10)})`,

						marginRight: theme.spacing(4),
						transition: theme.transitions.create('margin', {
							easing: theme.transitions.easing.easeIn,
							duration: theme.transitions.duration.enteringScreen,
						}),

						[theme.breakpoints.up('md')]: drawerMinOpen
							? {
									marginLeft: `calc(${drawerWidth} + ${theme.spacing(4)})`,
									transition: theme.transitions.create('margin', {
										easing: theme.transitions.easing.easeIn,
										duration: theme.transitions.duration.enteringScreen,
									}),
							  }
							: {
									marginLeft: `calc(${theme.spacing(8)} + ${theme.spacing(4)})`,
									transition: theme.transitions.create('margin', {
										easing: theme.transitions.easing.easeIn,
										duration: theme.transitions.duration.enteringScreen,
									}),
							  },

						[theme.breakpoints.down('md')]: {
							marginLeft: `calc(${theme.spacing(8)} + ${theme.spacing(4)})`,
							transition: theme.transitions.create('margin', {
								easing: theme.transitions.easing.easeIn,
								duration: theme.transitions.duration.enteringScreen,
							}),
						},
						[theme.breakpoints.down('sm')]: {
							marginLeft: theme.spacing(4),
							transition: theme.transitions.create('margin', {
								easing: theme.transitions.easing.easeOut,
								duration: theme.transitions.duration.enteringScreen,
							}),
						},
					}}>
					<Outlet />
				</Box>
			)}

			{!state.user && isOnline && (
				<Box
					component="section"
					sx={{
						display: 'flex',

						alignItems: 'center',
						justifyContent: 'center',
						paddingY: theme.spacing(10),
						width: '100%',
						marginX: 0,
						minHeight: `calc(100vh - ${theme.spacing(0)})`,
					}}>
					<Outlet />
				</Box>
			)}
			{/* </Container> */}
		</>
	);
};

export default Layout;
