import { IconButton, TableCell, useMediaQuery, useTheme } from '@mui/material';
import LoupeOutlinedIcon from '@mui/icons-material/LoupeOutlined';

type Props = {
	onOpen: (id: number) => void;
	openId: number | undefined;
	color: 'warning' | 'info';
};

const LoupeIcon = ({ onOpen, openId, color }: Props) => {
	const theme = useTheme();
	const sizeButton = useMediaQuery(theme.breakpoints.up('sm')) ? 'small' : 'medium';

	return (
		<IconButton
			sx={{
				borderRadius: 1,
				border: 0,
				color: theme.palette.grey[500],
				borderColor: theme.palette.info.dark,
				padding: '0.15em',
			}}
			onClick={() => onOpen(openId ? openId : 0)}
			size="small">
			<LoupeOutlinedIcon color={color} fontSize={sizeButton} />
		</IconButton>
	);
};

export default LoupeIcon;
