// import Joi from 'joi';
import Joi from 'joi';

import { dictKey } from '../translate/translate-dictionary';

export const emailSchema = Joi.string()
	.trim()
	.email({ tlds: { allow: false } })
	.required()
	.lowercase()
	.messages({
		'string.email': dictKey.ENTER_EMAIL_IN_THE_CORRECT_FORMAT,
		'string.empty': dictKey.PLEASE_ENTER_EMAIL,
		'any.required': dictKey.PLEASE_ENTER_EMAIL,
	});
export const passwordSchema = Joi.string().trim().min(8).max(100).required().messages({
	'string.min': dictKey.FIELD_REQUIRES_MIN_8_CHARACTERS,
	'string.max': dictKey.FIELD_REQUIRES_MAX_100_CHARACTERS,
	'string.empty': dictKey.PLEASE_ENTER_PASSWORD,
	'any.required': dictKey.PLEASE_ENTER_PASSWORD,
});

export const passwordConfirmSchema = Joi.any().equal(Joi.ref('password')).required().messages({
	'any.only': dictKey.PASSWORD_DOES_NOT_MATCH,
});

export const fieldStr2_250Required = Joi.string().trim().min(2).max(250).required().messages({
	'string.min': dictKey.FIELD_REQUIRES_MIN_2_CHARACTERS,
	'string.max': dictKey.FIELD_REQUIRES_MAX_250_CHARACTERS,
	'string.empty': dictKey.ENTER_THE_REQUIRED_FIELDS,
	'any.required': dictKey.ENTER_THE_REQUIRED_FIELDS,
});

export const fieldStr2_100Required = Joi.string().trim().min(2).max(100).required().messages({
	'string.min': dictKey.FIELD_REQUIRES_MIN_2_CHARACTERS,
	'string.max': dictKey.FIELD_REQUIRES_MAX_100_CHARACTERS,
	'string.empty': dictKey.ENTER_THE_REQUIRED_FIELDS,
	'any.required': dictKey.ENTER_THE_REQUIRED_FIELDS,
});

export const fieldStr5_20Required = Joi.string().trim().min(5).max(20).required().messages({
	'string.min': dictKey.FIELD_REQUIRES_MIN_5_CHARACTERS,
	'string.max': dictKey.FIELD_REQUIRES_MAX_20_CHARACTERS,
	'string.empty': dictKey.ENTER_THE_REQUIRED_FIELDS,
	'any.required': dictKey.ENTER_THE_REQUIRED_FIELDS,
});

export const fieldStrMax20Required = Joi.string().trim().max(20).required().messages({
	'string.max': dictKey.FIELD_REQUIRES_MAX_20_CHARACTERS,
	'string.empty': dictKey.ENTER_THE_REQUIRED_FIELDS,
	'any.required': dictKey.ENTER_THE_REQUIRED_FIELDS,
});
export const requiredIdSelect = Joi.number().integer().greater(0).messages({
	'number.base': dictKey.SELECT_A_VALUE_FROM_SELECT,
	'number.greater': dictKey.SELECT_A_VALUE_FROM_SELECT,
});

export const requiredIdSelectClient = Joi.number().integer().greater(0).messages({
	'number.base': dictKey.SELECT_A_VALUE_FROM_SELECT_CLIENT,
	'number.greater': dictKey.SELECT_A_VALUE_FROM_SELECT_CLIENT,
});

export const requiredIdSelectDriver = Joi.number().integer().greater(0).messages({
	'number.base': dictKey.SELECT_A_VALUE_FROM_SELECT_DRIVER,
	'number.greater': dictKey.SELECT_A_VALUE_FROM_SELECT_DRIVER,
});

export const requiredIdSelectCar = Joi.number().integer().greater(0).messages({
	'number.base': dictKey.SELECT_A_VALUE_FROM_SELECT_CAR,
	'number.greater': dictKey.SELECT_A_VALUE_FROM_SELECT_CAR,
});

export const requiredNumber = Joi.number().greater(0).required().messages({
	'number.base': dictKey.VALUE_MUST_BE_GRATER_THEN_ZERO,
	'number.greater': dictKey.VALUE_MUST_BE_GRATER_THEN_ZERO,
	'any.required': dictKey.VALUE_MUST_BE_GRATER_THEN_ZERO,
});

export const dateFrom = Joi.any().optional().allow(null, '');
export const dateTo = Joi.any().optional().allow(null, '');

export const timeFrom = Joi.any().optional().allow(null, '');
export const timeTo = Joi.any().optional().allow(null, '');

// __________________NOT REQUIRED____________________
export const passwordSchemaNewUser = Joi.string().optional().allow(null, '').trim().min(8).max(100).messages({
	'string.min': dictKey.FIELD_REQUIRES_MIN_8_CHARACTERS,
	'string.max': dictKey.FIELD_REQUIRES_MAX_100_CHARACTERS,
});

export const fieldStr5_6 = Joi.string().optional().allow(null, '').trim().min(5).max(6).messages({
	'string.min': dictKey.FIELD_REQUIRES_MIN_5_CHARACTERS,
	'string.max': dictKey.FIELD_REQUIRES_MAX_6_CHARACTERS,
});

export const fieldStr2_100 = Joi.string().optional().allow(null, '').trim().min(2).max(100).messages({
	'string.min': dictKey.FIELD_REQUIRES_MIN_2_CHARACTERS,
	'string.max': dictKey.FIELD_REQUIRES_MAX_100_CHARACTERS,
});
export const fieldStr5_100 = Joi.string().optional().allow(null, '').trim().min(5).max(100).messages({
	'string.min': dictKey.FIELD_REQUIRES_MIN_5_CHARACTERS,
	'string.max': dictKey.FIELD_REQUIRES_MAX_100_CHARACTERS,
});
export const fieldStrMax20 = Joi.string().trim().optional().allow(null, '').max(20).messages({
	'string.max': dictKey.FIELD_REQUIRES_MAX_20_CHARACTERS,
});
export const fieldStrMax100 = Joi.string().optional().allow(null, '').trim().max(100).messages({
	'string.max': dictKey.FIELD_REQUIRES_MAX_100_CHARACTERS,
});

export const fieldStr250 = Joi.string().trim().max(250).optional().allow(null, '').messages({
	'string.max': dictKey.FIELD_REQUIRES_MAX_250_CHARACTERS,
});

export const fieldStr250ReqCosts = Joi.string().trim().max(250).optional().allow(null, '').messages({
	'string.max': dictKey.FIELD_REQUIRES_MAX_250_CHARACTERS,
});

export const fieldDescriptionText = Joi.string().trim().optional().allow(null, '').messages({});

export const emailNotRequired = Joi.string()
	.trim()
	.email({ tlds: { allow: false } })
	.optional()
	.allow(null, '')
	.lowercase()
	.messages({
		'string.email': dictKey.ENTER_EMAIL_IN_THE_CORRECT_FORMAT,
	});

export const roleSchema = Joi.string().optional().allow(null, '');
export const countrySchema = Joi.string().optional().allow(null, '');
export const imageSchema = Joi.string().optional().allow(null, '');

export const phoneSchema = Joi.string().optional().allow(null, '').trim().min(5).max(20).messages({
	'string.min': dictKey.FIELD_REQUIRES_MIN_5_CHARACTERS,
	'string.max': dictKey.FIELD_REQUIRES_MAX_20_CHARACTERS,
});

export const phoneMax20 = Joi.string().optional().allow(null, '').trim().max(20).messages({
	'string.max': dictKey.FIELD_REQUIRES_MAX_20_CHARACTERS,
});

export const notRequiredNumber = Joi.number().optional().allow('').greater(0).messages({
	'number.base': dictKey.VALUE_MUST_BE_GRATER_THEN_ZERO,
	'number.greater': dictKey.VALUE_MUST_BE_GRATER_THEN_ZERO,
});

// _________________ SCHEMA OBJECT ___________________

export const loginSchema = Joi.object().keys({
	email: emailSchema,
	password: passwordSchema,
});

export const forgotPasswordSchema = Joi.object().keys({
	email: emailSchema,
});

export const carCategorySchema = Joi.object().keys({
	name: fieldStr2_100Required,
});

export const carSchema = Joi.object().keys({
	name: fieldStr2_100Required,
	spz: fieldStr5_20Required,
	carCategoryId: requiredIdSelect,
});

export const userSchema = Joi.object().keys({
	firstName: fieldStr2_100Required,
	lastName: fieldStr2_100Required,
	email: emailSchema,
	phone: phoneSchema,
	street: fieldStr2_100,
	city: fieldStr2_100,
	zipCode: fieldStr5_6,
	password: passwordSchemaNewUser,
	role: roleSchema,
});

export const resetPasswordSchema = Joi.object({
	password: passwordSchema,
	passwordConfirm: passwordConfirmSchema,
});

export const resetMyPasswordSchema = Joi.object({
	oldPassword: passwordSchema,
	password: passwordSchema,
	passwordConfirm: passwordConfirmSchema,
});

export const userProfileSchema = Joi.object().keys({
	firstName: fieldStr2_100Required,
	lastName: fieldStr2_100Required,
	email: emailSchema,
	phone: phoneSchema,
	street: fieldStr2_100,
	city: fieldStr2_100,
	zipCode: fieldStr5_6,
});

export const clientSchema = Joi.object().keys({
	companyName: fieldStr2_250Required,
	idNum: fieldStrMax20Required,
	VAT: fieldStrMax20,
	companyEmail: emailNotRequired,
	companyPhone: fieldStrMax100,
	street: fieldStr2_100Required,
	city: fieldStr2_100Required,
	zipCode: fieldStrMax20Required,
	country: countrySchema,
	firstName: fieldStr2_100,
	lastName: fieldStr2_100,
	email: emailNotRequired,
	phone: phoneSchema,
	note: fieldStr250,
	logo: imageSchema,
});

export const contractSchema = Joi.object().keys({
	name: fieldStr2_250Required,
	clientId: requiredIdSelectClient,
	dateFrom: dateFrom,
	dateTo: dateTo,
	description: fieldDescriptionText,
});

export const carAddSchema = Joi.object().keys({
	dailyRate: requiredNumber,
	includedKm: requiredNumber,
	ratePerKm: requiredNumber,
});

export const userAddSchema = Joi.object().keys({
	buyDriver: requiredNumber,
	saleDriver: requiredNumber,
	buyOverDriver: notRequiredNumber,
	saleOverDriver: notRequiredNumber,
	buyWorker: requiredNumber,
	saleWorker: requiredNumber,
	buyOverWorker: notRequiredNumber,
	saleOverWorker: notRequiredNumber,
});

export const reportContractCarSchema = Joi.object().keys({
	userId: requiredIdSelectDriver,
	date: dateFrom,
	timeFrom: timeFrom,
	timeTo: timeTo,
	driveKm: requiredNumber,
	description: fieldStr2_250Required,
	costs: notRequiredNumber,
	descriptionCosts: Joi.alternatives().conditional('costs', {
		is: Joi.number().greater(0),
		then: Joi.string().max(250).required().messages({
			'string.max': dictKey.FIELD_REQUIRES_MAX_250_CHARACTERS,
			'string.empty': dictKey.ENTER_THE_REQUIRED_FIELDS,
		}),
		otherwise: Joi.string().max(250).optional().allow(null, '').messages({
			'string.max': dictKey.FIELD_REQUIRES_MAX_250_CHARACTERS,
		}),
	}),
	costsDriver: notRequiredNumber,
	descriptionCostsDriver: Joi.alternatives().conditional('costsDriver', {
		is: Joi.number().greater(0),
		then: Joi.string().max(250).required().messages({
			'string.max': dictKey.FIELD_REQUIRES_MAX_250_CHARACTERS,
			'string.empty': dictKey.ENTER_THE_REQUIRED_FIELDS,
		}),
		otherwise: Joi.string().max(250).optional().allow(null, '').messages({
			'string.max': dictKey.FIELD_REQUIRES_MAX_250_CHARACTERS,
		}),
	}),
});

export const reportMyContractCarSchema = Joi.object().keys({
	carId: requiredIdSelectCar,
	date: dateFrom,
	timeFrom: timeFrom,
	timeTo: timeTo,
	driveKm: requiredNumber,
	description: fieldStr2_250Required,
	costs: notRequiredNumber,
	descriptionCosts: Joi.alternatives().conditional('costs', {
		is: Joi.number().greater(0),
		then: Joi.string().max(250).required().messages({
			'string.max': dictKey.FIELD_REQUIRES_MAX_250_CHARACTERS,
			'string.empty': dictKey.ENTER_THE_REQUIRED_FIELDS,
		}),
		otherwise: Joi.string().max(250).optional().allow(null, '').messages({
			'string.max': dictKey.FIELD_REQUIRES_MAX_250_CHARACTERS,
		}),
	}),
	costsDriver: notRequiredNumber,
	descriptionCostsDriver: Joi.alternatives().conditional('costsDriver', {
		is: Joi.number().greater(0),
		then: Joi.string().max(250).required().messages({
			'string.max': dictKey.FIELD_REQUIRES_MAX_250_CHARACTERS,
			'string.empty': dictKey.ENTER_THE_REQUIRED_FIELDS,
		}),
		otherwise: Joi.string().max(250).optional().allow(null, '').messages({
			'string.max': dictKey.FIELD_REQUIRES_MAX_250_CHARACTERS,
		}),
	}),
});

export const reportContractWorkerSchema = Joi.object().keys({
	date: dateFrom,
	timeFrom: timeFrom,
	timeTo: timeTo,
	description: fieldStr2_250Required,
	costs: notRequiredNumber,
	descriptionCosts: Joi.alternatives().conditional('costs', {
		is: Joi.number().greater(0),
		then: Joi.string().max(250).required().messages({
			'string.max': dictKey.FIELD_REQUIRES_MAX_250_CHARACTERS,
			'string.empty': dictKey.ENTER_THE_REQUIRED_FIELDS,
		}),
		otherwise: Joi.string().max(250).optional().allow(null, '').messages({
			'string.max': dictKey.FIELD_REQUIRES_MAX_250_CHARACTERS,
		}),
	}),
});
