import { Box, Collapse, Table, TableBody, TableCell, TableRow, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { ContractDB } from '../../types';

type Props = {
	contract: ContractDB;
	onOpen: (id: number) => {};

	open: boolean;
	openId: number;
};

const DataCollapse = ({ contract, onOpen, open, openId }: Props) => {
	const theme = useTheme();
	return (
		<>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
					<Collapse in={open && openId === contract.id} timeout="auto" unmountOnExit>
						<Box sx={{ margin: 1, marginBottom: 3 }}>
							<Table size="small" aria-label="purchases">
								<TableBody>
									<TableRow>
										<TableCell align="left" width={20} sx={{ padding: 0 }}></TableCell>
										<TableCell align="left" width={100}>
											Klient:
										</TableCell>
										<TableCell align="left">{contract.client?.companyName}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell align="left" width={20} sx={{ padding: 0 }}></TableCell>
										<TableCell align="left" width={100}>
											Zahájení:
										</TableCell>
										<TableCell align="left">
											{dayjs(contract.dateFrom).format('DD.MM.YYYY')}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell align="left" width={20} sx={{ padding: 0 }}></TableCell>
										<TableCell align="left" width={100}>
											Ukončení:
										</TableCell>
										<TableCell align="left">
											{dayjs(contract.dateTo).format('DD.MM.YYYY')}
										</TableCell>
									</TableRow>

									{contract.description && (
										<TableRow>
											<TableCell align="left" width={20} sx={{ padding: 0 }}></TableCell>
											<TableCell align="left" width={100}>
												Popis:
											</TableCell>
											<TableCell align="left">{contract.description}</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
};

export default DataCollapse;
