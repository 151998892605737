import React, { useCallback, useEffect, useState } from 'react';

import { useAppContext } from '../../../context/appContext/appContext';
import { AresAddress, ClientDB, ClientFormInputs } from '../types';

import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { clientSchema } from '../../../utils/joiSchema';
import { dict } from '../../../translate/translate-dictionary';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import {
	CircularProgress,
	Divider,
	FilledInput,
	FormControl,
	FormHelperText,
	IconButton,
	InputAdornment,
	InputLabel,
	LinearProgress,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
	useTheme,
} from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

import { useUpdate } from '../../../utils/useUpdate';
import DeleteDialog from '../../../components/pageContent/TableView/DeleteDialog';
import Loading from '../../../components/pageContent/Loading';

type Props = {
	editID: number | null;
	editData: ClientDB | null;
	onClose: () => void;
	onFormSubmit: (data: ClientFormInputs, logo?: any) => Promise<string | undefined>;
};

const ClientForm = ({ editData, editID, onClose, onFormSubmit }: Props) => {
	const [logo, setLogo] = useState<string | undefined>(undefined);
	const [logoUrl, setLogoUrl] = useState('');
	const [masterError, setMasterError] = useState<string>('');
	const [aresError, setAresError] = useState<string>('');
	const [isLoadingAres, setIsLoadingAres] = useState<boolean>(false);
	const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false);

	const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
	const [idDelete, setIdDelete] = React.useState<number | null>(null);
	const [nameDelete, setNameDelete] = React.useState<string>('');
	const [isDeleted, setIsDeleted] = useState<boolean>(false);

	const { state } = useAppContext();
	const theme = useTheme();

	const lang = state.lang;

	// ____________ USE UPDATE - ostatní metody ______________________
	const {
		isLoading,
		data: aresData,
		update: updateAres,
		error: updateError,
	} = useUpdate<{ status: string; error?: string; message?: string; address: AresAddress }>('');

	// ____________ USE UPDATE - ostatní metody ______________________
	const {
		isLoading: isLoadingLogo,
		data: dataEditing,
		update: updateClient,
	} = useUpdate<{ status: string; error?: string; message?: string; client: ClientDB }>('');

	const {
		register,
		handleSubmit,
		reset,
		control,
		getValues,
		formState: { errors },
	} = useForm<ClientFormInputs>({
		defaultValues: {
			companyName: editData ? editData.companyName : '',
			idNum: editData ? editData.idNum : '',
			VAT: editData ? editData.VAT : '',
			companyEmail: editData ? editData.companyEmail : '',
			companyPhone: editData ? editData.companyPhone : '',
			street: editData ? editData.street : '',
			city: editData ? editData.city : '',
			zipCode: editData ? editData.zipCode : '',
			country: editData ? editData.country : '',
			firstName: editData ? editData.firstName : '',
			lastName: editData ? editData.lastName : '',
			email: editData ? editData.email : '',
			phone: editData ? editData.phone : '',
			note: editData ? editData.note : '',
		},
		resolver: joiResolver(clientSchema),
		mode: 'onChange',
		delayError: 2000,
	});

	const onImageChange = (event: any) => {
		if (event.target.files[0]) {
			setLogo(event.target.files[0]);
			setLogoUrl(URL.createObjectURL(event.target.files[0]));
		}
	};

	const onImageChangeDelete = (event: any) => {
		setLogo(undefined);
		setLogoUrl('');
	};

	// ____________ DELETE CONFIRM ______________________
	const handleDeleteConfirm = (id: number, name?: string) => {
		setIdDelete(id);
		setOpenDeleteDialog(true);
	};

	// ____________ DELETE CLOSE DIALOG ______________________
	const handleCloseDeleteDialog = () => {
		setIdDelete(null);
		setOpenDeleteDialog(false);
	};

	// ____________ DELETE LOGO ______________________
	const handleDeleteLogo = async (): Promise<void> => {
		setIsLoadingForm(true);
		const response = await updateClient(null, 'DELETE', `/clients/delete-logo/${idDelete}`);
		if (!response) {
			return;
		}

		setIdDelete(null);
		setNameDelete('');
		handleCloseDeleteDialog();
		setIsDeleted(true);
		setIsLoadingForm(false);
	};

	const onSubmit = async (data: ClientFormInputs) => {
		setIsLoadingForm(true);
		const response = await onFormSubmit(data, logo);

		if (response) setMasterError(response);
		setIsLoadingForm(false);
	};

	const handleImportAres = useCallback(async () => {
		setIsLoadingAres(true);
		const values = getValues();
		const response = await updateAres(null, 'GET', `/clients/ares/${values.idNum}`);

		if (!response) {
			return;
		}

		if (response?.status === 'error') {
			response.message && setAresError(response.message);
		}

		if (response?.status === 'success') {
			setAresError('');
			if (response.address) {
				const ares: AresAddress = response.address;
				ares &&
					reset(
						{
							...values,
							VAT: ares?.VAT && ares.VAT,
							companyName: ares?.companyName && ares.companyName,
							street: ares?.street && ares.street,
							city: ares?.city && ares.city,
							zipCode: ares?.zipCode && ares.zipCode,
						},
						{
							keepErrors: true,
							keepDirty: true,
							keepDefaultValues: true,
							keepDirtyValues: true,
							keepIsSubmitted: false,
							keepTouched: false,
							keepIsValid: false,
							keepSubmitCount: false,
						}
					);
			}
		}

		setIsLoadingAres(false);
	}, [reset, updateAres]);

	return (
		<>
			<DeleteDialog
				onOpen={openDeleteDialog}
				handleClose={handleCloseDeleteDialog}
				onDelete={handleDeleteLogo}
				recordType={'logo'}
			/>

			<Stack sx={{ width: '100%', marginTop: 2 }} spacing={3}>
				{aresError && (
					<Alert sx={{ borderRadius: '0.5em' }} severity="error">
						{dict[aresError] ? dict[aresError][lang] : aresError ? aresError : ' '}
					</Alert>
				)}
			</Stack>
			<form
				noValidate
				onSubmit={handleSubmit(onSubmit)}
				encType={'multipart/form-data'}
				// sx={{
				// 	mt: 1,
				// }}
			>
				{/* ________ idNum a VAT _______ */}
				<Box
					sx={{
						display: 'flex',
						justifyContent: { sm: 'space-between' },
						flexDirection: { xs: 'column', sm: 'row' },
						alignItems: 'center',
					}}>
					<Box
						sx={{
							display: 'flex',
							width: { xs: '100%', sm: '48%' },
							flexDirection: 'row',
							alignItems: 'baseline',
							justifyContent: 'flex-start',
						}}>
						<Controller
							name="idNum"
							control={control}
							render={({ field: { ref, ...field } }) => (
								<TextField
									{...field}
									inputRef={ref}
									required
									type="text"
									label="IČO"
									variant="filled"
									size="medium"
									margin="normal"
									onChange={e => {
										field.onChange(e.target.value.replaceAll(' ', ''));
									}}
									inputProps={{ maxLength: 20 }}
									sx={{ width: { xs: '100%', sm: '80%' } }}
									error={!!errors?.idNum}
									helperText={
										errors?.idNum?.message
											? dict[errors.idNum.message]
												? dict[errors.idNum.message][lang]
												: errors?.idNum?.message
											: ' '
									}
								/>
							)}
						/>
						{!isLoadingAres && (
							<IconButton
								onClick={handleImportAres}
								color="primary"
								size="medium"
								sx={{ border: 1, borderRadius: 1, p: 0.5, marginLeft: 1 }}>
								<FileDownloadOutlinedIcon fontSize="small" />
							</IconButton>
						)}
						{isLoadingAres && (
							<IconButton
								color="primary"
								size="medium"
								sx={{ border: 1, borderRadius: 1, p: 0.5, marginLeft: 1 }}>
								<CircularProgress color="inherit" size={20} />
							</IconButton>
						)}
					</Box>

					<Controller
						name="VAT"
						control={control}
						render={({ field: { ref, ...field } }) => (
							<TextField
								{...field}
								inputRef={ref}
								// required
								type="text"
								label="DIČ"
								variant="filled"
								size="medium"
								margin="normal"
								inputProps={{ maxLength: 20 }}
								sx={{ width: { xs: '100%', sm: '48%' } }}
								error={!!errors?.VAT}
								helperText={
									errors?.VAT?.message
										? dict[errors.VAT.message]
											? dict[errors.VAT.message][lang]
											: errors?.VAT?.message
										: ' '
								}
							/>
						)}
					/>
				</Box>

				{/* ________ COMPANY NAME _______ */}
				<Box
					sx={{
						display: 'flex',
						justifyContent: { sm: 'space-between' },
						flexDirection: { xs: 'column', sm: 'column' },
						alignItems: 'center',
					}}>
					<Controller
						name="companyName"
						control={control}
						render={({ field: { ref, ...field } }) => (
							<TextField
								{...field}
								inputRef={ref}
								required
								type="text"
								label="Název firmy"
								variant="filled"
								size="medium"
								fullWidth
								inputProps={{ maxLength: 250 }}
								sx={{ width: { xs: '100%', sm: '100%' } }}
								error={!!errors?.companyName}
								helperText={
									errors?.companyName?.message
										? dict[errors.companyName.message]
											? dict[errors.companyName.message][lang]
											: errors?.companyName?.message
										: ' '
								}
							/>
						)}
					/>
				</Box>

				{/* ________ STREET, CITY _______ */}

				<Box
					sx={{
						display: 'flex',
						justifyContent: { sm: 'space-between' },
						flexDirection: { xs: 'column', sm: 'row' },
						alignItems: 'center',
					}}>
					<Controller
						name="street"
						control={control}
						render={({ field: { ref, ...field } }) => (
							<TextField
								{...field}
								inputRef={ref}
								required
								type="text"
								label="Ulice a číslo popisné"
								variant="filled"
								size="medium"
								margin="normal"
								inputProps={{ maxLength: 100 }}
								sx={{ width: { xs: '100%', sm: '48%' } }}
								error={!!errors?.street}
								helperText={
									errors?.street?.message
										? dict[errors.street.message]
											? dict[errors.street.message][lang]
											: errors?.street?.message
										: ' '
								}
							/>
						)}
					/>

					<Controller
						name="city"
						control={control}
						render={({ field: { ref, ...field } }) => (
							<TextField
								{...field}
								inputRef={ref}
								required
								type="text"
								label="Město"
								variant="filled"
								size="medium"
								margin="normal"
								inputProps={{ maxLength: 100 }}
								sx={{ width: { xs: '100%', sm: '48%' } }}
								error={!!errors?.city}
								helperText={
									errors?.city?.message
										? dict[errors.city.message]
											? dict[errors.city.message][lang]
											: errors?.city?.message
										: ' '
								}
							/>
						)}
					/>
				</Box>

				{/* ________ ZIP, COUNTRY _______ */}

				<Box
					sx={{
						display: 'flex',
						justifyContent: { sm: 'space-between' },
						flexDirection: { xs: 'column', sm: 'row' },
						alignItems: 'center',
					}}>
					<Controller
						name="zipCode"
						control={control}
						render={({ field: { ref, ...field } }) => (
							<TextField
								{...field}
								inputRef={ref}
								required
								type="text"
								label="PSČ"
								variant="filled"
								size="medium"
								margin="normal"
								inputProps={{ maxLength: 20 }}
								sx={{ width: { xs: '100%', sm: '48%' } }}
								error={!!errors?.zipCode}
								helperText={
									errors?.zipCode?.message
										? dict[errors.zipCode.message]
											? dict[errors.zipCode.message][lang]
											: errors?.zipCode?.message
										: ' '
								}
							/>
						)}
					/>

					<Controller
						name="country"
						control={control}
						render={({ field: { ref, ...field } }) => (
							<TextField
								{...field}
								inputRef={ref}
								// required
								type="text"
								label="Země"
								variant="filled"
								size="medium"
								margin="normal"
								inputProps={{ maxLength: 100 }}
								sx={{ width: { xs: '100%', sm: '48%' } }}
								error={!!errors?.country}
								helperText={
									errors?.country?.message
										? dict[errors.country.message]
											? dict[errors.country.message][lang]
											: errors?.country?.message
										: ' '
								}
							/>
						)}
					/>
				</Box>

				{/* ________ COMPANY EMAIL, PHONE _______ */}

				<Box
					sx={{
						display: 'flex',
						justifyContent: { sm: 'space-between' },
						flexDirection: { xs: 'column', sm: 'row' },
						alignItems: 'center',
					}}>
					<Controller
						name="companyEmail"
						control={control}
						render={({ field: { ref, ...field } }) => (
							<TextField
								{...field}
								inputRef={ref}
								// required
								type="text"
								label="Firemní email"
								variant="filled"
								size="medium"
								margin="normal"
								inputProps={{ maxLength: 100 }}
								sx={{ width: { xs: '100%', sm: '48%' } }}
								error={!!errors?.companyEmail}
								helperText={
									errors?.companyEmail?.message
										? dict[errors.companyEmail.message]
											? dict[errors.companyEmail.message][lang]
											: errors?.companyEmail?.message
										: ' '
								}
							/>
						)}
					/>
					<Controller
						name="companyPhone"
						control={control}
						render={({ field: { ref, ...field } }) => (
							<TextField
								{...field}
								inputRef={ref}
								// required
								type="text"
								label="Firemní telefon"
								variant="filled"
								size="medium"
								margin="normal"
								inputProps={{ maxLength: 100 }}
								sx={{ width: { xs: '100%', sm: '48%' } }}
								error={!!errors?.companyPhone}
								helperText={
									errors?.companyPhone?.message
										? dict[errors.companyPhone.message]
											? dict[errors.companyPhone.message][lang]
											: errors?.companyPhone?.message
										: ' '
								}
							/>
						)}
					/>
				</Box>

				<Divider sx={{ backgroundColor: theme.palette.info.main, marginY: 1 }} />
				<Typography variant="h6">Kontaktní osoba</Typography>

				{/* ________ FIRST and LAST NAME _______ */}

				<Box
					sx={{
						display: 'flex',
						justifyContent: { sm: 'space-between' },
						flexDirection: { xs: 'column', sm: 'row' },
						alignItems: 'center',
					}}>
					<Controller
						name="firstName"
						control={control}
						render={({ field: { ref, ...field } }) => (
							<TextField
								{...field}
								inputRef={ref}
								// required
								type="text"
								label="Jméno"
								variant="filled"
								size="medium"
								margin="normal"
								inputProps={{ maxLength: 100 }}
								sx={{ width: { xs: '100%', sm: '48%' } }}
								error={!!errors?.firstName}
								helperText={
									errors?.firstName?.message
										? dict[errors.firstName.message]
											? dict[errors.firstName.message][lang]
											: errors?.firstName?.message
										: ' '
								}
							/>
						)}
					/>

					<Controller
						name="lastName"
						control={control}
						render={({ field: { ref, ...field } }) => (
							<TextField
								{...field}
								inputRef={ref}
								// required
								type="text"
								label="Příjmení"
								variant="filled"
								size="medium"
								margin="normal"
								inputProps={{ maxLength: 100 }}
								sx={{ width: { xs: '100%', sm: '48%' } }}
								error={!!errors?.lastName}
								helperText={
									errors?.lastName?.message
										? dict[errors.lastName.message]
											? dict[errors.lastName.message][lang]
											: errors?.lastName?.message
										: ' '
								}
							/>
						)}
					/>
				</Box>

				{/* ________ EMAIL and PHONE _______ */}

				<Box
					sx={{
						display: 'flex',
						justifyContent: { sm: 'space-between' },
						flexDirection: { xs: 'column', sm: 'row' },
						alignItems: 'center',
					}}>
					<Controller
						name="email"
						control={control}
						render={({ field: { ref, ...field } }) => (
							<TextField
								{...field}
								inputRef={ref}
								// required
								type="text"
								label="Email"
								variant="filled"
								size="medium"
								margin="normal"
								inputProps={{ maxLength: 100 }}
								sx={{ width: { xs: '100%', sm: '48%' } }}
								error={!!errors?.email}
								helperText={
									errors?.email?.message
										? dict[errors.email.message]
											? dict[errors.email.message][lang]
											: errors?.email?.message
										: ' '
								}
							/>
						)}
					/>
					<Controller
						name="phone"
						control={control}
						render={({ field: { ref, ...field } }) => (
							<TextField
								{...field}
								inputRef={ref}
								// required
								type="text"
								label="Telefon"
								variant="filled"
								size="medium"
								margin="normal"
								inputProps={{ maxLength: 20 }}
								sx={{ width: { xs: '100%', sm: '48%' } }}
								error={!!errors?.phone}
								helperText={
									errors?.phone?.message
										? dict[errors.phone.message]
											? dict[errors.phone.message][lang]
											: errors?.phone?.message
										: ' '
								}
							/>
						)}
					/>
				</Box>

				{/* ________ NOTE _______ */}

				<Box
					sx={{
						display: 'flex',
						justifyContent: { sm: 'space-between' },
						flexDirection: { xs: 'column', sm: 'column' },
						alignItems: 'center',
					}}>
					<Controller
						name="note"
						control={control}
						render={({ field: { ref, ...field } }) => (
							<TextField
								{...field}
								inputRef={ref}
								// required
								multiline
								rows={4}
								type="text"
								label="Poznámka"
								variant="filled"
								size="medium"
								margin="normal"
								inputProps={{ maxLength: 250 }}
								sx={{ width: { xs: '100%', sm: '100%' } }}
								error={!!errors?.note}
								helperText={
									errors?.note?.message
										? dict[errors.note.message]
											? dict[errors.note.message][lang]
											: errors?.note?.message
										: ' '
								}
							/>
						)}
					/>
				</Box>

				{/* ________ IMAGE _______ */}

				<Divider sx={{ backgroundColor: theme.palette.info.main, marginY: 1 }} />
				<Typography variant="h6">Logo</Typography>

				{editData?.imageUrl && !isDeleted && (
					<Box
						sx={{
							width: '100%',
							height: 103,
							border: 1,
							borderColor: '#aaa',
							backgroundColor: '#eeeeee',
							padding: 8,
							borderRadius: 2,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							marginY: 1,
						}}>
						<img
							src={`${process.env.REACT_APP_BASE_IMAGE_URL}/${editData.imageUrl}`}
							style={{ width: '98px', padding: 0, border: 0 }}
							alt={'logo'}
						/>
					</Box>
				)}

				<Box
					sx={{
						display: 'flex',
						justifyContent: { sm: 'space-between' },
						flexDirection: { xs: 'column', sm: 'column' },
						alignItems: 'left',
						paddingY: 3,
					}}>
					{/*<input accept="image/*" multiple type="file" />*/}

					<Stack direction="row" alignItems="flex-start" spacing={2}>
						{editData?.imageUrl && (
							<Button
								variant="contained"
								component="label"
								color="error"
								onClick={() => editID && handleDeleteConfirm(editID)}>
								Smazat
							</Button>
						)}
						<Button variant="contained" component="label">
							Vyberte logo (*.png *.jpg)
							<input
								name={'logo'}
								hidden
								accept="image/*"
								// multiple
								type="file"
								onChange={onImageChange}
							/>
						</Button>

						{logoUrl && (
							<Box
								sx={{
									width: 50,
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}>
								<IconButton
									onClick={onImageChangeDelete}
									color="error"
									size="small"
									sx={{ border: 1, borderRadius: 1, p: 0.5, marginLeft: 1 }}>
									<img src={logoUrl} style={{ width: 50 }} />
									<ClearOutlinedIcon fontSize="small" />
								</IconButton>
							</Box>
						)}
					</Stack>
				</Box>

				{/* <Divider sx={{ backgroundColor: theme.palette.info.main, marginY: 1 }} /> */}

				{isLoadingForm && (
					<Box
						sx={{
							marginY: 1,
							width: '100%',
						}}>
						<LinearProgress color="info" />
					</Box>
				)}

				<Stack sx={{ width: '100%', marginTop: 2 }} spacing={3}>
					{masterError && (
						<Alert sx={{ borderRadius: '0.5em' }} severity="error">
							{dict[masterError] ? dict[masterError][lang] : masterError ? masterError : ' '}
						</Alert>
					)}
				</Stack>

				<Stack direction="row" justifyContent="center" alignItems="center" spacing={2} mt={3}>
					<Button variant="contained" size="large" color="inherit" onClick={onClose}>
						zrušit
					</Button>

					<Button variant="contained" type="submit" size="large">
						{`${editID ? 'Editovat' : 'Vytvořit'}`}
						<Box component={'span'} sx={{ display: { xs: 'none', sm: 'inline' } }}>
							&nbsp;klienta
						</Box>
					</Button>
				</Stack>
			</form>
		</>
	);
};

export default ClientForm;
