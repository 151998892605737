import React, { ReactNode, useCallback, useContext, useMemo, useState } from 'react';

import customFetcher from '../../utils/fetchInstance';

import { ContextData, ContextState } from './types';

type Props = {
	children: ReactNode;
};

const CarsContext = React.createContext<ContextData>(null as any);

const initialState: ContextState = {
	page: 0,
	isInit: false,
	isLoading: false,
	isLoadingCat: false,
	filterCategory: '',
	cars: null,
	carsCategory: null,
	filterText: '',
};

const CarsProvider: React.FC<Props> = ({ children }) => {
	const [state, setState] = useState(initialState);

	const getCars = useCallback(async (filterCategory: string, filterText: string) => {
		try {
			const { data } = await customFetcher(
				`/dials/cars?category=${encodeURI(filterCategory)}&search=${encodeURI(filterText)}`
			);
			const { cars } = data;
			setState(prevState => {
				return { ...prevState, cars };
			});
		} catch (error) {
			console.log(error);
		}
	}, []);

	const getCarsCategory = useCallback(async () => {
		try {
			const { data } = await customFetcher(`/dials/cars/category`);
			const { carsCategory } = data;
			setState(prevState => {
				return { ...prevState, carsCategory };
			});
		} catch (error) {
			console.log(error);
		}
	}, []);

	const setInitCars = useCallback(async () => {
		setState(prevState => {
			return {
				...prevState,
				isInit: true,
				isLoading: true,
				page: 0,
				filterStatus: '',
				filterText: '',
			};
		});
		await getCars('', '');
		await getCarsCategory();

		setState(prevState => {
			return { ...prevState, isLoading: false };
		});
	}, [getCars, getCarsCategory]);

	const removeIsInit = useCallback(async () => {
		setState({ ...state, isInit: false });
	}, [state]);

	const setPage = useCallback(
		(page: number): void => {
			setState({ ...state, page });
		},
		[state]
	);

	const setFilterCategory = useCallback(
		async (filterCategory: string) => {
			setState(prevState => {
				return { ...prevState, isLoading: true, page: 0, filterCategory };
			});

			await getCars(filterCategory, state.filterText);

			setState(prevState => {
				return { ...prevState, isLoading: false };
			});
		},
		[getCars, state.filterText]
	);

	const setFilterText = useCallback(
		async (filterText: string) => {
			setState(prevState => {
				return { ...prevState, isLoading: true, page: 0, filterText };
			});

			await getCars(state.filterCategory, filterText);

			setState(prevState => {
				return { ...prevState, isLoading: false };
			});
		},
		[getCars, state.filterCategory]
	);

	const refetchCars = useCallback(async () => {
		setState(prevState => {
			return { ...prevState, isLoading: true };
		});

		await getCars(state.filterCategory, state.filterText);

		setState(prevState => {
			return { ...prevState, isLoading: false };
		});
	}, [getCars, state.filterCategory, state.filterText]);

	const value = useMemo(
		(): ContextData => ({
			state,
			setInitCars,
			removeIsInit,
			setFilterCategory,
			setPage,
			refetchCars,
			setFilterText,
		}),
		[state, setInitCars, removeIsInit, setFilterCategory, setPage, refetchCars, setFilterText]
	);
	return <CarsContext.Provider value={value}>{children}</CarsContext.Provider>;
};

const useCarsContext = () => {
	return useContext(CarsContext);
};

export { CarsProvider, useCarsContext };
