import { Box, Typography } from '@mui/material';
import React from 'react';
import TableView from '../../../../../components/pageContent/TableView/TableView';
import InfoRecords from '../../../../../components/uiComponents/InfoRecords';
import { ReportToDriverDB } from '../../../types';
import DataTableRows from './DataTableRows';
import TitleTableRow from './TitleTableRow';

type Props = {
	reportsDriver: ReportToDriverDB[] | null;
	onEdit: (id: number) => void;
	onDeleteConfirm: (id: number, name: string) => void;
};

const ShowReportDriver = ({ reportsDriver, onEdit, onDeleteConfirm }: Props) => {
	const records = reportsDriver?.length;
	return (
		<>
			<Box sx={{ marginY: 3 }}>
				<InfoRecords records={records} />

				{reportsDriver &&
					reportsDriver.map(report => {
						return (
							<React.Fragment key={report.id}>
								<TableView
									titleRow={<TitleTableRow report={report} />}
									dataRow={
										<DataTableRows
											report={report}
											onEdit={onEdit}
											onDeleteConfirm={onDeleteConfirm}
										/>
									}
									colspan={8}
								/>
							</React.Fragment>
						);
					})}
			</Box>
		</>
	);
};
export default ShowReportDriver;
