import React, { useCallback, useState } from 'react';
import { debounce } from 'lodash';
import { useCarsContext } from '../../../context/carsContext/CarsContext';
import { CarCategoryDB } from '../types';

import {
	Box,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	SelectChangeEvent,
	TextField,
	InputAdornment,
	IconButton,
} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const FilterCar = () => {
	const { state, setFilterCategory, setFilterText } = useCarsContext();

	const [search, setSearch] = useState(state.filterText);

	const handleFilterCategoryChange = (event: SelectChangeEvent) => {
		setFilterCategory(event.target.value);
	};

	const handleFilterTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearch(event.target.value);
		debouncedFilterTextChange(event.target.value);
	};

	const debouncedFilterTextChange = useCallback(
		debounce(criteria => {
			setFilterText(criteria);
		}, 500),
		[]
	);

	return (
		<Box
			component="div"
			sx={{ background: 'white', paddingX: 3, paddingY: 2, marginTop: 2, marginBottom: 2, borderRadius: 2 }}>
			<FormControl variant="standard" sx={{ maxWidth: { sx: '100%', sm: 300 }, width: '100%' }}>
				<InputLabel id="categoryCar">Kategorie</InputLabel>
				<Select
					labelId="categoryCar"
					value={state.filterCategory}
					onChange={handleFilterCategoryChange}
					label="Kategorie">
					<MenuItem value="">žádný filtr</MenuItem>
					{state.carsCategory &&
						state.carsCategory.map((item: CarCategoryDB) => {
							return (
								<MenuItem key={item.id} value={item.id}>
									{item.name}
								</MenuItem>
							);
						})}
				</Select>
			</FormControl>

			<Box sx={{ marginY: 2 }}>
				<TextField
					id="fulltext"
					label="Hledání auta nebo SPZ"
					variant="standard"
					value={search}
					onChange={handleFilterTextChange}
					fullWidth
					InputProps={{
						endAdornment: search && (
							<InputAdornment position="end">
								<IconButton
									onClick={() => {
										setSearch('');
										setFilterText('');
									}}
									edge="end">
									<CloseOutlinedIcon />
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			</Box>
		</Box>
	);
};

export default FilterCar;
