import React, { useEffect } from 'react';
import { useAppContext } from '../../context/appContext/appContext';

type Props = {};

const Logout = (props: Props) => {
	const { logout } = useAppContext();
	useEffect(() => {
		logout();
	});
	return <h1>Logout...</h1>;
};

export default Logout;
