import { Box } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { relative } from 'path';

type Props = {
	isLoading: boolean;
};

const Loading = ({ isLoading }: Props) => {
	return (
		<Backdrop
			sx={{
				top: 0,
				left: 0,

				width: '100%',

				borderRadius: 2,
				backgroundColor: 'rgba(0,0,0,0.1)',
				position: 'absolute',
				zIndex: theme => theme.zIndex.drawer + 1,
			}}
			open={isLoading}>
			<CircularProgress color="inherit" />
		</Backdrop>
	);
};

export default Loading;
