import { useCallback, useEffect, useState } from 'react';
import customFetcher from './fetchInstance';

export const useUpdate = <T,>(url: string) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [data, setData] = useState<T | null>(null);
	const [error, setError] = useState<any>(null);

	const update = useCallback(
		async (payload: any, method: string = 'GET', newUrl: string = url): Promise<T | undefined> => {
			try {
				setIsLoading(true);
				const { data, response } = await customFetcher(newUrl, method, payload);
				// console.log(data);

				if (response.ok) {
					setData(data);
				}
				return data;
			} catch (error) {
				console.log('Err: ', error);
				setError(error);
				return undefined;
			} finally {
				setIsLoading(false);
			}
		},
		[url]
	);

	return {
		isLoading,
		update,
		data,
		error,
	};
};
