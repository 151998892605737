import { debounce } from 'lodash';
import React, { useCallback, useState } from 'react';

import {
	Box,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	SelectChangeEvent,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Radio,
	TextField,
	InputAdornment,
	IconButton,
} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useUsersContext } from '../../../context/usersContext/UsersContext';

const FilterUser = () => {
	const { state, setFilterText, setFilterStatus } = useUsersContext();

	const [search, setSearch] = useState(state.filterText);

	const handleFilterStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFilterStatus(event.target.value);
	};

	const handleFilterTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearch(event.target.value);
		debouncedFilterTextChange(event.target.value);
	};

	const debouncedFilterTextChange = useCallback(
		debounce(criteria => {
			setFilterText(criteria);
		}, 500),
		[]
	);

	React.useEffect(() => {
		return () => {
			debouncedFilterTextChange.cancel();
		};
	}, [debouncedFilterTextChange]);

	return (
		<Box
			component="div"
			sx={{ background: 'white', paddingX: 3, paddingY: 2, marginTop: 2, marginBottom: 2, borderRadius: 2 }}>
			<FormControl>
				<FormLabel id="filter-status">Uživatelé</FormLabel>
				<RadioGroup
					row
					aria-labelledby="filter-status"
					defaultValue={''}
					name="filter-status"
					value={state.filterStatus}
					onChange={handleFilterStatusChange}>
					<FormControlLabel value="" control={<Radio />} label="všichni" />
					<FormControlLabel value="active" control={<Radio />} label="aktivní" />
					<FormControlLabel value="inactive" control={<Radio />} label="neaktivní" />
				</RadioGroup>
			</FormControl>
			<Box sx={{ marginY: 2 }}>
				<TextField
					id="fulltext"
					label="Hledání uživatele"
					variant="standard"
					value={search}
					onChange={handleFilterTextChange}
					fullWidth
					InputProps={{
						endAdornment: search && (
							<InputAdornment position="end">
								<IconButton
									onClick={() => {
										setSearch('');
										setFilterText('');
									}}
									edge="end">
									<CloseOutlinedIcon />
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			</Box>
		</Box>
	);
};

export default FilterUser;
