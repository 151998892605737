import { TableRow, Typography, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import locale from 'dayjs/locale/cs';

import TableCellViewTitle from '../../../../../components/pageContent/TableView/TableCellViewTitle';
import { useAppContext } from '../../../../../context/appContext/appContext';
import { ReportToDriverDB, ReportToWorkerDB } from '../../types';

type Props = {
	report: ReportToDriverDB;
};

const TitleTableRow = ({ report }: Props) => {
	const { state } = useAppContext();

	const reportNumber = report.reportNumber * 1;

	return (
		<TableRow>
			<TableCellViewTitle align="left" w={'auto'} colspan={2}>
				<>
					<Typography sx={{ fontWeight: 'bold' }} component="span" variant="body2">
						Datum:
					</Typography>

					<Typography sx={{ fontWeight: 'bold', marginLeft: 2 }} component="span" variant="subtitle1">
						{dayjs(report.date)
							.locale({ ...locale })
							.format('DD.MM.YYYY, dddd')}
					</Typography>
				</>
			</TableCellViewTitle>

			{report.status === 'closed' && reportNumber > 0 && (
				<TableCellViewTitle align="right" w={10} bgr="#398943">
					<Typography color={'white'} component="span" variant="button">
						{report.reportNumber}
					</Typography>
				</TableCellViewTitle>
			)}
		</TableRow>

		// 	<TableRow>

		// 	<TableCellViewTitle align="right" colspan={5}>
		// 			{`${report.car.name}, SPZ: ${report.car.spz}`}
		// 		</TableCellViewTitle>
		// 	</TableRow>
	);
};

export default TitleTableRow;
