import { AlertColor } from '@mui/material';

import React, { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useLocation, useNavigate, useParams, useSearchParams, createSearchParams } from 'react-router-dom';
import FormModal from '../../../../components/modal/FormModal';
import Loading from '../../../../components/pageContent/Loading';
import DeleteDialog from '../../../../components/pageContent/TableView/DeleteDialog';

import SnackbarAfterAction from '../../../../components/uiComponents/SnackbarAfterAction';
import { useAppContext } from '../../../../context/appContext/appContext';
import { useData } from '../../../../utils/useData';
import { useUpdate } from '../../../../utils/useUpdate';

import FormReportCar from '../driver/FormReportCar';

import { ContractDB, ReportCarDetail, ReportContractCarFormInputs, ReportToDriverDB } from '../../types';
import AddReport from '../driver/AddReport';
import ShowReportDriver from './dataTable/ShowReportDriver';
import FilterReport from '../../../../components/pageContent/FilterReport';

type Props = {};

const MyReportDriver = (props: Props) => {
	const [openModal, setOpenModal] = useState<boolean>(false);

	const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
	const [msgSnackbar, setMsgSnackbar] = useState<string>('');
	const [severitySnackbar, setSeveritySnackbar] = useState<AlertColor>('success');

	const [editID, setEditID] = useState<number | null>(null);
	const [editData, setEditData] = useState<any | null>(null);

	const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
	const [idDelete, setIdDelete] = React.useState<number | null>(null);
	const [nameDelete, setNameDelete] = React.useState<string>('');

	const { contractId } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();

	// ____________ ZISKANI PARAMETRU Z URL ______________________
	const filterStatus = searchParams.get('filterStatus')! ? searchParams.get('filterStatus')! : '';

	const dateFrom = searchParams.get('dateFrom')!
		? dayjs(searchParams.get('dateFrom')!)
		: dayjs(new Date().setDate(new Date().getDate() - 30));
	const dateTo = searchParams.get('dateTo')!
		? dayjs(searchParams.get('dateTo')!)
		: dayjs(new Date().setDate(new Date().getDate()));

	const navigate = useNavigate();
	const location = useLocation();

	const { state } = useAppContext();

	// ____________ SKLADANI PARAMETRU PRO DOTAZ DO DB ______________________
	const params: string[] = [];

	dateFrom ? params.push(`dateFrom=${dateFrom.format('YYYY-MM-DD')}`) : params.push(`dateFrom=`);
	dateTo ? params.push(`dateTo=${dateTo.format('YYYY-MM-DD')}`) : params.push(`dateTo=`);
	filterStatus ? params.push(`status=${filterStatus}`) : params.push(`status=`);

	// ____________ USE DATA - načtení CONTRACT ______________________
	const {
		isLoading,
		data: contract,
		refetch,
		error: contractError,
	} = useData<ContractDB, { contract: ContractDB }>(`/my-contracts/${contractId}`, data => {
		return data.contract;
	});

	// ____________ USE DATA - NACTENI REPORTU PRO RIDICE  ______________________
	const {
		isLoading: isLoadingReport,
		data: reportsDriver,
		refetch: refetchReportsDriver,
	} = useData<ReportToDriverDB[], { reportsDriver: ReportToDriverDB[] }>(
		`/my-contracts/report-driver/${contractId}/${state.user?.id}?${params.join('&')}`,
		data => data.reportsDriver
	);

	// ____________ USE UPDATE - ostatni metody ______________________
	const {
		isLoading: isUpdateLoading,
		data: dataEditing,
		update: updateReport,
		error: updateError,
	} = useUpdate<{ status: string; error?: string; reportCarDetail?: ReportCarDetail }>('');

	// ____________ HANDLE FILTER DATE ______________________
	const handleFilterDate = (dateFromForm: Dayjs, dateToForm: Dayjs) => {
		if (dateFromForm.diff(dateToForm, 'second') > 0) {
			dateToForm = dateFromForm;
		}

		const dateFromSend = dateFromForm ? dateFromForm.format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD');
		const dateToSend = dateToForm ? dateToForm.format('YYYY-MM-DD') : dateFromForm.format('YYYY-MM-DD');

		navigate({
			pathname: location.pathname,
			search: createSearchParams({
				filterStatus: filterStatus,
				dateFrom: dateFromSend,
				dateTo: dateToSend,
			}).toString(),
		});
	};

	// ____________ HANDLE FILTER STATUS ______________________
	const handleFilterStatus = (filterStatus: string) => {
		navigate({
			pathname: location.pathname,
			search: createSearchParams({
				filterStatus: filterStatus,
				dateFrom: dateFrom.format('YYYY-MM-DD'),
				dateTo: dateTo.format('YYYY-MM-DD'),
			}).toString(),
		});
	};

	// ____________ SET ADD ______________________
	const handleAdd = async (): Promise<void> => {
		const response = await updateReport(null, 'GET', `/contracts/contract-is-active/${contractId}`);

		if (response?.status === 'error') {
			if (response.error === 'INACTIVE') {
				navigate(0);
				return;
			}
		}

		setOpenModal(true);
		setEditID(null);
	};

	// ____________ SET EDIT ______________________
	const handleEdit = async (id: number): Promise<void> => {
		const response = await updateReport(null, 'GET', `/my-contracts/report-car/detail/${id}`);
		if (!response) {
			return;
		}

		if (response?.status === 'error') {
			if (response.error === 'Výkaz již nelze editovat.') {
				refetchReportsDriver();
				return;
			}
		}

		if (response?.status === 'success') {
			if (response.reportCarDetail) setEditData(response.reportCarDetail);
		}

		setOpenModal(true);
		setEditID(id);
	};

	// ____________ DELETE ______________________
	const handleDelete = async (): Promise<void> => {
		const response = await updateReport(null, 'DELETE', `/my-contracts/report-car/${idDelete}`);
		if (!response) {
			return;
		}
		if (response.status === 'error') {
			if (response.error === 'Výkaz již nelze smazat.') {
				setMsgSnackbar(`Výkaz již nelze smazat.`);
				setSeveritySnackbar('error');
				setOpenSnackbar(true);
			}
			if (response.error === 'RECORD_CANNOT_BE_DELETED') {
				setMsgSnackbar(`"${nameDelete}" nelze smazat!`);
				setSeveritySnackbar('error');
				setOpenSnackbar(true);
			}
		} else {
			setMsgSnackbar(`Smazáno: "${nameDelete}"`);
			setSeveritySnackbar('success');
			setOpenSnackbar(true);
		}
		refetchReportsDriver();
		setIdDelete(null);
		setNameDelete('');
		handleCloseDeleteDialog();
	};

	// ____________ SUBMIT FORM MODAL ______________________
	const handleFormSubmit = async (data: ReportContractCarFormInputs) => {
		console.log(data);

		if (editID) {
			const response = await updateReport(
				{
					contractId: data.contractId,
					carId: data.carId,
					userId: data.userId,
					date: data.date,
					timeFrom: data.timeFrom,
					timeTo: data.timeTo,
					workedHours: data.workedHours,
					driveKm: data.driveKm,
					description: data.description,
					costs: data.costs,
					descriptionCosts: data.descriptionCosts,
					costsDriver: data.costsDriver,
					descriptionCostsDriver: data.descriptionCostsDriver,
				},
				'PATCH',
				`/my-contracts/report-car/${editID}`
			);
			if (!response) {
				return 'ERROR_NETWORK';
			}
			if (response?.status === 'error') {
				return response.error;
			}
		} else {
			const response = await updateReport(
				{
					contractId: data.contractId,
					carId: data.carId,
					userId: data.userId,
					date: data.date,
					timeFrom: data.timeFrom,
					timeTo: data.timeTo,
					workedHours: data.workedHours,
					driveKm: data.driveKm,
					description: data.description,
					costs: data.costs,
					descriptionCosts: data.descriptionCosts,
					costsDriver: data.costsDriver,
					descriptionCostsDriver: data.descriptionCostsDriver,
				},
				'POST',
				`/my-contracts/report-car`
			);

			if (response?.status === 'error') {
				return response.error;
			}

			if (!response) {
				return 'ERROR_NETWORK';
			}
		}

		setOpenModal(false);

		refetchReportsDriver();

		setMsgSnackbar(editData ? `Výkaz byl editován.` : `Výkaz byl přidán.`);
		setSeveritySnackbar('success');
		setOpenSnackbar(true);

		setEditData(null);
		setEditID(null);

		return undefined;
	};

	// ____________ DELETE CONFIRM ______________________
	const handleDeleteConfirm = (id: number, name: string) => {
		setIdDelete(id);
		setNameDelete(name);
		setOpenDeleteDialog(true);
	};

	// ____________ DELETE CLOSE DIALOG ______________________
	const handleCloseDeleteDialog = () => {
		setIdDelete(null);
		setOpenDeleteDialog(false);
	};

	// ____________ CLOSE MODAL ______________________
	const handleCloseModal = (): void => {
		setOpenModal(false);
		setEditData(null);
		setEditID(null);
		refetchReportsDriver();
	};

	// ____________ SNACKBAR ______________________
	const handleCloseSnackbar = () => {
		setOpenSnackbar(false);
	};

	return (
		<>
			<Loading isLoading={isLoading || isUpdateLoading} />

			<SnackbarAfterAction
				severity={severitySnackbar}
				openSnackbar={openSnackbar}
				onClose={handleCloseSnackbar}
				msgSnackbar={msgSnackbar}
			/>

			<DeleteDialog
				onOpen={openDeleteDialog}
				handleClose={handleCloseDeleteDialog}
				onDelete={handleDelete}
				nameDelete={nameDelete}
			/>

			<FormModal
				modalTitle={`${editID ? 'Editace' : 'Vytvoření'} výkazu`}
				openModal={openModal}
				onClose={handleCloseModal}>
				<FormReportCar
					editID={editID}
					editData={editData}
					onFormSubmit={handleFormSubmit}
					onClose={handleCloseModal}
				/>
			</FormModal>

			{contract?.status === 'active' && <AddReport onAdd={handleAdd} />}

			<FilterReport
				handleFilterDate={handleFilterDate}
				handleFilterStatus={handleFilterStatus}
				dateFrom={dateFrom}
				dateTo={dateTo}
				filterStatus={filterStatus}
				isMyContract={true}
			/>

			<ShowReportDriver reportsDriver={reportsDriver} onEdit={handleEdit} onDeleteConfirm={handleDeleteConfirm} />
		</>
	);
};

export default MyReportDriver;
