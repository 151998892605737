import React, { useEffect, useState } from 'react';
import { TableBody, TableRow, TableCell, IconButton, useTheme } from '@mui/material';

import { useAppContext } from '../../../../context/appContext/appContext';
import { CarCategoryDB, CarDB } from '../../../cars/types';

import TableCellView from '../../../../components/pageContent/TableView/TableCellView';
import TableCellEditIcon from '../../../../components/pageContent/TableView/TableCellEditIcon';
import TableCellDeleteIcon from '../../../../components/pageContent/TableView/TableCellDeleteIcon';
import TableCellIsActiveIcon from '../../../../components/pageContent/TableView/TableCellIsActiveIcon';
import { ContractCarDB } from '../types';

type Props = {
	status: string | undefined;
	contractCars: ContractCarDB[] | null;
	onEdit: (id: number) => void;
	onDeleteConfirm: (id: number, name: string) => void;
};

const DataTableRows = ({ contractCars, onEdit, onDeleteConfirm, status }: Props) => {
	const theme = useTheme();
	const { state } = useAppContext();
	const colspan = 7;

	return (
		<>
			{!contractCars?.length && (
				<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
					<TableCellView align="left" w="auto" colspan={colspan}>
						Nebyl nalezen žádný záznam.
					</TableCellView>
				</TableRow>
			)}

			{contractCars &&
				contractCars.map((conCar: ContractCarDB, index) => {
					return (
						<TableRow hover key={conCar.id}>
							<TableCellView align="center" w={50} isActive={conCar.car.isActive}>
								{index + 1}
							</TableCellView>

							<TableCellView align="left" mW={150} w="auto" isActive={conCar.car.isActive}>
								{conCar.car.name}
							</TableCellView>

							<TableCellView align="left" mW={150} w="auto" isActive={conCar.car.isActive}>
								{conCar.car.spz}
							</TableCellView>

							<TableCellView align="right" mW={100} w="auto" isActive={conCar.car.isActive}>
								{Math.floor(conCar.dailyRate) > 0
									? `${Math.floor(conCar.dailyRate).toLocaleString()} Kč`
									: ' '}
								{/* <>{Math.floor(conCar.dailyRate).toLocaleString()} Kč</> */}
							</TableCellView>

							<TableCellView align="right" mW={100} w="auto" isActive={conCar.car.isActive}>
								{Math.floor(conCar.includedKm) > 0
									? `${Math.floor(conCar.includedKm).toLocaleString()} km`
									: ' '}
								{/* <>{Math.floor(conCar.includedKm).toLocaleString()} km</> */}
							</TableCellView>

							<TableCellView align="right" mW={100} w="auto" isActive={conCar.car.isActive}>
								{conCar.ratePerKm * 1 > 0 ? `${(conCar.ratePerKm * 1).toFixed(1)} Kč` : ' '}
								{/* <>{(conCar.ratePerKm * 1).toFixed(1)} Kč</> */}
							</TableCellView>

							{status === 'closed' && (
								<>
									<TableCellView align="center" w={30}>
										{' '}
									</TableCellView>
									<TableCellView align="center" w={30}>
										{' '}
									</TableCellView>
								</>
							)}
							{status !== 'closed' && (
								<>
									<TableCellEditIcon onEdit={onEdit} idEdit={conCar.id} />

									<TableCellDeleteIcon
										onDeleteConfirm={onDeleteConfirm}
										idDelete={conCar.id}
										nameDelete={conCar.car.name}
									/>
								</>
							)}

							<TableCellView
								align="right"
								w={state.user?.role === 'admin' ? 40 : '5px'}
								pX={state.user?.role === 'admin' ? undefined : '5px'}
								isActive={conCar.car.isActive}>
								{state.user?.role === 'admin' ? conCar.id : ''}
							</TableCellView>
						</TableRow>
					);
				})}
		</>
	);
};

export default DataTableRows;
