import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams, Outlet, useSearchParams } from 'react-router-dom';

import { AlertColor, Box } from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

import { useAppContext } from '../../../context/appContext/appContext';

import { useMyContractsContext } from '../../../context/myContractsContext/MyContractsContext';
import { useData } from '../../../utils/useData';

import Loading from '../../../components/pageContent/Loading';
import PageTitle from '../../../components/pageContent/PageTitle';
import PermissionAlert from '../../../components/pageContent/PermissionAlert';
import SnackbarAfterAction from '../../../components/uiComponents/SnackbarAfterAction';

import { tabsName } from './tabsName';
import PageSubtitle from '../../../components/pageContent/PageSubtitle';
import PageTabNavigator from '../../../components/pageContent/PageTabNavigator';
import dayjs from 'dayjs';
import { ContractDB } from '../types';
import ContractPermissionAlert from '../../../components/pageContent/ContractPermissionAlert';

type Props = {};

const MyContractReport = (props: Props) => {
	const [tabValue, setTabValue] = useState<number>(0);
	const [error, setError] = useState<string>('');

	const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
	const [msgSnackbar, setMsgSnackbar] = useState<string>('');
	const [severitySnackbar, setSeveritySnackbar] = useState<AlertColor>('success');

	const { contractId } = useParams();
	const { state } = useAppContext();
	const { state: stateMyContract } = useMyContractsContext();
	const navigate = useNavigate();
	const location = useLocation();

	const [searchParams, setSearchParams] = useSearchParams();

	// ____________ ZISKANI PARAMETRU Z URL ______________________
	// const carId = searchParams.get('carId')! ? searchParams.get('carId')! : '';
	// const userId = searchParams.get('userId')! ? searchParams.get('userId')! : '';
	const filterStatus = searchParams.get('filterStatus')! ? searchParams.get('filterStatus')! : '';

	const dateFrom = searchParams.get('dateFrom')!
		? searchParams.get('dateFrom')!
		: dayjs(new Date().setDate(new Date().getDate() - 30)).format('YYYY-MM-DD');
	const dateTo = searchParams.get('dateTo')!
		? searchParams.get('dateTo')!
		: dayjs(new Date().setDate(new Date().getDate())).format('YYYY-MM-DD');

	const baseURL = `/my-contracts/report/${contractId}`;

	// ____________ osetreni, ze existuje contractId, pokud ne, vrati na vyber ______________________

	useEffect(() => {
		contractId && isNaN(parseInt(contractId)) && navigate(`/my-contracts`);
	}, [contractId, navigate]);

	// ____________ USE DATA - načtení zakazky ______________________
	const {
		isLoading,
		data: contract,
		refetch,
		error: contractError,
	} = useData<ContractDB, { contract: ContractDB }>(`/my-contracts/${contractId}`, data => {
		return data.contract;
	});

	// ____________ BUTTON ZPET NA PREHLED ______________________
	const handleHeaderButton = () => {
		navigate(`/my-contracts`);
	};

	const handleChangeTabNavigator = (event: React.SyntheticEvent, newValue: number) => {
		setTabValue(newValue);
	};

	// ____________ SNACKBAR ______________________
	const handleCloseSnackbar = () => {
		setOpenSnackbar(false);
	};

	if (isLoading) {
		return (
			<>
				<Loading isLoading={isLoading} />
			</>
		);
	}
	if (!contract) {
		return (
			<>
				<ContractPermissionAlert
					backUrl="/my-contracts"
					title="Tato zakázka je již uzavřena, nebo nemáte oprávnění vkládat report!"
				/>
			</>
		);
	}

	return (
		<>
			<Loading isLoading={isLoading} />

			<SnackbarAfterAction
				severity={severitySnackbar}
				openSnackbar={openSnackbar}
				onClose={handleCloseSnackbar}
				msgSnackbar={msgSnackbar}
			/>

			<PageTitle
				title={`Výkazy k zakázce`}
				buttonText="zpět na přehled"
				onClickHeaderButton={handleHeaderButton}
				icon={<ArrowBackOutlinedIcon />}
			/>

			{contract && (
				<PageSubtitle
					status={contract.status ? contract.status : ''}
					title={contract.name ? contract.name : ''}
					client={contract.client?.companyName}
				/>
			)}

			<Box mb={2} sx={{ width: '100%', padding: '0.3em 0em 0em 0em', backgroundColor: 'white', borderRadius: 2 }}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider', paddingX: 2 }}>
					<PageTabNavigator
						baseURL={baseURL}
						tabs={tabsName}
						filterStatus={filterStatus}
						dateFrom={dateFrom}
						dateTo={dateTo}
					/>
				</Box>
				<Box sx={{ paddingX: 2, paddingY: 3 }}>
					<Outlet />
				</Box>
			</Box>
		</>
	);
};

export default MyContractReport;
