import { Backdrop, CircularProgress, useTheme } from '@mui/material';

import { Navigate, Outlet } from 'react-router-dom';
import { useAppContext } from '../context/appContext/appContext';

type Props = {
	children: JSX.Element;
};

const ProtectedRoutes = (props: Props) => {
	const { state } = useAppContext();
	const { user, userLoading } = state;
	const theme = useTheme();

	if (userLoading) {
		return (
			<>
				<Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={state.userLoading}>
					<CircularProgress color="inherit" size={40} />
				</Backdrop>
			</>
		);
	}

	return user ? props.children : <Navigate to="/login" />;
};

export default ProtectedRoutes;
