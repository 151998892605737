import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Link, useLocation } from 'react-router-dom';

export interface IPageTabNavigator {
	tabs: {
		url: string;
		label: string;
	}[];
	baseURL?: string;
	userId?: string;
	carId?: string;
	filterStatus?: string;
	dateFrom?: string;
	dateTo?: string;
}

const PageTabNavigator = ({ tabs, baseURL, userId, carId, dateFrom, dateTo, filterStatus }: IPageTabNavigator) => {
	const location = useLocation();

	const params: string[] = [];

	carId && params.push(`carId=${carId}`);
	userId && params.push(`userId=${userId}`);
	filterStatus && params.push(`filterStatus=${filterStatus}`);
	dateFrom && params.push(`dateFrom=${dateFrom}`);
	dateTo && params.push(`dateTo=${dateTo}`);

	return (
		<Box sx={{ maxWidth: { xs: 300, sm: 480 }, width: '100%', padding: '0.3em 0em 0em 0em' }}>
			<Tabs value={location.pathname} aria-label="nav tabs example" variant="scrollable">
				{tabs.map((tab, index) => (
					<Tab
						key={index}
						component={Link}
						label={tab.label}
						value={`${baseURL}${tab.url}`}
						to={`${baseURL}${tab.url}${params.length > 0 ? `?${params.join('&')}` : ''}`}
					/>
				))}
			</Tabs>
		</Box>
	);
};

export default PageTabNavigator;
