import { TableRow } from '@mui/material';
import TableCellViewTitle from '../../../../components/pageContent/TableView/TableCellViewTitle';
import { useAppContext } from '../../../../context/appContext/appContext';

type Props = {};

const TitleTableRow = (props: Props) => {
	const { state } = useAppContext();

	return (
		<TableRow>
			<TableCellViewTitle align="center" w={50}>
				#
			</TableCellViewTitle>

			<TableCellViewTitle align="center" w={30}>
				{' '}
			</TableCellViewTitle>

			<TableCellViewTitle align="left" w={'auto'}>
				Pracovník
			</TableCellViewTitle>

			<TableCellViewTitle align="left" w={'auto'}>
				Telefon
			</TableCellViewTitle>

			<TableCellViewTitle align="left" w={'auto'}>
				Email
			</TableCellViewTitle>

			<TableCellViewTitle align="center" w={30}>
				{' '}
			</TableCellViewTitle>
		</TableRow>
	);
};

export default TitleTableRow;
