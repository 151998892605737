import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { emailSchema, passwordSchema, loginSchema } from '../../utils/joiSchema';
import { Link } from 'react-router-dom';

import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';

import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CommonButton from '../../components/uiComponents/CommonButton';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { dict, dictKey } from '../../translate/translate-dictionary';
import { useAppContext } from '../../context/appContext/appContext';
import { useNavigate } from 'react-router-dom';
import { Backdrop, Button, CircularProgress, Snackbar } from '@mui/material';

const theme = createTheme();

type Inputs = {
	email: string;
	password: string;
};

const Login = () => {
	const [masterError, setMasterError] = useState<string>('');
	const { state, loginUser } = useAppContext();
	const navigate = useNavigate();
	const lang = state.lang;

	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm<Inputs>({
		defaultValues: {
			email: '',
			password: '',
		},
		resolver: joiResolver(loginSchema),
		mode: 'onChange',
		delayError: 2000,
	});

	const onSubmit = (data: Inputs) => {
		setMasterError('');

		(async () => {
			const response: any = await loginUser(data);

			if (!response) {
				setMasterError('ERROR_NETWORK');
			}

			if (response) {
				setMasterError(response);
			}
		})();
	};

	// pokud je uzivatel prihlasen presmeruji ho
	useEffect(() => {
		if (state.user) {
			navigate('/');
		}
	}, [state, navigate]);

	return (
		<Box
			sx={{
				borderRadius: '1em',
				marginX: '10px',
				backgroundColor: 'white',
				width: '100%',
				maxWidth: 450,
			}}>
			{/* <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={state.isLoading}>
					<CircularProgress color="inherit" />
				</Backdrop> */}

			<Container component="main" maxWidth="xs">
				<Box
					sx={{
						mt: 2,
						pt: 0,
						padding: 5,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						backgroundColor: 'white',
					}}>
					<Avatar sx={{ m: 2, mt: 0, bgcolor: 'secondary.main' }}>
						<LockOutlinedIcon />
					</Avatar>
					<Typography component="h1" variant="h5" sx={{ textAlign: 'center' }}>
						Just Easy - přihlášení
					</Typography>
					<Typography component="h2" variant="subtitle1" sx={{ textAlign: 'center' }}>
						management zakázek
					</Typography>

					{/* noValidate */}
					<Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
						<TextField
							{...register('email')}
							required
							margin="normal"
							fullWidth
							label="Email"
							type="email"
							autoComplete="email"
							autoFocus
							error={!!errors?.email}
							helperText={errors?.email?.message && dict[errors.email.message][lang]}
						/>

						<TextField
							{...register('password')}
							required
							margin="normal"
							fullWidth
							label="Heslo"
							type="password"
							autoComplete="current-password"
							error={Object.hasOwn(errors, 'password')}
							helperText={errors?.password?.message && dict[errors.password.message][lang]}
						/>

						<Stack sx={{ width: '100%', marginTop: 2 }} spacing={3}>
							{masterError && (
								<Alert sx={{ borderRadius: '0.5em' }} severity="error">
									{dict[masterError] ? dict[masterError][lang] : masterError ? masterError : ' '}
								</Alert>
							)}
						</Stack>

						<Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }} fullWidth>
							{dict[dictKey.LOGIN][lang]}
						</Button>

						<Box display="flex" justifyContent="center" alignItems="center">
							<Typography component={'span'} variant="body2">
								<Link
									to="forgot-password"
									style={{ color: theme.palette.primary.main, textDecoration: 'none' }}>
									Zapomenuté heslo?
								</Link>
							</Typography>
						</Box>
					</Box>
				</Box>
			</Container>
		</Box>
	);
};

export default Login;
