import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { forgotPasswordSchema, resetPasswordSchema } from '../../utils/joiSchema';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, Container, Avatar, Typography, TextField, Stack, Alert, Button } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { dict, dictKey } from '../../translate/translate-dictionary';
import { useAppContext } from '../../context/appContext/appContext';
import { useUpdate } from '../../utils/useUpdate';
import Loading from '../../components/pageContent/Loading';
import PageAlert from '../../components/pageContent/PageAlert';

const theme = createTheme();

type Inputs = {
	password: string;
	passwordConfirm: string;
};

type Props = {};

const ResetPassword = (props: Props) => {
	const params = useParams();
	const [userEmail, setUserEmail] = useState<string>('');
	const [token, setToken] = useState<string | undefined>(params ? params.token : '');
	const [masterError, setMasterError] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [infoResponse, setInfoResponse] = useState<string>('');
	const { state, loginUser } = useAppContext();
	const navigate = useNavigate();
	const lang = state.lang;

	const {
		isLoading: isLoadingUpdate,
		data: dataUser,
		update: updateUser,
	} = useUpdate<{
		status: string;
		error?: string;
		message?: string;
		userEmail?: string;
	}>('');

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<Inputs>({
		defaultValues: {
			password: '',
			passwordConfirm: '',
		},
		resolver: joiResolver(resetPasswordSchema),
		mode: 'onChange',
		delayError: 2000,
	});

	// pokud je uzivatel prihlasen presmeruji ho
	useEffect(() => {
		if (state.user) {
			navigate('/');
		}
	}, [state, navigate]);

	useEffect(() => {
		if (infoResponse) {
			setTimeout(() => {
				setIsLoading(true);
				setTimeout(() => {
					setIsLoading(false);
					navigate('/');
				}, 500);
			}, 3000);
		}
	}, [infoResponse]);

	useEffect(() => {
		(async () => {
			const response = await updateUser({ token }, 'POST', `/auth/get-user-reset-password`);

			if (response?.status === 'success') {
				if (response.userEmail) setUserEmail(response.userEmail);
			}

			if (!response) {
				return setMasterError('ERROR_NETWORK');
			}

			if (response.status === 'error') {
				if (response.message) setMasterError(response.message);
			}

			if (response.error) {
				setMasterError(response.error);
			}
		})();
	}, [token, updateUser]);

	const onSubmit = (data: Inputs) => {
		setMasterError('');

		(async () => {
			setInfoResponse('');
			setMasterError('');
			const response = await updateUser(
				{ password: data.password, passwordConfirm: data.passwordConfirm },
				'POST',
				`/auth/reset-password/${token}`
			);

			if (response?.status === 'success') {
				if (response.message) setInfoResponse(response.message);
			}

			if (!response) {
				return setMasterError('ERROR_NETWORK');
			}

			if (response.status === 'error') {
				if (response.message) setMasterError(response.message);
			}

			if (response.error) {
				setMasterError(response.error);
			}
		})();
	};

	return (
		<>
			<Loading isLoading={isLoading || isLoadingUpdate} />

			{!userEmail && (
				<PageAlert>
					<>
						<Typography component={'h4'} variant="h6">
							Bohužel tento odkaz již vypršel.
						</Typography>
						<Typography component={'h4'} variant="body2">
							<Link to="/login" style={{ color: theme.palette.primary.main, textDecoration: 'none' }}>
								Zpět na přihlášení
							</Link>
						</Typography>
					</>
				</PageAlert>
			)}

			{userEmail && (
				<>
					<Box
						sx={{
							borderRadius: '1em',
							marginX: '10px',
							backgroundColor: 'white',
							width: '100%',
							maxWidth: 450,
						}}>
						{/* <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={state.isLoading}>
					<CircularProgress color="inherit" />
				</Backdrop> */}

						<Container component="main" maxWidth="xs">
							<Box
								sx={{
									mt: 2,
									pt: 0,
									padding: 5,
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
									backgroundColor: 'white',
									width: '100%',
								}}>
								<Avatar sx={{ m: 2, mt: 0, bgcolor: 'secondary.main' }}>
									<LockOutlinedIcon />
								</Avatar>
								<Typography component="h1" variant="h5" sx={{ textAlign: 'center' }}>
									Zapomenuté heslo
								</Typography>

								<Typography component="h4" variant="h6" sx={{ textAlign: 'center', marginT: 1 }}>
									{userEmail}
								</Typography>

								{/* noValidate */}
								<Box
									component="form"
									noValidate
									onSubmit={handleSubmit(onSubmit)}
									sx={{ mt: 1, width: '100%' }}>
									<TextField
										{...register('password')}
										required
										margin="normal"
										fullWidth
										label="Heslo"
										type="password"
										error={Object.hasOwn(errors, 'password')}
										helperText={
											errors?.password?.message
												? dict[errors.password.message]
													? dict[errors.password.message][lang]
													: errors?.password?.message
												: ' '
										}
									/>

									<TextField
										{...register('passwordConfirm')}
										required
										margin="normal"
										fullWidth
										label="Heslo potvrzení"
										type="password"
										error={Object.hasOwn(errors, 'passwordConfirm')}
										helperText={
											errors?.passwordConfirm?.message
												? dict[errors.passwordConfirm.message]
													? dict[errors.passwordConfirm.message][lang]
													: errors?.passwordConfirm?.message
												: ' '
										}
									/>

									<Stack sx={{ width: '100%', marginTop: 2 }} spacing={3}>
										{masterError && (
											<Alert sx={{ borderRadius: '0.5em' }} severity="error">
												{dict[masterError]
													? dict[masterError][lang]
													: masterError
													? masterError
													: ' '}
											</Alert>
										)}
										{infoResponse && (
											<Alert sx={{ borderRadius: '0.5em' }} severity="success">
												{dict[infoResponse]
													? dict[infoResponse][lang]
													: infoResponse
													? infoResponse
													: ' '}
											</Alert>
										)}
									</Stack>

									<Button
										type="submit"
										variant="contained"
										sx={{ mt: 3, mb: 2 }}
										fullWidth
										disabled={!!infoResponse}>
										Odeslat
									</Button>

									<Box display="flex" justifyContent="center" alignItems="center">
										<Typography component={'span'} variant="body2">
											<Link
												to="/login"
												style={{ color: theme.palette.primary.main, textDecoration: 'none' }}>
												Přihlášení
											</Link>
										</Typography>
									</Box>
								</Box>
							</Box>
						</Container>
					</Box>
				</>
			)}
		</>
	);
};

export default ResetPassword;
