import { CircularProgress, IconButton, TableCell, useMediaQuery, useTheme } from '@mui/material';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReportWorkers from '../../pdf/ReportWorkers';

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import { useState } from 'react';

type Props = {
	name: number;
	type: string;
	idDownload: number;
	onPdfDownload: (id: number, type: string) => Promise<void>;
};

const TableCellDownloadIcon = ({ name, onPdfDownload, idDownload, type }: Props) => {
	const [isLoading, setIsLoading] = useState(false);

	const theme = useTheme();
	const sizeButton = useMediaQuery(theme.breakpoints.up('sm')) ? 'small' : 'medium';

	const handleDownload = async (id: number, type: string): Promise<void> => {
		setIsLoading(true);
		await onPdfDownload(idDownload, type);
		setIsLoading(false);
	};

	return (
		<>
			<TableCell align="center" width={30} size="small" sx={{ paddingX: '5px' }}>
				{!isLoading && (
					<IconButton
						sx={{
							borderRadius: 1,
							border: 0,
							color: theme.palette.error.dark,
							borderColor: theme.palette.error.dark,
							padding: '0.15em',
						}}
						aria-label="stáhnout"
						size="small"
						onClick={() => handleDownload(idDownload, type)}>
						<FileDownloadOutlinedIcon fontSize={sizeButton} />
					</IconButton>
				)}
				{isLoading && (
					<IconButton
						sx={{
							borderRadius: 1,
							border: 0,
							color: theme.palette.error.dark,
							borderColor: theme.palette.error.dark,
							padding: '0.15em',
						}}
						aria-label="stáhnout"
						size="small">
						<CircularProgress size={18} />
					</IconButton>
				)}
			</TableCell>
		</>
	);
};

export default TableCellDownloadIcon;
