import { Button } from '@mui/material';
import React from 'react';

type Props = {
	title?: string;
	onReportConfirm?: () => void;
};

const ButtonDocumentReport = ({ title = 'Vytvořit vyúčtování', onReportConfirm }: Props) => {
	return (
		<Button
			fullWidth={true}
			color={'success'}
			variant={'contained'}
			sx={{ marginTop: 2, marginBottom: 4 }}
			onClick={onReportConfirm}>
			{title}
		</Button>
	);
};

export default ButtonDocumentReport;
