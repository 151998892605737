import { TableCell, useTheme } from '@mui/material';
import React from 'react';

type Props = {
	align: 'inherit' | 'left' | 'center' | 'right' | 'justify' | undefined;
	size?: 'small' | 'medium' | undefined;
	mW?: number;
	w?: string | number;
	pX?: string | number;
	isActive?: boolean | undefined;
	background?: string | undefined;
	colspan?: number;
	children: JSX.Element | string | number | undefined;
};

const TableCellView = ({ align, size, mW, w, pX, isActive, background, colspan, children }: Props) => {
	const theme = useTheme();

	const colorCellInActive = theme.palette.error.dark;
	return (
		<TableCell
			colSpan={colspan ? colspan : undefined}
			align={align}
			size={size ? size : 'small'}
			sx={{
				minWidth: mW ? mW : undefined,
				paddingX: pX ? pX : undefined,
				width: w ? w : 'auto',
				backgroundColor:
					background === undefined
						? undefined
						: background === 'subTitle'
						? theme.palette.grey[300]
						: undefined,
				color: isActive === undefined ? undefined : !isActive ? colorCellInActive : undefined,
			}}>
			{children}
		</TableCell>
	);
};

export default TableCellView;
