import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';

type Props = {
	onOpen: boolean;
	nameDelete?: string;
	handleClose: () => void;
	onDelete: () => void;
};

const DocumentReportDeleteDialog = ({ onOpen, handleClose, onDelete, nameDelete }: Props) => {
	return (
		<Dialog
			open={onOpen}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description">
			<DialogTitle>{`Odstranění vyúčtování ${nameDelete}`}</DialogTitle>
			<DialogContent>
				{`Vyúčtování bude odstraněno. Budou smazány zafixované ceny a vyúčtované záznamy se prevedou do stavu "uzavřené a nevyúčtované". Opravdu chcete toto vyúčtování odstranit?`}
			</DialogContent>

			<DialogActions>
				<Button onClick={handleClose}>Zrušit</Button>
				<Button onClick={onDelete} autoFocus>
					Ano, odstranit vyúčtování
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DocumentReportDeleteDialog;
