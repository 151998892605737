import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';

type Props = {
	onOpen: boolean;
	nameDelete?: string;
	recordType?: string;
	handleClose: () => void;
	onDelete: () => void;
};

const DeleteDialog = ({ onOpen, nameDelete, handleClose, onDelete, recordType }: Props) => {
	return (
		<Dialog
			open={onOpen}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description">
			{recordType === 'logo' && <DialogTitle>Opravdu chcete toto logo smazat?</DialogTitle>}
			{recordType === 'avatar' && <DialogTitle>Opravdu chcete tento avatar smazat?</DialogTitle>}
			{!recordType && <DialogTitle>Opravdu chcete tento záznam vymazat?</DialogTitle>}
			{nameDelete && <DialogContent>{nameDelete}</DialogContent>}

			<DialogActions>
				<Button onClick={handleClose}>Zrušit</Button>
				<Button onClick={onDelete} autoFocus>
					Ano, smazat
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DeleteDialog;
