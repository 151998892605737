export enum Lang {
	cs = 'cs',
	en = 'en',
}

export enum ButtonVariant {
	contained = 'contained',
	outlined = 'outlined',
	text = 'text',
}

export enum ButtonColor {
	inherit = 'inherit',
	primary = 'primary',
	secondary = 'secondary',
	success = 'success',
	error = 'error',
	info = 'info',
	warning = 'warning',
}

export enum ButtonSize {
	small = 'small',
	medium = 'medium',
	large = 'large',
}

export enum ButtonStyleType {
	submit = 'submit',
	button = 'button',
	reset = 'reset',
}

export const drawerSpacing = 30; //240px => theme.spacing(30)

export const WORKING_HOURS = 12;

// nastaveni krokovani minut,
export const MINUTES_STEP_TIME_PICKER = 15;
